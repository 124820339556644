import { Slider, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";

/**
 *
 * @param props
 * @param props.classes
 * @param props.label
 * @param props.value
 * @param props.setValue
 * @param props.min
 * @param props.max
 * @param props.step
 * @returns {JSX.Element|null}
 */

export default function sliderDelayOnEnd(props) {
  if (_.isNil(props.value)) {
    return null;
  }
  const [value, setValue] = useState(props.value);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);
  return (
    <div className={props.classes.editorItem}>
      <Typography id="discrete-slider" gutterBottom>
        {_.get(props, "label")}
      </Typography>
      <Slider
        onKeyDown={(event) => {
          event.stopPropagation();
        }}
        style={{ width: "100%" }}
        value={value}
        aria-labelledby="discrete-slider"
        disabled={props.disabled}
        valueLabelDisplay="auto"
        step={props.step}
        min={props.min}
        max={props.max}
        onChange={(event, newValue) => {
          if (props.userPermissions["update"] === false) {
            props.createSnackbar("You do not have permission to update, contact your administrator.", "warning", false);
            return;
          }
          setValue(newValue);
          props.setValue(newValue);
        }}
      />
    </div>
  );
}
