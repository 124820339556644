import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { Card, Typography } from "@material-ui/core";
import { CardContent } from "@mui/material";
import EditorCaption from "./editorCaption";
import EditorWordManager from "./editorWordManager";
import SelectorFontFamily from "./selectorFontFamily";
import SliderGeneric from "./sliderGeneric";
import SelectorFontStyle from "./selectorFontStyle";
import SelectorColor from "./selectorColor";
import SwitchGeneric from "./switchGeneric";
import _ from "lodash";
import DisabledByDefaultRoundedIcon from "@mui/icons-material/DisabledByDefaultRounded";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { SimpleTreeView, TreeItem } from "@mui/x-tree-view";
import OuterComponentIcon from "../../images/gameConfig/Outer-component-icon.svg";
import InnerComponentIcon from "../../images/gameConfig/Inner-component-icon.svg";
import EditorGridArea from "./editorGridArea";

const useStyles = makeStyles({
  card: { margin: "5px" },
  imageSelector: { width: "100%" },
  imageSetSelector: { width: "100%" },
  cardOrderSelector: { width: "100%" },
  containerVersionSettingsDiv: {
    margin: "0px",
    height: "100%",
    overflowY: "auto"
  },
  textField: {
    width: "150%"
    // fontFamily: "monospace",
  }
});

function EndIcon(props) {
  return <DisabledByDefaultRoundedIcon {...props} sx={{ opacity: 0.3 }} />;
}

/**
 * @param props
 * @param props.classes
 * @param props.selectedBase
 * @param props.selectedBase.localizationSettings
 * @param props.selectedBase.localizationSettings.components
 * @param props.setSelectedVersionPathValue
 * @param props.selectedVersion
 * @returns {null}
 */
export default function containerLocalizationTreeView(props) {
  console.debug(`Selected language during rendering of containerLocalization: ${props.selectedLanguage}`);

  const [components, setComponents] = useState(_.get(props, "selectedModifiedBase.localizationSettings.components"));
  const classes = useStyles();
  let [sections, setSections] = useState([]);
  const [firstTime, setFirstTime] = useState(false);
  const [expandedItems, setExpandedItems] = useState([]);

  const handleToggle = (event, itemIds) => {
    setExpandedItems(itemIds); // Set the expanded item IDs (array)
  };

  useEffect(() => {
    function checkForCheerBoard(component) {
      //TODO: Can be improved
      if (component.id === "cheerBoardSelector1") {
        const selectedCheerBoard = _.get(
          props,
          `selectedVersion.gameConfig.components.${component.id}.selectedCheerBoard`
        );
        if (selectedCheerBoard === "Ribbon Type 1") {
          _.set(component, "gameComponentId", "cheerBoard1");
        } else if (selectedCheerBoard === "Ribbon Type 2") {
          _.set(component, "gameComponentId", "cheerBoardWithTexture1");
        }
      }
      if (_.has(component, "children") && component.children.length > 0) {
        component.children.map((child) => {
          checkForCheerBoard(child);
        });
      }
    }

    if (!_.isNil(components)) {
      components.map((component) => {
        checkForCheerBoard(component);
      });
    }
  }, []);

  useEffect(() => {
    if (_.isNil(props.selectedModifiedBase)) return;
    // console.log("modified base", props.selectedModifiedBase);
    const cloneComponents = _.cloneDeep(_.get(props.selectedModifiedBase.localizationSettings, "components", []));
    const sections = [];
    cloneComponents.forEach((component) => {
      const sectionText = _.get(component, "section", "Default");
      const subSectionText = _.get(component, "subSection", null);
      if (_.isNil(component.parentKey)) {
        let index;
        component.colorIndex = 1;
        if (sections.length === 0) {
          const obj = { caption: sectionText, components: [] };
          if (!_.isNil(subSectionText)) {
            obj.subSections = [{ caption: subSectionText, children: [] }];
          }
          sections.push(obj);
          index = 0;
        } else {
          index = _.findIndex(sections, (e) => {
            return e.caption === sectionText;
          });
          if (index < 0) {
            const obj = { caption: sectionText, components: [] };
            if (!_.isNil(subSectionText)) {
              obj.subSections = [{ caption: subSectionText, children: [] }];
            }
            sections.push(obj);
            index = sections.length - 1;
          }
        }
        if (index > -1) {
          if (!_.isNil(sections[index].subSections)) {
            const subIndex = _.findIndex(sections[index].subSections, (e) => {
              return e.caption === subSectionText;
            });
            if (subIndex < 0) {
              sections[index].subSections.push({ caption: subSectionText, children: [component] });
            } else {
              sections[index].subSections[subIndex].children.push(component);
            }
          } else {
            sections[index].components.push(component);
          }
        }
      } else {
        const parentComponent = _.find(cloneComponents, (e) => {
          if (component.isGroup) {
            return e.key === component.parentKey && e.id === component.id;
          } else {
            return e.key === component.parentKey;
          }
        });

        component.colorIndex = parentComponent.colorIndex + 1;
        parentComponent.children.push(component);
      }
    });
    // console.log("enters here components", cloneComponents, sections);
    setSections(sections);
  }, [props.selectedModifiedBase]);

  if (!_.has(props, "selectedModifiedBase.localizationSettings")) {
    return null;
  }
  let returnedData = localStorage.getItem("newgetValLocal");
  let baseIDSession = JSON.parse(localStorage.getItem("selectedBaseSessionLocal"));
  if (baseIDSession === _.get(props, "selectedBase._id")) {
    if (!firstTime) {
      setFirstTime(true);
    }
  } else {
    localStorage.setItem("newgetValLocal", JSON.stringify([]));
  }

  const defaultCardColor = "#474747";
  const cardColors = ["#313131", "#474747", "#545454", "#656565"];

  function getCardColor(index) {
    return cardColors[index >= cardColors.length ? cardColors.length - 1 : index];
  }

  function renderTooltip(tooltip, isVisible, marginLeft) {
    return isVisible && tooltip ? (
      <div>
        <Tooltip title={tooltip} aria-label="add">
          <HelpOutlineIcon style={{ marginLeft: marginLeft }} color={"primary"} />
        </Tooltip>
      </div>
    ) : null;
  }

  function getTextWidth(ttt) {
    let text = document.createElement("span");
    document.body.appendChild(text);

    (text.style.font = " Roboto"), "Helvetica", "Arial", "sans-serif";
    text.style.fontSize = "0.9285714285714286rem";
    text.style.height = "auto";
    text.style.width = "auto";
    text.style.letterSpacing = "0.00938em";
    text.style.position = "absolute";
    text.style.whiteSpace = "no-wrap";
    text.innerHTML = ttt;

    let width = Math.ceil(text.clientWidth);

    document.body.removeChild(text);
    return width;
  }

  function renderTreeView(component, isSubSection = false) {
    return (
      <TreeItem
        itemId={`${component.caption}_${component.id}`}
        label={
          <div style={{ display: "flex", alignItems: "center", minHeight: "32px" }}>
            <img
              src={isSubSection ? OuterComponentIcon : InnerComponentIcon}
              alt="custom icon"
              style={{ width: "18px", height: "18px", marginRight: "8px" }}
            />
            {component.caption}
          </div>
        }
        style={{
          backgroundColor: getCardColor(component.colorIndex),
          marginTop: "5px",
          marginBottom: "5px",
          borderRadius: "4px"
        }}
      >
        <Card
          className={props.classes.card}
          key={`${component.id}-${component.caption.replace(/\s/g, "")}`}
          style={{
            backgroundColor: isSubSection ? "#ffffff00" : getCardColor(component.colorIndex),
            boxShadow: "none"
          }}
        >
          <CardContent
            sx={{
              "&:last-child": {
                paddingBottom: isSubSection ? "0px" : "24px"
              },
              padding: isSubSection ? "0px" : "16px"
            }}
          >
            <Typography key={`${component.id}_caption`} variant={"h6"} gutterBottom>
              {component.caption}
            </Typography>
            {_.get(component, "settings", []).length > 0 &&
              component.settings.map(
                /**
                 * @param setting
                 * @param setting.setting
                 * @returns {JSX.Element}
                 */
                (setting) => {
                  //setting, caption, type

                  const value = _.get(
                    props,
                    `selectedVersion.gameConfig.components.${component.gameComponentId}.localization.${props.selectedLanguage}.${setting.path}`,
                    _.get(
                      props,
                      `selectedVersion.gameConfig.components.${component.id}.localization.${props.selectedLanguage}.${setting.path}`
                    )
                  );
                  const path = _.has(component, "gameComponentId")
                    ? `gameConfig.components.${component.gameComponentId}.localization.${props.selectedLanguage}.${setting.path}`
                    : `gameConfig.components.${component.id}.localization.${props.selectedLanguage}.${setting.path}`;

                  const setValue = (value) => {
                    console.log("debounce value");
                    let path;
                    if (_.has(component, "gameComponentId")) {
                      path = `gameConfig.components.${component.gameComponentId}.localization.${props.selectedLanguage}.${setting.path}`;
                    } else {
                      path = `gameConfig.components.${component.id}.localization.${props.selectedLanguage}.${setting.path}`;
                    }
                    props.setSelectedVersionPathValue({
                      path,
                      value
                    });
                  };

                  const setValueSliderGeneric = (p) => {
                    props.setSelectedVersionPathValue(p);
                  };
                  const isVisible = true;
                  if (setting.editorType === "editorGridArea") {
                    let tooltipLength = getTextWidth(setting.caption);

                    return (
                      <div key={`${component.id}.${setting.path}`}>
                        {renderTooltip(setting.tooltip, isVisible, tooltipLength + 5)}

                        <EditorGridArea
                          id={`${component.id}.${setting.path}`}
                          componentID={component.id}
                          tooltipLength={-28}
                          tooltip={setting.tooltip}
                          classes={props.classes}
                          caption={setting.caption}
                          isDialogEditEnabled={true}
                          isTextFieldVisible={false}
                          value={value}
                          setValue={setValue}
                          buttonText={setting.caption}
                          userPermissions={props.userPermissions}
                          createSnackbar={props.createSnackbar}
                          isVisible={isVisible}
                        />
                      </div>
                    );
                  } else {
                    return (
                      <div key={`${component.id}.${setting.path}`}>
                        {setting.editorType === "caption" && (
                          <div key={`${component.id}.${setting.path}`}>
                            {renderTooltip(setting.tooltip, isVisible, getTextWidth(setting.caption) + 5)}
                            {console.log("setting.tooltip", setting.tooltip)}
                            <EditorCaption
                              id={`${component.id}.${setting.path}`}
                              key={`${component.id}.${setting.path}`}
                              classes={props.classes}
                              value={value}
                              tooltipLength={-24}
                              tooltip={setting.tooltip}
                              setValue={setValue}
                              label={setting.caption}
                              userPermissions={props.userPermissions}
                              createSnackbar={props.createSnackbar}
                              isVisible={isVisible}
                            ></EditorCaption>
                          </div>
                        )}
                        {setting.editorType === "editorTextArea" && (
                          <div key={`${component.id}.${setting.path}`}>
                            {renderTooltip(setting.tooltip, isVisible, getTextWidth(setting.caption) + 5)}
                            <EditorCaption
                              id={`${component.id}.${setting.path}`}
                              key={`${component.id}.${setting.path}`}
                              multiline={true}
                              rows={2}
                              rowsMax={10}
                              tooltip={setting.tooltip}
                              tooltipLength={-24}
                              classes={props.classes}
                              value={value}
                              setValue={setValue}
                              label={setting.caption}
                              userPermissions={props.userPermissions}
                              createSnackbar={props.createSnackbar}
                              isVisible={isVisible}
                            ></EditorCaption>
                          </div>
                        )}
                        {setting.editorType === "wordManager" && (
                          <div key={`${component.id}.${setting.path}`}>
                            {renderTooltip(setting.tooltip, isVisible, getTextWidth(setting.caption) + 5)}
                            <EditorWordManager
                              id={`${component.id}.${setting.path}`}
                              key={`${component.id}.${setting.path}`}
                              tooltip={setting.tooltip}
                              tooltipLength={-23}
                              classes={props.classes}
                              value={value}
                              setValue={setValue}
                              selectedGame={props.selectedGame}
                              selectedBase={props.selectedBase}
                              label={setting.caption}
                              seperator={setting.seperator || ""}
                              userPermissions={props.userPermissions}
                              createSnackbar={props.createSnackbar}
                              isVisible={isVisible}
                            ></EditorWordManager>
                          </div>
                        )}
                        {setting.editorType === "selectorFontFamily" && (
                          <div key={`${component.id}.${setting.path}`}>
                            {renderTooltip(setting.tooltip, isVisible, getTextWidth(setting.caption) + 5)}
                            <SelectorFontFamily
                              id={`${component.id}.${setting.path}`}
                              key={`${component.id}.${setting.path}`}
                              tooltip={setting.tooltip}
                              tooltipLength={-24}
                              classes={props.classes}
                              value={value}
                              setValue={setValue}
                              label={setting.caption}
                              editorSubType={setting.editorSubType}
                              selectedVersion={props.selectedVersion}
                              userPermissions={props.userPermissions}
                              createSnackbar={props.createSnackbar}
                              isVisible={isVisible}
                            ></SelectorFontFamily>
                          </div>
                        )}
                        {setting.editorType === "sliderGeneric" && (
                          <div key={`${component.id}.${setting.path}`}>
                            {renderTooltip(setting.tooltip, isVisible, getTextWidth(setting.caption) + 5)}
                            <SliderGeneric
                              id={`${component.id}.${setting.path}`}
                              key={`${component.id}.${setting.path}`}
                              classes={props.classes}
                              tooltip={setting.tooltip}
                              tooltipLength={-28}
                              editorSubType={setting.editorSubType}
                              value={value}
                              min={setting.min}
                              max={setting.max}
                              step={setting.step}
                              path={path}
                              setValue={(pathToSet, value) => {
                                setValueSliderGeneric({
                                  path: pathToSet,
                                  value
                                });
                              }}
                              label={setting.caption}
                              userPermissions={props.userPermissions}
                              createSnackbar={props.createSnackbar}
                              isVisible={isVisible}
                            ></SliderGeneric>
                          </div>
                        )}
                        {setting.editorType === "selectorFontStyle" && (
                          <div key={`${component.id}.${setting.path}`}>
                            {renderTooltip(setting.tooltip, isVisible, getTextWidth(setting.caption) + 5)}
                            <SelectorFontStyle
                              id={`${component.id}.${setting.path}`}
                              key={`${component.id}.${setting.path}`}
                              classes={props.classes}
                              value={value}
                              tooltip={setting.tooltip}
                              tooltipLength={-26}
                              setValue={setValue}
                              label={setting.caption}
                              userPermissions={props.userPermissions}
                              createSnackbar={props.createSnackbar}
                              isVisible={isVisible}
                            ></SelectorFontStyle>
                          </div>
                        )}
                        {setting.editorType === "selectorColor" && (
                          <div>
                            {renderTooltip(setting.tooltip, isVisible, getTextWidth(setting.caption) + 5)}
                            <SelectorColor
                              id={`${component.id}.${setting.path}`}
                              key={`${component.id}.${setting.path}`}
                              classes={props.classes}
                              mode={"String"}
                              tooltip={setting.tooltip}
                              tooltipLength={-28}
                              value={value}
                              setValue={setValue}
                              label={setting.caption}
                              userPermissions={props.userPermissions}
                              createSnackbar={props.createSnackbar}
                              isVisible={isVisible}
                            ></SelectorColor>
                          </div>
                        )}

                        {setting.editorType === "switchGeneric" && (
                          <div>
                            {renderTooltip(setting.tooltip, isVisible, getTextWidth(setting.caption) + 5)}
                            <SwitchGeneric
                              id={`${component.id}.${setting.path}`}
                              key={`${component.id}.${setting.path}`}
                              classes={props.classes}
                              value={value}
                              tooltip={setting.tooltip}
                              tooltipLength={-22}
                              setValue={setValue}
                              label={setting.caption}
                              userPermissions={props.userPermissions}
                              createSnackbar={props.createSnackbar}
                              isVisible={isVisible}
                            ></SwitchGeneric>
                          </div>
                        )}
                      </div>
                    );
                  }
                }
              )}
            {_.get(component.children, "length") > 0
              ? component.children.map((child) => {
                  if (isSubSection) {
                    return (
                      <Card
                        className={props.classes.card}
                        key={`${component.id}-${component.caption.replace(/\s/g, "")}`}
                        style={{
                          overflow: "unset"
                        }}
                      >
                        {renderTreeView(child)}
                      </Card>
                    );
                  } else {
                    return renderTreeView(child);
                  }
                })
              : null}
          </CardContent>
        </Card>
      </TreeItem>
    );
  }

  return (
    <SimpleTreeView
      aria-label="customized"
      defaultExpandedItems={["1", "3"]}
      slots={{
        expandIcon: ArrowRightIcon,
        collapseIcon: ArrowDropDownIcon,
        endIcon: EndIcon
      }}
      sx={{ overflowX: "hidden", flexGrow: 1 }}
      expandedItems={expandedItems}
      onExpandedItemsChange={handleToggle}
    >
      {!_.isNil(sections) &&
        sections.map((section) => {
          return (
            <Card
              className={props.classes.card}
              key={section.caption}
              style={{
                backgroundColor:
                  _.findIndex(expandedItems, (e) => {
                    return e === section.caption;
                  }) > -1
                    ? getCardColor(0)
                    : defaultCardColor
              }}
            >
              <TreeItem
                itemId={section.caption}
                label={
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontWeight: "bold",
                      minHeight: "32px"
                    }}
                  >
                    <img
                      src={OuterComponentIcon}
                      alt="custom icon"
                      style={{ width: "18px", height: "18px", marginRight: "8px" }}
                    />
                    {section.caption}
                  </div>
                }
              >
                {_.get(section.subSections, "length") > 0
                  ? section.subSections.map((subSection) => {
                      return renderTreeView(subSection, true);
                    })
                  : section.components.map((component) => {
                      return renderTreeView(component);
                    })}
              </TreeItem>
            </Card>
          );
        })}
    </SimpleTreeView>
  );
}
