import React from "react";
import { Button, Dialog, DialogTitle } from "@material-ui/core";
import { getServerUri } from "../modules/uriUtility";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";

const useClasses = makeStyles({
  dialogSound: {
    margin: 10,
  },
});
/**
 *
 * @param {Boolean} props.isOpen is it open?
 * @param {Function} props.setIsOpen set is open to true or false
 * @param props.soundToDelete
 * @param props.selectedSoundID
 * @param props.sounds
 * @param props.setSounds
 * @param props.createSnackbar
 * @param props.selectSound
 * @param props.deleteSoundFromSounds
 * @returns {JSX.Element}
 */
export default function dialogSoundDelete(props) {
  const classes = useClasses();
  return (
    <Dialog
      open={props.isOpen}
      onClose={() => {
        props.setIsOpen(false);
      }}
    >
      <DialogTitle>Delete sound?</DialogTitle>
      <div className={classes.dialogSound}>
        <Button
          label={"OK"}
          onClick={(event) => {
            console.log("deleting sound");
            fetch(getServerUri("sound/deleteOne"), {
              method: "DELETE",
              headers: {
                "content-type": "application/x-www-form-urlencoded",
              },
              body: new URLSearchParams({
                accessToken: localStorage.getItem("session.accessToken"),
                soundID: props.soundToDelete._id,
                fileName: _.get(props.soundToDelete, "file"),
              }),
            })
              .then((response) => {
                if (response.status === 200) {
                  let newSounds = _.cloneDeep(props.sounds);
                  newSounds.splice(
                    newSounds.indexOf(newSounds.filter((sound) => sound._id === props.soundToDelete._id)[0]),
                    1
                  );
                  props.deleteSoundFromSounds(props.soundToDelete._id);
                  props.setSounds(newSounds);
                  if (newSounds.length > 0) {
                    if (props.soundToDelete._id === props.selectedSoundID) {
                      props.selectSound(newSounds[0]._id);
                    }
                  } else {
                    props.selectSound("");
                  }
                  console.log("deleted sound");
                  props.createSnackbar("Sound deleted", "success");
                  props.setIsOpen(false);
                } else {
                  response
                    .text()
                    .then((responseText) => {
                      console.log(responseText);
                    })
                    .catch(console.error);
                }
              })
              .catch((err) => {
                console.log("fetch error");
                console.error(err);
                props.createSnackbar("An error occurred deleting the sound.", "error");
              });
            props.setIsOpen(false);
          }}
        >
          OK
        </Button>
        <Button
          label={"Cancel"}
          onClick={() => {
            props.setIsOpen(false);
          }}
        >
          Cancel
        </Button>
      </div>
    </Dialog>
  );
}
