import React from "react";
import { useState, useEffect } from "react";
import {
  Dialog,
  Box,
  TextField,
  Button,
  DialogTitle,
  DialogContent,
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
  DialogActions,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { getServerUri } from "../modules/uriUtility";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

let useStyles = makeStyles({});

/**
 *
 * @param props
 * @returns {JSX.Element|null}
 */
export default function dialogPlayableErrors(props) {
  const [isOpen, setOpen] = useState(false);
  const [errors, setErrors] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    if (props.playableErrors.length === 0) {
      setOpen(false);
    } else {
      setErrors(props.playableErrors);
      setOpen(true);
    }
  }, [props.playableErrors]);

  function renderError(error, index) {
    let { path, file } = error;

    if (_.isNil(path) || _.isNil(file)) {
      console.log("invalid error parameters");
      return;
    }

    let parts = path.split(".");
    let imagePath = parts[parts.length - 1];

    return (
      <ListItem key={index}>
        <ListItemIcon>
          <ErrorOutlineIcon />
        </ListItemIcon>
        <ListItemText>
          <Typography>{`File is not exist in path: "${imagePath}", file name: "${file}"`}</Typography>
        </ListItemText>
      </ListItem>
    );
  }

  return (
    <Dialog
      open={isOpen}
      disableBackdropClick
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle
        style={{
          textAlign: "center",
        }}
      >
        Playable Errors
      </DialogTitle>
      <DialogContent>
        {!_.isNil(errors) && Array.from(errors.values()).map((error, index) => renderError(error, index))}
      </DialogContent>
      <DialogActions
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button
          color="primary"
          variant="contained"
          label={"Refresh"}
          style={{
            margin: "0.5rem",
          }}
          disabled={!isOpen}
          onClick={() => {
            setOpen(false);
            setErrors(null);
            props.doRefresh((prev) => prev + 1);
            props.onRefresh();
          }}
        >
          Refresh
        </Button>
      </DialogActions>
    </Dialog>
  );
  return null;
}
