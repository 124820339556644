import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Divider, Card, CardContent, Typography, IconButton, Chip } from "@material-ui/core";
import ShowMore from "../../../images/documentationImages/showmore.png";
import Hide from "../../../images/documentationImages/hide.png";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "80px",
  },
  imgContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    borderRadius: "4px",
    border: "1px solid #727171",
    boxShadow: "rgb(0 0 0 / 20%) 1px 1px 10px 0px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px",
  },
  img: {
    borderRadius: "4px",
    width: "100%",
    height: "auto",
  },
  main: {
    align: "center",
    textAlign: "left",
  },
  chip: {
    float: "left",
    marginRight: "90%",
    marginBottom: "5%",
  },
  context: {
    textAlign: "justify",
  },
}));

const ShowHideFeat = () => {
  const classes = useStyles();

  return (
    <div className={classes.main}>
      <Card className={classes.container}>
        <CardContent>
          <div className={classes.imgContainer}>
            <img src={ShowMore} className={classes.img} alt="" />
            <Divider />
            <img src={Hide} className={classes.img} alt="" />
          </div>
          <br />
          <Typography variant="h6">Dashboard Has Collapsable Settings Now!</Typography>
          <Typography paragraph>28.10.2021</Typography>
          <Chip className={classes.chip} label="Standard" variant="outlined" />
          <Typography paragraph className={classes.context}>
            We offer customizable settings as much as the client needs, and because of that the settings can be quite
            long to scroll. We decided to fix this issue with adding a collapsable feature to our settings. With this
            feature the settings comes into view as collapsed, desired settings can be uncollapsed by clicking the Show
            More button and recollapsed by clicking the Hide button which replaces the Show More at the bottom of the
            chosen settings.
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
};

export default ShowHideFeat;
