import React from "react";
import { useState, useEffect } from "react";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { makeStyles } from "@material-ui/core/styles";
import Navbar from "./navbarAccountSettings";
import AccountInformation from "./accountInformation";
import Billing from "./billing";
import UserManagement from "./userManagement";
import Pricing from "./pricing";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: "#404040",
    top: "46px",
  },
}));

/**
 * @param props.createSnackbar
 * @param props.userPermissions
 */
export default function dialogAccountSettings(props) {
  const classes = useStyles();
  const [isOpen, setOpen] = useState(false);
  const [selectedPage, setSelectedPage] = useState(0);

  useEffect(() => {
    if (props.isAccountInfoVisible) {
      toggleDrawer(props.isAccountInfoVisible);
    }
  }, [props.isAccountInfoVisible]);

  const toggleDrawer = (isOpen) => {
    setOpen(isOpen);
    props.setIsAccountInfoVisible(isOpen);
  };

  const list = (anchor) => (
    <div id="dialogAccountSettings" role="presentation" onKeyDown={() => {}}>
      <Navbar
        setSelectedPage={setSelectedPage}
        isAdmin={props.isAdmin}
        createSnackbar={props.createSnackbar}
        selectedVersion={props.selectedVersion}
        setIsVersionBeingUpdatedFalse={props.setIsVersionBeingUpdatedFalse}
      />
      {selectedPage === 0 && (
        <AccountInformation
          userCompany={props.userCompany}
          userName={props.userName}
          numberOfBases={props.numberOfBases}
          plans={props.plans}
          createSnackbar={props.createSnackbar}
          getPlanIndex={props.getPlanIndex}
        />
      )}
      {selectedPage === 1 && <Billing userCompany={props.userCompany} createSnackbar={props.createSnackbar} />}
      {selectedPage === 2 && (
        <UserManagement
          userCompany={props.userCompany}
          userName={props.userName}
          userEmail={props.userEmail}
          games={props.games}
          plans={props.plans}
          createSnackbar={props.createSnackbar}
          userPermissions={props.userPermissions}
          classes={props.classes}
          getPlanIndex={props.getPlanIndex}
        />
      )}
      {selectedPage === 3 && <Pricing plans={props.plans} classes={props.classes} />}
    </div>
  );

  return (
    <div>
      {
        <React.Fragment key={"bottom"}>
          <SwipeableDrawer
            anchor={"bottom"}
            open={isOpen}
            onClose={() => toggleDrawer(false)}
            onOpen={() => toggleDrawer(true)}
            classes={{ paper: classes.paper }}
          >
            {list("bottom")}
          </SwipeableDrawer>
        </React.Fragment>
      }
    </div>
  );
}
