import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Divider, Card, CardContent, Typography, IconButton, Chip } from "@material-ui/core";
import TestPlayableImage from "../../../images/documentationImages/testPlayable.png";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "80px",
  },
  imgContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    borderRadius: "4px",
    border: "1px solid #727171",
    boxShadow: "rgb(0 0 0 / 20%) 1px 1px 10px 0px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px",
  },
  img: {
    borderRadius: "4px",
    width: "100%",
    height: "auto",
  },
  main: {
    align: "center",
    textAlign: "left",
  },
  chip: {
    float: "left",
    marginRight: "90%",
    marginBottom: "5%",
  },
  context: {
    textAlign: "justify",
  },
}));

const TestPlayableFeature = () => {
  const classes = useStyles();

  return (
    <div className={classes.main}>
      <Card className={classes.container}>
        <CardContent>
          <div className={classes.imgContainer}>
            <img src={TestPlayableImage} className={classes.img} alt="" />
            <Divider />
          </div>
          <br />
          <Typography variant="h6">Test Playable export feature is now available on dashboard !</Typography>
          <Typography paragraph>09.09.2022</Typography>
          <Chip className={classes.chip} label="Standard" variant="outlined" />
          <Typography paragraph className={classes.context}>
            With test playable export, you can export your creatives unlimited and test your ads before your final cut
            through ad networks. Simply mark the test playable choice and click download button and that’s all!
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
};

export default TestPlayableFeature;
