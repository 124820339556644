import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress, IconButton, Typography } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import SelectorPeriod from "./selectorPeriod";
import _ from "lodash";
import { GetApp } from "@material-ui/icons";
import { getServerUri } from "../../modules/uriUtility";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    marginLeft: "21.5rem",
    paddingBottom: "1rem",
    backgroundColor: "#404040",
  },
  content: {
    flexGrow: 1,
    backgroundColor: "#404040",
    color: "white",
    align: "center",
    textAlign: "center",
    marginTop: "0.5%",
    marginRight: "1rem",
  },
  billingWrapper: {
    padding: "2rem 2rem 2rem 2rem",
    width: "100%",
  },
  billingInfo: {
    display: "flex",
    justifyContent: "flex-start",
    margin: "2rem 0rem 2rem 0rem",
  },
  card: {
    margin: "0rem 0rem 0.5rem 0.5rem",
    display: "flex",
    justifyContent: "space-between",
  },
  titles: {
    width: "33%",
    float: "left",
    margin: "0rem",
  },
  titleText: {
    margin: "0rem 0rem 0.5rem 0rem",
  },
  periodTitle: {
    fontSize: 26,
    fontWeight: 540,
  },
  downloadReceiptButton: {
    display: "flex",
    alignItems: "flex-start",
    margin: "1rem",
  },
  cardTitleText: {
    fontSize: 26,
    fontWeight: 540,
  },
}));

const months = [
  "",
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default function billing(props) {
  const [period, setPeriod] = useState(3);
  const [periods, setPeriods] = useState(_.get(props, "userCompany.subscription.periods"));
  const [isDownloading, setIsDownloading] = useState(false);
  const [periodsIsDownloading, setPeriodsIsDownloading] = useState({ "": false });

  const classes = useStyles();

  useEffect(() => {
    let newPeriods = _.cloneDeep(periods);
    newPeriods.forEach((period) => {
      _.set(period, "isHidden", true);
    });
    newPeriods.sort(function (a, b) {
      return new Date(b.startDate) - new Date(a.startDate);
    });
    setPeriods(newPeriods);
  }, []);

  function parseDate(date) {
    const timeZoneSetDate = new Date(date);
    return (
      months[timeZoneSetDate.getMonth() + 1].substr(0, 3) +
      " " +
      parseInt(timeZoneSetDate.getDate()) +
      ", " +
      timeZoneSetDate.getFullYear()
    );
  }

  function toggleIsHidden(selectedPeriod) {
    let newPeriods = _.cloneDeep(periods);
    const selectedPeriodInPeriods = newPeriods.filter(
      (filteredPeriod) => filteredPeriod.startDate === selectedPeriod.startDate
    )[0];
    newPeriods[newPeriods.indexOf(selectedPeriodInPeriods)].isHidden =
      !newPeriods[newPeriods.indexOf(selectedPeriodInPeriods)].isHidden;
    setPeriods(newPeriods);
  }

  function getReceiptPdfFileName(companyName, startDate, endDate) {
    const startDateTimeZoneSet = new Date(startDate);
    const endDateTimeZoneSet = new Date(endDate);
    let createdFileName = `${props.userCompany.name}`;
    createdFileName += `_${months[startDateTimeZoneSet.getMonth() + 1].substr(0, 3)}${startDateTimeZoneSet
      .getDate()
      .toString()
      .padStart(2, "0")}_${startDateTimeZoneSet.getFullYear()}`;
    createdFileName += `_${months[endDateTimeZoneSet.getMonth() + 1].substr(0, 3)}${endDateTimeZoneSet
      .getDate()
      .toString()
      .padStart(2, "0")}_${endDateTimeZoneSet.getFullYear()}`;
    createdFileName += `.pdf`;
    return createdFileName;
  }

  function handleDownloadReceipt(selectedPeriod) {
    const pdfFileName = getReceiptPdfFileName(props.userCompany.name, selectedPeriod.startDate, selectedPeriod.endDate);

    return fetch(getServerUri("company/downloadReceipt"), {
      method: "POST",
      headers: {
        "content-type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams({
        accessToken: localStorage.getItem("session.accessToken"),
        fileName: String(pdfFileName),
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          response
            .json()
            .then((object) => {
              if (_.has(object, "completion")) {
                console.info("Progress Received: ", object);
              } else if (_.has(object, "file")) {
                console.log("calling window.open:" + object.file);
                window.location = object.file;
                props.createSnackbar("Receipt downloaded, check your downloads folder.", "success");
              }
            })
            .catch((err) => {
              props.createSnackbar("Error downloading the receipt, contact us for information.", "error");
              console.log(err);
            });
        } else if (response.status === 400) {
          response
            .text()
            .then((textResponse) => {
              console.log(textResponse);
              if (textResponse === "FILE_DOES_NOT_EXIST") {
                props.createSnackbar("We do not have the receipt for this period yet.", "error");
              }
            })
            .catch(console.error);
        } else {
          response
            .json()
            .then((object) => {
              props.createSnackbar("Error downloading the receipt, contact us for information.", "error");
              console.log("non 200 response:");
              console.dir(object);
            })
            .catch((err) => {
              props.createSnackbar("Error downloading the receipt, contact us for information.", "error");
              console.log("can't parse response as json");
              console.log(err);
            });
        }
      })
      .catch((err) => {
        props.createSnackbar("Error downloading the receipt, contact us for information.", "error");
        console.error("FETCH ERROR");
        console.error(err);
      });
  }

  return (
    <div className={classes.root} id="Billing">
      <main className={classes.content}>
        <Card className={classes.card}>
          <div className={classes.billingWrapper}>
            <Typography className={classes.cardTitleText} align="left">
              Billing
            </Typography>
            <div className={classes.billingInfo}>
              <div className={classes.titles}>
                <Typography className={classes.titleText} variant="h6" align="left">
                  Current Plan
                </Typography>
                <Typography align="left" gutterBottom>
                  {_.get(props, "userCompany.subscription.currentPlan")}
                </Typography>
              </div>
              <div className={classes.titles}>
                <Typography className={classes.titleText} variant="h6" align="left">
                  Subscription Start Date
                </Typography>
                <Typography align="left" gutterBottom>
                  {parseDate(_.get(props, "userCompany.subscription.subscriptionStartDate"))}
                </Typography>
              </div>
              <div className={classes.titles}>
                <Typography className={classes.titleText} variant="h6" align="left">
                  Subscription End Date
                </Typography>
                <Typography align="left" gutterBottom>
                  {parseDate(_.get(props, "userCompany.subscription.subscriptionEndDate"))}
                </Typography>
              </div>
            </div>
          </div>
        </Card>
        <SelectorPeriod setPeriod={setPeriod} />
        {!_.isNil(periods) &&
          periods
            .filter((selectedPeriod) => {
              return periods.indexOf(selectedPeriod) < period;
            })
            .map((selectedPeriod) => {
              return (
                <Card key={selectedPeriod.startDate} className={classes.card}>
                  <div className={classes.billingWrapper} onClick={() => toggleIsHidden(selectedPeriod)}>
                    <Typography className={classes.periodTitle} align="left">
                      {parseDate(selectedPeriod.startDate)} - {parseDate(selectedPeriod.endDate)}
                    </Typography>
                    {!selectedPeriod.isHidden && (
                      <div className={classes.billingInfo}>
                        <div className={classes.titles}>
                          <Typography className={classes.titleText} variant="h6" align="left">
                            Date
                          </Typography>
                          <Typography align="left" gutterBottom>
                            {parseDate(selectedPeriod.startDate)} - {parseDate(selectedPeriod.endDate)}
                          </Typography>
                        </div>
                        <div className={classes.titles}>
                          <Typography className={classes.titleText} variant="h6" align="left">
                            Description
                          </Typography>
                          <Typography align="left" gutterBottom>
                            Active Playable: {selectedPeriod.activePlayableCount}
                          </Typography>
                        </div>
                        <div className={classes.titles}>
                          <Typography className={classes.titleText} variant="h6" align="left">
                            Amount
                          </Typography>
                          <Typography align="left" gutterBottom>
                            ${selectedPeriod.amount}
                            {selectedPeriod.amount === 0
                              ? ""
                              : selectedPeriod.isPaid
                              ? " (Paid on " + parseDate(selectedPeriod.paymentDate) + ")"
                              : " (Not Paid)"}
                          </Typography>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className={classes.downloadReceiptButton}>
                    <CircularProgress
                      color={"secondary"}
                      style={{
                        textAlign: "center",
                        display: periodsIsDownloading[selectedPeriod.startDate] ? "block" : "none",
                      }}
                    ></CircularProgress>
                    <IconButton
                      disabled={isDownloading}
                      style={{
                        textAlign: "center",
                        display: periodsIsDownloading[selectedPeriod.startDate] ? "none" : "block",
                      }}
                      onClick={() => {
                        setIsDownloading(true);

                        let newPeriodsIsDownloading = _.clone(periodsIsDownloading);
                        newPeriodsIsDownloading[selectedPeriod.startDate] = true;
                        setPeriodsIsDownloading(newPeriodsIsDownloading);

                        handleDownloadReceipt(selectedPeriod).then(() => {
                          newPeriodsIsDownloading[selectedPeriod.startDate] = false;
                          setPeriodsIsDownloading(newPeriodsIsDownloading);
                          setIsDownloading(false);
                        });
                      }}
                    >
                      <GetApp variant="contained"></GetApp>
                    </IconButton>
                  </div>
                </Card>
              );
            })}
      </main>
    </div>
  );
}
