import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextGameObjectEditor from "./editorTextGameObject";
import { Card, CardContent, Typography, Button } from "@material-ui/core";

const useStyles = makeStyles({});

/**
 * @param props.selectedVersion
 * @param props.resetGameConfig
 * @param props.setSelectedVersionPathValue
 * @returns {JSX.Element}
 */
export default function containerTextGameObject(props) {
  const classes = useStyles();
  return (
    <form
      noValidate
      autoComplete="off"
      style={{
        height: "100%",
        overflowY: "auto",
      }}
    >
      {Object.entries(_.get(props, `selectedVersion.gameConfig.gameObjects`, {})).map(([gameObjectName, detail]) => {
        if (!detail.hasOwnProperty("modes")) {
          console.log("modes are not set");
          console.dir({ gameObjectName, detail, gameObjects: props.gameObjects });
          throw { message: "Customizable game object record doesn't have modes property", detail };
        }
        return detail.modes.map((mode) => {
          if (mode === "text") {
            return (
              <div key={gameObjectName}>
                <Card className={props.classes.card}>
                  <CardContent>
                    <Typography className={classes.title} variant={"h6"} gutterBottom>
                      {_.get(props, "selectedBase.gameObjectLabels." + gameObjectName, "NOT ENTERED")}
                    </Typography>
                    <TextGameObjectEditor
                      gameObjectName={gameObjectName}
                      gameObjectDetail={detail}
                      classes={props.classes}
                      setPathValue={(p) => {
                        props.setSelectedVersionPathValue({
                          path: `gameConfig.gameObjects.${gameObjectName}.${p.path}`,
                          value: p.value,
                        });
                      }}
                    ></TextGameObjectEditor>
                  </CardContent>
                </Card>
              </div>
            );
          }
        });
      })}
    </form>
  );
}
