import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Divider, Card, CardContent, Typography, IconButton, Chip } from "@material-ui/core";
import FolderCreationImage from "../../../images/documentationImages/folderCreation.png";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "80px",
  },
  imgContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    borderRadius: "4px",
    border: "1px solid #727171",
    boxShadow: "rgb(0 0 0 / 20%) 1px 1px 10px 0px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px",
  },
  img: {
    borderRadius: "4px",
    width: "100%",
    height: "auto",
  },
  main: {
    align: "center",
    textAlign: "left",
  },
  chip: {
    float: "left",
    marginRight: "90%",
    marginBottom: "5%",
  },
  context: {
    textAlign: "justify",
  },
}));

const FolderCreation = () => {
  const classes = useStyles();

  return (
    <div className={classes.main}>
      <Card className={classes.container}>
        <CardContent>
          <div className={classes.imgContainer}>
            <img src={FolderCreationImage} className={classes.img} alt="" />
            <Divider />
          </div>
          <br />
          <Typography variant="h6">Folder create feature !</Typography>
          <Typography paragraph>03.07.2022</Typography>
          <Chip className={classes.chip} label="Standard" variant="outlined" />
          <Typography paragraph className={classes.context}>
            Users of the creative dashboard can now create folders and keep their campaigns in order. You can
            create/rename/delete folders just like versions. Our versions and folders support nested features too. You
            can move your versions by drag and drop. In this way, our clients can have customized order to keep track of
            their creatives. For more features, you can read our documentation.
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
};

export default FolderCreation;
