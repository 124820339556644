import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogTitle } from "@material-ui/core";
import { getServerUri } from "../modules/uriUtility";
import { TextField } from "@material-ui/core";
import ReactGA from "react-ga";
import ReactGA4 from "react-ga4";

import _ from "lodash";

import { makeStyles } from "@material-ui/core/styles";
ReactGA.initialize("UA-186063268-1", {
  debug: false,
  gaOptions: {
    userId: localStorage.getItem("user.id"),
  },
});
ReactGA4.initialize("G-TB46JQ70PR");

const useClasses = makeStyles({
  dialogDelete: {
    margin: 10,
  },
});
/**
 *
 * @param {Boolean} props.isOpen is it open?
 * @param {Function} props.setIsOpen set is open to true or false
 * @param {Function} props.deleteVersionFromView delete the version from view
 * @param {String} props.selectedVersionID the selected version id
 * @param props.createSnackbar
 * @returns {JSX.Element}
 */
export default function dialogVersionDuplicate(props) {
  let [newVersionTitle, setNewVersionTitle] = useState(
    props.selectedVersion ? props.selectedVersion.title + " copy" : ""
  );
  let [versionObject, setVersionObject] = useState({});
  let [title, setTitle] = useState(newVersionTitle);
  let [buttonClick, setButtonClick] = useState(false);

  let [versionHie, setVersionHierarchy] = useState(1);
  let [previousSelectedVersion, setPreviousSelectedVersion] = useState(
    props.selectedVersion ? props.selectedVersion._id : ""
  );
  let [parentOfSelectedVersion, setParentOfSelectedVersion] = useState(0);

  useEffect(() => {
    setNewVersionTitle(String(_.get(props, "selectedVersion.title")));
  }, [props.selectedVersion]);
  useEffect(() => {
    setNewVersionTitle(props.selectedVersion ? props.selectedVersion.title + " copy" : "");
    setTitle(props.selectedVersion ? props.selectedVersion.title + " copy" : "");
  }, [props.isOpen]);

  function getCurrentDate(separator = "") {
    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    let time = newDate.getHours();
    let minute = newDate.getMinutes() < 10 ? "0" + newDate.getMinutes() : newDate.getMinutes();
    return `${date < 10 ? `0${date}` : `${date}`}${separator}${
      month < 10 ? `0${month}` : `${month}`
    }${separator}${year} ${time}:${minute}`;
  }
  useEffect(() => {
    if (Object.keys(versionObject).length !== 0) {
      fetchVersionHierarchy(versionObject);
    }
  }, [versionObject]);

  useEffect(() => {
    if (versionHie != 1) {
      var baseFolder = new Promise((resolve, reject) => {
        fetch(getServerUri("version/updateHierarchy"), {
          method: "POST",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          body: new URLSearchParams({
            accessToken: localStorage.getItem("session.accessToken"),
            baseID: props.selectedBase._id,
            versionHierarchy: JSON.stringify(versionHie),
            companyID: props.companyID,
          }),
        }).then((response) => {
          if (response.status === 200) {
            props.setVersionHierarchy(versionHie);
          }
        });
      });
    }
  }, [versionHie]);
  const handleKeyPress = (event) => {
    if (event.key === "Enter" || event.key === "Return") {
      handleButtonClick();
    }
  };
  function resetSearchBar() {
    const searchBar = document.getElementById("searchBar");

    if (searchBar) {
      props.setSearchTerm(Math.floor(Math.random() * 10000) + 1);
      // searchBar.value = "";
    }
  }
  const handleButtonClick = () => {
    resetSearchBar();
    setPreviousSelectedVersion(props.selectedVersion._id);

    props.onClose(false);
    fetch(getServerUri("version/duplicate"), {
      method: "POST",
      headers: {
        "content-type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams({
        accessToken: localStorage.getItem("session.accessToken"),
        versionID: String(props.selectedVersion._id),
        title: title,
      }),
    })
      .then((response) => {
        //select the created version but load versions first
        if (response.status === 200) {
          props.fetchVersions();
          let newSelectedVersion;
          response.json().then((object) => {
            newSelectedVersion = object.insertedVersion;
            console.trace("toolbarGamePreview - version duplicate");
            props.setSelectedVersion(newSelectedVersion);
            setVersionObject(newSelectedVersion);
          });
          ReactGA.event({
            category: "Preview",
            action: "Version Duplicated Clicked",
            label:
              "game: " +
              props.selectedGame.title +
              ", base: " +
              props.selectedBase.title +
              "originalVersion: " +
              props.selectedVersion.title +
              ", duplicatedVersion: " +
              newVersionTitle,
            dimension1: localStorage.getItem("user.id"),
          });
          ReactGA4.event({
            category: "Preview",
            action: "Version Duplicated Clicked",
            label:
              "game: " +
              props.selectedGame.title +
              ", base: " +
              props.selectedBase.title +
              "originalVersion: " +
              props.selectedVersion.title +
              ", duplicatedVersion: " +
              newVersionTitle,
            dimension1: localStorage.getItem("user.id"),
          });
          setNewVersionTitle("");
          props.createSnackbar("Successfully duplicated", "success");
        } else if (response.status === 401) {
          props.createSnackbar("You are not authorized to insert, contact your administrator.", "warning");
          response
            .text()
            .then((textResponse) => {
              console.log({ message: "error inserting", textResponse });
            })
            .catch(console.error);
        }
      })
      .catch((err) => {
        console.error(err);
        props.createSnackbar("An error occurred when inserting a new version.", "error");
      });
  };
  function fetchVersionHierarchy(version) {
    //take the version hierarchy

    fetch(getServerUri("version/getHierarchy"), {
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({
        accessToken: localStorage.getItem("session.accessToken"),
        baseID: props.selectedBase._id,
        companyID: props.companyID,
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((json) => {
            let parentOfSelected = "";

            json.versionHierarchy.map((versionInner) => {
              if (versionInner.id == previousSelectedVersion) {
                parentOfSelected = versionInner.parent;

                return;
              }
            });
            var tmpObj = {
              "id": version._id,
              "parent": parentOfSelected,
              //"parent": 0,
              //"parent": json.versionHierarchy.filter(ver => ver.id = props.selectedVersion._id)[0].parent,

              "text": version.title,
              "data": {
                "fileType": "text",
                "createdDate": getCurrentDate("."),
                "updateDate": getCurrentDate("."),
              },
            };
            //add new object to the versionHierarchy
            json.versionHierarchy.push(tmpObj);
            let newVersionTree = {};
            newVersionTree["tree"] = json.versionHierarchy;
            setVersionHierarchy(newVersionTree);
          });
        } else if (response.status === 401) {
          props.createSnackbar("You are not authorized to update, contact your administrator.", "warning");
          response
            .text()
            .then((textResponse) => {
              console.log({ message: "error updating", textResponse });
            })
            .catch(console.error);
        } else {
          response
            .text()
            .then((textResponse) => {
              console.log({ message: "error renaming", textResponse });
              props.createSnackbar("An error occurred when renaming a new version.", "error");
            })
            .catch(console.error);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  if (_.isNil(String(_.get(props, "selectedVersion._id")))) return null;
  return (
    <Dialog
      id="versionDuplicate_dialog"
      open={props.isOpen}
      onClose={() => {
        props.onClose(false);
      }}
    >
      <DialogTitle id="versionDuplicate_dialogTitle">Name of the new version</DialogTitle>
      <div id="versionDuplicate1" style={{ padding: "10px", textAlign: "center" }}>
        <TextField
          id="versionDuplicateTextfield"
          style={{ width: "100%" }}
          value={newVersionTitle}
          onKeyPress={handleKeyPress}
          inputProps={{ maxLength: 101, autoFocus: true }}
          onChange={(event) => {
            if (event.target.value.length < 101) {
              setTitle("New Version");

              var a = event.target.value;
              var a = a.replace(/\s+/g, " ").replace(/^\s/, "").replace(/\s$/, "");

              if (a.length == 0) {
                setNewVersionTitle(event.target.value);
                setTitle("New Version");
                setButtonClick(false);
              } else {
                setTitle(event.target.value);
                setNewVersionTitle(event.target.value);
                setButtonClick(false);
              }
            } else {
              setButtonClick(true);
              props.createSnackbar("No more than 100 characters can be entered", "error");
            }
          }}
        ></TextField>
        <Button id="dialogVersionDuplicate_button" disabled={buttonClick} onClick={handleButtonClick}>
          Save
        </Button>
      </div>
    </Dialog>
  );
}
