import React, { useEffect, useState } from "react";
import { DataGrid } from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/core/styles";
import "../modules/stringPolyFill";
import _ from "lodash";
import { getServerUri } from "../modules/uriUtility";

/**
 * @param props.versions
 * @param props.setVersions
 * @param props.setValue
 * @param props.selectedVersion
 * @param props.setSelectedVersionPathValueWithVersion
 * @param props.userPermissions
 * @param props.setIsDialogCircularProgressOpen
 * @returns {JSX.Element}
 */
export default function selectorVersion(props) {
  const [versionViews, setVersionViews] = useState([]);

  const [isSelectionDisabled, setIsSelectionDisabled] = useState(false);

  const columns = [
    {
      field: "title",
      headerName: "Title",
      width: 160,
      renderCell: (props) => (
        <span className="version-title" style={{ width: "100%", height: "100%", outline: "none" }} title={props.value}>
          {props.value}
        </span>
      ),
    }, //, width: "200px"
    {
      field: "createdDate",
      headerName: "Created Date",
      width: 160,
      sortComparator: (v1, v2, param1, param2) => param1.data.createdDateTime - param2.data.createdDateTime,
    }, //width: "200px"
    {
      field: "updatedDate",
      headerName: "Updated Date",
      width: 160,
      sortComparator: (v1, v2, param1, param2) => param1.data.updatedDateTime - param2.data.updatedDateTime,
    }, //width: "200px"
  ];

  function viewVersions(versionRecords) {
    if (_.isNil(versionRecords)) {
      return [];
    }
    const versionRecordsView = versionRecords.map((versionRecord) => {
      const createdDate = new Date(versionRecord.dateCreated);
      const updatedDate = new Date(versionRecord.dateUpdated);
      return {
        id: String(versionRecord._id),
        title: versionRecord.title,
        createdDate: `${createdDate.getDate()}.${
          createdDate.getMonth() + 1
        }.${createdDate.getFullYear()}  ${createdDate.getHours()}:${String(createdDate.getMinutes()).padStart(2, "0")}`,
        createdDateTime: createdDate.getTime(),
        updatedDate:
          versionRecord.dateUpdated &&
          `${updatedDate.getDate()}.${
            updatedDate.getMonth() + 1
          }.${updatedDate.getFullYear()}  ${updatedDate.getHours()}:${String(updatedDate.getMinutes()).padStart(
            2,
            "0"
          )}`,
        updatedDateTime: updatedDate.getTime(),
      };
    });
    versionRecordsView.forEach((versionRecordView) => {
      if (versionRecordView.hasOwnProperty("id") && versionRecordView.id === undefined) {
        console.log("error is here");
      }
    });
    return versionRecordsView;
  }

  useEffect(() => {
    setVersionViews(viewVersions(props.versions));
    setTimeout(handleActiveRow, 100);
  }, [props.versions]);

  useEffect(() => {
    setVersionViews(viewVersions(props.versions));
    setTimeout(handleActiveRow, 100);
  }, [props.selectedVersion]);

  const handleActiveRow = () => {
    const { selectedVersion } = props;
    if (selectedVersion) {
      const selectedRows = document.querySelectorAll("#versionSelectorDiv .MuiDataGrid-row");
      Array.from(selectedRows).filter((r) => {
        r.classList.remove("Mui-selected");
      });
      const selectedRow = Array.from(selectedRows).find((r) => r.getAttribute("data-id") === selectedVersion._id);
      if (selectedRow) selectedRow.classList.add("Mui-selected");
    }
  };

  function getVersionByID(versionIDSString) {
    //console.debug("getting version by id", versionIDSString);
    const found = props.versions.filter((version) => {
      return String(version._id) === versionIDSString;
    });
    if (_.size(found) > 0) {
      return found[0];
    } else {
      throw { message: "could not find version by id: ", versionIDSString };
    }
  }

  if (_.size(props.versions) < 1) return null;
  return (
    <div id={"versionSelectorDiv"} style={{ flex: 1 }}>
      <DataGrid
        id={"versionSelectorDataGrid"}
        rows={versionViews}
        disableSelectionOnClick={isSelectionDisabled}
        columns={columns}
        hideFooterRowCount={true}
        hideFooterSelectedRowCount={true}
        onSelectionChange={(e) => {
          if (isSelectionDisabled) return;
          setIsSelectionDisabled(true);
          const selection = _.get(e, "rows", []);
          if (
            !_.isNil(props.selectedVersion) &&
            _.get(props.selectedVersion, "lastUpdatingUserID", "-1") === localStorage.getItem("user.id") &&
            props.userPermissions["update"] &&
            Array.isArray(selection) &&
            selection.length > 0 &&
            selection[0].id !== props.selectedVersion._id
          ) {
            props.setIsDialogCircularProgressOpen(true);
            const createdLanguages = _.get(props, "selectedVersion.createdLanguages") ? _.get(props, "selectedVersion.createdLanguages"): ["en"];

            fetch(getServerUri("version/updateOne"), {
              method: "POST",
              headers: { "content-type": "application/x-www-form-urlencoded" },
              body: new URLSearchParams({
                accessToken: localStorage.getItem("session.accessToken"),
                versionID: String(_.get(props, "selectedVersion._id")),
                gameConfig: JSON.stringify(_.get(props, "selectedVersion.gameConfig")),
                recorderSettings: JSON.stringify(_.get(props, "selectedVersion.recorderSettings")),
                createdLanguages: JSON.stringify(createdLanguages)

                //isBeingUpdated: String(false)
              }),
            })
              .then((response) => {
                if (response.status === 200) {
                  console.log({ message: "version updated" });
                  const newVersion = _.cloneDeep(props.selectedVersion);
                  //_.set(newVersion, 'isBeingUpdated', false);
                  const newVersions = _.cloneDeep(props.versions);
                  for (let i = 0; i < newVersions.length; i++)
                    if (newVersions[i]._id === props.selectedVersion._id) {
                      newVersions[i] = newVersion;
                    }
                  props.setVersions(newVersions);
                  props.setValue(getVersionByID(selection[0].id));
                  setIsSelectionDisabled(false);
                  props.setIsDialogCircularProgressOpen(false);
                } else if (response.status === 401) {
                  //props.createSnackbar("You are not authorized to update, contact your administrator.", "warning");
                  response
                    .text()
                    .then((textResponse) => {
                      console.log({ message: "error updating", textResponse });
                    })
                    .catch(console.log);
                  setIsSelectionDisabled(false);
                  props.setIsDialogCircularProgressOpen(false);
                }
              })
              .catch((err) => {
                console.log(err);
                props.setIsDialogCircularProgressOpen(false);
                setIsSelectionDisabled(false);
              });
          } else {
            if (Array.isArray(selection) && selection.length > 0) {
              const newSelectedVersion = _.cloneDeep(getVersionByID(selection[0].id));
              props.setValue(newSelectedVersion);
            }
            setIsSelectionDisabled(false);
          }
        }}
      />
    </div>
  );
}
