import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, Typography } from "@material-ui/core";
import Imgoverview from "../../../images/documentationImages/imgoverview.png";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    backgroundColor: "#404040",
  },
  content: {
    backgroundColor: "#404040",
    color: "white",
    align: "center",
    textAlign: "left",
    marginTop: "1%",
    marginLeft: "5%",
    marginRight: "20%",
  },
  card: {
    margin: "5%",
  },
  img: {
    borderRadius: "4px",
    border: "1px solid #727171",
    boxShadow: "rgb(0 0 0 / 20%) 1px 1px 10px 0px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px",
    width: "100%",
    height: "auto",
  },
}));

const Overview = () => {
  const classes = useStyles();

  return (
    <div className={classes.root} id="Overview">
      <main className={classes.content}>
        <Card>
          <CardContent>
            <Typography variant="h5">Overview</Typography>
            <br />
            <Typography paragraph>
              The Playin Dashboard has four main parts. From left to right; Game List, Version List, Preview Field,
              Settings. Dashboard offers to review playable ads in real time. Playables can be adjusted and customized
              through settings. Settings changes can be seen in real time on the screen. Preview field shows the
              playable file. Through here, playables can be played and tested. Device icons above the preview field,
              adjust the size and orientation of the playable. Using device icons, playables’ view on different screens
              and orientations can be tested and adjusted. Unlimited versions can be created under one game. New bases
              can be created and added to the game to have different playables type for one game.
            </Typography>
            <Typography paragraph>
              The Playin Dashboard supports these ad platforms: AppLovin, Facebook, Google, IronSource, Tiktok,
              Mintegral, Unity, Vungle.
            </Typography>
            <img className={classes.img} src={Imgoverview} alt="" />
          </CardContent>
        </Card>
      </main>
    </div>
  );
};

export default Overview;
