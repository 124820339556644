import { useLayoutEffect, useRef } from "react";

function useResizeObserver(callback) {
  const ref = useRef(null);

  useLayoutEffect(() => {
    const domElement = ref?.current;

    if (!domElement) {
      return;
    }

    const observer = new ResizeObserver(entries => {
      callback(entries[0], domElement);
    });

    observer.observe(domElement);

    return () => {
      observer.disconnect(domElement);
    }
  }, [callback]);

  return ref;
}

export default useResizeObserver;