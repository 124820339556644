import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Typography } from "@material-ui/core";

const drawerWidth = 240;
const headerHeight = 46;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    top: headerHeight,
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    backgroundColor: "#404040",
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: "#404040",
    align: "center",
    textAlign: "left",
    top: headerHeight,
    marginTop: "1%",
    marginLeft: "5%",
    marginRight: "20%",
    color: "white",
  },
  card: {
    margin: "5%",
  },
}));

const Title = () => {
  const classes = useStyles();

  return (
    <div className={classes.root} id="Dashboard">
      <CssBaseline />
      <main className={classes.content}>
        <div className={classes.toolbar}>
          <Typography variant="h4">Playin Dashboard</Typography>
          <br />
          <Typography paragraph>
            The Playin Dashboard is a great tool for playable creators. All playable ads can be stored, customized and
            downloaded any time for the supported ad platforms.
          </Typography>
        </div>
      </main>
    </div>
  );
};

export default Title;
