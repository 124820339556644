import { getServerUri } from "./uriUtility";
import * as _ from "lodash";

export default function fetcher({ servicePath, sendAccessToken, urlEncodedParams, shouldLog, callback }) {
  const fetchInit = {
    method: "POST",
    headers: {
      "content-type": "application/x-www-form-urlencoded",
    },
  };
  if (sendAccessToken) {
    fetchInit.body = new URLSearchParams({ accessToken: localStorage.getItem("session.accessToken") });
  }
  Object.entries(urlEncodedParams).forEach(([key, value]) => {
    fetchInit.body.append(key, value);
  });
  const backendUri = getServerUri(servicePath);
  fetch(backendUri, fetchInit)
    .then((response) => {
      let responseClone = response.clone();
      response
        .json()
        .then((responseJson) => {
          if (response.status === 200) {
            callback(responseJson);
          } else {
            if (shouldLog) {
              console.log("RESPONSE IS NOT 200");
              console.dir(responseJson);
            }
          }
        })
        .catch((err) => {
          console.log("Json parse failed. Reading as text.");
          console.dir({ servicePath: getServerUri(servicePath), fetchInit });
          console.log(err);

          responseClone
            .text()
            .then((responseText) => {
              if (shouldLog) {
                console.log("Fetched text as a result:" + responseText);
              }
            })
            .catch((err) => {
              console.log("Could not read the result as text.");
              console.error(err);
            });
        });
    })
    .catch((err) => {
      console.error("could not fetch");
      console.error(err);
      console.dir(fetchInit);
    });
}
