import React from "react";
import { useState, useEffect } from "react";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, Typography, IconButton } from "@material-ui/core";
import clsx from "clsx";
import { Close } from "@material-ui/icons";
import ShowHideFeat from "./ShowHideFeat";
import RenameVersion from "./RenameVersion";
import FolderCreation from "./FolderCreation";
import PlayableLink from "./PlayableLink";
import UndoRedo from "./UndoRedo";
import CompressImage from "./CompressImage";
import FilterComponents from "./FilterComponents";
import TestPlayableFeature from "./TestPlayableFeature";
import TitleAdjust from "./TitleAdjust";
import VersionPath from "./VersionPath";

const columnWidth = 61;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
  },
  list: {
    width: "400px",
    right: 0,
  },
  fullList: {
    width: "auto",
    right: 0,
  },
  paper: {
    width: "400px",
    left: columnWidth,
    backgroundColor: "#404040",
    height: `calc(100% - 46px)`,
    textAlign: "center",
  },
  header: {
    position: "fixed",
    width: "400px",
  },
  content: {
    marginLeft: "20px",
    marginRight: "5px",
  },
  card: {
    padding: "15px",
  },
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  Typography: {
    fontWeight: "bold",
  },
}));

export default function Features(props) {
  const classes = useStyles();
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    if (props.isFeaturesOpen) {
      toggleFeatures(props.isFeaturesOpen);
    }
  }, [props.isFeaturesOpen]);

  const toggleFeatures = (isOpen) => {
    setOpen(isOpen);
    props.setFeaturesOpen(isOpen);
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "bottom",
      })}
      role="presentation"
      onKeyDown={() => toggleFeatures(false)}
    >
      <div className={classes.header}>
        <Card>
          <CardContent>
            <div className={classes.title}>
              <Typography variant="h5">What's New?</Typography>
              <IconButton onClick={() => toggleFeatures(false)}>
                <Close />
              </IconButton>
            </div>
          </CardContent>
        </Card>
      </div>
      <div className={classes.content}>
        <VersionPath></VersionPath>
        <TitleAdjust></TitleAdjust>
        <CompressImage></CompressImage>
        <UndoRedo></UndoRedo>
        <PlayableLink></PlayableLink>
        <TestPlayableFeature />
        <FolderCreation />
        <FilterComponents />
        <ShowHideFeat />
        <RenameVersion />
      </div>
    </div>
  );

  return (
    <div>
      {
        <React.Fragment key={"bottom"}>
          <SwipeableDrawer
            anchor={"bottom"}
            open={isOpen}
            onClose={() => toggleFeatures(false)}
            onOpen={() => toggleFeatures(true)}
            classes={{ paper: classes.paper }}
          >
            {list("bottom")}
          </SwipeableDrawer>
        </React.Fragment>
      }
    </div>
  );
}
