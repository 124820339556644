import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Grid, Icon, Dialog, DialogTitle, Box, TextField, Button } from "@material-ui/core";
import SelectorGame from "../components/selectorGame";
import BasePicker from "../components/selectorBase";
import GamePreview from "../components/containerGamePreview";
import RequireLogin from "../components/requireLogin";
import GameConfig from "../components/containerGameConfig";
import VersionPicker from "../components/selectorVersion";
import { getServerUri } from "../modules/uriUtility";
import ImageAccountItem from "../components/imageAccountItem";
import DialogAccountInfo from "../components/dialogAccounntInfo";
import { Add } from "@material-ui/icons";
import DialogVersionInsert from "../components/dialogVersionInsert";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import ToolbarGamePreviewOrientation from "../components/toolbarGamePreviewOrientation";
import _ from "lodash";
import { applyPreview } from "./home";

const useStyles = makeStyles((theme) => ({
  gamePreviewDiv: {},
}));

export default function previewPage(props) {
  let [isPreviewLoaded, setIsPreviewLoaded] = useState(false);
  let [selectedVersion, setSelectedVersion] = useState();
  //load data

  useEffect(() => {
    const requestURI = getServerUri("version/findOne");
    const requestInit = {
      method: "POST",
      headers: {
        "content-type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams({ accessToken: localStorage.getItem("session.accessToken") }),
    };
    fetch("")
      .then((response) => {})
      .catch(console.error);
  }, [props.versionID]);
  const classes = useStyles();
  const circularProgress = (
    <div id={"circularProgressDiv"} className={classes.circularProgressDiv}>
      <CircularProgress variant={"indeterminate"}></CircularProgress>
    </div>
  );
  const preview = <GamePreview setIsPreviewLoaded={setIsPreviewLoaded} applyPreview={applyPreview}></GamePreview>;
  if (!_.isNil(selectedVersion) && isPreviewLoaded) {
    return preview;
  } else {
    return circularProgress;
  }
}
