import React, { useState, useEffect } from "react";

import {
  Button,
  Card,
  CardContent,
  Typography,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import {getServerUri} from "../modules/uriUtility";
import Tooltip from "@mui/material/Tooltip";

const useStyles = makeStyles(() => ({
  dialogPaper: {
    height: "40rem",
  },
}));

export default function defaultVersionSetting(props) {

  const [error, setError] = useState("");
  const [defaultVersion, setDefaultVersion] = useState("");
  const noDefaultVersionText = "No default version";
  const [defaultVersionName, setDefaultVersionName] = useState(noDefaultVersionText);
  const classes = useStyles();

  useEffect(() => {
    findDefaultVersion();
  }, [props.defaultVersionUpdated]);

  useEffect(() => {
    if(props.versionRename){
      if(props.defaultVersion?.versionID == props.selectedVersion?._id){
        setDefaultVersionName(props.versionRename);
        props.selectedVersion.title = props.versionRename;
        saveDefaultVersion();
      }
    }
  }, [props.versionRename]);

  function findDefaultVersion(){

    return new Promise((resolve, reject) => {
      fetch(getServerUri("version/findDefaultVersion"), {
        method: "POST",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams({
          accessToken: localStorage.getItem("session.accessToken"),
          baseID: props.selectedVersion.baseID,
          companyID:props.selectedVersion?.companyID
        }),
      })
        .then((response) => {
          if (response.status === 200) {
            response.json().then((fetchedVersion) => {
              setDefaultVersion(fetchedVersion);
              setDefaultVersionName(fetchedVersion ? fetchedVersion.versionName : noDefaultVersionText);
              resolve(fetchedVersion);

            });
          } else {
            response
              .text()
              .then((err) => reject(err))
              .catch((err) => {
                console.log(err);
              });
          }

        })
        .catch((err) => reject(err));
    });
  }

  function goToDefaultVersion(){

    const searchBar = document.getElementById("searchBar");
    searchBar.focus();
    searchBar.value = defaultVersionName;

    props.setSearchBarValue(searchBar.value);

  }

  function saveDefaultVersion(){

    props.setDefaultVersionUpdated(false);

    if(defaultVersion){

      if (!_.isNil(props.selectedVersion)) {

        fetch(getServerUri("version/updateDefaultVersion"), {
          method: "POST",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          body: new URLSearchParams({
            accessToken: localStorage.getItem("session.accessToken"),
            versionName: props.selectedVersion.title,
            versionID: props.selectedVersion._id,
            baseID: props.selectedVersion.baseID,
            companyID: props.selectedVersion.companyID
          }),
        })
          .then((response) => {
            if (response.status === 200) {
              setDefaultVersion(props.selectedVersion);
              setDefaultVersionName(props.selectedVersion.title);
              props.setDefaultVersionUpdated(true);
              props.getDefaultVersion(props.selectedVersion);
            } else if (response.status === 401) {
              //props.createSnackbar("You are not authorized to update, contact your administrator.", "warning");
              response
                .text()
                .then((textResponse) => {
                  console.log({ message: "error updating", textResponse });
                })
                .catch(console.error);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }

    }else{
      fetch(getServerUri("version/insertDefaultVersion"), {
        method: "POST",
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          accessToken: localStorage.getItem("session.accessToken"),
          versionName: props.selectedVersion.title,
          versionID: props.selectedVersion._id,
          gameID: props.selectedVersion.gameID,
          baseID: props.selectedVersion.baseID,
          companyID: props.selectedVersion.companyID,
          userID: props.selectedVersion.userID
        }),
      })
        .then((response) => {
          if (response.status === 200) {
            response
              .json()
              .then((object) => {
                console.log("Default version saved");
                setDefaultVersionName(props.selectedVersion ? props.selectedVersion.title : noDefaultVersionText);
                setDefaultVersion(props.selectedVersion);
                props.getDefaultVersion(props.selectedVersion);
                props.setDefaultVersionUpdated(true);
                console.info("Progress Received: ", object);
              })
              .catch((err) => {
                setError("Server did not process the request 1");
                setError(err);
                console.log(error);
              });
          } else {
            response
              .json()
              .then((object) => {
                setError("Server did not process the request 2");
                console.log("non 200 response:");
                console.dir(object);
              })
              .catch((err) => {
                console.log("can't parse response as json", err);
              });
          }
        })
        .catch((err) => {
          setError("Could not send request to server");
          console.error("FETCH ERROR");
          console.error(err);
        });
    }
  }

  let buttonText = defaultVersion?.versionID == props.selectedVersion._id ? "Default" : "Set Default";


  return (
    <>
      <Card className={props.classes.card}>
        <CardContent>
          <Typography className={classes.title} variant={"h6"}>
            Default Version
          </Typography>
          <div
            style={{
              marginTop: "0.5rem",
              display: "flex",
              overflowX: "hidden",

            }}
          >
            <Typography
              style={{
                textWrap: "nowrap"
              }}
              variant={"subtitle1"}>{"Default Version Name: "}</Typography>
            <Tooltip title={defaultVersionName} aria-label="versionName">
              <Button
                id={"versionName"}
                variant="text"
                label={"Version Name"}
                disabled={defaultVersionName == noDefaultVersionText}
                style={{
                  marginLeft: "5px",
                  marginBottom: "10px",
                  padding: "0px 5px 0px 5px",
                  fontSize: "1rem",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  textAlign: "left",
                  display: "block",
                  textTransform: "none"
                }}
                onClick={() => {
                  goToDefaultVersion();
                }}
              >
                {defaultVersionName}
              </Button>
            </Tooltip>
          </div>

          <Tooltip title={"If the 'Set Default' button is pressed, the selected " +
            "version will be set as the default. All future versions " +
            "will be created based on this default version."} aria-label="componentExplanation">
            <Button
              id={"setDefaultVersion"}
              color="secondary"
              variant="contained"
              label={"Set Default"}
              disabled={defaultVersion?.versionID == props.selectedVersion._id}
              style={{
                margin: "1rem 3rem 0 0",
              }}
              onClick={() => {
                saveDefaultVersion();
              }}
            >
              {buttonText}
            </Button>
          </Tooltip>
        </CardContent>
      </Card>

    </>
  );


}