import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { createMuiTheme, ThemeProvider, makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import RequireLogin from "./components/requireLogin";
import Home from "./pages/home";
import Login from "./pages/login";
import Preview from "./pages/preview";
import playinLogo from "../images/playin/playin_dashboard.png";
import { Container, colors } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CssBaseline from "@material-ui/core/CssBaseline";
import _ from "lodash";
import { SnackbarProvider } from "notistack";
import DialogCountdown from "./components/dialogCountdown";
import { UndoContextProvider } from "./contexts/UndoContext";

const palettes = {
  light: {
    type: "light",
    primary: { main: "#4eabb1" },
    secondary: { main: "#9bcec0" },
    background: { default: "#eee" },
    text: { primary: "#111" },
  },
  dark: {
    type: "dark",
    primary: { main: "#4eabb1" },
    secondary: { main: "#9bcec0" },
    background: { default: "#404040" },
    text: { primary: "#eee", secondary: "#eee" },
  },
};

const useStyles = makeStyles((theme) => ({
  indexDiv: {
    margin: "0px",
    padding: "0px",
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    width: "100vw",
    overflow: "auto",
    textAlign: "center",
  },
  playinLogo: { height: "40px", padding: "10px 0px 5px 0px" },
  logoDiv: {
    borderStyle: "none none solid none",
    borderWidth: "1px",
    borderColor: "black",
    background: "linear-gradient(90deg, #409788 0%, #216576 50%, #409788 100%)",
  },
  success: { backgroundColor: "#9bcec0", color: "rgba(0, 0, 0, 0.87)" },
  error: { backgroundColor: "#CC736E", color: "rgba(0, 0, 0, 0.87)" },
  warning: { backgroundColor: "#F2DD5C", color: "rgba(0, 0, 0, 0.87)" },
  info: { backgroundColor: "#9bc3ce", color: "rgba(0, 0, 0, 0.87)" },
}));

function Index(props) {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  let [selectedPalette, setSelectedPalette] = useState("dark"); // (prefersDarkMode) ? "dark" : "light"
  const classes = useStyles();

  const [isPreviewFullScreen, setIsPreviewFullScreen] = useState(false);
  const [isRecordingStarted, setIsRecordingStarted] = useState(false);

  const theme = React.useMemo(
    () =>
      createMuiTheme({
        palette: palettes[selectedPalette],
        typography: {
          fontSize: 13,
          h6: { fontWeight: 800, fontSize: "0.98rem" },
        },
        overrides: {
          "MuiDataGrid.cell": {
            root: {
              outline: "none",
            },
          },
          MuiTooltip: {
            tooltip: {
              fontSize: "14px",
            },
          },
        },
      }),
    [selectedPalette]
  );

  return (
    <div id={"indexDiv"} className={classes.indexDiv}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className={classes.logoDiv}>
          <img src={playinLogo} className={classes.playinLogo} />
        </div>
        <Router>
          <Switch>
            <Route path="/login">
              <Login classes={classes} theme={theme}></Login>
            </Route>
            <Route path={"/preview"}>
              <Preview classes={classes} theme={theme}>
                {" "}
              </Preview>
            </Route>
            <Route path="/">
              <SnackbarProvider
                maxSnack={3}
                preventDuplicate
                classes={{
                  variantSuccess: classes.success,
                  variantError: classes.error,
                  variantWarning: classes.warning,
                  variantInfo: classes.info,
                }}
              >
                <UndoContextProvider>
                  <Home
                    classes={classes}
                    theme={theme}
                    isPreviewFullScreen={isPreviewFullScreen}
                    setIsPreviewFullScreen={setIsPreviewFullScreen}
                    isRecordingStarted={isRecordingStarted}
                    setIsRecordingStarted={setIsRecordingStarted}
                  ></Home>
                </UndoContextProvider>
              </SnackbarProvider>
            </Route>
          </Switch>
        </Router>
      </ThemeProvider>
      {isPreviewFullScreen && <DialogCountdown setIsRecordingStarted={setIsRecordingStarted} />}
    </div>
  );
}

ReactDOM.render(<Index shouldLog={true} />, document.getElementById("root"));
