import React, { useEffect, useState } from "react";
import { Button, TextField } from "@material-ui/core";

import DialogTextEdit from "./dialogTextEdit";
import { makeStyles } from "@material-ui/core/styles";
import { Edit } from "@material-ui/icons";
import _ from "lodash-core";
import { Typography } from "@material-ui/core";

/**
 *
 * @param props
 * @param props.caption
 * @param props.value
 * @param props.classes
 * @param props.setValue
 * @returns {JSX.Element}
 */

const useStyles = makeStyles(() => ({
  editButton: {
    width: "100%",
    margin: "0.5rem 0rem 0rem 0rem"
  }
}));

export default function editorGridArea(props) {
  let [value, setValue] = useState(_.get(props, "value", ""));
  let [isEditDialogOpen, setIsEditDialogOpen] = useState(_.get(props, "isEditDialogOpen", false));

  const classes = useStyles();
  function gettingMargin(numberMargin) {
    if (props.tooltip != undefined) {
      if (numberMargin) {
        return numberMargin.toString() + "px";
      }
    } else return (0).toString() + "px";
  }

  useEffect(() => {
    setValue(_.get(props, "value", ""));
  }, [props.value]);

  return (
    <>
      {" "}
      {props.isVisible && (
        <div className={props.isTextFieldVisible ? props.classes.editorItem : props.classes.dialogEditorItem}>
          {props.isTextFieldVisible ? (
            <>
              {_.get(props, "caption") && (
                <Typography style={{ marginTop: gettingMargin(props.tooltipLength) }} id="discrete-slider" gutterBottom>
                  {_.get(props, "caption")}
                </Typography>
              )}
              <TextField
                onKeyDown={(event) => {
                  event.stopPropagation();
                }}
                // style={{ marginTop: gettingMargin(props.tooltipLength) }}
                multiline
                id={`${props.id}.textField`}
                rows={1}
                rowsMax={2}
                className={props.classes.textField}
                // label={props.caption}
                value={value}
                onChange={(event) => {
                  if (props.userPermissions["update"] === false) {
                    props.createSnackbar(
                      "You do not have permission to update, contact your administrator.",
                      "warning",
                      false
                    );
                    return;
                  }
                  setValue(event.target.value);
                  props.setValue(event.target.value);
                }}
              ></TextField>
            </>
          ) : null}
          {props.isDialogEditEnabled ? (
            <div>
              <Button
                color="secondary"
                className={classes.editButton}
                variant="contained"
                startIcon={<Edit />}
                onClick={() => {
                  if (props.userPermissions["update"] === false) {
                    props.createSnackbar(
                      "You do not have permission to update, contact your administrator.",
                      "warning",
                      false
                    );
                    return;
                  }
                  setIsEditDialogOpen(true);
                }}
              >
                {_.isNil(props.buttonText) ? "EDIT GRID" : props.buttonText}
              </Button>
              {isEditDialogOpen ? (
                <DialogTextEdit
                  style={{ marginTop: gettingMargin(props.tooltipLength) }}
                  open={true}
                  onClose={() => setIsEditDialogOpen(false)}
                  value={value}
                  setValue={props.setValue}
                  classes={props.classes}
                  label={props.caption}
                />
              ) : null}
            </div>
          ) : null}
        </div>
      )}
    </>
  );
}
