import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Divider, Card, CardContent, Typography, IconButton, Chip } from "@material-ui/core";
import compressImage from "../../../images/documentationImages/compressImage.png";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "80px",
  },
  imgContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    borderRadius: "4px",
    border: "1px solid #727171",
    boxShadow: "rgb(0 0 0 / 20%) 1px 1px 10px 0px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px",
  },
  img: {
    borderRadius: "4px",
    width: "100%",
    height: "auto",
  },
  main: {
    align: "center",
    textAlign: "left",
  },
  chip: {
    float: "left",
    marginRight: "90%",
    marginBottom: "5%",
  },
  context: {
    textAlign: "justify",
  },
}));

const CompressImage = () => {
  const classes = useStyles();

  return (
    <div className={classes.main}>
      <Card className={classes.container}>
        <CardContent>
          <div className={classes.imgContainer}>
            <img src={compressImage} className={classes.img} alt="" />
            <Divider />
          </div>
          <br />
          <Typography variant="h6">Compressing images are easier now ! 🎉</Typography>
          <Typography paragraph>01.06.2023</Typography>
          <Chip className={classes.chip} label="Standard" variant="outlined" />
          <Typography paragraph className={classes.context}>
          Creative dashboard users can compress images now.
           This component helps you to reduce your file sizes with the help of a slider where you can define the compression ratio.
           Moreover, you can compare file size and preview this compression process thanks to a panel that allows you to see the quality of your photo instantly.
           Note that only jpeg and png image files are supported. For more features, you can read our documentation.
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
};

export default CompressImage;
