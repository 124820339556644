import { useEffect } from "react";

function useCtrlZEffect(callback) {
  useEffect(() => {
    function handleKeyDown(event) {
      if (navigator.platform.indexOf("Mac") !== -1) {
        if (event.metaKey && event.shiftKey && event.key === "z") {
          callback();
        }
      } else {
        if (event.ctrlKey && event.shiftKey && event.key === "z") {
          callback();
        }
      }
    }
    document.addEventListener("keydown", handleKeyDown, { once: true });

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [callback]);
}

export default useCtrlZEffect;
