import React from "react";
import FolderIcon from "@material-ui/icons/Folder";
import ImageIcon from "@material-ui/icons/Image";
import ListAltIcon from "@material-ui/icons/ListAlt";
import DescriptionIcon from "@material-ui/icons/Description";

export const CustomTypeIcon = (props) => {
  if (props.fileType !== "text") {
    return <FolderIcon style={{width: "20px"}} />;
  } else {
    return null;
  }

  // switch (props.fileType) {
  //     case "image":
  //         return <ImageIcon />;
  //     case "csv":
  //         return <ListAltIcon />;
  //     case "text":
  //         return <DescriptionIcon />;
  //     default:
  //         return null;
  // }
};
