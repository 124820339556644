import React, { useState, useEffect } from "react";
import { Dialog, TextField, Button, DialogTitle } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { getServerUri } from "../modules/uriUtility";
import _ from "lodash";
import ReactGA from "react-ga";
import ReactGA4 from "react-ga4";

let useStyles = makeStyles({
  insertDialog: { margin: 10, display: "flex", alignItems: "center" },
  insertButton: {
    marginLeft: "0.5rem",
  },
});

/**
 *
 * @param props
 * @param props.open
 * @param props.selectedGame
 * @param props.selectedBase
 * @param props.selectedModifiedBase
 * @param props.selectedVersion
 * @param props.versions
 * @param props.setIsVersionBeingUpdatedFalse
 * @param props.setOpen
 * @param props.insertNewVersion
 * @param props.createSnackbar
 * @returns {JSX.Element|null}
 */
export default function dialogVersionInsert(props) {
  let [title, setTitle] = useState("New Version");
  let [versionHie, setVersionHierarchy] = useState(1);
  let [versionObject, setVersionObject] = useState({});
  let [buttonClick, setButtonClick] = useState(false);
  let [defaultVersion, setDefaultVersion] = useState("");
  let [isCharacterAllowed, setIsCharacterAllowed] = useState(true);
  let [value, setValue] = useState("");

  useEffect(() => {
    if (versionHie != 1) {
      const baseFolder = async () => {
        await fetch(getServerUri("version/updateHierarchy"), {
          method: "POST",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          body: new URLSearchParams({
            accessToken: localStorage.getItem("session.accessToken"),
            baseID: _.get(props, "selectedBase._id"),
            versionHierarchy: JSON.stringify(versionHie),
            companyID: props.companyID,
          }),
        })
          .then((response) => {
            if (response.status === 200) {
              props.setInsertNewVersion(title + Math.floor(Math.random() * 100000).toString());
            }
          })
          .then(() => {
            //props.setSortAgain(Math.random() * 100000)
          });
      };
      baseFolder();
    }
  }, [versionHie]);

  useEffect(() => {
    if (Object.keys(versionObject).length !== 0) {
      fetchVersionHierarchy(versionObject);
    }
  }, [versionObject]);

  const classes = useStyles();

  useEffect(() => {
    findDefaultVersion();
  }, [props.defaultVersionUpdated]);

  function findDefaultVersion() {
    return new Promise((resolve, reject) => {
      fetch(getServerUri("version/findDefaultVersion"), {
        method: "POST",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams({
          accessToken: localStorage.getItem("session.accessToken"),
          baseID: _.get(props, "selectedBase._id"),
          companyID: _.get(props, "companyID"),
        }),
      })
        .then((response) => {
          if (response.status === 200) {
            response.json().then((fetchedVersion) => {
              setDefaultVersion(fetchedVersion);
              resolve(fetchedVersion);
            });
          } else {
            response
              .text()
              .then((err) => reject(err))
              .catch((err) => {
                console.log(err);
              });
          }
        })
        .catch((err) => reject(err));
    });
  }

  function resetSearchBar() {
    const searchBar = document.getElementById("searchBar");

    if (searchBar) {
      props.setSearchTerm(Math.floor(Math.random() * 1000) + 1);
      // searchBar.value = "";
    }
  }
  function getCurrentDate(separator = "") {
    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    let time = newDate.getHours();
    let minute = newDate.getMinutes() < 10 ? "0" + newDate.getMinutes() : newDate.getMinutes();
    return `${date < 10 ? `0${date}` : `${date}`}${separator}${
      month < 10 ? `0${month}` : `${month}`
    }${separator}${year} ${time}:${minute}`;
  }
  function sortedTree(property) {
    var sortOrder = 1;

    return function (a, b) {
      if (sortOrder == 1) {
        return a[property].localeCompare(b[property]);
      } else {
        return b[property].localeCompare(a[property]);
      }
    };
  }
  const handleKeyPress = (event) => {
    var blockSpecialRegex = /[~`!@#$%|&()={}[\]:;,.<>+\/?]/;
    var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (blockSpecialRegex.test(key)) {
      event.preventDefault();
      setIsCharacterAllowed(false);
    }

    if (event.key === "Enter" || event.key === "Return") {
      handleButtonClick();
    }
  };

  const handleButtonClick = () => {
    resetSearchBar();
    props.setOpen(false);

    if (props.defaultVersion) {
      fetch(getServerUri("version/duplicate"), {
        method: "POST",
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          accessToken: localStorage.getItem("session.accessToken"),
          versionID: String(props.defaultVersion.versionID),
          title: title,
        }),
      })
        .then((response) => {
          //select the created version but load versions first
          if (response.status === 200) {
            props.fetchVersions();
            let newSelectedVersion;
            response.json().then((object) => {
              console.log("object", object);
              newSelectedVersion = object.insertedVersion;
              console.trace("toolbarGamePreview - version insert");
              props.setSelectedVersion(newSelectedVersion);
              setVersionObject(newSelectedVersion);
            });
            ReactGA.event({
              category: "Preview",
              action: "Version Insert Clicked",
              label:
                "game: " +
                props.selectedGame.title +
                ", base: " +
                props.selectedBase.title +
                "defaultVersion: " +
                props.selectedVersion.title +
                ", newVersion: " +
                title,
              dimension1: localStorage.getItem("user.id"),
            });
            ReactGA4.event({
              category: "Preview",
              action: "Version Insert Clicked",
              label:
                "game: " +
                props.selectedGame.title +
                ", base: " +
                props.selectedBase.title +
                "defaultVersion: " +
                props.selectedVersion.title +
                ", newVersion: " +
                title,
              dimension1: localStorage.getItem("user.id"),
            });
            setTitle("");
            props.createSnackbar("Successfully added", "success");
          } else if (response.status === 401) {
            props.createSnackbar("You are not authorized to insert, contact your administrator.", "warning");
            response
              .text()
              .then((textResponse) => {
                console.log({ message: "error inserting", textResponse });
              })
              .catch(console.error);
          }
        })
        .catch((err) => {
          console.error(err);
          props.createSnackbar("An error occurred when inserting a new version.", "error");
        });
    } else {
      fetch(getServerUri("version/insert"), {
        method: "POST",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams({
          accessToken: localStorage.getItem("session.accessToken"),
          baseID: _.get(props, "selectedBase._id"),
          gameID: _.get(props, "selectedGame._id"),
          gameConfig: JSON.stringify(
            _.has(props.selectedBase, "predefinedGameComponents")
              ? props.selectedModifiedBase.gameConfig
              : props.selectedBase.gameConfig
          ),
          recorderSettings: JSON.stringify(props.selectedBase.recorderSettings),
          title,
          createdLanguages: JSON.stringify(["en"]),
        }),
      })
        .then((response) => {
          if (response.status === 200) {
            response.json().then((insertedRecordObject) => {
              props.insertNewVersion(insertedRecordObject, props.selectedVersion);
              setVersionObject(insertedRecordObject);
              console.log({ message: "insert successful", insertedRecordObject });
              props.createSnackbar("Successfully added", "success");
            });
          } else if (response.status === 401) {
            props.createSnackbar("You are not authorized to insert, contact your administrator.", "warning");
            response
              .text()
              .then((textResponse) => {
                console.error(textResponse);
              })
              .catch(console.error);
          } else {
            response
              .text()
              .then((textResponse) => {
                console.log({ message: "error inserting", textResponse });
                props.createSnackbar("An error occurred when inserting a new version.", "error");
              })
              .catch(console.error);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };
  function fetchVersionHierarchy(version) {
    //take the version hierarchy

    fetch(getServerUri("version/getHierarchy"), {
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({
        accessToken: localStorage.getItem("session.accessToken"),
        baseID: _.get(props, "selectedBase._id"),
        companyID: props.companyID,
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          console.log("version hiearchy response", response);
          response.json().then((json) => {
            if (props.selectedFolderId != null) {
              if (props.selectedFolderId == 9999999) {
                var tmpObj = {
                  "id": version._id,
                  "parent": 0,
                  "text": version.title,
                  "data": {
                    "fileType": "text",
                    "createdDate": getCurrentDate("."),
                    "updateDate": getCurrentDate("."),
                  },
                };
              } else {
                var tmpObj = {
                  "id": version._id,
                  "parent": props.selectedFolderId.id,
                  "text": version.title,
                  "data": {
                    "fileType": "text",
                    "createdDate": getCurrentDate("."),
                    "updateDate": getCurrentDate("."),
                  },
                };
                props.setSelectedFolderId(9999999);
              }
            }
            //add new object to the versionHierarchy
            json.versionHierarchy.push(tmpObj);
            let newVersionTree = {};
            newVersionTree["tree"] = json.versionHierarchy;
            setVersionHierarchy(newVersionTree);
            setTitle("New Version");
          });
        } else if (response.status === 401) {
          props.createSnackbar("You are not authorized to update, contact your administrator.", "warning");
          response
            .text()
            .then((textResponse) => {
              console.log({ message: "error updating", textResponse });
            })
            .catch(console.error);
        } else {
          response
            .text()
            .then((textResponse) => {
              console.log({ message: "error renaming", textResponse });
              props.createSnackbar("An error occurred when renaming a new version.", "error");
            })
            .catch(console.error);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  return (
    <Dialog
      id="versionInsert_dialog"
      open={props.open}
      onClose={() => {
        props.setOpen(false);
      }}
    >
      <DialogTitle
        id="versionInsert_dialogTitle"
        style={{
          textAlign: "center",
        }}
      >
        Insert Version
      </DialogTitle>
      <div className={classes.insertDialog}>
        <TextField
          error={!isCharacterAllowed}
          id="versionInsert_textField"
          label={"Version Title"}
          variant={"filled"}
          onKeyPress={handleKeyPress}
          helperText={isCharacterAllowed ? "" : "This character is not allowed"}
          inputProps={{ maxLength: 101, autoFocus: true }}
          value={value}
          onChange={(event) => {
            const regex = /[~`!@#$%|&()={}[\]:;,.<>+\/?^]/;
            const inputValue = event.target.value;

            // Remove characters that match the regex
            const sanitizedValue = inputValue
              .split("")
              .filter((char) => !regex.test(char))
              .join("");

            setValue(sanitizedValue);

            if (inputValue != sanitizedValue) {
              setIsCharacterAllowed(false);
            } else {
              setIsCharacterAllowed(true);
            }

            if (event.target.value.length < 101) {
              setTitle("New Version");

              var a = event.target.value;
              a = a.replace(/\s/g, "");
              if (a.length == 0) {
                setTitle("New Version");
                setButtonClick(false);
              } else {
                setTitle(event.target.value);
                setButtonClick(false);
              }
            } else {
              setButtonClick(true);
              props.createSnackbar("No more than 100 characters can be entered", "error");
            }
          }}
        ></TextField>
        <Button
          id="insertVersion_button"
          className={classes.insertButton}
          label={"Insert"}
          disabled={buttonClick}
          onClick={handleButtonClick}
        >
          OK
        </Button>
      </div>
    </Dialog>
  );
  return null;
}
