const logging = {
  fetchedBases: false,
  fetchedGames: false,
  fetchedVersions: false,
  selections: true,
  savedVersion: true,
};

const config = {
  backendPort: 3000,
  loggingLevel: 3,
  logging,
};

export default config;
