import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";

const useClasses = makeStyles({
  dialog: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  root: {
    display: "flex",
  },
  headerWrapper: {
    margin: "1rem 2rem 0rem 2rem",
  },
  buttonText: {},
});
/**
 *
 * @param {Boolean} props.isOpen is it open?
 * @param {Function} props.setIsOpen set is open to true or false
 * @returns {JSX.Element}
 */
export default function dialogViewOnlyMode(props) {
  const classes = useClasses();

  return (
    <Dialog
      fullWidth={true}
      open={props.isOpen}
      onClose={() => {
        props.setIsOpen(false);
      }}
    >
      <DialogTitle
        style={{
          textAlign: "center",
          fontSize: "1.0vw",
        }}
      >
        {`WARNING`}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          style={{
            textAlign: "center",
            marginBottom: "6px",
          }}
        >
          {`This version is being edited by the user:`}
        </DialogContentText>
        <DialogContentText
          color={"primary"}
          style={{
            textAlign: "center",
            marginBottom: "6px",
          }}
        >
          {`${_.get(props, "lastUpdatingUser.username", "anonymous")}`}
        </DialogContentText>
        <DialogContentText
          style={{
            textAlign: "center",
            marginBottom: "0px",
          }}
        >
          {`You can still view this version but can not make any changes.`}
        </DialogContentText>
        <DialogContentText
          style={{
            textAlign: "center",
            marginBottom: "0px",
          }}
        >
          {`Refresh to fetch the most recent changes by your teammate.`}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.dialog}>
        <Button
          label={"OK"}
          color="primary"
          variant="contained"
          style={{
            width: "17.5%",
            margin: "0.5rem",
          }}
          onClick={(event) => {
            props.setIsOpen(false);
          }}
        >
          <span className={classes.buttonText}>Ok</span>
        </Button>
        <Button
          label={"Refresh"}
          color="primary"
          variant="contained"
          style={{
            width: "17.5%",
            margin: "0.5rem",
          }}
          onClick={(event) => {
            window.location.reload();
          }}
        >
          <span className={classes.buttonText}>Refresh</span>
        </Button>
      </DialogActions>
    </Dialog>
  );
}
