import { Slider, Typography } from "@material-ui/core";
import { ExpandLessOutlined } from "@material-ui/icons";
import React, { useEffect, useState } from "react";

/**
 *
 * @param props
 * @param props.classes
 * @param props.label
 * @param props.value
 * @param props.setValue
 * @param props.min
 * @param props.max
 * @param props.step
 * @param props.userPermissions
 * @param props.createSnackbar
 * @returns {JSX.Element|null}
 */

export default function sliderGeneric(props) {
  if (_.isNil(props.value)) {
    return null;
  }

  const [x, setX] = useState(_.get(props, "value.x", 0.5));
  const [y, setY] = useState(_.get(props, "value.y", 0.5));
  const [value, setValue] = useState(props.value);
  const [portrait, setPortrait] = useState(_.get(props, "value.portrait", 0.5));
  const [landscape, setLandscape] = useState(_.get(props, "value.landscape", 0.5));
  const [portraitX, setPortraitX] = useState(_.get(props, "value.portrait.x", 0.5));
  const [portraitY, setPortraitY] = useState(_.get(props, "value.portrait.y", 0.5));
  const [landscapeX, setLandscapeX] = useState(_.get(props, "value.landscape.x", 0.5));
  const [landscapeY, setLandscapeY] = useState(_.get(props, "value.landscape.x", 0.5));
  const [portraitWidth, setPortraitWidth] = useState(_.get(props, "value.portrait.width", 0.5));
  const [portraitHeight, setPortraitHeight] = useState(_.get(props, "value.portrait.height", 0.5));
  const [landscapeWidth, setLandscapeWidth] = useState(_.get(props, "value.landscape.width", 0.5));
  const [landscapeHeight, setLandscapeHeight] = useState(_.get(props, "value.landscape.height", 0.5));
  useEffect(() => {
    setPortraitWidth(_.get(props, "value.portrait.width", 0.5));
    setPortraitHeight(_.get(props, "value.portrait.height", 0.5));
    setLandscapeWidth(_.get(props, "value.landscape.width", 0.5));
    setLandscapeHeight(_.get(props, "value.landscape.height", 0.5));
    setPortraitX(_.get(props, "value.portrait.x", 0.5));
    setPortraitY(_.get(props, "value.portrait.y", 0.5));
    setLandscapeX(_.get(props, "value.landscape.x", 0.5));
    setLandscapeY(_.get(props, "value.landscape.y", 0.5));
    setPortrait(_.get(props, "value.portrait", 0.5));
    setLandscape(_.get(props, "value.landscape", 0.5));
    setX(_.get(props, "value.x", 0.5));
    setY(_.get(props, "value.y", 0.5));
    setValue(props.value);
  }, [props.value]);
  function gettingMargin(numberMargin) {
    if (props.tooltip != undefined) {
      if (numberMargin) {
        return numberMargin.toString() + "px";
      }
    } else return (0).toString() + "px";
  }
  function checkPosition(label) {
    if (label.includes("Landscape") || label.includes("Portrait")) {
      if (props.newSetPortrait && label.includes("Portrait")) {
        return true;
      } else if (!props.newSetPortrait && label.includes("Landscape")) {
        return true;
      }
      return false;
    } else {
      return true;
    }
  }

  if (props.editorSubType === "XY_4" || props.editorSubType === "XY_4_FullPath") {
    return (
      <>
        {" "}
        {props.isVisible && !props.y_axis && (
          <div>
            <Typography style={{ marginTop: gettingMargin(props.tooltipLength) }}>{props.label}</Typography>
            {props.newSetPortrait && (
              <div>
                <Typography>Portrait X</Typography>
                <Slider
                  onKeyDown={(event) => {
                    event.stopPropagation();
                  }}
                  className={props.classes.sliderSize}
                  value={portraitX}
                  aria-labelledby="labelOffsetX"
                  valueLabelDisplay="auto"
                  step={props.step}
                  min={props.min}
                  max={props.max}
                  //step={props.step} min={props.min} max={props.max}
                  onChange={(event, newValue) => {
                    if (props.userPermissions["update"] === false) {
                      props.createSnackbar(
                        "You do not have permission to update, contact your administrator.",
                        "warning",
                        false
                      );
                      return;
                    }
                    setPortraitX(newValue);
                    props.setValue(props.path + ".portrait.x", newValue);
                  }}
                />
                <Typography>Portrait Y</Typography>
                <Slider
                  onKeyDown={(event) => {
                    event.stopPropagation();
                  }}
                  className={props.classes.sliderSize}
                  value={portraitY}
                  aria-labelledby="labelOffsetX"
                  valueLabelDisplay="auto"
                  step={props.step}
                  min={props.min}
                  max={props.max}
                  onChange={(event, newValue) => {
                    if (props.userPermissions["update"] === false) {
                      props.createSnackbar(
                        "You do not have permission to update, contact your administrator.",
                        "warning",
                        false
                      );
                      return;
                    }
                    setPortraitY(newValue);
                    // props.setValue({
                    //     portrait: { x: portraitX, y: newValue },
                    //     landscape: { x: landscapeX, y: landscapeY }
                    // });
                    props.setValue(props.path + ".portrait.y", newValue); //
                  }}
                  // onDragStop={ (event) => this.props.update(event, control.id, value) }
                />
              </div>
            )}

            {!props.newSetPortrait && (
              <div>
                <Typography>Landscape X</Typography>
                <Slider
                  onKeyDown={(event) => {
                    event.stopPropagation();
                  }}
                  className={props.classes.sliderSize}
                  value={landscapeX}
                  aria-labelledby="labelOffsetX"
                  valueLabelDisplay="auto"
                  step={props.step}
                  min={props.min}
                  max={props.max}
                  onChange={(event, newValue) => {
                    if (props.userPermissions["update"] === false) {
                      props.createSnackbar(
                        "You do not have permission to update, contact your administrator.",
                        "warning",
                        false
                      );
                      return;
                    }
                    setLandscapeX(newValue);
                    // props.setValue({
                    //     portrait: { x: portraitX, y: portraitY },
                    //     landscape: { x: newValue, y: landscapeY }
                    // });
                    props.setValue(props.path + ".landscape.x", newValue);
                  }}
                />
                <Typography>Landscape Y</Typography>
                <Slider
                  onKeyDown={(event) => {
                    event.stopPropagation();
                  }}
                  className={props.classes.sliderSize}
                  value={landscapeY}
                  aria-labelledby="labelOffsetX"
                  valueLabelDisplay="auto"
                  step={props.step}
                  min={props.min}
                  max={props.max}
                  // step={0.001} min={0} max={1}
                  onChange={(event, newValue) => {
                    if (props.userPermissions["update"] === false) {
                      props.createSnackbar(
                        "You do not have permission to update, contact your administrator.",
                        "warning",
                        false
                      );
                      return;
                    }
                    setLandscapeY(newValue);
                    // props.setValue({
                    //     portrait: { x: portraitX, y: portraitY },
                    //     landscape: { x: landscapeX, y: newValue }
                    // });
                    props.setValue(props.path + ".landscape.y", newValue);
                  }}
                />
              </div>
            )}
          </div>
        )}
        {props.isVisible && props.y_axis && (
          <div>
            <Typography>{props.label}</Typography>
            {props.newSetPortrait && (
              <div>
                <Typography>Portrait Y</Typography>
                <Slider
                  onKeyDown={(event) => {
                    event.stopPropagation();
                  }}
                  className={props.classes.sliderSize}
                  value={portraitY}
                  aria-labelledby="labelOffsetX"
                  // step={props.step} min={props.min} max={props.max}
                  valueLabelDisplay="auto"
                  step={props.step}
                  min={props.min}
                  max={props.max}
                  onChange={(event, newValue) => {
                    if (props.userPermissions["update"] === false) {
                      props.createSnackbar(
                        "You do not have permission to update, contact your administrator.",
                        "warning",
                        false
                      );
                      return;
                    }
                    setPortraitY(newValue);
                    props.setValue(props.path + ".portrait.y", newValue);
                  }}
                />
              </div>
            )}
            {!props.newSetPortrait && (
              <div>
                <Typography>Landscape Y</Typography>
                <Slider
                  onKeyDown={(event) => {
                    event.stopPropagation();
                  }}
                  className={props.classes.sliderSize}
                  value={landscapeY}
                  aria-labelledby="labelOffsetX"
                  valueLabelDisplay="auto"
                  step={props.step}
                  min={props.min}
                  max={props.max}
                  onChange={(event, newValue) => {
                    if (props.userPermissions["update"] === false) {
                      props.createSnackbar(
                        "You do not have permission to update, contact your administrator.",
                        "warning",
                        false
                      );
                      return;
                    }
                    setLandscapeY(newValue);
                    // props.setValue({
                    //     portrait: { x: portraitX, y: portraitY },
                    //     landscape: { x: landscapeX, y: newValue }
                    // });
                    props.setValue(props.path + ".landscape.y", newValue);
                  }}
                />
              </div>
            )}
          </div>
        )}
      </>
    );
  } else if (props.editorSubType == "WHR_2" || props.editorSubType == "WHR_2_FullPath") {
    return (
      <>
        {" "}
        {props.isVisible && (
          <div>
            <Typography style={{ marginTop: gettingMargin(props.tooltipLength) }}>{props.label || "Scale"}</Typography>
            {props.newSetPortrait && (
              <div>
                <Typography>Portrait</Typography>
                <Slider
                  onKeyDown={(event) => {
                    event.stopPropagation();
                  }}
                  className={props.classes.sliderSize}
                  value={portrait}
                  aria-labelledby="labelOffsetX"
                  valueLabelDisplay="auto"
                  step={props.step}
                  min={props.min}
                  max={props.max}
                  onChange={(event, newValue) => {
                    if (props.userPermissions["update"] === false) {
                      props.createSnackbar(
                        "You do not have permission to update, contact your administrator.",
                        "warning",
                        false
                      );
                      return;
                    }
                    setPortrait(newValue);
                    props.setValue(props.path + ".portrait", newValue);
                  }}
                />
              </div>
            )}
            {!props.newSetPortrait && (
              <div>
                <Typography>Landscape</Typography>
                <Slider
                  onKeyDown={(event) => {
                    event.stopPropagation();
                  }}
                  className={props.classes.sliderSize}
                  value={landscape}
                  aria-labelledby="labelOffsetX"
                  valueLabelDisplay="auto"
                  step={props.step}
                  min={props.min}
                  max={props.max}
                  onChange={(event, newValue) => {
                    if (props.userPermissions["update"] === false) {
                      props.createSnackbar(
                        "You do not have permission to update, contact your administrator.",
                        "warning",
                        false
                      );
                      return;
                    }
                    setLandscape(newValue);
                    props.setValue(props.path + ".landscape", newValue);
                  }}
                />
              </div>
            )}
          </div>
        )}
      </>
    );
  } else if (props.editorSubType === "WHR_4" || props.editorSubType === "WHR_4_FullPath") {
    return (
      <>
        {" "}
        {props.isVisible && !props.y_axis && (
          <div>
            <Typography style={{ marginTop: gettingMargin(props.tooltipLength) }}>{props.label}</Typography>
            {props.newSetPortrait && (
              <div>
                <Typography>Portrait Width</Typography>
                <Slider
                  onKeyDown={(event) => {
                    event.stopPropagation();
                  }}
                  className={props.classes.sliderSize}
                  value={portraitWidth}
                  aria-labelledby="labelOffsetX"
                  valueLabelDisplay="auto"
                  step={props.step}
                  min={props.min}
                  max={props.max}
                  onChange={(event, newValue) => {
                    if (props.userPermissions["update"] === false) {
                      props.createSnackbar(
                        "You do not have permission to update, contact your administrator.",
                        "warning",
                        false
                      );
                      return;
                    }
                    setPortraitWidth(newValue);
                    props.setValue(props.path + ".portrait.width", newValue);
                  }}
                />
                <Typography>Portrait Height</Typography>
                <Slider
                  onKeyDown={(event) => {
                    event.stopPropagation();
                  }}
                  className={props.classes.sliderSize}
                  value={portraitHeight}
                  aria-labelledby="labelOffsetX"
                  valueLabelDisplay="auto"
                  step={props.step}
                  min={props.min}
                  max={props.max}
                  onChange={(event, newValue) => {
                    if (props.userPermissions["update"] === false) {
                      props.createSnackbar(
                        "You do not have permission to update, contact your administrator.",
                        "warning",
                        false
                      );
                      return;
                    }
                    setPortraitHeight(newValue);
                    props.setValue(props.path + ".portrait.height", newValue); //
                  }}
                  // onDragStop={ (event) => this.props.update(event, control.id, value) }
                />
              </div>
            )}

            {!props.newSetPortrait && (
              <div>
                <Typography>Landscape Width</Typography>
                <Slider
                  onKeyDown={(event) => {
                    event.stopPropagation();
                  }}
                  className={props.classes.sliderSize}
                  value={landscapeWidth}
                  aria-labelledby="labelOffsetX"
                  valueLabelDisplay="auto"
                  step={props.step}
                  min={props.min}
                  max={props.max}
                  onChange={(event, newValue) => {
                    if (props.userPermissions["update"] === false) {
                      props.createSnackbar(
                        "You do not have permission to update, contact your administrator.",
                        "warning",
                        false
                      );
                      return;
                    }
                    setLandscapeWidth(newValue);
                    props.setValue(props.path + ".landscape.width", newValue);
                  }}
                />
                <Typography>Landscape Height</Typography>
                <Slider
                  onKeyDown={(event) => {
                    event.stopPropagation();
                  }}
                  className={props.classes.sliderSize}
                  value={landscapeHeight}
                  aria-labelledby="labelOffsetX"
                  valueLabelDisplay="auto"
                  step={props.step}
                  min={props.min}
                  max={props.max}
                  onChange={(event, newValue) => {
                    if (props.userPermissions["update"] === false) {
                      props.createSnackbar(
                        "You do not have permission to update, contact your administrator.",
                        "warning",
                        false
                      );
                      return;
                    }
                    setLandscapeHeight(newValue);
                    props.setValue(props.path + ".landscape.height", newValue);
                  }}
                />
              </div>
            )}
          </div>
        )}
        {props.isVisible && props.y_axis && (
          <div>
            <Typography>{props.label}</Typography>
            {props.newSetPortrait && (
              <div>
                <Typography>Portrait Y</Typography>
                <Slider
                  onKeyDown={(event) => {
                    event.stopPropagation();
                  }}
                  className={props.classes.sliderSize}
                  value={portraitY}
                  aria-labelledby="labelOffsetX"
                  // step={props.step} min={props.min} max={props.max}
                  valueLabelDisplay="auto"
                  step={props.step}
                  min={props.min}
                  max={props.max}
                  onChange={(event, newValue) => {
                    if (props.userPermissions["update"] === false) {
                      props.createSnackbar(
                        "You do not have permission to update, contact your administrator.",
                        "warning",
                        false
                      );
                      return;
                    }
                    setPortraitY(newValue);
                    props.setValue(props.path + ".portrait.y", newValue);
                  }}
                />
              </div>
            )}
            {!props.newSetPortrait && (
              <div>
                <Typography>Landscape Y</Typography>
                <Slider
                  onKeyDown={(event) => {
                    event.stopPropagation();
                  }}
                  className={props.classes.sliderSize}
                  value={landscapeY}
                  aria-labelledby="labelOffsetX"
                  valueLabelDisplay="auto"
                  step={props.step}
                  min={props.min}
                  max={props.max}
                  onChange={(event, newValue) => {
                    if (props.userPermissions["update"] === false) {
                      props.createSnackbar(
                        "You do not have permission to update, contact your administrator.",
                        "warning",
                        false
                      );
                      return;
                    }
                    setLandscapeY(newValue);
                    // props.setValue({
                    //     portrait: { x: portraitX, y: portraitY },
                    //     landscape: { x: landscapeX, y: newValue }
                    // });
                    props.setValue(props.path + ".landscape.y", newValue);
                  }}
                />
              </div>
            )}
          </div>
        )}
      </>
    );
  } else if (props.editorSubType == "XY_2" || props.editorSubType == "XY_2_FullPath") {
    return (
      <>
        {" "}
        {props.isVisible && (
          <div id={"sliderOriginDiv"} className={props.classes.editorItem}>
            <Typography style={{ marginTop: gettingMargin(props.tooltipLength) }} id="discrete-slider" gutterBottom>
              {_.get(props, "label")}
            </Typography>
            <Typography id="labelOriginX" gutterBottom>
              {"X"}
            </Typography>
            <Slider
              onKeyDown={(event) => {
                event.stopPropagation();
              }}
              style={{ width: "100%" }}
              value={x}
              aria-labelledby="labelOriginX"
              valueLabelDisplay="auto"
              step={props.step}
              min={props.min}
              max={props.max}
              onChange={(event, newValue) => {
                if (props.userPermissions["update"] === false) {
                  props.createSnackbar(
                    "You do not have permission to update, contact your administrator.",
                    "warning",
                    false
                  );
                  return;
                }
                setX(newValue);
                props.setValue(props.path + ".x", newValue);
              }}
            />
            <Typography id="labelOriginY" gutterBottom>
              {"Y"}
            </Typography>
            <Slider
              onKeyDown={(event) => {
                event.stopPropagation();
              }}
              style={{ width: "100%" }}
              value={y}
              aria-labelledby="labelOriginY"
              valueLabelDisplay="auto"
              step={props.step}
              min={props.min}
              max={props.max}
              onChange={(event, newValue) => {
                if (props.userPermissions["update"] === false) {
                  props.createSnackbar(
                    "You do not have permission to update, contact your administrator.",
                    "warning",
                    false
                  );
                  return;
                }
                setY(newValue);
                props.setValue(props.path + ".y", newValue);
              }}
            />
          </div>
        )}
      </>
    );
  } else if (props.editorSubType == "singleSliderFullPath") {
    return (
      <>
        {props.isVisible && (
          <div className={props.classes.editorItem}>
            <Typography id="discrete-slider" gutterBottom>
              {_.get(props, "label")}
            </Typography>

            <Slider
              onKeyDown={(event) => {
                event.stopPropagation();
              }}
              style={{ width: "100%" }}
              value={value}
              aria-labelledby="discrete-slider"
              valueLabelDisplay="auto"
              step={props.step}
              min={props.min}
              max={props.max}
              onChange={(event, newValue) => {
                if (props.userPermissions["update"] === false) {
                  props.createSnackbar(
                    "You do not have permission to update, contact your administrator.",
                    "warning",
                    false
                  );
                  return;
                }
                setValue(newValue);
                props.setValue(props.path, newValue);
              }}
            />
          </div>
        )}
      </>
    );
  } else {
    return (
      <>
        {props.isVisible && (
          <div>
            {checkPosition(_.get(props, "label")) && (
              <div>
                <Typography style={{ marginTop: gettingMargin(props.tooltipLength) }} id="discrete-slider" gutterBottom>
                  {_.get(props, "label")}
                </Typography>
                <Slider
                  onKeyDown={(event) => {
                    event.stopPropagation();
                  }}
                  style={{ width: "100%" }}
                  value={value}
                  aria-labelledby="discrete-slider"
                  valueLabelDisplay="auto"
                  step={props.step}
                  min={props.min}
                  max={props.max}
                  onChange={(event, newValue) => {
                    if (props.userPermissions["update"] === false) {
                      props.createSnackbar(
                        "You do not have permission to update, contact your administrator.",
                        "warning",
                        false
                      );
                      return;
                    }
                    setValue(newValue);
                    props.setValue(props.path, newValue);
                  }}
                />
              </div>
            )}
          </div>
        )}
      </>
    );
  }
}
