import React from "react";
import Popover from "@material-ui/core/Popover";
import MoreVertIconRounded from "@material-ui/icons/MoreVertRounded";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { Refresh, FileCopy, GetApp, Visibility } from "@material-ui/icons";

import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, IconButton } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  renameButton: {
    textTransform: "none",
  },
  editIcon: {
    margin: "0.1rem",
  },
  deleteIcon: {
    margin: "0.1rem",
  },
  fileCopy: {
    margin: "0.1rem",
  },
  getApp: {
    margin: "0.1rem",
  },

  renameFolder: {
    textTransform: "none",
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
  },
}));

export default function versionSelectorOptions(props) {
  let [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  function ButtonVisibilty() {
    if (props.userID) {
      if (props.userID == "6332cfdaf6904200291ca8c3") {
        return "flex";
      } else {
        return "none";
      }
    }
  }

  return (
    <div id="threeDot_button">
      <IconButton id="versionSelectorOptions_button" component="span" onClick={handleClick}>
        <MoreVertIconRounded
          aria-owns={open ? "simple-popper" : undefined}
          aria-haspopup="true"
          variant="contained"
        ></MoreVertIconRounded>
      </IconButton>
      <Popover
        id="simplePopper_versionSelector"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {props.menuLayout ? (
          <Button
            id="renameVersion_button"
            className={classes.renameButton}
            style={{
              width: "150px",
              justifyContent: "flex-start",
            }}
            startIcon={<EditIcon className={classes.editIcon} />}
            onClick={() => {
              if (props.userPermissions["insert"] === false) {
                props.createSnackbar("You are not authorized to insert, contact your administrator.", "warning", false);
                return;
              }
              if (props.userPermissions["update"] === false) {
                props.createSnackbar("You are not authorized to update, contact your administrator.", "warning", false);
                return;
              }
              props.setIsRenameVersionDialogOpen(true);
              setAnchorEl(false);
            }}
          >
            Rename Version
          </Button>
        ) : (
          <Button
            id="renameFolder_button"
            style={{
              width: "150px",
              justifyContent: "flex-start",
            }}
            className={classes.renameFolder}
            startIcon={<EditIcon className={classes.editIcon} />}
            onClick={() => {
              if (props.userPermissions["insert"] === false) {
                props.createSnackbar("You are not authorized to insert, contact your administrator.", "warning", false);
                return;
              }
              if (props.userPermissions["update"] === false) {
                props.createSnackbar("You are not authorized to update, contact your administrator.", "warning", false);
                return;
              }

              props.setIsRenameFolderDialogOpen(true);
              setAnchorEl(false);
            }}
          >
            Rename Folder
          </Button>
        )}

        {props.menuLayout ? (
          <Button
            id="duplicateVersion_button"
            className={classes.renameFolder}
            startIcon={<FileCopy className={classes.fileCopy} />}
            onClick={() => {
              if (props.userPermissions["insert"] === false) {
                props.createSnackbar("You are not authorized to insert, contact your administrator.", "warning", false);
                return;
              }
              props.setIsDuplicateDialogOpen(true);
              setAnchorEl(false);
            }}
          >
            Duplicate Version
          </Button>
        ) : (
          <Button
            id="duplicateFolder_button"
            className={classes.renameFolder}
            style={{
              width: "150px",
              justifyContent: "flex-start",
            }}
            startIcon={<FileCopy className={classes.fileCopy} />}
            onClick={() => {
              if (props.userPermissions["insert"] === false) {
                props.createSnackbar("You are not authorized to insert, contact your administrator.", "warning", false);
                return;
              }
              props.setIsFolderDialogOpen(true);
              setAnchorEl(false);
            }}
          >
            Duplicate Folder
          </Button>
        )}

        <Button
          className={classes.renameFolder}
          id="delete_button"
          style={{
            width: "150px",
            justifyContent: "flex-start",
          }}
          startIcon={<DeleteIcon className={classes.deleteIcon} />}
          onClick={() => {
            if (props.userPermissions["delete"] === false) {
              props.createSnackbar("You are not authorized to delete, contact your administrator.", "warning", false);
              return;
            }
            props.setIsDeleteDialogOpen(true);
            setAnchorEl(false);
          }}
        >
          Delete
        </Button>
        <Button
          className={classes.renameFolder}
          id="deleteAll_button"
          style={{
            width: "150px",
            justifyContent: "flex-start",
            display: ButtonVisibilty(),
          }}
          startIcon={<DeleteIcon className={classes.deleteIcon} />}
          onClick={() => {
            if (props.userPermissions["deleteAll"] === false) {
              props.createSnackbar("You are not authorized to delete, contact your administrator.", "warning", false);
              return;
            }
            props.setIsDeleteAllDialogOpen(true);
            setAnchorEl(false);
          }}
        >
          Delete All
        </Button>
        {/* <Button
                    className={classes.renameFolder}
                    startIcon={<GetApp className={classes.getApp} />}
                    onClick={() => {
                        if (props.userPermissions["delete"] === false) {
                            props.createSnackbar("You are not authorized to delete, contact your administrator.", "warning", false);
                            return;
                        }
                        props.setIsDownloadDialogOpen(true);
                    }}>
                    Download Version
                </Button> */}
      </Popover>
    </div>
  );
}
