import React, { useState, useEffect } from "react";
import { Dialog, Box, TextField, Button, DialogTitle } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { getServerUri } from "../modules/uriUtility";
import _ from "lodash";
import ReactGA4 from "react-ga4";

let useStyles = makeStyles({
  insertDialog: { margin: 10, display: "flex", alignItems: "center" },
  insertButton: {
    marginLeft: "0.5rem",
  },
});

/**
 *
 * @param props
 * @param props.open
 * @param props.selectedGame
 * @param props.selectedBase
 * @param props.versions
 * @param props.setIsVersionBeingUpdatedFalse
 * @param props.setOpen
 * @param props.insertNewVersion
 * @param props.createSnackbar
 * @returns {JSX.Element|null}
 */

export default function dialogFolderRename(props) {
  let [newVersionTitle, setNewVersionTitle] = useState(props.selectedFolderId ? props.selectedFolderId.text : "");
  let [title, setTitle] = useState(newVersionTitle);

  let [versionHierarchy, setVersionHierarchy] = useState(1);
  let [baseIDProps, setBaseID] = useState([]);
  let [buttonClick, setButtonClick] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    if (versionHierarchy != 1) {
      var baseFolder = new Promise((resolve, reject) => {
        fetch(getServerUri("version/updateHierarchy"), {
          method: "POST",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          body: new URLSearchParams({
            accessToken: localStorage.getItem("session.accessToken"),
            baseID: _.get(props, "selectedBase._id"),
            versionHierarchy: JSON.stringify(versionHierarchy),
            companyID: props.companyID,
          }),
        }).then((response) => {
          if (response.status === 200) {
            console.log(response.text);
            props.setVersionHierarchy(versionHierarchy);
            props.setUpdateTreeOpen(title);
          }
        });
      });
    }
  }, [versionHierarchy]);

  useEffect(() => {
    setNewVersionTitle(props.selectedFolderId.text);
    setTitle(props.selectedFolderId.text);
  }, [props.selectedFolderId]);

  useEffect(() => {
    setNewVersionTitle(props.selectedFolderId ? props.selectedFolderId.text : "");
    setTitle(props.selectedFolderId ? props.selectedFolderId.text : "");
  }, [props.isOpen]);

  function getCurrentDate(separator = "") {
    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    let time = newDate.getHours();
    let minute = newDate.getMinutes() < 10 ? "0" + newDate.getMinutes() : newDate.getMinutes();
    return `${date < 10 ? `0${date}` : `${date}`}${separator}${
      month < 10 ? `0${month}` : `${month}`
    }${separator}${year} ${time}:${minute}`;

    //return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date}`
  }
  const handleKeyPress = (event) => {
    if (event.key === "Enter" || event.key === "Return") {
      handleButtonClick();
    }
  };
  const handleButtonClick = () => {

    if (props.selectedFolderId == 9999999) {
      const createdLanguages = _.get(props, "selectedVersion.createdLanguages") ? _.get(props, "selectedVersion.createdLanguages"): ["en"];

      fetch(getServerUri("version/updateOne"), {
        method: "POST",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams({
          accessToken: localStorage.getItem("session.accessToken"),
          versionID: String(_.get(props, "selectedVersion._id")),
          gameID: String(_.get(props, "selectedGame._id")),
          baseID: String(_.get(props, "selectedBase._id")),
          gameConfig: JSON.stringify(_.get(props, "selectedVersion.gameConfig")),
          recorderSettings: JSON.stringify(_.get(props, "selectedVersion.recorderSettings")),
          title: String(title),
          createdLanguages: JSON.stringify(createdLanguages)
        }),
      })
        .then((response) => {
          if (response.status === 200) {
            props.renameVersion(title);
            console.log({ message: "version updated", versions: props.versions });
            console.log({ message: "rename successful" });
            props.createSnackbar("Version Renamed", "success");
            props.setSelectedVersionPathValue({
              path: "lastUpdatingUserID",
              value: localStorage.getItem("user.id"),
            });
            setTitle(String(title));
            ReactGA4.event({
              category: "Preview",
              action: "Folder Rename",
              label:
                "game: " +
                props.selectedGame.title +
                ", base: " +
                props.selectedBase.title +
                "original folderName: " +
                props.selectedFolderId.text +
                ", duplicated folderName: " +
                title,
              dimension1: localStorage.getItem("user.id"),
            });
          } else if (response.status === 401) {
            props.createSnackbar("You are not authorized to update, contact your administrator.", "warning");
            response
              .text()
              .then((textResponse) => {
                console.log({ message: "error updating", textResponse });
              })
              .catch(console.error);
          } else {
            response
              .text()
              .then((textResponse) => {
                console.log({ message: "error renaming", textResponse });
                props.createSnackbar("An error occurred when renaming a new version.", "error");
              })
              .catch(console.error);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      logla();
    }
  };
  const logla = () => {
    props.setOpen(false);
    fetch(getServerUri("version/getHierarchy"), {
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({
        accessToken: localStorage.getItem("session.accessToken"),
        baseID: _.get(props, "selectedBase._id"),
        companyID: props.companyID,
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((json) => {
            json.versionHierarchy.map(function (versionHier) {
              if (versionHier.id === props.selectedFolderId.id) {
                versionHier.text = title;
                versionHier.data.updateDate = getCurrentDate(".");
              } else {
                console.log("else");
              }
            });

            let newVersionTree = {};
            newVersionTree["tree"] = json.versionHierarchy;
            setVersionHierarchy(newVersionTree);
            setTitle("New Folder");
          });
        } else if (response.status === 401) {
          props.createSnackbar("You are not authorized to update, contact your administrator.", "warning");
          response
            .text()
            .then((textResponse) => {
              console.log({ message: "error updating", textResponse });
            })
            .catch(console.error);
        } else {
          response
            .text()
            .then((textResponse) => {
              console.log({ message: "error renaming", textResponse });
              props.createSnackbar("An error occurred when renaming a new version.", "error");
            })
            .catch(console.error);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <Dialog
      id="folderRename_dialog"
      open={props.open}
      onClose={() => {
        props.setOpen(false);
      }}
    >
      <DialogTitle
        id="folderRename_dialogTitle"
        style={{
          textAlign: "center",
        }}
      >
        Rename Folder
      </DialogTitle>
      <div id="folderRename_div" className={classes.insertDialog}>
        <TextField
          id="folderRename_textField"
          value={newVersionTitle}
          label={"Folder Title"}
          variant={"filled"}
          onKeyPress={handleKeyPress}
          inputProps={{ maxLength: 31, autoFocus: true }}
          onChange={(event) => {
            if (event.target.value.length < 31) {
              setTitle("New Folder");

              var a = event.target.value;
              var a = a.replace(/\s+/g, " ").replace(/^\s/, "").replace(/\s$/, "");
              if (a.length == 0) {
                setNewVersionTitle("");
                setTitle("New Folder");
                setButtonClick(false);
              } else if (a == "") {
                setTitle(props.selectedFolderId.text);
                setButtonClick(false);
              } else {
                setTitle(event.target.value);
                setNewVersionTitle(event.target.value);
                setButtonClick(false);
              }
            } else {
              setButtonClick(true);
              props.createSnackbar("No more than 30 characters can be entered", "error");
            }
          }}
        ></TextField>
        <Button //ok un onclick i
          id="dialogFolderRename_button"
          className={classes.insertButton}
          label={"Insert"}
          disabled={buttonClick}
          onClick={handleButtonClick}
        >
          OK
        </Button>
      </div>{" "}
    </Dialog>
  );
  return null;
}
