import React, { useEffect, useState } from "react";

import { TextField, Typography } from "@material-ui/core";

/**
 *
 * @param props
 * @param props.caption
 * @param props.value
 * @param props.classes
 * @param props.setValue
 * @returns {JSX.Element}
 */
export default function editorText(props) {
  let [value, setValue] = useState(_.get(props, "value", ""));
  useEffect(() => {
    setValue(_.get(props, "value", ""));
  }, [props.value]);
  function gettingMargin(numberMargin) {
    if (props.tooltip != undefined) {
      if (numberMargin) {
        return numberMargin.toString() + "px";
      }
    } else return (0).toString() + "px";
  }

  useEffect(() => {
    checkMargin(props.tooltipLength);
  }, [props.isVisible]);

  function checkMargin(numberMargin) {
    try {
      if (props.tooltip != undefined) {
        if (numberMargin) {
          document.getElementById(`${props.id}.textField-label`).style.marginTop = "-48.5px";
        }
      }
    } catch {}
  }

  return (
    <>
      {" "}
      {props.isVisible && (
        <div className={props.classes.editorItem}>
          <Typography style={{ marginTop: gettingMargin(props.tooltipLength) }} id="discrete-slider" gutterBottom>
            {props.caption}
          </Typography>
          <TextField
            onKeyDown={(event) => {
              event.stopPropagation();
            }}
            id={`${props.id}.textField`}
            className={props.classes.textField}
            value={value}
            onChange={(event) => {
              if (props.userPermissions["update"] === false) {
                props.createSnackbar(
                  "You do not have permission to update, contact your administrator.",
                  "warning",
                  false
                );
                return;
              }
              setValue(event.target.value);
              props.setValue(event.target.value);
            }}
          ></TextField>
          {checkMargin()}
        </div>
      )}{" "}
    </>
  );
}
