import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Card, CardContent, Typography } from "@material-ui/core";
import Imgpreview from "../../../images/documentationImages/imgpreview.png";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    backgroundColor: "#404040",
  },
  content: {
    backgroundColor: "#404040",
    color: "white",
    align: "center",
    textAlign: "left",
    marginTop: "1%",
    marginLeft: "5%",
    marginRight: "20%",
  },
  card: {
    margin: "5%",
  },
  img: {
    borderRadius: "4px",
    border: "1px solid #727171",
    boxShadow: "rgb(0 0 0 / 20%) 1px 1px 10px 0px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px",
    width: "40%",
    height: "auto",
    marginBottom: "16px",
  },
  imgContainer: {
    display: "flex",
    justifyContent: "center",
  },
}));

const Preview = () => {
  const classes = useStyles();

  return (
    <div className={classes.root} id="PreviewGame">
      <main className={classes.content}>
        <Card>
          <CardContent>
            <Typography variant="h5">Preview Game</Typography>
            <br />
            <Typography paragraph>
              In this section, the version will be shown on the screen. The versions can be tested and played in the
              preview field. The setting adjustments for the version can be seen in real time.
            </Typography>
            <Typography paragraph>The game can be paused or restarted by pause and refresh buttons.</Typography>
            <Typography paragraph>
              By clicking the device icons, the responsiveness of the game can be tested. Device buttons will resize and
              rotate the preview to show playables on different devices and orientations. Screen icon will open up a
              window to adjust size. With this, custom sizes can be put to check responsiveness. Thereby, the needed
              adjustments for different devices and orientations can be done.
            </Typography>
            <div className={classes.imgContainer}>
              <img className={classes.img} src={Imgpreview} alt="" />
            </div>
          </CardContent>
        </Card>
      </main>
    </div>
  );
};

export default Preview;
