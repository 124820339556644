import React from "react";
import { Button, Dialog, DialogTitle } from "@material-ui/core";
import { getServerUri } from "../modules/uriUtility";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";

const useClasses = makeStyles({
  dialogDelete: {
    margin: 10,
  },
});
/**
 *
 * @param {Boolean} props.isOpen is it open?
 * @param {Function} props.setIsOpen set is open to true or false
 * @param props.videoToDelete
 * @param props.selectedVideoID
 * @param props.videos
 * @param props.setVideos
 * @param props.createSnackbar
 * @param props.selectVideo
 * @param props.deleteVideoFromMedia
 * @returns {JSX.Element}
 */
export default function dialogVideoDelete(props) {
  const classes = useClasses();
  return (
    <Dialog
      open={props.isOpen}
      onClose={() => {
        props.setIsOpen(false);
      }}
    >
      <DialogTitle>Delete video?</DialogTitle>
      <div className={classes.dialogDelete}>
        <Button
          label={"OK"}
          onClick={(event) => {
            console.log("deleting video");
            fetch(getServerUri("video/deleteOne"), {
              method: "DELETE",
              headers: {
                "content-type": "application/x-www-form-urlencoded",
              },
              body: new URLSearchParams({
                accessToken: localStorage.getItem("session.accessToken"),
                videoID: props.videoToDelete._id,
              }),
            })
              .then((response) => {
                if (response.status === 200) {
                  debugger;
                  let newVideos = _.cloneDeep(props.videos);
                  newVideos.splice(
                    newVideos.indexOf(newVideos.filter((video) => video._id === props.videoToDelete._id)[0]),
                    1
                  );
                  props.deleteVideoFromVideos(props.videoToDelete._id);
                  props.setVideos(newVideos);
                  if (newVideos.length > 0) {
                    if (props.videoToDelete._id === props.selectedVideoID) {
                      props.selectVideo(newVideos[0]._id);
                    }
                  } else props.selectVideo("");
                  console.log("deleted video");
                  props.createSnackbar("Video deleted", "success");
                  props.setIsOpen(false);
                } else {
                  response
                    .text()
                    .then((responseText) => {
                      console.log(responseText);
                    })
                    .catch(console.error);
                }
              })
              .catch((err) => {
                console.log("fetch error");
                console.error(err);
                props.createSnackbar("An error occurred deleting the video.", "error");
              });
            props.setIsOpen(false);
          }}
        >
          OK
        </Button>
        <Button
          label={"Cancel"}
          onClick={() => {
            props.setIsOpen(false);
          }}
        >
          Cancel
        </Button>
      </div>
    </Dialog>
  );
}
