import React, { useEffect, useState } from "react";
import { MenuItem, TextField, Typography } from "@material-ui/core";
import { getFontList } from "../modules/getFontList";

export default function selectorFontFamily(props) {
  const [fontList, setFontList] = useState([]);
  const [value, setValue] = useState("");
  const [customFonts, setCustomFonts] = useState([]);

  function isValidFont(font, fonts) {
    return fonts.indexOf(font) !== -1;
  }

  useEffect(() => {
    checkMargin(props.tooltipLength);
  }, [props.isVisible]);

  function gettingMargin(numberMargin) {
    if (props.tooltip != undefined) {
      if (numberMargin) {
        return numberMargin.toString() + "px";
      }
    } else return (-10).toString() + "px";
  }

  function checkMargin(numberMargin) {
    try {
      if (props.tooltip) {
        if (numberMargin) {
          document.getElementById(`${props.id}.textField-label`).style.marginTop = "-48px";
        }
      } else {
        document.getElementById(`${props.id}.textField-label`).style.marginTop = "-25px";
      }
    } catch {}
  }

  useEffect(() => {
    let isMounted = true;
    getFontList((fontList) => {
      if (isMounted) {
        let customFonts = getCustomFonts();
        setCustomFonts(customFonts);
        customFonts.forEach((font) => {
          fontList.indexOf(font) === -1 ? fontList.push(font) : console.log("This font is already exists");
        });
        fontList.sort((a, b) => a.localeCompare(b));

        setFontList(fontList);
        if (isValidFont(props.value, fontList)) {
          setValue(props.value);
        } else {
          setValue("Avenir Next");
        }
      }
    });
    return () => {
      isMounted = false;
    };
  }, []);

  function getCustomFonts() {
    const selectedVersion = props.selectedVersion;
    const components = _.get(selectedVersion, "gameConfig.components", []);

    const detail = Object.entries(components).find(([componentID, detail]) => {
      if (componentID === "fontUploader1") {
        return detail;
      }
    });

    if (_.isNil(detail)) {
      return [];
    }

    const fontUploaderComponent = detail[1];

    let fonts = _.get(fontUploaderComponent, "fonts", []);

    let fontFamilyList = Object.values(fonts).map((font) => {
      return font.fontFamily;
    });

    return fontFamilyList;
  }

  useEffect(() => {
    if (isValidFont(props.value, fontList)) {
      setValue(props.value);
    } else {
      setValue("Avenir Next");
    }
  }, [props.value]);

  if (_.size(fontList) > 0) {
    return (
      <div id={`${props.id}.Div`}>
        {" "}
        {props.isVisible && (
          <div className={props.classes.editorItem}>
            <Typography id="discrete-slider" gutterBottom>
              {_.get(props, `label`)}
            </Typography>
            <TextField
              style={{ marginTop: gettingMargin(props.tooltipLength) }}
              id={`${props.id}.textField`}
              className={props.classes.textField}
              select
              // InputLabelProps={{
              //     shrink: false,
              // }}
              // label={_.get(props, `label`)}
              value={value}
              onChange={(event) => {
                if (props.userPermissions["update"] === false) {
                  props.createSnackbar(
                    "You do not have permission to update, contact your administrator.",
                    "warning",
                    false
                  );
                  return;
                }
                props.setValue(event.target.value);
                setValue(event.target.value);
              }}
            >
              {fontList.map((font) => {
                return (
                  <MenuItem
                    key={font}
                    value={font}
                    classes={{
                      root: customFonts.includes(font) ? props.classes.customFont : "",
                    }}
                  >
                    {font}
                  </MenuItem>
                );
              })}
            </TextField>
            {checkMargin(props.tooltipLength)}
          </div>
        )}
      </div>
    );
  } else return null;
}
