import React, { useEffect, useRef, useState, useCallback } from "react";
import { StylesProvider, ThemeProvider, makeStyles, styled } from "@material-ui/core/styles";
import CssBaseLine from "@material-ui/core/CssBaseline";
import { Tree } from "@minoru/react-dnd-treeview";
import { CustomNode } from "./customNode";
import { CustomDragPreview } from "./customDragPreview";
import { theme } from "./theme";
import moment from "moment";
import { getServerUri } from "../modules/uriUtility";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import useResizeObserver from "../customHooks/useResizeObserver";
import TextField from "@mui/material/TextField";
import Typography from "@material-ui/core/Typography";
import { CustomTypeIcon } from "./customTypeIcon";
import FileCopyIcon from "@mui/icons-material/InsertDriveFile";
import { Button } from "@material-ui/core";

const useStyles = makeStyles({
  showMoreButton: {
    // float: "right",
    margin: "0.5rem 0rem 0.5rem 0rem",
    padding: "0.5rem 0.5rem 0.5rem 0.5rem",
    textTransform: "none",
    textDecorationLine: "underline",
  },
  scrollBarCss: {
    "&::-webkit-scrollbar": {
      webkit: "none",
      width: "2px",
    },

    "&::-webkit-scrollbar-thumb": {
      borderRadius: "4px",
      backgroundColor: "#FCF5E5",
    },
  },
  searchBarCss: {
    '[type="search"]::-webkit-search-cancel-button': {
      webkit: "none",
      appearance: "none",
      backgroundColor: "white",
      color: "#FCF5E5",
    },
  },
});

function ResizableFileTreeLabel(props) {
  const elementRef = useResizeObserver(props.resizeObserverCallback);
  const { maxWidth, minWidth, initialWidth } = props.widthConfig;

  return (
    <div
      id={props.id}
      ref={elementRef}
      style={{
        margin: "auto",
        display: "inline-flex",
        resize: "horizontal",
        overflow: "hidden",
        height: "100%",
        minWidth: `${minWidth}`,
        maxWidth: `${maxWidth}`,
        width: `${initialWidth}`,
        borderLeft: props.hasLeftBorder ? "1px solid rgba(81, 81, 81, 1)" : "unset",
      }}
    >
      <div
        id="sort_click_div"
        onClick={(e) => props.sortFunc(e)}
        style={{
          margin: "auto",
          display: "inline-flex",
          overflow: "hidden",
          height: "100%",
          minWidth: "100%",
        }}
      >
        <Typography
          variant="body2"
          style={{
            textAlign: "left",
            width: "100%",
            padding: "10px 16px 8px 26px",
            margin: "auto 0",
            position: "relative",
            left: "0px",
          }}
        >
          {props.innerText}
        </Typography>
        <ArrowDropDown
          style={{
            transform: props.degree,
            visibility: props.visibility,
            margin: "auto",
          }}
        />
      </div>
    </div>
  );
}

function UpdateHierarchy(a, baseId, companyId) {
  if (baseId != null) {
    fetch(getServerUri("version/updateHierarchy"), {
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({
        accessToken: localStorage.getItem("session.accessToken"),
        baseID: baseId._id,
        versionHierarchy: JSON.stringify({ "tree": a }),
        companyID: companyId,
      }),
    }).then((response) => {
      if (response.status === 200) {
        // this is for folder render process
      }
    });
  }
}

function CustomDragComponent(props) {
  const [treeData, setTreeData] = useState(props.versionHierarchy);
  const [treeIsSorted, setSortedTree] = useState("+");
  const [treeIsSortedDate, setSortedTreeDate] = useState("+");
  const [treeIsSortedUpdateDate, setSortedUpdateDate] = useState("+");
  const [originalTreeData, setOTreeData] = useState(props.versionHierarchy);
  const [inputValue, setInputValue] = useState("");
  const [draggingNode, setDraggingNode] = useState("");
  const [nodeList, setNodeList] = useState([]);
  const [degree, setDegree] = useState("rotate(0deg)");
  const [degreeDate, setDegreeDate] = useState("rotate(0deg)");
  const [degreeUpdatedDate, setDegreeUpdatedate] = useState("rotate(0deg)");
  const [searchable, setSearchable] = useState(false);
  const [visibility, setVisibility] = useState("hidden");
  const [visibilityDate, setVisibilityDate] = useState("hidden");
  const [visibilityUpdatedDate, setVisibilityUpdatedDate] = useState("hidden");
  const [newHierarchy, setNewHierarchy] = useState("hidden");
  const [searchTerm, setSearchTerm] = useState(props.searchTerm);
  const [expanded, setExpanded] = React.useState([]);
  const [pathValue, setPathValue] = React.useState("");
  const [textValueWidth, setTextValueWidth] = useState(0);
  const divRef = useRef(null);
  const [prevScrollLeft, setPrevScrollLeft] = useState(0);

  const [isCustomNode, setCustomNode] = useState("");
  const ref = useRef(null);
  const versionSelectorDiv = useRef();
  const searchInput = useRef();
  const classes = useStyles();
  const dndTree = ".dndTree{height : 100%}";
  const [scrollX, setScrollX] = useState(0);
  const [divHeight, setDivHeight] = useState(null);
  let [isDefaultVersion, setIsDefaultVersion] = useState(false);

  useEffect(() => {
    fetchNewHierarchy();
    setSearchable(false);
    setSearchTerm("");
  }, [props.searchTerm]);
  useEffect(() => {
    if (props.heightInPx == null) {
      setDivHeight(getHeightOfComponent());
    } else {
      setDivHeight(props.heightInPx);
    }
  }, [props.heightInPx]);

  useEffect(() => {
    if (props.treeUpdate != "") {
      fetchHierarchy();
    }
  }, [props.treeUpdate]);
  useEffect(() => {
    if (divRef.current) {
      const width = divRef.current.getBoundingClientRect().width;
      setTextValueWidth(width);
    }
  }, [pathValue]);
  useEffect(() => {
    if (!_.isNil(props.selectedVersion)) {
      const nTreeData = originalTreeData;
      setPathValue(getVersionPathRecursive(nTreeData, props.selectedVersion._id));
    }
  }, [props.selectedVersion]);

  useEffect(() => {
    if (props.versionUpdate !== "") {
      fetchHierarchy();
    }
  }, [props.versionUpdate]);

  useEffect(() => {
    if (props.versionRename !== "") {
      fetchHierarchy();
    }
  }, [props.versionRename]);

  useEffect(() => {
    if (isCustomNode !== "") {
      props.selectedFolder(isCustomNode);
    }
  }, [isCustomNode]);

  useEffect(() => {
    searchInput.current.getElementsByTagName("input")[0].focus();
  }, [searchTerm]);

  const handleScroll = (e) => {
    const { scrollLeft } = e.target; // Yatay olarak kaydırılan mesafeyi alır.

    if (scrollLeft > prevScrollLeft) {
      const newNScrollX = (scrollLeft * textValueWidth) / 538;
      setScrollX(newNScrollX);
      document.getElementById("scrollableDiv").scrollLeft += newNScrollX;
    } else if (scrollLeft < prevScrollLeft) {
      const newNScrollX = (scrollLeft * textValueWidth) / 538;
      document.getElementById("scrollableDiv").scrollLeft -= newNScrollX;
    }

    // Yenileme
    setPrevScrollLeft(scrollLeft);
  };

  const getHeightOfComponent = () => {
    const element = document.getElementById("column1Top"); // Değiştirmeniz gereken bileşenin ID'sini belirtin
    if (element) {
      const height = element.clientHeight;
      return height;
    }
    return 0; // Bileşen bulunamadıysa veya yükseklik alınamazsa 0 dönebilirsiniz.
  };
  const componentHeight = getHeightOfComponent();
  function resetSearchBar() {
    const searchBar = document.getElementById("searchBar");

    if (searchBar) {
      setSearchTerm("");
    }
  }

  function fetchNewHierarchy() {
    fetch(getServerUri("version/getHierarchy"), {
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({
        accessToken: localStorage.getItem("session.accessToken"),
        baseID: props.selectedBase._id,
        companyID: props.companyID,
      }),
    }).then((response) => {
      if (response.status === 200) {
        response.json().then((json) => {
          setNewHierarchy(json.versionHierarchy); // bunun set edilmesi lazım.
        });
      }
    });
  }

  //bu pislik gibi olan yazının içinde enter olmasını sağlıyo
  function fetchHierarchy() {
    fetch(getServerUri("version/getHierarchy"), {
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({
        accessToken: localStorage.getItem("session.accessToken"),
        baseID: props.selectedBase._id,
        companyID: props.companyID,
      }),
    }).then((response) => {
      if (response.status === 200) {
        response.json().then((json) => {
          setTreeData(json.versionHierarchy); // bunun set edilmesi lazım.
          setOTreeData(json.versionHierarchy);
          if (props.selectedVersion) {
            setPathValue(getVersionPathRecursive(json.versionHierarchy, props.selectedVersion._id));
          }
          // setPathValue(getVersionPathRecursive(json.versionHierarchy, props.selectedVersion._id));

          props.updateHierarchyFolder(treeData);
        });
      }
    });
  }

  function sortedTree(property) {
    var sortOrder = 1;

    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }

    return function (a, b) {
      if (sortOrder == -1) {
        return b[property].localeCompare(a[property]);
      } else {
        return a[property].localeCompare(b[property]);
      }
    };
  }

  function updateNode(node, depth, hasChild) {
    let checkExist = false;

    nodeList.map((value) => {
      if (value.id == node.id) {
        value.depth = depth;
        value.hasChild = hasChild;
        value.parent = node.parent;
        checkExist = true;
      }
    });

    if (checkExist) {
      setNodeList(nodeList);
    } else {
      node.depth = depth;
      node.hasChild = hasChild;
      nodeList.push(node);
      setNodeList(nodeList);
    }
  }

  const updateParent = (myArray, dropTarget, dragSourceId) => {
    let dropTargetID = 0;

    if (dropTarget !== undefined) {
      dropTargetID = dropTarget.id;
    }

    const updatedArray = myArray.map((item) => {
      if (item.id === dragSourceId) {
        return {
          ...item,
          parent: dropTargetID,
        };
      }

      return item;
    });

    setSearchable(false);

    return updatedArray;
  };

  function checkChildIsFolder(parentID) {
    let fileExist = false;

    nodeList.map((value) => {
      if (value.parent == parentID) {
        if (value.data.fileType != "text") {
          fileExist = true;
        }
      }
    });
    return fileExist;
  }

  function getDropTarget(dropTargetId) {
    let dropTarget;

    nodeList.map((value) => {
      if (value.id == dropTargetId) {
        dropTarget = value;
      }
    });
    return dropTarget;
  }

  function getDragTarget(dragTargetId) {
    let dragTarget;

    nodeList.map((value) => {
      if (value.id == dragTargetId) {
        dragTarget = value;
      }
    });
    return dragTarget;
  }

  function convertFromStringToDate(responseDate) {
    var now = moment.utc(responseDate, "DD.MM.YYYY HH:mm").format("DD.MM.YYYY HH:mm ");
    let dateComponents = now.split(" ");
    let datePieces = dateComponents[0].split(".");
    let timePieces = dateComponents[1].split(":");

    return new Date(datePieces[2], datePieces[1] - 1, datePieces[0], timePieces[0], timePieces[1]);
  }

  //globaldan yeni folder eklenince renderlanma
  // const handleOpenAll = (isCustomNode) => ref.current.open(isCustomNode);
  // const handleCloseAll = (isCustomNode) => ref.current.close(isCustomNode);

  function sortByCreatedDate() {
    let newTreeData = [];
    if (treeIsSortedDate == "+") {
      newTreeData = treeData.sort(function (a, b) {
        return convertFromStringToDate(b.data.createdDate) - convertFromStringToDate(a.data.createdDate);
      });
      setSortedTreeDate("-");
      setDegreeDate("rotate(0deg)");
      setVisibilityDate("");
      setVisibility("hidden");
      setVisibilityUpdatedDate("hidden");
    } else if (treeIsSortedDate == "-") {
      newTreeData = treeData.sort(function (a, b) {
        return convertFromStringToDate(a.data.createdDate) - convertFromStringToDate(b.data.createdDate);
      });
      setSortedTreeDate("+");
      setDegreeDate("rotate(180deg)");
      setVisibilityDate("");
      setVisibility("hidden");
      setVisibilityUpdatedDate("hidden");
    } else if (treeIsSortedDate == "") {
      newTreeData = treeData.sort(function (a, b) {
        return convertFromStringToDate(a.data.createdDate) - convertFromStringToDate(b.data.createdDate);
      });
      setSortedTreeDate("+");
      setDegreeDate("rotate(180deg)");
      setVisibilityDate("");
      setVisibility("hidden");
      setVisibilityUpdatedDate("hidden");
    }

    console.log(newTreeData);

    props.updateHierarchyFolder(newTreeData);
  }

  function sortByUpdatedDate() {
    let a = [];

    if (treeIsSortedUpdateDate == "+") {
      a = treeData.sort(function (a, b) {
        return convertFromStringToDate(b.data.updateDate) - convertFromStringToDate(a.data.updateDate);
      });
      setSortedUpdateDate("-");
      setDegreeUpdatedate("rotate(0deg)");
      setVisibilityUpdatedDate("");
      setVisibilityDate("hidden");
      setVisibility("hidden");
    } else if (treeIsSortedUpdateDate == "-") {
      a = treeData.sort(function (a, b) {
        return convertFromStringToDate(a.data.updateDate) - convertFromStringToDate(b.data.updateDate);
      });
      setSortedUpdateDate("+");
      setDegreeUpdatedate("rotate(180deg)");
      setVisibilityUpdatedDate("");
      setVisibilityDate("hidden");
      setVisibility("hidden");
    } else if (treeIsSortedUpdateDate == "") {
      a = treeData.sort(function (a, b) {
        return convertFromStringToDate(a.data.updateDate) - convertFromStringToDate(b.data.updateDate);
      });
      setSortedUpdateDate("+");
      setDegreeUpdatedate("rotate(180deg)");
      setVisibilityUpdatedDate("");
      setVisibilityDate("hidden");
      setVisibility("hidden");
    }
    props.updateHierarchyFolder(a);
  }

  function sortByTitle(event) {
    event.stopPropagation();

    let a = [];

    if (treeIsSorted == "+") {
      a = treeData.sort(sortedTree("-text"));
      setSortedTree("-");
      props.setSortedTree("-");
      setDegree("rotate(0deg)");
      setVisibility("");
      setVisibilityDate("hidden");
      setVisibilityUpdatedDate("hidden");
    } else if (treeIsSorted == "-") {
      a = treeData.sort(sortedTree("text"));
      setSortedTree("+");
      props.setSortedTree("+");

      setDegree("rotate(180deg)");
      setVisibility("");
      setVisibilityDate("hidden");
      setVisibilityUpdatedDate("hidden");
    } else if (treeIsSorted == "") {
      a = treeData.sort(sortedTree("text"));
      setSortedTree("+");
      setDegree("rotate(180deg)");
      setVisibility("");
      setVisibilityDate("hidden");
      setVisibilityUpdatedDate("hidden");
    }

    props.updateHierarchyFolder(a);
  }

  const handleBlur = (event) => {
    fetchNewHierarchy();
  };

  const [resizedWidths, setResizedWidths] = useState({
    title: titleRef?.current?.offsetWidth || 0,
    createdDate: createdDateRef?.current?.offsetWidth || 0,
    updatedDate: updatedDateRef?.current?.offsetWidth || 0,
  });

  const onTitleResize = useCallback((titleRef) => {
    setResizedWidths((prevState) => ({
      ...prevState,
      title: titleRef?.contentRect?.width,
    }));
  }, []);

  const onCreatedDateResize = useCallback((createdDateRef) => {
    setResizedWidths((prevState) => ({
      ...prevState,
      createdDate: createdDateRef?.contentRect?.width,
    }));
  }, []);

  const onUpdatedDateResize = useCallback((updatedDateRef) => {
    setResizedWidths((prevState) => ({
      ...prevState,
      updatedDate: updatedDateRef?.contentRect?.width,
    }));
  }, []);

  const titleRef = useResizeObserver(onTitleResize);
  const createdDateRef = useResizeObserver(onCreatedDateResize);
  const updatedDateRef = useResizeObserver(onUpdatedDateResize);

  useEffect(() => {
    setSearchTerm(props.searchBarValue);

    handleChange(null);

    const version = getDefaultVersionInfo();

    if (!_.isNil(version)) {
      const versionInfo = props.versions.filter((vers) => vers._id === version[1].id)[0];

      props.setValue(versionInfo);
    }
  }, [props.searchBarValue]);

  const handleChange = (event) => {
    if (event?.target.value == "") {
      setSearchTerm(event.target.value);
      props.setSearchBarValue(event.target.value);

      setSearchable(false);
      fetchHierarchy();
    } else {
      // fetchNewHierarchy();
      setSearchable(true);
      let copiedArray = JSON.parse(JSON.stringify(originalTreeData));

      let searchTex = event ? event.target.value : props.searchBarValue;

      if (searchTex.substring(0, 1) == " ") {
        searchTex = searchTex.substring(1);
      }
      // let filteredArray = copiedArray.filter((item) => item.text.toLowerCase().includes(searchTex.toLowerCase()));
      let filteredArray = [];
      let newfilteredArray = copiedArray.filter((item) => {
        return item.data.fileType !== "text" && item.text.toLowerCase().includes(searchTex.toLowerCase());
      });
      copiedArray.map((element) => {
        if (newfilteredArray.some((item) => item.id === element.id || item.id === element.parent)) {
          filteredArray.push(element);
        } else {
          if (newfilteredArray.length == 0) {
            if (element.data.fileType == "text") {
              if (element.text.toLowerCase().includes(searchTex.toLowerCase())) {
                element.parent = 0;
                filteredArray.push(element);
              }
            }
          } else {
            if (element.text.toLowerCase().includes(searchTex.toLowerCase())) {
              filteredArray.push(element);
            }
          }
        }
      });

      let childArray = [];

      filteredArray.forEach((element) => {
        if (!newfilteredArray.some((elm) => elm.id == element.parent)) {
          element.parent = 0;
        }
      });

      setSearchTerm(event ? event.target.value : props.searchBarValue);
      setTreeData(filteredArray);
    }
  };

  // function getVersionPathRecursive(tree, targetVersionId) {
  //   const versionMap = {};
  //   tree.forEach((node) => {
  //     versionMap[node.id] = node.text;
  //   });

  //   function findPathToVersion(currentNodeId) {
  //     if (!currentNodeId) {
  //       return [];
  //     }

  //     const currentNode = tree.find((node) => node.id === currentNodeId);
  //     if (!currentNode) {
  //       return [];
  //     }

  //     const parentPath = findPathToVersion(currentNode.parent);
  //     parentPath.push(currentNode.text);

  //     return parentPath;
  //   }

  //   const versionPath = findPathToVersion(targetVersionId);
  //   return versionPath.join(" > ");
  // }
  // function getVersionPathRecursive(tree, targetVersionId) {
  //   const versionMap = {};
  //   tree.forEach((node) => {
  //     versionMap[node.id] = node.text;
  //   });

  //   function findPathToVersion(currentNodeId) {
  //     if (!currentNodeId) {
  //       return [];
  //     }

  //     const currentNode = tree.find((node) => node.id === currentNodeId);
  //     if (!currentNode) {
  //       return [];
  //     }

  //     const parentPath = findPathToVersion(currentNode.parent);
  //     console.log("currentNode.parent", currentNode.parent, currentNode);
  //     const pathElement = currentNode.data.fileType ? (
  //       <React.Fragment key={currentNode.id}>
  //         <FileCopyIcon />
  //         <span style={{ marginLeft: "5px", marginRight: "5px" }}>{currentNode.text}</span>
  //       </React.Fragment>
  //     ) : (
  //       <React.Fragment key={currentNode.id}>
  //         <CustomTypeIcon />
  //         <span style={{ marginLeft: "5px", marginRight: "5px" }}>{currentNode.text}</span>

  //         {/* {currentNode.text} */}
  //         {"  >  "}
  //       </React.Fragment>
  //     );

  //     // const pathElement = (
  //     //   // ? (
  //     //   //   <React.Fragment key={currentNode.id}>
  //     //   //     <CustomTypeIcon />
  //     //   //     {">"}
  //     //   //     {currentNode.text}
  //     //   //   </React.Fragment>
  //     //   // ) : (
  //     //   <React.Fragment key={currentNode.id}>
  //     //     <CustomTypeIcon />
  //     //     {currentNode.text}
  //     //     {">"}
  //     //   </React.Fragment>
  //     // );

  //     parentPath.push(pathElement);

  //     return parentPath;
  //   }

  //   const versionPath = findPathToVersion(targetVersionId);
  //   return versionPath;
  // }
  function getVersionPathRecursive(tree, targetVersionId) {
    const versionMap = {};
    tree.forEach((node) => {
      versionMap[node.id] = node.text;
    });

    function findPathToVersion(currentNodeId) {
      if (!currentNodeId) {
        return [];
      }

      const currentNode = tree.find((node) => node.id === currentNodeId);
      if (!currentNode) {
        return [];
      }

      const parentPath = findPathToVersion(currentNode.parent);

      const pathElement = currentNode.data.fileType ? (
        <React.Fragment key={currentNode.id}>
          <FileCopyIcon />
          <span style={{ marginLeft: "5px", marginRight: "5px" }}>{currentNode.text}</span>
        </React.Fragment>
      ) : (
        <React.Fragment key={currentNode.id}>
          <CustomTypeIcon />
          <span style={{ marginLeft: "5px" }}>{currentNode.text}</span>
          <span style={{ marginLeft: "5px", marginRight: "5px" }}>{">"}</span>
        </React.Fragment>
      );

      parentPath.push(pathElement);

      return parentPath;
    }

    const versionPath = findPathToVersion(targetVersionId);
    return versionPath;
  }

  const handleDrop = (newTree, { dragSourceId, dropTargetId, dragSource, dropTarget }) => {
    resetSearchBar();
    let nTree = newTree;
    if (searchable === true) {
      let updatedTree = updateParent(newHierarchy, dropTarget, dragSourceId);
      nTree = updatedTree;
    }
    setTreeData(nTree);
    setPathValue(getVersionPathRecursive(nTree, props.selectedVersion._id));
    fetch(getServerUri("version/updateHierarchy"), {
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({
        accessToken: localStorage.getItem("session.accessToken"),
        baseID: props.selectedBase._id,
        companyID: props.companyID,
        versionHierarchy: JSON.stringify({ "tree": nTree }),
      }),
    }).then((response) => {
      if (response.status === 200) {
        setOTreeData(nTree);
      }
    });
  };

  function getDefaultVersionInfo() {
    const version = Object.entries(treeData).filter(([key, value]) => {
      return value.id == props.defaultVersion?.versionID;
    })[0];

    return version;
  }

  const SearchBar = styled(TextField)({
    "& label.Mui-focused": {
      color: "#FCF5E5",
      width: "317px",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#FCF5E5",
      width: "317px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        // borderColor: '#8f8f8d',
        borderColor: "#FCF5E5)",
        width: "317px",
      },
      "&:hover fieldset": {
        borderColor: "#FCF5E5",
        width: "317px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#FCF5E5",
        width: "317px",
      },
    },
  });

  return (
    <>
      <div
        id={"versionSelectorDiv"}
        ref={versionSelectorDiv}
        onScroll={handleScroll}
        style={{ overflow: "hidden", height: `${divHeight - 54}px` }}
      >
        <div
          id="versionSelector_emptyDiv"
          style={{
            marginTop: "10px",
            position: "fixed",
            marginLeft: "10px",
            width: "100%",
          }}
        ></div>
        <div id="searchBar_div" style={{ marginLeft: "10px", width: "100%", marginTop: "8px" }}>
          <SearchBar
            className={classes.searchBarCss}
            sx={{ input: { color: "#FCF5E5" }, label: { color: "#FCF5E5" }, width: "322px", visibility: "true" }}
            fullWidth
            size="small"
            id="searchBar"
            label="Search.."
            defaultValue={searchTerm}
            ref={searchInput}
            autoComplete="off"
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </div>
        <div
          id="customDragComponent_div"
          style={{
            height: `${divHeight - 100}px`,
            width: "100%",
            overflowX: "auto",
            overflowY: "hidden",
          }}
        >
          <div
            id="grid_div"
            style={{
              display: "inline-flex",
              marginTop: "5px",
              marginBottom: "0px",
              borderBottom: "1px solid rgba(81, 81, 81, 1)",
              height: "53px",
            }}
          >
            <ResizableFileTreeLabel
              innerText="Title"
              degree={degree}
              visibility={visibility}
              sortFunc={sortByTitle}
              resizeObserverCallback={onTitleResize}
              widthConfig={{
                minWidth: "100px",
                maxWidth: "unset",
                initialWidth: "180px",
              }}
            />
            <ResizableFileTreeLabel
              innerText="Created Date"
              degree={degreeDate}
              visibility={visibilityDate}
              sortFunc={sortByCreatedDate}
              resizeObserverCallback={onCreatedDateResize}
              widthConfig={{
                minWidth: "120px",
                maxWidth: "180px",
                initialWidth: "180px",
              }}
              hasLeftBorder={true}
            />
            <ResizableFileTreeLabel
              innerText="Updated Date"
              degree={degreeUpdatedDate}
              visibility={visibilityUpdatedDate}
              sortFunc={sortByUpdatedDate}
              resizeObserverCallback={onUpdatedDateResize}
              widthConfig={{
                minWidth: "120px",
                maxWidth: "180px",
                initialWidth: "180px",
              }}
              hasLeftBorder={true}
            />
          </div>
          {/* , height: "90%"  */}
          <div style={{ position: "relative" }}>
            <StylesProvider injectFirst>
              <ThemeProvider theme={theme}>
                <CssBaseLine />
                <div
                  id="dndTree_outlineDiv1"
                  style={{
                    // height: `${divHeight - 153}px`,
                    height: `${divHeight - 168}px`,
                    overflowY: "auto",
                    overflowX: "hidden",
                    minWidth: `${resizedWidths.title + resizedWidths.createdDate + resizedWidths.updatedDate}px`,
                    // buraya
                  }}
                >
                  <style>{dndTree}</style>
                  <Tree
                    id="dndTree"
                    tree={treeData}
                    ref={ref}
                    rootId={0}
                    sort={false}
                    classes={{ container: "dndTree", root: "tr" }}
                    render={(node, { depth, isOpen, onToggle, isDragging, isDropTarget, hasChild, dropTarget }) => (
                      <CustomNode
                        parentWidths={{
                          titleWidth: resizedWidths.title,
                          createdDateWidth: resizedWidths.createdDate,
                          updatedDateWidth: resizedWidths.updatedDate,
                        }}
                        node={node}
                        depth={depth}
                        isOpen={isOpen}
                        dropTarget={dropTarget}
                        calculatedIndent={160 - (depth * 25).toString() + "px"} //140 - 25  160-15
                        onToggle={onToggle}
                        isDragging={isDragging}
                        isDropTarget={isDropTarget}
                        draggingNode={draggingNode}
                        hasChild={hasChild}
                        updateNode={(value) => {
                          updateNode(value, depth, hasChild);
                        }}
                        setCustomNode={(value) => {
                          setCustomNode(value);
                        }}
                        p={props}
                        defaultVersion={props.defaultVersion}
                        defaultVersionUpdated={props.defaultVersionUpdated}
                        isDefaultVersion={props.defaultVersion?.versionID == node.id}
                        defaultVersionInfo={getDefaultVersionInfo}
                      />
                    )}
                    dragPreviewRender={(monitorProps) => <CustomDragPreview monitorProps={monitorProps} />}
                    onDrop={handleDrop}
                    canDrop={(treeData, { dragSource, dropTarget, dropTargetId, dragSourceId }) => {
                      if (dragSource != dropTarget) {
                        let dropT = getDropTarget(dropTargetId);
                        let dragT = getDragTarget(dragSourceId);

                        if (dropT != null) {
                          if (dropT.data.fileType != "text") {
                            if (dropT.depth >= 1) {
                              if (dragSource.data.fileType == "text") {
                                return true;
                              } else {
                                return false;
                              }
                              //return false;
                            } else {
                              if (dragT.hasChild) {
                                if (checkChildIsFolder(dragSourceId)) {
                                  return false;
                                } else {
                                  return true;
                                }
                              } else {
                                return true;
                              }
                            }
                          }
                        }
                      }
                    }}
                  />
                </div>
              </ThemeProvider>
            </StylesProvider>
          </div>
        </div>
      </div>
      <div
        id="scrollableDiv"
        style={{
          width: "100%",
          height: "74px !important",
          borderTop: "1px solid black",
          overflowX: "auto",
          overflowY: "hidden",
          display: "flex",
          // transform: `translateX(-${scrollX}px)`,
        }}
      >
        <div
          ref={divRef}
          style={{
            // overflowX: "auto",
            // overflowY: "hidden",
            marginLeft: "6px",
            display: "flex",
            marginTop: "25px",
            marginBottom: "25px",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "0.9625rem",
            // maxWidth: "321px",
            whiteSpace: "nowrap",
          }}
        >
          {props.selectedVersion ? pathValue : props.selectedVersion?.title}

          {/* {props.selectedVersion} */}
          {/* <div style={{ width: "100%" }}> </div> */}
        </div>
      </div>
    </>
  );
}

export default CustomDragComponent;
