import React, { useEffect, useState } from "react";
import { Typography, MenuItem, Select, IconButton } from "@material-ui/core";
import { Remove } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import { getServerUri } from "../modules/uriUtility";
import _ from "lodash";
import DialogSoundDelete from "./dialogSoundDelete";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  iconButton: { padding: 0 },
});

/**
 * @param props
 * @param props.sounds
 * @param props.selectedSound
 * @param props.classes
 * @param props.setValue
 * @param props.path
 * @param props.destination
 * @param props.bucket
 * @param props.componentType
 * @param props.insertSoundPathValue
 * @param props.componentID
 * @param props.selectedBase
 * @param props.userPermissions
 * @param props.createSnackbar
 * @param props.deleteSoundFromSounds
 * @param props.selectedVersion
 * @returns {JSX.Element|null}
 */
export default function selectorSound(props) {
  const [sounds, setSounds] = useState(soundsToView(_.get(props, "sounds", [])));
  const defaultSoundID = String(_.get(sounds, `[0]._id`, ``));
  const [selectedSoundID, setSelectedSoundID] = useState(_.get(props, "selectedSound._id", defaultSoundID));
  const [isDeleteSoundDialogOpen, setIsDeleteSoundDialogOpen] = useState(false);
  const [soundToDelete, setSoundToDelete] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    if (isSelectionValid(_.get(props, "selectedSound._id", ""))) {
      setSelectedSoundID(_.get(props, "selectedSound._id", ""));
    } else if (isSelectionValid(defaultSoundID)) {
      selectSound(defaultSoundID);
    }
  }, [_.get(props, "selectedVersion")]);

  function soundsToView(sounds) {
    return sounds.filter((sound) => {
      return _.get(sound, "bucket") === _.get(props, "bucket");
    });
  }
  function gettingMargin(numberMargin) {
    if (props.tooltip != undefined) {
      if (numberMargin) {
        return numberMargin.toString() + "px";
      }
    } else return (0).toString() + "px";
  }

  useEffect(() => {
    setSounds(soundsToView(_.get(props, "sounds", [])));
  }, [_.get(props, "sounds", []), _.size(props.sounds)]);

  function isSelectionValid(id, newSounds) {
    let sounds2;
    if (_.isNil(newSounds)) {
      sounds2 = sounds;
    } else {
      sounds2 = newSounds;
    }
    const foundSounds = sounds2.filter((sound) => {
      return String(sound._id) === String(id);
    });
    return foundSounds.length > 0 || id === "";
  }

  const inputID = `${_.get(props, "componentID")}-${_.get(props, "path")}`;

  function selectSound(id, newSounds) {
    if (!isSelectionValid(id, newSounds)) return;
    setSelectedSoundID(id);
    let sounds2;
    if (_.isNil(newSounds)) {
      sounds2 = sounds;
    } else {
      sounds2 = newSounds;
    }
    const selectedSoundRecord = sounds2.filter((sound) => {
      return String(sound._id) === String(id);
    })[0];
    const newSelectedSoundRecord = _.clone(selectedSoundRecord);
    _.set(newSelectedSoundRecord, "destination", _.get(props, "destination"));
    props.setValue({
      value: [newSelectedSoundRecord],
    });
  }

  function setInputFileListEmpty(inputId) {
    let list = new DataTransfer();
    document.getElementById(inputId).files = list.files;
  }

  const checkFileSize = (file) => {
    return file.size > 1024 * 1024; // 1 MB
  };

  return (
    <div id={`${props.id}.Div`}>
      {" "}
      {props.isVisible && (
        <>
          {_.get(props, `label`) && (
            <Typography style={{ marginTop: gettingMargin(props.tooltipLength) }} id="discrete-slider" gutterBottom>
              {_.get(props, `label`)}
            </Typography>
          )}
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}>
            <input
              id={inputID}
              type={"file"}
              hidden={"hidden"}
              multiple={true}
              accept=".mp3, .wav, .m4a, .ogg"
              onChange={(e) => {
                //upload the file immediately
                for (let i = 0; i < e.target.files.length; i++) {
                  let file = e.target.files[i];
                  // checkImageWidthHeight(file, () => {
                  if (checkFileSize(file)) {
                    props.createSnackbar("File size should be less than 1 MB.", "error");
                    return;
                  }
                  const formData = new FormData();

                  formData.append("files", file);
                  formData.append("name", file.name);
                  formData.append("componentType", _.get(props, "componentType"));
                  formData.append("path", _.get(props, "path"));
                  formData.append("bucket", _.get(props, "bucket"));
                  formData.append("accessToken", localStorage.getItem("session.accessToken"));

                  fetch(getServerUri("sound/uploadSound"), {
                    method: "POST",
                    body: formData,
                  })
                    .then((response) => {
                      if (response.status !== 200) {
                        if (response.status === 401) {
                          props.createSnackbar(
                            "You are not authorized to insert, contact your administrator.",
                            "warning",
                            false
                          );
                        }
                        response
                          .text()
                          .then((textResponse) => {
                            console.error(textResponse);
                          })
                          .catch(console.error);
                      } else {
                        console.info(`Upload successful`);
                        response.json().then((objectResponse) => {
                          const newSounds = _.cloneDeep(sounds);
                          const newRecords = _.get(objectResponse, "records", []);
                          newSounds.push(...newRecords);
                          setSounds(newSounds);
                          props.insertSoundPathValue(newRecords);

                          const newSoundID = String(newRecords[0]._id);
                          setSelectedSoundID(newSoundID);
                          selectSound(newSoundID, newSounds);

                          //reset file list on input element
                          setInputFileListEmpty(inputID);
                        });
                      }
                    })
                    .catch(console.error);
                }
              }}
            />
            <Select
              onChange={(event) => {
                if (props.userPermissions["update"] === false) {
                  props.createSnackbar(
                    "You do not have permission to update, contact your administrator.",
                    "warning",
                    false
                  );
                  return;
                }
                if (isSelectionValid(String(event.target.value))) {
                  selectSound(String(event.target.value));
                }
              }}
              className={props.classes.imageSelector}
              value={String(selectedSoundID)}
            >
              {sounds.map((sound) => (
                <MenuItem
                  key={String(sound._id)}
                  value={String(sound._id)}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography id="discrete-slider" gutterBottom>
                    {sound.name}
                  </Typography>
                  {selectedSoundID !== sound._id && (
                    <div
                      style={{
                        float: "right",
                      }}
                    >
                      <IconButton
                        className={classes.iconButton}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (props.userPermissions["delete"] === false) {
                            props.createSnackbar(
                              "You do not have permission to delete, contact your administrator.",
                              "warning",
                              false
                            );
                            return;
                          }
                          setSoundToDelete(sound);
                          setIsDeleteSoundDialogOpen(true);
                        }}
                      >
                        <Remove />
                      </IconButton>
                    </div>
                  )}
                </MenuItem>
              ))}
            </Select>
            <IconButton
              id={`${props.id}.uploadButton`}
              className={classes.iconButton}
              onClick={(e) => {
                if (props.userPermissions["insert"] === false) {
                  props.createSnackbar(
                    "You are not authorized to insert, contact your administrator.",
                    "warning",
                    false
                  );
                  return;
                }
                if (props.userPermissions["update"] === false) {
                  props.createSnackbar(
                    "You are not authorized to update, contact your administrator.",
                    "warning",
                    false
                  );
                  return;
                }
                document.getElementById(inputID).click();
              }}
            >
              <AddIcon />
            </IconButton>
            <DialogSoundDelete
              isOpen={isDeleteSoundDialogOpen}
              setIsOpen={(value) => setIsDeleteSoundDialogOpen(value)}
              createSnackbar={props.createSnackbar}
              soundToDelete={soundToDelete}
              selectedSoundID={selectedSoundID}
              sounds={sounds}
              setSounds={setSounds}
              selectSound={selectSound}
              deleteSoundFromSounds={props.deleteSoundFromSounds}
            />
          </div>
        </>
      )}
    </div>
  );
}
