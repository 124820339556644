import React, { useEffect, useRef, useState } from "react";
import {
  CardContent,
  Card,
  Typography,
  TextField,
  Slider,
  FormControlLabel,
  FormControl,
  Checkbox,
  FormGroup,
  Switch,
  Button,
} from "@material-ui/core";
import { FiberManualRecord, PlayCircleOutline, Stop, PlayArrow } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import TextEditor from "./editorText";
import GenericSwitch from "./switchGeneric";
import SliderEndCardSeconds from "./sliderEndCardSeconds";
import SliderEndCardTaps from "./sliderEndCardTaps";
import SliderEndCardMoves from "./sliderEndCardMoves";
import SliderGoToMarketAfterXTaps from "./sliderGoToMarketAfterXTaps";
import SliderEndCardAutoForwardToStoreSeconds from "./sliderEndCardAutoForwardToStoreSeconds";
import _ from "lodash";

const useStyles = makeStyles({ card: { margin: "5px" } });

function valuetext(value) {
  return value + " Seconds";
}

/**
 *
 * @param props.setKeyValue
 * @param props.classes
 * @param props.setSelectedVersionPathValue
 * @returns {JSX.Element|null}
 */
export default function editorGeneralSettings(props) {
  const classes = useStyles();
  const language = props.selectedVersion.gameConfig.options.language;

  const setValue = (p) => {
    props.setSelectedVersionPathValue(p);
  };

  function isGameCardContentExist(){
    return (
      isOptionsHas("isSoundEnabled") ||
      isOptionsHas("isTutorialEnabled") ||
      isOptionsHas("isAutoplayEnabled") ||
      isOptionsHas("isBannerEnabled") ||
      isOptionsHas("shouldForcePortraitMode")
    );

  }

  function isOptionsHas(path) {
    return _.has(props, `selectedVersion.gameConfig.options.${path}`);
  }

  function isLocalizationLinkHas(){
    return _.has(props, "selectedVersion.gameConfig.options.localizationLink");
  }

  function isLocalizationLinkExistCurrentLanguage(path){

    return !(_.get(props, `selectedVersion.gameConfig.options.localizationLink.${path}`) == undefined || _.get(props, `selectedVersion.gameConfig.options.localizationLink.${path}`) == "");
  }

  function getAndroidLink(path){

    if(!isLocalizationLinkHas()){
      return _.get(props, "selectedVersion.gameConfig.options.link.android");
    }
    else {

      if(!isLocalizationLinkExistCurrentLanguage(path)){
        return _.get(props, "selectedVersion.gameConfig.options.localizationLink.en.android");
      }else{
        return _.get(props, `selectedVersion.gameConfig.options.localizationLink.${path}`);
      }
    }

  }
  function getIosLink(path){

    if(!isLocalizationLinkHas()){
      return _.get(props, "selectedVersion.gameConfig.options.link.ios");
    }
    else {
      if(!isLocalizationLinkExistCurrentLanguage(path)){
        return _.get(props, "selectedVersion.gameConfig.options.localizationLink.en.ios");
      }else{
        return _.get(props, `selectedVersion.gameConfig.options.localizationLink.${path}`);
      }
    }
  }

  function returnIosPath(path){

    if(!isLocalizationLinkHas()){
      return "gameConfig.options.link.ios";
    }
    return `gameConfig.options.localizationLink.${path}`;
  }

  function returnAndroidPath(path){

    if(!isLocalizationLinkHas()){
      return "gameConfig.options.link.android";
    }
    return `gameConfig.options.localizationLink.${path}`;
  }

  return (
    <FormGroup>
      <Card className={props.classes.card}>
        <CardContent>
          <Typography>App Store Links</Typography>
          <TextEditor
            id={returnAndroidPath(`${language}.android`)}
            classes={props.classes}
            caption={"Android Link"}
            value={getAndroidLink(`${language}.android`)}
            setValue={(value) => {
              const pathValue = `${language}.android`;
              setValue({
                path: returnAndroidPath(pathValue),
                value,
              });
            }}
            userPermissions={props.userPermissions}
            createSnackbar={props.createSnackbar}
            isVisible={true}
          />
          <TextEditor
            id={returnIosPath(`${language}.ios`)}
            classes={props.classes}
            caption={"iOS Link"}
            value={getIosLink(`${language}.ios`)}
            setValue={(value) => {
              const pathValue = `${language}.ios`;
              setValue({
                path: returnIosPath(pathValue),
                value,
              });
            }}
            userPermissions={props.userPermissions}
            createSnackbar={props.createSnackbar}
            isVisible={true}
          ></TextEditor>
        </CardContent>
      </Card>
      {isOptionsHas("isEndCardEnabled") && (
        <Card className={props.classes.card}>
          <CardContent>
            <Typography>End Card</Typography>
            <GenericSwitch
              value={ _.get(props, "selectedVersion.gameConfig.options.isEndCardEnabled", true)}
              label={"Enable End Card"}
              disabled={ _.get(props, "selectedVersion.gameConfig.options.isGoToMarketEnabled", false)}
              setValue={(value) => {
                setValue({
                  path: "gameConfig.options.isEndCardEnabled",
                  value,
                });
              }}
              userPermissions={props.userPermissions}
              createSnackbar={props.createSnackbar}
              isVisible={true}
            ></GenericSwitch>
            {isOptionsHas("endCardDelay") && (<SliderEndCardSeconds
              classes={props.classes}
              value={_.get(props, "selectedVersion.gameConfig.options.endCardDelay", 1)}
              label={"End Card Delay"}
              setValue={(value) => {
                setValue({
                  path: "gameConfig.options.endCardDelay",
                  value,
                });
              }}
              step={1}
              min={0}
              max={10}
              userPermissions={props.userPermissions}
              createSnackbar={props.createSnackbar}
              isVisible={true}
            ></SliderEndCardSeconds>)}
            {isOptionsHas("endCardSeconds") && <SliderEndCardSeconds
              classes={props.classes}
              value={_.get(props, "selectedVersion.gameConfig.options.endCardSeconds", 60)}
              label={"Show End Card After X Seconds"}
              setValue={(value) => {
                setValue({
                  path: "gameConfig.options.endCardSeconds",
                  value,
                });
              }}
              step={1}
              min={0}
              max={60}
              userPermissions={props.userPermissions}
              createSnackbar={props.createSnackbar}
              isVisible={true}
            ></SliderEndCardSeconds>}
            {isOptionsHas("endCardTaps") && (<SliderEndCardTaps
              classes={props.classes}
              value={_.get(props, "selectedVersion.gameConfig.options.endCardTaps", 60)}
              label={"Show End Card After X Taps"}
              setValue={(value) => {
                setValue({
                  path: "gameConfig.options.endCardTaps",
                  value,
                });
              }}
              userPermissions={props.userPermissions}
              createSnackbar={props.createSnackbar}
              isVisible={true}
            ></SliderEndCardTaps>)}
            {isOptionsHas("endCardMoves") && (<SliderEndCardMoves
              classes={props.classes}
              label={"Show End Card After X Moves"}
              value={_.get(props, "selectedVersion.gameConfig.options.endCardMoves", 30)}
              setValue={(value) => {
                setValue({
                  path: "gameConfig.options.endCardMoves",
                  value,
                });
              }}
              userPermissions={props.userPermissions}
              createSnackbar={props.createSnackbar}
              isVisible={true}
            ></SliderEndCardMoves>)}
            {isOptionsHas("endCardMovesPlus") && (<SliderEndCardMoves
              classes={props.classes}
              label={"Show End Card After X Moves Plus"}
              value={_.get(props, "selectedVersion.gameConfig.options.endCardMovesPlus", 30)}
              setValue={(value) => {
                setValue({
                  path: "gameConfig.options.endCardMovesPlus",
                  value,
                });
              }}
              userPermissions={props.userPermissions}
              createSnackbar={props.createSnackbar}
              isVisible={true}
            ></SliderEndCardMoves>)}

            <SliderEndCardAutoForwardToStoreSeconds
              classes={props.classes}
              label={"Auto Forward to Store After X Seconds"}
              value={_.get(props, "selectedVersion.gameConfig.options.endCardAutoForwardToStoreSeconds", 30)}
              setValue={(value) => {
                setValue({
                  path: "gameConfig.options.endCardAutoForwardToStoreSeconds",
                  value,
                });
              }}
              userPermissions={props.userPermissions}
              createSnackbar={props.createSnackbar}
              isVisible={true}
            ></SliderEndCardAutoForwardToStoreSeconds>
          </CardContent>
        </Card>
      )}
      {isOptionsHas("isGoToMarketEnabled") && (
        <Card className={props.classes.card}>
          <CardContent>
            <Typography>Go To Market</Typography>
            <GenericSwitch
              value={ _.get(props, "selectedVersion.gameConfig.options.isGoToMarketEnabled", false)}
              label={"Enable Market"}
              disabled={_.get(props, "selectedVersion.gameConfig.options.isEndCardEnabled", true)}
              setValue={(value) => {
                setValue({
                  path: "gameConfig.options.isGoToMarketEnabled",
                  value
                });
              }}
              userPermissions={props.userPermissions}
              createSnackbar={props.createSnackbar}
              isVisible={true}
            ></GenericSwitch>
            {isOptionsHas("openStoreForEveryMove") && (<GenericSwitch
              value={ _.get(props, "selectedVersion.gameConfig.options.openStoreForEveryMove", false)}
              label={"Open Store For Every Move"}
              disabled={false}
              setValue={(value) => {
                setValue({
                  path: "gameConfig.options.openStoreForEveryMove",
                  value
                });
              }}
              userPermissions={props.userPermissions}
              createSnackbar={props.createSnackbar}
              isVisible={true}
            ></GenericSwitch>)}
            {isOptionsHas("goToMarketTaps") && (<SliderGoToMarketAfterXTaps
              label={"Go To Market After X Taps"}
              classes={props.classes}
              value={_.get(props, "selectedVersion.gameConfig.options.goToMarketTaps", 60)}
              setValue={(value) => {
                setValue({
                  path: "gameConfig.options.goToMarketTaps",
                  value,
                });
              }}
              userPermissions={props.userPermissions}
              createSnackbar={props.createSnackbar}
              isVisible={true}
            ></SliderGoToMarketAfterXTaps>)}
            {isOptionsHas("goToMarketSeconds") && (<SliderEndCardSeconds
              classes={props.classes}
              value={_.get(props, "selectedVersion.gameConfig.options.goToMarketSeconds", 60)}
              label={"Go To Market After X Seconds"}
              setValue={(value) => {
                setValue({
                  path: "gameConfig.options.goToMarketSeconds",
                  value,
                });
              }}
              step={1}
              min={0}
              max={60}
              userPermissions={props.userPermissions}
              createSnackbar={props.createSnackbar}
              isVisible={true}
            ></SliderEndCardSeconds>)}
            {isOptionsHas("goToMarketMoves") && (<SliderEndCardMoves
              classes={props.classes}
              label={"Go To Market After X Moves"}
              value={_.get(props, "selectedVersion.gameConfig.options.goToMarketMoves", 30)}
              setValue={(value) => {
                setValue({
                  path: "gameConfig.options.goToMarketMoves",
                  value,
                });
              }}
              userPermissions={props.userPermissions}
              createSnackbar={props.createSnackbar}
              isVisible={true}
            ></SliderEndCardMoves>)}
            {isOptionsHas("goToMarketMovesPlus") && (<SliderEndCardMoves
              classes={props.classes}
              label={"Go To Market After X Moves Plus"}
              value={_.get(props, "selectedVersion.gameConfig.options.goToMarketMovesPlus", 30)}
              setValue={(value) => {
                setValue({
                  path: "gameConfig.options.goToMarketMovesPlus",
                  value,
                });
              }}
              userPermissions={props.userPermissions}
              createSnackbar={props.createSnackbar}
              isVisible={true}
            ></SliderEndCardMoves>)}
          </CardContent>
        </Card>
      )}
      {isOptionsHas("isInGameCTAEnabled") && (
        <Card className={props.classes.card}>
          <CardContent>
            <Typography>Call To Action</Typography>
            <GenericSwitch
              label={"Entire Screen CTA"}
              value={_.get(props, "selectedVersion.gameConfig.options.isEntireScreenCTAEnabled", false)}
              setValue={(value) => {
                setValue({
                  path: "gameConfig.options.isEntireScreenCTAEnabled",
                  value,
                });
              }}
              userPermissions={props.userPermissions}
              createSnackbar={props.createSnackbar}
              isVisible={true}
            ></GenericSwitch>
            <GenericSwitch
              label={"In Game CTA"}
              value={_.get(props, "selectedVersion.gameConfig.options.isInGameCTAEnabled", false)}
              setValue={(value) => {
                setValue({
                  path: "gameConfig.options.isInGameCTAEnabled",
                  value,
                });
              }}
              userPermissions={props.userPermissions}
              createSnackbar={props.createSnackbar}
              isVisible={true}
            ></GenericSwitch>
          </CardContent>
        </Card>
      )}
      {isGameCardContentExist() && <Card className={props.classes.card}>
        <CardContent>
          <Typography>Game</Typography>
          {isOptionsHas("isSoundEnabled") && (
            <GenericSwitch
              label={"Sound"}
              value={_.get(props, "selectedVersion.gameConfig.options.isSoundEnabled", false)}
              setValue={(value) => {
                setValue({
                  path: "gameConfig.options.isSoundEnabled",
                  value,
                });
                try {
                  document.getElementById("gamePreviewIframe").focus();
                } catch (err) {
                  console.error(err);
                }
              }}
              userPermissions={props.userPermissions}
              createSnackbar={props.createSnackbar}
              isVisible={true}
            ></GenericSwitch>
          )}
          {isOptionsHas("isTutorialEnabled") && (
            <GenericSwitch
              label={"Tutorial"}
              value={_.get(props, "selectedVersion.gameConfig.options.isTutorialEnabled", false)}
              setValue={(value) => {
                setValue({
                  path: "gameConfig.options.isTutorialEnabled",
                  value,
                });
              }}
              userPermissions={props.userPermissions}
              createSnackbar={props.createSnackbar}
              isVisible={true}
            ></GenericSwitch>
          )}
          {isOptionsHas("isAutoplayEnabled") && (
            <GenericSwitch
              label={"Autoplay"}
              value={_.get(props, "selectedVersion.gameConfig.options.isAutoplayEnabled", false)}
              setValue={(value) => {
                setValue({
                  path: "gameConfig.options.isAutoplayEnabled",
                  value,
                });
              }}
              userPermissions={props.userPermissions}
              createSnackbar={props.createSnackbar}
              isVisible={true}
            ></GenericSwitch>
          )}
          {isOptionsHas("isBannerEnabled") && (
            <GenericSwitch
              label={"Banner"}
              value={_.get(props, "selectedVersion.gameConfig.options.isBannerEnabled", false)}
              setValue={(value) => {
                setValue({
                  path: "gameConfig.options.isBannerEnabled",
                  value,
                });
              }}
              userPermissions={props.userPermissions}
              createSnackbar={props.createSnackbar}
              isVisible={true}
            ></GenericSwitch>
          )}
          {isOptionsHas("shouldForcePortraitMode") && (
            <GenericSwitch
              label={"Force Portrait Mode"}
              value={_.get(props, "selectedVersion.gameConfig.options.shouldForcePortraitMode", false)}
              setValue={(value) => {
                setValue({
                  path: "gameConfig.options.shouldForcePortraitMode",
                  value,
                });
              }}
              userPermissions={props.userPermissions}
              createSnackbar={props.createSnackbar}
              isVisible={true}
            ></GenericSwitch>
          )}
        </CardContent>
      </Card>
      }

    </FormGroup>
  );
}
