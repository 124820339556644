import React, { useEffect, useState } from "react";
import { Typography, MenuItem, Select } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import { getServerUri } from "../modules/uriUtility";

/**
 *
 * @param props
 * @param props.images
 * @param props.selectedImage
 * @param props.classes
 * @param props.setValue
 * @param props.path
 * @param props.destination
 * @param props.bucket
 * @param props.componentType
 * @param props.insertMediaPathValue
 * @param props.componentID
 * @param props.selectedBase
 * @returns {JSX.Element|null}
 */
export default function selectorTexture(props) {
  const [images, setImages] = useState(imagesToView(_.get(props, "images", [])));
  const defaultImageID = String(_.get(images, `[0]._id`, ``));
  const [selectedImageID, setSelectedImageID] = useState(_.get(props, "selectedImage._id", defaultImageID));
  const [error, setError] = useState("");

  function getImageByID(id) {
    return props.images.filter((imageRecord) => {
      return String(imageRecord._id) === String(id);
    })[0];
  }

  useEffect(() => {
    if (isSelectionValid(_.get(props, "selectedImage._id", ""))) {
      setSelectedImageID(_.get(props, "selectedImage._id", ""));
    } else if (isSelectionValid(defaultImageID)) {
      setSelectedImageID(defaultImageID);
    }
  }, [_.get(props, "selectedImage._id")]);

  function imagesToView(images) {
    return images.filter((image) => {
      return _.get(image, "bucket") === _.get(props, "bucket");
    });
  }

  useEffect(() => {
    setImages(imagesToView(_.get(props, "images", [])));
  }, [_.get(props, "images", []), _.size(props.images)]);

  useEffect(() => {
    const recordedSelectedImageID = _.get(props, "selectedImage._id");
    if (isSelectionValid(recordedSelectedImageID)) {
      setSelectedImageID(recordedSelectedImageID);
    }
  }, [_.get(props, "selectedImage._id")]);

  function isSelectionValid(id) {
    const foundImages = images.filter((image) => {
      return String(image._id) === String(id);
    });
    return foundImages.length > 0 || id === "";
  }

  const inputID = `${_.get(props, "componentID")}-${_.get(props, "path")}`;

  function selectImage(id, newImages) {
    if (!isSelectionValid(id)) return;
    setSelectedImageID(id);
    let images2;
    if (_.isNil(newImages)) {
      images2 = images;
    } else {
      images2 = newImages;
    }
    const selectedImageRecord = images2.filter((imageRecord) => {
      return String(imageRecord._id) === String(id);
    })[0];
    const newSelectedImageRecord = _.clone(selectedImageRecord);
    _.set(newSelectedImageRecord, "destination", _.get(props, "destination"));
    props.setValue({
      value: [newSelectedImageRecord],
    });
  }

  const isUnfavorableSelection = !isSelectionValid(selectedImageID) || selectedImageID === "";
  const isFavorableSelectionAvailable =
    _.size(images) > 0 && isSelectionValid(_.get(images, "[0]._id")) && _.get(images, "[0]._id") !== "";
  if (isUnfavorableSelection && isFavorableSelectionAvailable) {
    setSelectedImageID(_.get(images, "[0]._id"));
    selectImage(_.get(images, "[0]._id"));
  }

  const checkFileSize = (file) => {
    return file.size > 1000 * 200; // 200ßkb
  };

  return (
    <>
      {" "}
      {props.isVisible && (
        <div>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}>
            <input
              id={inputID}
              type={"file"}
              hidden={"hidden"}
              multiple={true}
              onChange={(e) => {
                //upload the file immediately
                const formData = new FormData();
                for (let i = 0; i < e.target.files.length; i++) {
                  formData.append("files", e.target.files[i]);
                  if (checkFileSize(e.target.files[i])) {
                    setError("file size should be less than 200kb");
                    return;
                  }
                }
                formData.append("componentType", _.get(props, "componentType"));
                formData.append("path", _.get(props, "path"));
                formData.append("bucket", _.get(props, "bucket"));
                formData.append("accessToken", localStorage.getItem("session.accessToken"));

                fetch(getServerUri("media/uploadImage"), {
                  method: "POST",
                  body: formData,
                })
                  .then((response) => {
                    if (response.status !== 200) {
                      if (response.status === 401) {
                        props.createSnackbar(
                          "You are not authorized to insert, contact your administrator.",
                          "warning"
                        );
                      }
                      response
                        .text()
                        .then((textResponse) => {
                          console.error(textResponse);
                        })
                        .catch(console.error);
                    } else {
                      console.info(`Upload successful`);
                      response.json().then((objectResponse) => {
                        const newImages = _.cloneDeep(images);
                        const newRecords = _.get(objectResponse, "records", []);
                        newImages.push(...newRecords);
                        setImages(newImages);
                        props.insertMediaPathValue({ path: `images`, value: newRecords });
                        if (!isSelectionValid(selectedImageID) || selectedImageID === "") {
                          const firstImageID = String(newImages[0]._id);
                          setSelectedImageID(firstImageID);
                          selectImage(firstImageID, newImages);
                        }
                      });
                    }
                  })
                  .catch(console.error);
              }}
            />
            <Select
              onChange={(event) => {
                if (props.userPermissions["update"] === false) {
                  props.createSnackbar(
                    "You do not have permission to update, contact your administrator.",
                    "warning",
                    false
                  );
                  return;
                }
                if (error) setError("");
                if (isSelectionValid(String(event.target.value))) {
                  selectImage(String(event.target.value));
                }
              }}
              className={props.classes.imageSelector}
              value={String(selectedImageID)}
            >
              {images.map((image) => (
                <MenuItem key={String(image._id)} value={String(image._id)}>
                  <img style={{ height: "50px", objectFit: "cover" }} src={`images/${image.file}`} />
                </MenuItem>
              ))}
            </Select>
            <AddIcon
              onClick={(e) => {
                setError("");
                document.getElementById(inputID).click();
              }}
            ></AddIcon>
          </div>
          <div>{error}</div>
        </div>
      )}
    </>
  );
}
