import React, { useEffect, useState } from "react";
import { MenuItem, TextField } from "@material-ui/core";

export default function selectorLevel(props) {
  if (_.isNil(props.value)) {
    return null;
  }
  let [value, setValue] = useState(props.value || 1);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  return (
    <>
      {" "}
      {props.isVisible && (
        <div className={props.classes.editorItem}>
          <TextField
            select
            label={_.get(props, `label`)}
            value={value}
            className={props.classes.textField}
            onChange={(event) => {
              if (props.userPermissions["update"] === false) {
                props.createSnackbar(
                  "You do not have permission to update, contact your administrator.",
                  "warning",
                  false
                );
                return;
              }
              setValue(event.target.value);
              props.setValue(event.target.value);
            }}
          >
            <MenuItem key={"1"} value={1}>
              Level 1
            </MenuItem>
            <MenuItem key={"2"} value={2}>
              Level 2
            </MenuItem>
            <MenuItem key={"3"} value={3}>
              Level 3
            </MenuItem>
            <MenuItem key={"4"} value={4}>
              Level 4
            </MenuItem>
          </TextField>
        </div>
      )}
    </>
  );
}
