import React from "react";
import { Button, Dialog, DialogTitle } from "@material-ui/core";
import { getServerUri } from "../modules/uriUtility";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";

const useClasses = makeStyles({
  dialogDelete: {
    margin: 10,
  },
});
/**
 *
 * @param {Boolean} props.isOpen is it open?
 * @param {Function} props.setIsOpen set is open to true or false
 * @param props.imageToDelete
 * @param props.selectedImageID
 * @param props.images
 * @param props.setImages
 * @param props.createSnackbar
 * @param props.selectImage
 * @param props.deleteImageFromMedia
 * @returns {JSX.Element}
 */
export default function dialogImageDelete(props) {
  const classes = useClasses();
  return (
    <Dialog
      open={props.isOpen}
      onClose={() => {
        props.setIsOpen(false);
      }}
    >
      <DialogTitle>Delete image?</DialogTitle>
      <div className={classes.dialogDelete}>
        <Button
          label={"OK"}
          onClick={(event) => {
            console.log("deleting image");
            fetch(getServerUri("media/deleteOne"), {
              method: "DELETE",
              headers: {
                "content-type": "application/x-www-form-urlencoded",
              },
              body: new URLSearchParams({
                accessToken: localStorage.getItem("session.accessToken"),
                imageID: props.imageToDelete._id,
              }),
            })
              .then((response) => {
                if (response.status === 200) {
                  let newImages = _.cloneDeep(props.images);
                  newImages.splice(
                    newImages.indexOf(newImages.filter((img) => img._id === props.imageToDelete._id)[0]),
                    1
                  );
                  props.deleteImageFromMedia(props.imageToDelete._id);
                  props.setImages(newImages);
                  if (newImages.length > 0) {
                    if (props.imageToDelete._id === props.selectedImageID) {
                      props.selectImage(newImages[0]._id);
                    }
                  } else props.selectImage("");
                  console.log("deleted image");
                  props.createSnackbar("Image deleted", "success");
                  props.setIsOpen(false);
                } else {
                  response
                    .text()
                    .then((responseText) => {
                      console.log(responseText);
                    })
                    .catch(console.error);
                }
              })
              .catch((err) => {
                console.log("fetch error");
                console.error(err);
                props.createSnackbar("An error occurred deleting the image.", "error");
              });
            props.setIsOpen(false);
          }}
        >
          OK
        </Button>
        <Button
          label={"Cancel"}
          onClick={() => {
            props.setIsOpen(false);
          }}
        >
          Cancel
        </Button>
      </div>
    </Dialog>
  );
}
