import { FormControlLabel, Slider, Switch, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";

/**
 *
 * @param props
 * @param props.value
 * @param props.setValue
 * @param props.label
 * @returns {JSX.Element|null}
 */
export default function switchGeneric(props) {
  if (_.isNil(props.value)) {
    return null;
  }
  const [value, setValue] = useState(props.value);


  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  function gettingMargin(numberMargin) {
    if (props.tooltip != undefined) {
      if (numberMargin) {
        return numberMargin.toString() + "px";
      }
    } else {
      return (0).toString() + "px";
    }
  }
  function gettingMarginBottom() {
    if (props.tooltip != undefined) {
      if (props.tooltip) {
        return (5).toString() + "px";
      } else {
        return (0).toString() + "%";
      }
    }
  }

  return (
    <div>
      {" "}
      {props.isVisible && (
        <FormControlLabel
          style={{ marginTop: gettingMargin(props.tooltipLength), marginBottom: gettingMarginBottom() }}
          label={props.label}
          control={
            <Switch
              id={`${props.id}.Switch`}
              checked={value}
              disabled={props.disabled}
              onChange={(event) => {
                if (props.userPermissions["update"] === false) {
                  props.createSnackbar(
                    "You do not have permission to update, contact your administrator.",
                    "warning",
                    false
                  );
                  return;
                }
                setValue(event.target.checked);
                props.setValue(event.target.checked);

              }}
            ></Switch>
          }
        />
      )}
    </div>
  );
}
