import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { Card } from "@material-ui/core";
import { CardContent } from "@mui/material";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import Tooltip from "@material-ui/core/Tooltip";
import EditorText from "./editorText";
import EditorTextArea from "./editorTextArea";
import SelectorColor from "./selectorColor";
import SelectorImageSet from "./selectorImageSet";
import SelectorTexture from "./selectorTexture";
import SelectorImage from "./selectorImage";
import SelectorAtlas from "./selectorAtlas";
import SelectorSound from "./selectorSound";
import SelectorVideo from "./selectorVideo";
import SelectorLevel from "./selectorLevel";
import SelectorAnimation from "./selectorAnimation";
import SwitchGeneric from "./switchGeneric";
import SliderGeneric from "./sliderGeneric";
import SelectorGeneric from "./selectorGeneric";
import SelectorCardOrder from "./selectorCardOrder";
import _ from "lodash";
import SelectorFontFamily from "./selectorFontFamily";
import SelectorFontStyle from "./selectorFontStyle";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import SelectorFont from "./selectorFont";
import SelectorRibbonType from "./selectorRibbonType";
import DialogPlayableSize from "./dialogPlayableSize";
import DefaultVersionSetting from "./defaultVersionSetting";
import { SimpleTreeView, TreeItem } from "@mui/x-tree-view";
import DisabledByDefaultRoundedIcon from "@mui/icons-material/DisabledByDefaultRounded";
import OuterComponentIcon from "../../images/gameConfig/Outer-component-icon.svg";
import InnerComponentIcon from "../../images/gameConfig/Inner-component-icon.svg";
import { IconButton } from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import CardItemsSelector from "./cardItemsSelector";
import EditorNumber from "./editorNumber";
const useStyles = makeStyles({
  card: { margin: "5px" },
  imageSelector: { width: "100%" },
  imageSetSelector: { width: "100%" },
  cardOrderSelector: { width: "100%" },
  containerVersionSettingsDiv: {
    margin: "0px",
    height: "100%",
    overflowY: "auto",
  },
});

function EndIcon(props) {
  return <DisabledByDefaultRoundedIcon {...props} sx={{ opacity: 0.3 }} />;
}

function getSiblingComponentsAndIndex(component, parent) {
  const componentPath = component.settings[0].path;
  const stringToCheck = componentPath.split("[")[0];
  let componentIndex;
  const siblingComponents = _.filter(parent.children, (e, index) => {
    const path = e.settings[0].path;
    if (path === componentPath) {
      componentIndex = index;
    }
    return _.startsWith(e.settings[0].path, stringToCheck);
  });
  return { siblingComponents, componentIndex };
}

/**
 * @param props.selectedVersion
 * @param props.selectedBase
 * @param props.selectedBase.gameObjectLabels
 * @param props.selectedBase.versionSettings
 * @param props.setSelectedVersionPathValue
 * @param props.setGameObjectKeyValue
 * @param props.setComponentPathValue
 * @param props.setKeyValue
 * @param props.resetGameConfig
 * @param props.media
 * @param props.areMediaLoaded
 * @param props.insertMediaPathValue
 * @param props.atlases
 * @param props.areAtlasesLoaded
 * @param props.insertAtlasPathValue
 * @param props.sounds
 * @param props.areSoundsLoaded
 * @param props.insertSoundPathValue
 * @param props.fonts
 * @param props.areFontsLoaded
 * @param props.videos
 * @param props.areVideosLoaded
 * @param props.insertFontPathValue
 * @param props.classes
 * @param props.createSnackbar
 * @param props.deleteImageFromMedia
 * @param props.deleteImageSetFromMedia
 * @param props.deleteFontFromFonts
 * @param props.deleteSoundFromSounds
 * @param props.deleteVideoFromVideos
 * @param props.deleteAnimationSetFromAtlases
 * @param props.userPermissions
 * @param props.setIsDialogConfirmRefreshOpen
 * @param props.setIsDialogCircularProgressOpen
 */
export default function containerVersionSettingsTreeView(props) {
  if (
    !_.has(props, "selectedBase") ||
    _.get(props, "areMediaLoaded") !== true ||
    _.get(props, "areAtlasesLoaded") !== true ||
    _.get(props, "areSoundsLoaded") !== true ||
    _.get(props, "areFontsLoaded") !== true ||
    _.get(props, "areFontsLoaded") !== true
  ) {
    return null;
  }
  const classes = useStyles();

  let [sections, setSections] = useState([]);

  const [refresh, doRefresh] = useState(0);
  const [firstTime, setFirstTime] = useState(false);

  const [expandedItems, setExpandedItems] = useState([]);

  const handleToggle = (event, itemIds) => {
    setExpandedItems(itemIds); // Set the expanded item IDs (array)
  };
  const setValue = (p, disableUndo = false) => {
    props.setSelectedVersionPathValue(p, disableUndo);
  };

  useEffect(() => {
    doRefresh((prev) => prev + 1);
  }, []);

  useEffect(() => {
    if (_.isNil(props.selectedModifiedBase)) return;
    // console.log("modified base", props.selectedModifiedBase);
    const cloneComponents = _.cloneDeep(_.get(props.selectedModifiedBase.versionSettings, "components", []));
    const sections = [];
    cloneComponents.forEach((component) => {
      const sectionText = _.get(component, "section", "Default");
      const subSectionText = _.get(component, "subSection", null);
      if (_.isNil(component.parentKey)) {
        let index;
        component.colorIndex = 1;
        if (sections.length === 0) {
          const obj = { caption: sectionText, components: [] };
          if (!_.isNil(subSectionText)) {
            obj.subSections = [{ caption: subSectionText, children: [] }];
          }
          sections.push(obj);
          index = 0;
        } else {
          index = _.findIndex(sections, (e) => {
            return e.caption === sectionText;
          });
          if (index < 0) {
            const obj = { caption: sectionText, components: [] };
            if (!_.isNil(subSectionText)) {
              obj.subSections = [{ caption: subSectionText, children: [] }];
            }
            sections.push(obj);
            index = sections.length - 1;
          }
        }
        if (index > -1) {
          if (!_.isNil(sections[index].subSections)) {
            const subIndex = _.findIndex(sections[index].subSections, (e) => {
              return e.caption === subSectionText;
            });
            if (subIndex < 0) {
              sections[index].subSections.push({ caption: subSectionText, children: [component] });
            } else {
              sections[index].subSections[subIndex].children.push(component);
            }
          } else {
            sections[index].components.push(component);
          }
        }
      } else {
        const parentComponent = _.find(cloneComponents, (e) => {
          if (component.isGroup) {
            return e.key === component.parentKey && e.id === component.id;
          } else {
            return e.key === component.parentKey;
          }
        });

        component.colorIndex = parentComponent.colorIndex + 1;
        parentComponent.children.push(component);
      }
    });
    // console.log("enters here components", cloneComponents, sections);
    setSections(sections);
  }, [props.selectedModifiedBase]);

  let baseIDSession = JSON.parse(localStorage.getItem("selectedBaseSession"));
  if (baseIDSession === _.get(props, "selectedBase._id")) {
    localStorage.setItem("selectedBaseSession", JSON.stringify(_.get(props, "selectedBase._id")));

    if (!firstTime) {
      setFirstTime(true);
    }
  } else {
    localStorage.setItem("newgetVal", JSON.stringify([]));
  }

  const defaultCardColor = "#474747";
  const cardColors = ["#313131", "#474747", "#545454", "#656565"];

  function getCardColor(index) {
    return cardColors[index >= cardColors.length ? cardColors.length - 1 : index];
  }

  function renderTooltip(tooltip, isVisible, marginLeft) {
    return isVisible && tooltip ? (
      <div>
        <Tooltip title={tooltip} aria-label="add">
          <HelpOutlineIcon style={{ marginLeft: marginLeft }} color={"primary"} />
        </Tooltip>
      </div>
    ) : null;
  }

  function getTextWidth(ttt) {
    let text = document.createElement("span");
    document.body.appendChild(text);

    (text.style.font = " Roboto"), "Helvetica", "Arial", "sans-serif";
    text.style.fontSize = "0.9285714285714286rem";
    text.style.height = "auto";
    text.style.width = "auto";
    text.style.letterSpacing = "0.00938em";
    text.style.position = "absolute";
    text.style.whiteSpace = "no-wrap";
    text.innerHTML = ttt;

    let width = Math.ceil(text.clientWidth);

    document.body.removeChild(text);
    return width;
  }

  function onAddIconClicked(parent, component) {
    // console.log("enters here onAddIconClicked", parent, component);
    const array = props.selectedVersion.gameConfig.components[component.id][component.settings[0].path.split("[")[0]];
    array.push(array[array.length - 1]);
    setValue(
      {
        path: `gameConfig.components[${component.id}][${component.settings[0].path.split("[")[0]}]`,
        value: array,
      },
      true
    );
    props.setSelectedModifiedBase(null);
  }

  function onRemoveIconClicked(event, parent, component) {
    const { siblingComponents, componentIndex } = getSiblingComponentsAndIndex(component, parent);
    const array = props.selectedVersion.gameConfig.components[component.id][component.settings[0].path.split("[")[0]];
    array.splice(componentIndex, 1);
    setValue(
      {
        path: `gameConfig.components[${component.id}][${component.settings[0].path.split("[")[0]}]`,
        value: array,
      },
      true
    );
    props.setSelectedModifiedBase(null);
  }

  function shouldAddIconBeVisible(parent, component) {
    const { siblingComponents, componentIndex } = getSiblingComponentsAndIndex(component, parent);
    const siblingComponentCount = siblingComponents.length;
    // console.log({ componentIndex, siblingComponentCount });
    if (component.maxCount <= siblingComponentCount) return false;
    return componentIndex === siblingComponentCount - 1;
  }

  function shouldRemoveIconBeVisible(parent, component) {
    const { siblingComponents } = getSiblingComponentsAndIndex(component, parent);
    const siblingComponentCount = siblingComponents.length;
    // console.log({ siblingComponentCount, minCount: component.minCount });
    return component.minCount < siblingComponentCount;
  }

  function renderTreeView(
    parent,
    component,
    props,
    classes,
    getTextWidth,
    renderTooltip,
    setValue,
    isSubSection = false
  ) {
    return (
      <TreeItem
        key={`${component.id}-${component.caption.replace(/\s/g, "")}`}
        itemId={`${component.caption}_${component.id}`}
        label={
          <div style={{ display: "flex", alignItems: "center", minHeight: "32px" }}>
            <img
              src={isSubSection ? OuterComponentIcon : InnerComponentIcon}
              alt="custom icon"
              style={{ width: "18px", height: "18px", marginRight: "8px" }}
            />
            {component.caption}
            {component.isBelongToArray && (
              <div
                style={{
                  "display": "flex",
                  "justifyContent": "space-between",
                  "marginLeft": "auto",
                }}
              >
                {shouldRemoveIconBeVisible(parent, component) && (
                  <IconButton
                    disableRipple={true}
                    style={{ background: "none", color: "#949494", padding: "0px" }}
                    onClick={(event) => {
                      if (props.userPermissions["update"] === false) {
                        props.createSnackbar(
                          "You do not have permission to update, contact your administrator.",
                          "warning",
                          false
                        );
                        return;
                      }
                      event.preventDefault();
                      event.stopPropagation(); // Prevents the parent from being clicked
                      onRemoveIconClicked(event, parent, component);
                    }}
                  >
                    <Remove />
                  </IconButton>
                )}
                {shouldAddIconBeVisible(parent, component) && (
                  <IconButton
                    disableRipple={true}
                    style={{ background: "none", color: "#949494", padding: "0px" }}
                    onClick={(event) => {
                      if (props.userPermissions["update"] === false) {
                        props.createSnackbar(
                          "You do not have permission to update, contact your administrator.",
                          "warning",
                          false
                        );
                        return;
                      }
                      event.preventDefault();
                      event.stopPropagation(); // Prevents the parent from being clicked
                      onAddIconClicked(parent, component);
                    }}
                  >
                    <Add />
                  </IconButton>
                )}
              </div>
            )}
          </div>
        }
        style={{
          backgroundColor: getCardColor(component.colorIndex),
          marginTop: "5px",
          marginBottom: "5px",
          borderRadius: "4px",
        }}
      >
        <Card
          className={props.classes.card}
          key={`${component.id}-${component.caption.replace(/\s/g, "")}`}
          style={{
            backgroundColor: isSubSection ? "#ffffff00" : getCardColor(component.colorIndex),
            boxShadow: "none",
          }}
        >
          <CardContent
            sx={{
              "&:last-child": {
                paddingBottom: isSubSection ? "0px" : "24px",
              },
              padding: isSubSection ? "0px" : "16px",
            }}
          >
            {_.get(component, "settings", []).map((setting, index) => {
              const isVisible = true;
              let sliderGenericPath = `gameConfig.components.${component.id}.${setting.path}`;
              let sliderGenericValue = _.get(
                props,
                `selectedVersion.gameConfig.components.${component.id}.${setting.path}`,
                {}
              );

              if (setting.editorSubType) {
                sliderGenericPath = setting.editorSubType.includes("FullPath")
                  ? `gameConfig.components.${setting.path}`
                  : `gameConfig.components.${component.id}.${setting.path}`;

                sliderGenericValue = setting.editorSubType.includes("FullPath")
                  ? _.get(props, `selectedVersion.gameConfig.components.${setting.path}`, {})
                  : _.get(props, `selectedVersion.gameConfig.components.${component.id}.${setting.path}`, {});
              }

              if (setting.editorType === "editorCardOrder") {
                let tooltipLength = getTextWidth(setting.caption);

                return (
                  <div key={`${component.id}.${setting.path}`}>
                    {renderTooltip(setting.tooltip, isVisible, tooltipLength + 5)}

                    <SelectorCardOrder
                      id={`${component.id}.${setting.path}`}
                      key={`${component.id}.${setting.path}`}
                      tooltipLength={-28}
                      tooltip={setting.tooltip}
                      cardOrders={_.get(props, "selectedBase.cardOrders")}
                      componentID={component.id}
                      classes={props.classes}
                      selectedCardOrderKey={_.get(
                        props,
                        `selectedVersion.gameConfig.components.${component.id}.cardOrder.key`
                      )}
                      setValue={(p) => {
                        setValue({
                          path: `gameConfig.components.${component.id}.${setting.path}`,
                          value: p.value,
                        });
                      }}
                      userPermissions={props.userPermissions}
                      createSnackbar={props.createSnackbar}
                      isVisible={isVisible}
                    />
                  </div>
                );
              }
              if (setting.editorType === "editorText") {
                let tooltipLength = getTextWidth(setting.caption);

                return (
                  <div key={`${component.id}.${setting.path}`}>
                    {renderTooltip(setting.tooltip, isVisible, tooltipLength + 5)}

                    <EditorText
                      id={`${component.id}.${setting.path}`}
                      componentID={component.id}
                      classes={props.classes}
                      tooltipLength={-24}
                      tooltip={setting.tooltip}
                      caption={setting.caption}
                      value={_.get(props, `selectedVersion.gameConfig.components.${component.id}.${setting.path}`)}
                      setValue={(value) => {
                        setValue({
                          path: `gameConfig.components.${component.id}.${setting.path}`,
                          value,
                        });
                      }}
                      userPermissions={props.userPermissions}
                      createSnackbar={props.createSnackbar}
                      isVisible={isVisible}
                    />
                  </div>
                );
              }
              if (setting.editorType === "editorTextArea") {
                let tooltipLength = getTextWidth(setting.caption);

                return (
                  <div key={`${component.id}.${setting.path}`}>
                    {renderTooltip(setting.tooltip, isVisible, tooltipLength + 5)}

                    <EditorTextArea
                      id={`${component.id}.${setting.path}`}
                      componentID={component.id}
                      tooltipLength={-28}
                      tooltip={setting.tooltip}
                      classes={props.classes}
                      caption={setting.caption}
                      isDialogEditEnabled={
                        _.get(component, "type") === "tilemap" ||
                        _.get(component, "type") === "level" ||
                        setting.caption === "Level"
                      }
                      isTextFieldVisible={
                        _.get(component, "type") !== "tilemap" &&
                        _.get(component, "type") !== "level" &&
                        setting.caption !== "Level"
                      }
                      value={_.get(props, `selectedVersion.gameConfig.components.${component.id}.${setting.path}`)}
                      setValue={(value) => {
                        setValue({
                          path: `gameConfig.components.${component.id}.${setting.path}`,
                          value,
                        });
                      }}
                      userPermissions={props.userPermissions}
                      createSnackbar={props.createSnackbar}
                      isVisible={isVisible}
                    />
                  </div>
                );
              }
              if (setting.editorType === "selectorVideo") {
                let tooltipLength = getTextWidth(setting.caption);

                return (
                  <div key={`${component.id}.${setting.path}`}>
                    {renderTooltip(setting.tooltip, isVisible, tooltipLength + 5)}

                    <SelectorVideo
                      id={`${component.id}.${setting.path}`}
                      selectedBase={props.selectedBase}
                      componentID={component.id}
                      classes={props.classes}
                      tooltipLength={-28}
                      tooltip={setting.tooltip}
                      label={setting.caption}
                      videos={_.get(props, "videos", [])}
                      componentType={component.type}
                      path={setting.path}
                      destination={setting.destination}
                      bucket={setting.bucket}
                      selectedVideo={_.get(
                        props,
                        `selectedVersion.gameConfig.components.${component.id}.${setting.path}[0]`
                      )}
                      setValue={(p) => {
                        setValue({
                          path: `gameConfig.components.${component.id}.${setting.path}`,
                          value: p.value,
                        });
                      }}
                      insertVideoPathValue={props.insertVideoPathValue}
                      deleteVideoFromVideos={props.deleteVideoFromVideos}
                      createSnackbar={props.createSnackbar}
                      selectedVersion={props.selectedVersion}
                      userPermissions={props.userPermissions}
                      isVisible={isVisible}
                      setIsDialogConfirmRefreshOpen={props.setIsDialogConfirmRefreshOpen}
                      setIsDialogCircularProgressOpen={props.setIsDialogCircularProgressOpen}
                    />
                  </div>
                );
              }
              if (setting.editorType === "selectorImage") {
                let tooltipLength = getTextWidth(setting.caption);

                return (
                  <div key={`${component.id}.${setting.path}`}>
                    {renderTooltip(setting.tooltip, isVisible, tooltipLength + 5)}
                    <SelectorImage
                      id={`${component.id}.${setting.path}`}
                      x
                      selectedBase={props.selectedBase}
                      componentID={component.id}
                      tooltipLength={-28}
                      tooltip={setting.tooltip}
                      label={setting.caption}
                      classes={props.classes}
                      images={_.get(props, "media.images", [])}
                      componentType={component.type}
                      path={setting.path}
                      destination={setting.destination}
                      bucket={setting.bucket}
                      selectedImage={_.get(
                        props,
                        `selectedVersion.gameConfig.components.${component.id}.${setting.path}[0]`
                      )}
                      setValue={(p) => {
                        setValue({
                          path: `gameConfig.components.${component.id}.${setting.path}`,
                          value: p.value,
                        });
                      }}
                      insertMediaPathValue={props.insertMediaPathValue}
                      createSnackbar={props.createSnackbar}
                      selectedVersion={props.selectedVersion}
                      deleteImageFromMedia={props.deleteImageFromMedia}
                      userPermissions={props.userPermissions}
                      isVisible={isVisible}
                      setIsDialogConfirmRefreshOpen={props.setIsDialogConfirmRefreshOpen}
                      setIsDialogCircularProgressOpen={props.setIsDialogCircularProgressOpen}
                    ></SelectorImage>
                  </div>
                );
              }
              if (setting.editorType === "selectorImageFullPath") {
                let tooltipLength = getTextWidth(setting.caption);

                return (
                  <div key={`${component.id}.${setting.path}`}>
                    {renderTooltip(setting.tooltip, isVisible, tooltipLength + 5)}

                    <SelectorImage
                      id={`${component.id}.${setting.path}`}
                      selectedBase={props.selectedBase}
                      selectedGame={props.selectedGame}
                      componentID={component.id}
                      label={setting.caption}
                      classes={props.classes}
                      tooltipLength={-28}
                      tooltip={setting.tooltip}
                      images={_.get(props, "media.images", [])}
                      componentType={component.type}
                      path={setting.path}
                      destination={setting.destination}
                      bucket={setting.bucket}
                      selectedImage={_.get(props, `selectedVersion.gameConfig.components.${setting.path}[0]`)}
                      setValue={(p) => {
                        setValue({
                          path: `gameConfig.components.${setting.path}`,
                          value: p.value,
                        });
                      }}
                      insertMediaPathValue={props.insertMediaPathValue}
                      createSnackbar={props.createSnackbar}
                      selectedVersion={props.selectedVersion}
                      deleteImageFromMedia={props.deleteImageFromMedia}
                      userPermissions={props.userPermissions}
                      isVisible={isVisible}
                      setIsDialogConfirmRefreshOpen={props.setIsDialogConfirmRefreshOpen}
                      setIsDialogCircularProgressOpen={props.setIsDialogCircularProgressOpen}
                    ></SelectorImage>
                  </div>
                );
              }
              if (setting.editorType === "selectorSound") {
                let tooltipLength = getTextWidth(setting.caption);

                return (
                  <div key={`${component.id}.${setting.path}`}>
                    {renderTooltip(setting.tooltip, isVisible, tooltipLength + 5)}

                    <SelectorSound
                      id={`${component.id}.${setting.path}`}
                      selectedBase={props.selectedBase}
                      componentID={component.id}
                      label={setting.caption}
                      classes={props.classes}
                      tooltipLength={-28}
                      tooltip={setting.tooltip}
                      sounds={_.get(props, "sounds", [])}
                      componentType={component.type}
                      path={setting.path}
                      destination={setting.destination}
                      bucket={setting.bucket}
                      selectedSound={_.get(props, `selectedVersion.gameConfig.components.${setting.path}[0]`)}
                      setValue={(p) => {
                        setValue({
                          path: `gameConfig.components.${setting.path}`,
                          value: p.value,
                        });
                      }}
                      insertSoundPathValue={props.insertSoundPathValue}
                      deleteSoundFromSounds={props.deleteSoundFromSounds}
                      createSnackbar={props.createSnackbar}
                      selectedVersion={props.selectedVersion}
                      userPermissions={props.userPermissions}
                      isVisible={isVisible}
                      setIsDialogConfirmRefreshOpen={props.setIsDialogConfirmRefreshOpen}
                    ></SelectorSound>
                  </div>
                );
              }
              if (setting.editorType === "selectorFont") {
                let tooltipLength = getTextWidth(setting.caption);

                return (
                  <div key={`${component.id}.${setting.path}`}>
                    {renderTooltip(setting.tooltip, isVisible, tooltipLength + 5)}

                    <SelectorFont
                      id={`${component.id}.${setting.path}`}
                      selectedBase={props.selectedBase}
                      componentID={component.id}
                      label={setting.caption}
                      classes={props.classes}
                      fonts={_.get(props, "fonts", [])}
                      componentType={component.type}
                      path={setting.path}
                      destination={setting.destination}
                      selectedFont={_.get(
                        props,
                        `selectedVersion.gameConfig.components.${component.id}.${setting.path}`
                      )}
                      setValue={setValue}
                      insertFontPathValue={props.insertFontPathValue}
                      deleteFontFromFonts={props.deleteFontFromFonts}
                      createSnackbar={props.createSnackbar}
                      selectedVersion={props.selectedVersion}
                      userPermissions={props.userPermissions}
                      isVisible={isVisible}
                      setIsDialogConfirmRefreshOpen={props.setIsDialogConfirmRefreshOpen}
                    ></SelectorFont>
                  </div>
                );
              }
              if (setting.editorType === "selectorAtlas") {
                let tooltipLength = getTextWidth(setting.caption);

                return (
                  <div key={`${component.id}.${setting.path}`}>
                    {renderTooltip(setting.tooltip, isVisible, tooltipLength + 5)}

                    <SelectorAtlas
                      id={`${component.id}.${setting.path}`}
                      selectedBase={props.selectedBase}
                      componentID={component.id}
                      label={setting.caption}
                      classes={props.classes}
                      tooltipLength={-28}
                      tooltip={setting.tooltip}
                      atlases={_.get(props, "atlases", [])}
                      componentType={component.type}
                      path={setting.path}
                      bucket={setting.bucket}
                      destinations={setting.destinations}
                      selectedAnimationSetID={_.get(
                        props,
                        `selectedVersion.gameConfig.components.${component.id}.${setting.path}.[0].animationSet`
                      )}
                      setValue={(p) => {
                        setValue({
                          path: `gameConfig.components.${component.id}.${setting.path}`,
                          value: p.value,
                        });
                      }}
                      insertAtlasPathValue={props.insertAtlasPathValue}
                      deleteAnimationSetFromAtlases={props.deleteAnimationSetFromAtlases}
                      createSnackbar={props.createSnackbar}
                      selectedVersion={props.selectedVersion}
                      userPermissions={props.userPermissions}
                      isVisible={isVisible}
                    ></SelectorAtlas>
                  </div>
                );
              }
              if (setting.editorType === "selectorTexture") {
                return (
                  <div key={"texture"}>
                    {_.get(props, `selectedVersion.gameConfig.components.${component.id}.useTexture`) && (
                      <SelectorTexture
                        id={`${component.id}.${setting.path}`}
                        selectedBase={props.selectedBase}
                        componentID={component.id}
                        classes={props.classes}
                        images={_.get(props, "media.images", [])}
                        componentType={component.type}
                        path={setting.path}
                        destination={setting.destination}
                        bucket={setting.bucket}
                        selectedImage={_.get(
                          props,
                          `selectedVersion.gameConfig.components.${component.id}.${setting.path}[0]`
                        )}
                        setValue={(p) => {
                          setValue({
                            path: `gameConfig.components.${component.id}.${setting.path}`,
                            value: p.value,
                          });
                        }}
                        insertMediaPathValue={props.insertMediaPathValue}
                        userPermissions={props.userPermissions}
                        createSnackbar={props.createSnackbar}
                        isVisible={isVisible}
                      ></SelectorTexture>
                    )}
                  </div>
                );
              }
              if (setting.editorType === "selectorImageSet") {
                let tooltipLength = getTextWidth(setting.caption);

                return (
                  <div key={`${component.id}.${setting.path}`}>
                    {renderTooltip(setting.tooltip, isVisible, tooltipLength + 5)}

                    <SelectorImageSet
                      id={`${component.id}.${setting.path}`}
                      tooltipLength={-28}
                      tooltip={setting.tooltip}
                      selectedBase={props.selectedBase}
                      componentID={component.id}
                      label={setting.caption}
                      classes={props.classes}
                      images={_.get(props, "media.images", [])}
                      componentType={component.type}
                      path={setting.path}
                      bucket={setting.bucket}
                      destinations={setting.destinations}
                      selectedImageSetID={_.get(
                        props,
                        `selectedVersion.gameConfig.components.${component.id}.${setting.path}.[0].imageSet`
                      )}
                      setValue={(p) => {
                        setValue({
                          path: `gameConfig.components.${component.id}.${setting.path}`,
                          value: p.value, //images of the imageSet, in the form of image records
                        });
                      }}
                      insertMediaPathValue={props.insertMediaPathValue}
                      createSnackbar={props.createSnackbar}
                      selectedVersion={props.selectedVersion}
                      deleteImageSetFromMedia={props.deleteImageSetFromMedia}
                      userPermissions={props.userPermissions}
                      isVisible={isVisible}
                      setIsDialogConfirmRefreshOpen={props.setIsDialogConfirmRefreshOpen}
                      setIsDialogCircularProgressOpen={props.setIsDialogCircularProgressOpen}
                    ></SelectorImageSet>
                  </div>
                );
              }
              if (setting.editorType === "sliderGeneric") {
                let tooltipLength = getTextWidth(setting.caption);
                return (
                  <div key={`${component.id}.${setting.path}`}>
                    {renderTooltip(setting.tooltip, isVisible, tooltipLength + 5)}
                    <SliderGeneric
                      id={`${component.id}.${setting.path}`}
                      classes={props.classes}
                      tooltipLength={-28}
                      newSetPortrait={props.newSetPortrait}
                      tooltip={setting.tooltip}
                      min={setting.min}
                      max={setting.max}
                      step={setting.step}
                      value={sliderGenericValue}
                      path={sliderGenericPath}
                      y_axis={!!setting.y_axis}
                      editorSubType={setting.editorSubType}
                      label={setting.caption}
                      setValue={(pathToSet, value) => {
                        setValue({
                          path: pathToSet,
                          value,
                        });
                      }}
                      userPermissions={props.userPermissions}
                      createSnackbar={props.createSnackbar}
                      isVisible={isVisible}
                    ></SliderGeneric>
                  </div>
                );
              }
              if (setting.editorType === "switchGeneric") {
                let tooltipLength = getTextWidth(setting.caption);
                return (
                  <div key={`${component.id}.${setting.path}`}>
                    {renderTooltip(setting.tooltip, isVisible, tooltipLength + 52)}

                    <SwitchGeneric
                      id={`${component.id}.${setting.path}`}
                      classes={props.classes}
                      tooltipLength={-54}
                      tooltip={setting.tooltip}
                      value={_.get(
                        props,
                        `selectedVersion.gameConfig.components.${component.id}.${setting.path}`,
                        false
                      )}
                      setValue={(value) => {
                        setValue({
                          path: `gameConfig.components.${component.id}.${setting.path}`,
                          value,
                        });
                      }}
                      label={setting.caption}
                      userPermissions={props.userPermissions}
                      createSnackbar={props.createSnackbar}
                      isVisible={isVisible}
                    ></SwitchGeneric>
                  </div>
                );
              }
              if (setting.editorType === "switchGenericFullPath") {
                let tooltipLength = getTextWidth(setting.caption);

                return (
                  <div key={`${component.id}.${setting.path}`}>
                    {renderTooltip(setting.tooltip, isVisible, tooltipLength + 5)}

                    <SwitchGeneric
                      id={`${component.id}.${setting.path}`}
                      classes={props.classes}
                      tooltipLength={-30}
                      value={_.get(props, `selectedVersion.gameConfig.components.${setting.path}`, false)}
                      setValue={(value) => {
                        setValue({
                          path: `gameConfig.components.${setting.path}`,
                          value,
                        });
                      }}
                      label={setting.caption}
                      userPermissions={props.userPermissions}
                      createSnackbar={props.createSnackbar}
                      isVisible={isVisible}
                    ></SwitchGeneric>
                  </div>
                );
              }
              if (setting.editorType === "color") {
                let tooltipLength = getTextWidth(setting.caption);
                return (
                  <div key={`${component.id}.${setting.path}`}>
                    {renderTooltip(setting.tooltip, isVisible, tooltipLength + 5)}

                    <SelectorColor
                      id={`${component.id}.${setting.path}`}
                      label={setting.caption}
                      classes={props.classes}
                      tooltipLength={-28}
                      tooltip={setting.tooltip}
                      value={_.get(
                        props,
                        `selectedVersion.gameConfig.components.${component.id}.${setting.path}`,
                        "#ffffff"
                      )}
                      setValue={(value) => {
                        setValue({
                          path: `gameConfig.components.${component.id}.${setting.path}`,
                          value,
                        });
                      }}
                      userPermissions={props.userPermissions}
                      createSnackbar={props.createSnackbar}
                      isVisible={isVisible}
                    ></SelectorColor>
                  </div>
                );
              }
              if (setting.editorType === "selectorLevel") {
                let tooltipLength = getTextWidth(setting.caption);

                return (
                  <div key={`${component.id}.${setting.path}`}>
                    {renderTooltip(setting.tooltip, isVisible, tooltipLength + 5)}

                    <SelectorLevel
                      id={`${component.id}.${setting.path}`}
                      classes={props.classes}
                      label={setting.caption}
                      value={_.get(props, `selectedVersion.gameConfig.components.${component.id}.${setting.path}`, 1)}
                      setValue={(value) => {
                        setValue({
                          path: `gameConfig.components.${component.id}.${setting.path}`,
                          value,
                        });
                      }}
                      userPermissions={props.userPermissions}
                      createSnackbar={props.createSnackbar}
                      isVisible={isVisible}
                    ></SelectorLevel>
                  </div>
                );
              }
              if (setting.editorType === "selectorAnimation") {
                let tooltipLength = getTextWidth(setting.caption);

                return (
                  <div key={`${component.id}.${setting.path}`}>
                    {renderTooltip(setting.tooltip, isVisible, tooltipLength + 5)}

                    <SelectorAnimation
                      id={`${component.id}.${setting.path}`}
                      classes={props.classes}
                      label={setting.caption}
                      tooltipLength={-28.5}
                      tooltip={setting.tooltip}
                      value={_.get(props, `selectedVersion.gameConfig.components.${component.id}.${setting.path}`, 1)}
                      setValue={(value) => {
                        setValue({
                          path: `gameConfig.components.${component.id}.${setting.path}`,
                          value,
                        });
                      }}
                      userPermissions={props.userPermissions}
                      createSnackbar={props.createSnackbar}
                      isVisible={isVisible}
                    ></SelectorAnimation>
                  </div>
                );
              }
              if (setting.editorType === "selectorFontFamily") {
                let tooltipLength = getTextWidth(setting.caption);

                return (
                  <div key={`${component.id}.${setting.path}`}>
                    {renderTooltip(setting.tooltip, isVisible, tooltipLength + 5)}

                    <SelectorFontFamily
                      id={`${component.id}.${setting.path}`}
                      classes={props.classes}
                      label={setting.caption}
                      tooltipLength={-24.5}
                      tooltip={setting.tooltip}
                      value={_.get(props, `selectedVersion.gameConfig.components.${component.id}.${setting.path}`, 1)}
                      setValue={(value) => {
                        setValue({
                          path: `gameConfig.components.${component.id}.${setting.path}`,
                          value,
                        });
                      }}
                      selectedVersion={props.selectedVersion}
                      userPermissions={props.userPermissions}
                      createSnackbar={props.createSnackbar}
                      isVisible={isVisible}
                    ></SelectorFontFamily>
                  </div>
                );
              }
              if (setting.editorType === "selectorFontStyle") {
                let tooltipLength = getTextWidth(setting.caption);

                return (
                  <div key={`${component.id}.${setting.path}`}>
                    {renderTooltip(setting.tooltip, isVisible, tooltipLength + 5)}

                    <SelectorFontStyle
                      id={`${component.id}.${setting.path}`}
                      classes={props.classes}
                      tooltipLength={-26}
                      tooltip={setting.tooltip}
                      value={_.get(props, `selectedVersion.gameConfig.components.${component.id}.${setting.path}`, 1)}
                      setValue={(value) => {
                        setValue({
                          path: `gameConfig.components.${component.id}.${setting.path}`,
                          value,
                        });
                      }}
                      label={setting.caption}
                      userPermissions={props.userPermissions}
                      createSnackbar={props.createSnackbar}
                      isVisible={isVisible}
                    ></SelectorFontStyle>
                  </div>
                );
              }
              if (setting.editorType === "selectorColor") {
                let tooltipLength = getTextWidth(setting.caption);

                return (
                  <div key={`${component.id}.${setting.path}`}>
                    {renderTooltip(setting.tooltip, isVisible, tooltipLength + 5)}

                    <SelectorColor
                      id={`${component.id}.${setting.path}`}
                      classes={props.classes}
                      mode={"String"}
                      tooltipLength={-28}
                      tooltip={setting.tooltip}
                      value={_.get(props, `selectedVersion.gameConfig.components.${component.id}.${setting.path}`, 1)}
                      setValue={(value) => {
                        setValue({
                          path: `gameConfig.components.${component.id}.${setting.path}`,
                          value,
                        });
                      }}
                      label={setting.caption}
                      userPermissions={props.userPermissions}
                      createSnackbar={props.createSnackbar}
                      isVisible={isVisible}
                    ></SelectorColor>
                  </div>
                );
              }
              if (setting.editorType === "selectorString") {
                let tooltipLength = getTextWidth(setting.caption);

                return (
                  <div key={`${component.id}.${setting.path}`}>
                    {renderTooltip(setting.tooltip, isVisible, tooltipLength + 5)}

                    <SelectorGeneric
                      id={`${component.id}.${setting.path}`}
                      label={setting.caption}
                      classes={props.classes}
                      tooltipLength={-32.5}
                      tooltip={setting.tooltip}
                      value={_.get(props, `selectedVersion.gameConfig.components.${component.id}.${setting.path}`, 0)}
                      items={setting.items}
                      setValue={(value) => {
                        setValue({
                          path: `gameConfig.components.${component.id}.${setting.path}`,
                          value,
                        });
                      }}
                      userPermissions={props.userPermissions}
                      createSnackbar={props.createSnackbar}
                      isVisible={isVisible}
                    ></SelectorGeneric>
                  </div>
                );
              }
              if (setting.editorType === "selectorRibbonType") {
                let tooltipLength = getTextWidth(setting.caption);

                return (
                  <div key={`${component.id}.${setting.path}`}>
                    {renderTooltip(setting.tooltip, isVisible, tooltipLength + 5)}

                    <SelectorRibbonType
                      id={`${component.id}.${setting.path}`}
                      label={setting.caption}
                      tooltipLength={-28}
                      tooltip={setting.tooltip}
                      newSetPortrait={props.newSetPortrait}
                      classes={props.classes}
                      path={`gameConfig.components.${component.id}.${setting.path}`}
                      value={_.get(props, `selectedVersion.gameConfig.components.${component.id}.${setting.path}`, 0)}
                      items={setting.items}
                      components={setting.components}
                      atlases={_.get(props, "atlases", [])}
                      images={_.get(props, "media.images", [])}
                      setValue={(pathToSet, value) => {
                        setValue({
                          path: pathToSet,
                          value,
                        });
                      }}
                      insertAtlasPathValue={props.insertAtlasPathValue}
                      insertMediaPathValue={props.insertMediaPathValue}
                      deleteImageFromMedia={props.deleteImageFromMedia}
                      userPermissions={props.userPermissions}
                      createSnackbar={props.createSnackbar}
                      selectedBase={props.selectedBase}
                      selectedVersion={props.selectedVersion}
                      setSelectedVersionPathValue={props.setSelectedVersionPathValue}
                      isVisible={true}
                      setIsDialogConfirmRefreshOpen={props.setIsDialogConfirmRefreshOpen}
                      setIsDialogCircularProgressOpen={props.setIsDialogCircularProgressOpen}
                    ></SelectorRibbonType>
                  </div>
                );
              }
              if (setting.editorType === "cardItemsSelector") {
                let tooltipLength = getTextWidth(setting.caption);

                return (
                  <div key={`${component.id}.${setting.path}`}>
                    {renderTooltip(setting.tooltip, isVisible, tooltipLength + 5)}

                    <CardItemsSelector
                      id={`${component.id}.${setting.path}`}
                      classes={props.classes}
                      value={_.get(props, `selectedVersion.gameConfig.components.${component.id}.${setting.path}`)}
                      setValue={(value) => {
                        setValue({
                          path: `gameConfig.components.${component.id}.${setting.path}`,
                          value,
                        });
                      }}
                      label={setting.caption}
                      userPermissions={props.userPermissions}
                      createSnackbar={props.createSnackbar}
                      isVisible={isVisible}
                      setSelectedVersionPathValue={props.setSelectedVersionPathValue}
                      duration={_.get(
                        props,
                        `selectedVersion.gameConfig.components.${component.id}.selectedVideos.video[0].duration`
                      )}
                    ></CardItemsSelector>
                  </div>
                );
              }
              if (setting.editorType === "number") {
                let tooltipLength = getTextWidth(setting.caption);

                return (
                  <div key={`${component.id}.${setting.path}`}>
                    {renderTooltip(setting.tooltip, isVisible, tooltipLength + 5)}

                    <EditorNumber
                      id={`${component.id}.${setting.path}`}
                      classes={props.classes}
                      tooltipLength={-28}
                      tooltip={setting.tooltip}
                      value={_.get(props, `selectedVersion.gameConfig.components.${component.id}.${setting.path}`)}
                      min={setting.min}
                      max={setting.max}
                      setValue={(value) => {
                        setValue({
                          path: `gameConfig.components.${component.id}.${setting.path}`,
                          value,
                        });
                      }}
                      label={setting.caption}
                      userPermissions={props.userPermissions}
                      createSnackbar={props.createSnackbar}
                      isVisible={isVisible}
                      setSelectedVersionPathValue={props.setSelectedVersionPathValue}
                      duration={_.get(
                        props,
                        `selectedVersion.gameConfig.components.${component.id}.selectedVideos.video[0].duration`
                      )}
                    ></EditorNumber>
                  </div>
                );
              }
            })}
            {_.get(component.children, "length") > 0
              ? component.children.map((child) => {
                  if (isSubSection) {
                    return (
                      <Card
                        className={props.classes.card}
                        key={`${component.id}-${component.caption.replace(/\s/g, "")}`}
                        style={{
                          overflow: "unset",
                        }}
                      >
                        {renderTreeView(component, child, props, classes, getTextWidth, renderTooltip, setValue)}
                      </Card>
                    );
                  } else {
                    return renderTreeView(component, child, props, classes, getTextWidth, renderTooltip, setValue);
                  }
                })
              : null}
          </CardContent>
        </Card>
      </TreeItem>
    );
  }
  return (
    <div className={classes.containerVersionSettingsDiv}>
      <div>
        <DialogPlayableSize
          classes={props.classes}
          selectedVersion={props.selectedVersion}
          selectedBase={props.selectedBase}
          setTotalSize={props.setTotalSize}
        />
        <SimpleTreeView
          aria-label="customized"
          defaultExpandedItems={["1", "3"]}
          slots={{
            expandIcon: ArrowRightIcon,
            collapseIcon: ArrowDropDownIcon,
            endIcon: EndIcon,
          }}
          sx={{ overflowX: "hidden", flexGrow: 1 }}
          expandedItems={expandedItems}
          onExpandedItemsChange={handleToggle}
        >
          {!_.isNil(sections) &&
            sections.map((section) => {
              return (
                <Card
                  className={props.classes.card}
                  key={section.caption}
                  style={{
                    backgroundColor:
                      _.findIndex(expandedItems, (e) => {
                        return e === section.caption;
                      }) > -1
                        ? getCardColor(0)
                        : defaultCardColor,
                  }}
                >
                  <TreeItem
                    itemId={section.caption}
                    label={
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontWeight: "bold",
                          minHeight: "32px",
                        }}
                      >
                        <img
                          src={OuterComponentIcon}
                          alt="custom icon"
                          style={{ width: "18px", height: "18px", marginRight: "8px" }}
                        />
                        {section.caption}
                      </div>
                    }
                  >
                    {_.get(section.subSections, "length") > 0
                      ? section.subSections.map((subSection) => {
                          return renderTreeView(
                            null,
                            subSection,
                            props,
                            classes,
                            getTextWidth,
                            renderTooltip,
                            setValue,
                            true
                          );
                        })
                      : section.components.map((component) => {
                          return renderTreeView(null, component, props, classes, getTextWidth, renderTooltip, setValue);
                        })}
                  </TreeItem>
                </Card>
              );
            })}
        </SimpleTreeView>
        {_.has(props.userCompany, "isPermittedSetDefault") && props.userCompany.isPermittedSetDefault === true && (
          <DefaultVersionSetting
            classes={props.classes}
            selectedVersion={props.selectedVersion}
            selectedBase={props.selectedBase}
            getDefaultVersion={props.getDefaultVersion}
            setDefaultVersion={(value) => {
              props.setDefaultVersion(value);
            }}
            setSearchBarValue={(value) => {
              props.setSearchBarValue(value);
            }}
            defaultVersion={props.defaultVersion}
            defaultVersionUpdated={props.defaultVersionUpdated}
            setDefaultVersionUpdated={(value) => {
              props.setDefaultVersionUpdated(value);
            }}
            prop={props}
            versionRename={props.versionRename}
            searchBarValue={props.searchBarValue}
          />
        )}
      </div>
    </div>
  );
}
