import React, { useEffect, useState } from "react";
import {
  Typography,
  MenuItem,
  Select,
  IconButton,
  Box,
  Tabs,
  Tab,
  CircularProgress,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { Remove } from "@material-ui/icons";
import { getServerUri } from "../modules/uriUtility";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import DialogVideoDelete from "./dialogVideoDelete";
import jsonImage from "../../images/json_d-2.png";
import TheatersRoundedIcon from "@material-ui/icons/TheatersRounded";
import PropTypes from "prop-types";

const useStyles = makeStyles({
  iconButton: {
    padding: 0,
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

/**
 *
 * @param props
 * @param props.videos
 * @param props.selectedVideo
 * @param props.classes
 * @param props.setValue
 * @param props.path
 * @param props.destination
 * @param props.bucket
 * @param props.componentType
 * @param props.insertMediaPathValue
 * @param props.componentID
 * @param props.selectedBase
 * @param props.createSnackbar
 * @param props.deleteVideoFromMedia
 * @param props.fetchVersions
 * @param props.fetchVideos
 * @param props.setIsDialogConfirmRefreshOpen
 * @param props.setIsDialogCircularProgressOpen
 * @returns {JSX.Element|null}
 */
export default function selectorVideo(props) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [videos, setVideos] = useState(videosToView(_.get(props, "videos", [])));
  const [selectedVideoID, setSelectedVideoID] = useState(
    isSelectionValid(_.get(props, "selectedVideo._id"))
      ? _.get(props, "selectedVideo._id")
      : String(_.get(videos, `[0]._id`, ``))
  );
  const [isDeleteVideoDialogOpen, setIsDeleteVideoDialogOpen] = useState(false);
  const [videoToDelete, setVideoToDelete] = useState(null);

  const defaultVideoID = String(_.get(videos, `[0]._id`, ``));
  const classes = useStyles();

  let [url, setURL] = useState("");
  let [errorText, setErrorText] = useState();

  let [uploadStatus, setUploadStatus] = useState("ready");
  const [isUploading, setIsUploading] = useState(false);
  const [tab, setTab] = useState(0);

  const testCors = (url) =>
    new Promise((resolve, reject) => {
      var myRequest = new XMLHttpRequest();
      myRequest.open("GET", url, true);
      myRequest.onreadystatechange = () => {
        if (myRequest.readyState !== 4) {
          return;
        }
        if (myRequest.status === 200) {
          var video = document.createElement("video");
          video.onerror = (...args) => {
            reject("Video URL is not valid.", args);
          };
          video.onloadedmetadata = (ev) => {
            resolve(ev.target.duration);
          };
          video.src = url;
          // resolve();
        } else {
          var video = document.createElement("video");
          video.onerror = (...args) => {
            reject("Video URL is not valid.", args);
          };
          video.onloadedmetadata = () => {
            reject("Video exists but CORS blocked");
          };
          video.src = url;
        }
      };
      myRequest.send();
    });

  function createVideoObject() {
    testCors(url)
      .then((duration) => {
        setErrorText(null);

        const formData = new FormData();
        formData.append("files", url);
        formData.append("componentType", _.get(props, "componentType"));
        formData.append("path", _.get(props, "path"));
        formData.append("bucket", _.get(props, "bucket"));
        formData.append("duration", duration);
        formData.append("filename", url);
        formData.append("variant", "url");
        formData.append("accessToken", localStorage.getItem("session.accessToken"));

        props.setIsDialogCircularProgressOpen(true);
        fetch(getServerUri("video/uploadVideo"), {
          method: "POST",
          body: formData,
        })
          .then((response) => {
            setIsUploading(false);
            if (response.status !== 200) {
              if (response.status === 401) {
                props.createSnackbar("You are not authorized to insert, contact your administrator.", "warning");
              }
              response
                .text()
                .then((textResponse) => {
                  console.error(textResponse);
                })
                .catch(console.error);
            } else {
              props.setIsDialogCircularProgressOpen(false);
              setIsDialogOpen(false);
              console.info(`Upload successful`);
              response.json().then((objectResponse) => {
                const newVideos = _.cloneDeep(videos);
                const newRecords = _.get(objectResponse, "records", []);

                newVideos.push(...newRecords);
                setVideos(newVideos);
                props.insertVideoPathValue(newVideos);

                const newVideoID = String(newRecords[0]._id);
                setSelectedVideoID(newVideoID);
                selectVideo(newVideoID, newVideos);
              });
            }
          })
          .catch(console.error);
      })
      .catch((error) => {
        setIsUploading(false);
        setErrorText(error);
      });
  }
  function gettingMargin(numberMargin) {
    if (props.tooltip != undefined) {
      if (numberMargin) {
        return numberMargin.toString() + "px";
      }
    } else return (0).toString() + "px";
  }

  useEffect(() => {
    if (isSelectionValid(_.get(props, "selectedVideo._id", ""))) {
      setSelectedVideoID(_.get(props, "selectedVideo._id", ""));
    } else if (isSelectionValid(defaultVideoID)) {
      selectVideo(defaultVideoID);
    }
  }, [_.get(props, "selectedVersion")]);

  function videosToView(videos) {
    return videos.filter((video) => {
      return _.get(video, "bucket") === _.get(props, "bucket");
    });
  }

  useEffect(() => {
    const bucketVideos = videosToView(_.get(props, "videos", []));
    setVideos(bucketVideos);
  }, [_.get(props, "videos", []), _.size(props.videos)]);

  useEffect(() => {
    if (_.size(videos) !== 0 && hasDuplicates(videos)) {
      const nonDuplicatedVideos = videos.filter((v1, i, a) => a.findIndex((v2) => v2._id === v1._id) === i);
      debugger;
      setVideos(nonDuplicatedVideos);
    }
  }, [videos]);

  function hasDuplicates(videoArray) {
    const idArray = videoArray.map(function (video) {
      return video._id;
    });
    let isDuplicated = idArray.some(function (id, index) {
      return idArray.indexOf(id) !== index;
    });
    return isDuplicated;
  }
  function isSelectionValid(id, newVideos) {
    let videos2;
    if (_.isNil(newVideos)) {
      videos2 = videos;
    } else {
      videos2 = newVideos;
    }
    const foundVideos = videos2.filter((video) => {
      return String(video._id) === String(id);
    });
    return foundVideos.length > 0 || id === "";
  }

  function selectVideo(id, newVideos) {
    if (!isSelectionValid(id, newVideos)) return;
    setSelectedVideoID(id);
    let videos2;
    if (_.isNil(newVideos)) {
      videos2 = videos;
    } else {
      videos2 = newVideos;
    }
    const selectedVideoRecord = videos2.filter((videoRecord) => {
      return String(videoRecord._id) === String(id);
    })[0];
    const newSelectedVideoRecord = _.clone(selectedVideoRecord);
    _.set(newSelectedVideoRecord, "destination", _.get(props, "destination"));
    props.setValue({
      value: [newSelectedVideoRecord],
    });
  }

  const checkFileSize = (file) => {
    return file.size > 1024 * 1024 * 5; // 1 MB
  };

  const loadVideo = (file) =>
    new Promise((resolve, reject) => {
      try {
        let video = document.createElement("video");
        video.preload = "metadata";

        video.onloadedmetadata = function () {
          resolve(this);
        };

        video.onerror = function () {
          reject("Invalid video. Please select a video file.");
        };

        video.src = window.URL.createObjectURL(file);
      } catch (e) {
        reject(e);
      }
    });

  const truncateURL = (fullStr, strLen, separator) => {
    if (fullStr.length <= strLen) return fullStr;

    separator = separator || "...";

    const sepLen = separator.length,
      charsToShow = strLen - sepLen,
      frontChars = Math.ceil(charsToShow / 2),
      backChars = Math.floor(charsToShow / 2);

    return fullStr.substr(0, frontChars) + separator + fullStr.substr(fullStr.length - backChars);
  };

  const renderInsertVideoDialog = () => {
    const imgID = `${_.get(props, `componentID`)}-upload-video-img`;
    const buttonID = `${_.get(props, `componentID`)}-upload-video-button`;
    const inputID = `${_.get(props, `componentID`)}-upload-video-input`;
    const checkButtonID = `${_.get(props, `componentID`)}-upload-video-check-button`;

    return (
      <>
        <Dialog
          open={isDialogOpen}
          onClose={(e) => {
            setIsDialogOpen(false);
          }}
        >
          <DialogTitle>{"Select Video Source"}</DialogTitle>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              margin: "1rem",
            }}
          >
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={tab}
                onChange={(event, newValue) => {
                  setTab(newValue);
                }}
              >
                <Tab label="upload" {...a11yProps(0)} />
                <Tab label="url" {...a11yProps(1)} />
              </Tabs>
            </Box>

            <div
              style={{
                marginTop: "2rem",
                marginBottom: "2rem",
              }}
            >
              <TabPanel value={tab} index={0}>
                <input
                  id={inputID}
                  type={"file"}
                  hidden={"hidden"}
                  accept="video/mp4,video/quicktime"
                  onChange={(e) => {
                    //upload the file immediately
                    for (let i = 0; i < e.target.files.length; i++) {
                      let file = e.target.files[i];
                      if (checkFileSize(file)) {
                        // todo check if it is working
                        props.createSnackbar("File size should be less than 5 MB.", "error");
                        return;
                      }
                      setIsUploading(true);
                      loadVideo(e.currentTarget.files[0])
                        .then((video) => {
                          const formData = new FormData();
                          formData.append("files", file);
                          formData.append("componentType", _.get(props, "componentType"));
                          formData.append("path", _.get(props, "path"));
                          formData.append("bucket", _.get(props, "bucket"));
                          formData.append("duration", video.duration);
                          formData.append("filename", file.name);
                          formData.append("variant", "path");
                          formData.append("accessToken", localStorage.getItem("session.accessToken"));

                          props.setIsDialogCircularProgressOpen(true);
                          fetch(getServerUri("video/uploadVideo"), {
                            method: "POST",
                            body: formData,
                          })
                            .then((response) => {
                              setIsUploading(false);
                              if (response.status !== 200) {
                                if (response.status === 401) {
                                  props.createSnackbar(
                                    "You are not authorized to insert, contact your administrator.",
                                    "warning"
                                  );
                                }
                                response
                                  .text()
                                  .then((textResponse) => {
                                    console.error(textResponse);
                                  })
                                  .catch(console.error);
                              } else {
                                props.setIsDialogCircularProgressOpen(false);
                                setIsDialogOpen(false);
                                console.info(`Upload successful`);
                                response.json().then((objectResponse) => {
                                  const newVideos = _.cloneDeep(videos);
                                  const newRecords = _.get(objectResponse, "records", []);

                                  newVideos.push(...newRecords);
                                  setVideos(newVideos);

                                  props.insertVideoPathValue(newVideos);

                                  const newVideoID = String(newRecords[0]._id);
                                  setSelectedVideoID(newVideoID);
                                  selectVideo(newVideoID, newVideos);
                                });
                              }
                            })
                            .catch(console.error);
                        })
                        .catch(console.error);
                    }
                  }}
                />
                <Card className={props.classes.imageUploadPreview}>
                  <CardContent
                    style={{
                      display: "flex",
                      flex: "1 0 auto",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      weight: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      id={imgID}
                      style={{
                        display: "none",
                        objectFit: "contain",
                        width: "100%",
                        height: "100%",
                      }}
                      onClick={(e) => {
                        document.getElementById(inputID).click();
                      }}
                    />
                    <AddIcon
                      id={buttonID}
                      style={{ width: 50, height: 50 }}
                      onClick={(e) => {
                        document.getElementById(inputID).click();
                      }}
                    ></AddIcon>
                    <img
                      id={checkButtonID}
                      src={jsonImage}
                      style={{ width: 50, height: 50, display: "none" }}
                      onClick={(e) => {
                        document.getElementById(inputID).click();
                      }}
                    />
                  </CardContent>
                </Card>
              </TabPanel>
              <TabPanel value={tab} index={1}>
                <Typography component={"span"}>{props.caption}</Typography>
                <TextField
                  style={{}}
                  id={`${props.id}.textField`}
                  className={props.classes.textField}
                  label={"URL"}
                  value={url}
                  onChange={(event) => {
                    if (props.userPermissions["update"] === false) {
                      props.createSnackbar(
                        "You do not have permission to update, contact your administrator.",
                        "warning",
                        false
                      );
                      return;
                    }
                    setURL(event.target.value);
                  }}
                  error={!_.isNil(errorText)}
                  helperText={errorText}
                ></TextField>
                <Button
                  id={`${props.id}.setURL`}
                  color="secondary"
                  className={props.classes.autoplayButton}
                  style={{ marginTop: "0.5rem" }}
                  variant="contained"
                  onClick={() => {
                    if (props.userPermissions["update"] === false) {
                      props.createSnackbar(
                        "You do not have permission to update, contact your administrator.",
                        "warning",
                        false
                      );
                      return;
                    }
                    setIsUploading(true);
                    createVideoObject();
                  }}
                  startIcon={<TheatersRoundedIcon />}
                >
                  Load Link
                </Button>
              </TabPanel>
            </div>
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress
                color={"secondary"}
                style={{
                  textAlign: "center",
                  display: isUploading ? "block" : "none",
                }}
              ></CircularProgress>
            </div>
          </div>
        </Dialog>
      </>
    );
  };

  return (
    <div id={`${props.id}.Div`}>
      {" "}
      {props.isVisible && (
        <>
          {_.get(props, `label`) && (
            <Typography style={{ marginTop: gettingMargin(props.tooltipLength) }} id="discrete-slider" gutterBottom>
              {_.get(props, `label`)}
            </Typography>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Select
              onChange={(event) => {
                if (props.userPermissions["update"] === false) {
                  props.createSnackbar(
                    "You do not have permission to update, contact your administrator.",
                    "warning",
                    false
                  );
                  return;
                }
                if (isSelectionValid(String(event.target.value))) {
                  selectVideo(String(event.target.value));
                }
              }}
              className={props.classes.imageSelector}
              value={String(selectedVideoID)}
            >
              {videos.map((video, index, arr) => {
                return (
                  <MenuItem
                    key={String(video._id)}
                    value={String(video._id)}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography variant="subtitle1">{truncateURL(video.name, 40)}</Typography>
                    {selectedVideoID !== video._id && (
                      <div
                        style={{
                          float: "right",
                        }}
                      >
                        <IconButton
                          className={classes.iconButton}
                          onClick={(e) => {
                            e.stopPropagation();
                            if (props.userPermissions["delete"] === false) {
                              props.createSnackbar(
                                "You do not have permission to delete, contact your administrator.",
                                "warning",
                                false
                              );
                              return;
                            }
                            setVideoToDelete(video);
                            setIsDeleteVideoDialogOpen(true);
                          }}
                        >
                          <Remove />
                        </IconButton>
                      </div>
                    )}
                  </MenuItem>
                );
              })}
            </Select>
            <IconButton
              id={`${props.id}.uploadButton`}
              className={classes.iconButton}
              onClick={(e) => {
                if (props.userPermissions["insert"] === false) {
                  props.createSnackbar(
                    "You are not authorized to insert, contact your administrator.",
                    "warning",
                    false
                  );
                  return;
                }
                if (props.userPermissions["update"] === false) {
                  props.createSnackbar(
                    "You are not authorized to update, contact your administrator.",
                    "warning",
                    false
                  );
                  return;
                }
                setUploadStatus("ready");
                setIsDialogOpen(true);
              }}
            >
              <AddIcon />
            </IconButton>
            <DialogVideoDelete
              isOpen={isDeleteVideoDialogOpen}
              setIsOpen={(value) => setIsDeleteVideoDialogOpen(value)}
              createSnackbar={props.createSnackbar}
              videoToDelete={videoToDelete}
              selectedVideoID={selectedVideoID}
              videos={videos}
              setVideos={setVideos}
              selectVideo={selectVideo}
              deleteVideoFromVideos={props.deleteVideoFromVideos}
            />
            {isDialogOpen && renderInsertVideoDialog()}
          </div>
        </>
      )}
    </div>
  );
}
