import React, { useState, useEffect } from "react";
import { Dialog, Box, TextField, Button, DialogTitle } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { getServerUri } from "../modules/uriUtility";
import _ from "lodash";
import ReactGA4 from "react-ga4";

let useStyles = makeStyles({
  insertDialog: { margin: 10, display: "flex", alignItems: "center" },
  insertButton: {
    marginLeft: "0.5rem",
  },
});

/**
 *
 * @param props
 * @param props.open
 * @param props.selectedGame
 * @param props.selectedBase
 * @param props.versions
 * @param props.setIsVersionBeingUpdatedFalse
 * @param props.setOpen
 * @param props.insertNewVersion
 * @param props.createSnackbar
 * @returns {JSX.Element|null}
 */

export default function dialogFolderInsert(props) {
  let [title, setTitle] = useState("New Folder");
  let [versionHierarchy, setVersionHierarchy] = useState(1);
  let [baseIDProps, setBaseID] = useState([]);
  let [buttonClick, setButtonClick] = useState(false);

  const classes = useStyles();
  //version hierarcy is updated it enter here
  useEffect(() => {
    if (versionHierarchy != 1) {
      var baseFolder = new Promise((resolve, reject) => {
        fetch(getServerUri("version/updateHierarchy"), {
          method: "POST",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          body: new URLSearchParams({
            accessToken: localStorage.getItem("session.accessToken"),
            baseID: _.get(props, "selectedBase._id"),
            versionHierarchy: JSON.stringify(versionHierarchy),
            companyID: props.companyID,
          }),
        }).then((response) => {
          if (response.status === 200) {
            // this is for folder render process
            props.setUpdateTreeOpen(title + Math.floor(Math.random() * 100000).toString());
          }
        });
      });
    }
  }, [versionHierarchy]);
  const handleKeyPress = (event) => {
    if (event.key === "Enter" || event.key === "Return") {
      handleButtonClick();
    }
  };
  function resetSearchBar() {
    const searchBar = document.getElementById("searchBar");

    if (searchBar) {
      props.setSearchTerm(Math.floor(Math.random() * 10000) + 1);
      // searchBar.value = "";
    }
  }
  const handleButtonClick = () => {
    resetSearchBar();

    props.setOpen(false);
    //take the version hierarchy
    fetch(getServerUri("version/getHierarchy"), {
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({
        accessToken: localStorage.getItem("session.accessToken"),
        baseID: _.get(props, "selectedBase._id"),
        companyID: props.companyID,
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((json) => {
            var tmpObj = {
              "id": Math.floor(Math.random() * 100000),
              "parent": 0,
              "droppable": true,
              "text": title,
              "data": {
                "createdDate": getCurrentDate("."),
                "updateDate": getCurrentDate("."),
              },
            };
            //add new object to the versionHierarchy
            json.versionHierarchy.push(tmpObj);
            let newVersionTree = {};
            newVersionTree["tree"] = json.versionHierarchy;
            setVersionHierarchy(newVersionTree);
            setTitle("New Folder");
            ReactGA4.event({
              category: "Preview",
              action: "Folder Insert",
              label:
                "game: " + props.selectedGame.title + ", base: " + props.selectedBase.title + "Folder Name: " + title,
              dimension1: localStorage.getItem("user.id"),
            });
          });
        } else if (response.status === 401) {
          props.createSnackbar("You are not authorized to update, contact your administrator.", "warning");
          response
            .text()
            .then((textResponse) => {
              console.log({ message: "error updating", textResponse });
            })
            .catch(console.error);
        } else {
          response
            .text()
            .then((textResponse) => {
              console.log({ message: "error renaming", textResponse });
              props.createSnackbar("An error occurred when renaming a new version.", "error");
            })
            .catch(console.error);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  function getCurrentDate(separator = "") {
    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    let time = newDate.getHours();
    let minute = newDate.getMinutes() < 10 ? "0" + newDate.getMinutes() : newDate.getMinutes();
    return `${date < 10 ? `0${date}` : `${date}`}${separator}${
      month < 10 ? `0${month}` : `${month}`
    }${separator}${year} ${time}:${minute}`;

    //return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date}`
  }

  return (
    <Dialog
      id="folderInsert_dialog"
      open={props.open}
      onClose={() => {
        props.setOpen(false);
      }}
    >
      <DialogTitle
        id="folderInsert_dialogTitle"
        style={{
          textAlign: "center",
        }}
      >
        Add Version Folder
      </DialogTitle>
      <div className={classes.insertDialog}>
        <TextField
          id="folderInsert_textField"
          label={"Folder Title"}
          variant={"filled"}
          inputProps={{ maxLength: 31, autoFocus: true }}
          onKeyPress={handleKeyPress}
          onChange={(event) => {
            if (event.target.value.length < 31) {
              setTitle("New Folder");

              var a = event.target.value;
              var a = a.replace(/\s/g, "");
              if (a.length == 0) {
                setTitle("New Folder");
                setButtonClick(false);
              } else {
                setTitle(event.target.value);
                setButtonClick(false);
              }
            } else {
              setButtonClick(true);
              props.createSnackbar("No more than 30 characters can be entered", "error");
            }
          }}
        ></TextField>
        <Button
          id="folderInsert_button"
          className={classes.insertButton}
          label={"Insert"}
          disabled={buttonClick}
          onClick={handleButtonClick}
        >
          OK
        </Button>
      </div>{" "}
    </Dialog>
  );
  return null;
}
