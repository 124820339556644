import React from "react";
import { Button, Dialog, DialogTitle } from "@material-ui/core";
import { getServerUri } from "../modules/uriUtility";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";

const useClasses = makeStyles({
  dialogSound: {
    margin: 10
  }
});
/**
 *
 * @param {Boolean} props.isOpen is it open?
 * @param {Function} props.setIsOpen set is open to true or false
 * @param props.glbToDelete
 * @param props.selectedGlbID
 * @param props.glbs
 * @param props.setGlbs
 * @param props.createSnackbar
 * @param props.selectGlb
 * @param props.deleteGlbFromGlbs
 * @returns {JSX.Element}
 */
export default function dialogGlbDelete(props) {
  const classes = useClasses();
  return (
    <Dialog
      open={props.isOpen}
      onClose={() => {
        props.setIsOpen(false);
      }}
    >
      <DialogTitle>Delete glb?</DialogTitle>
      <div className={classes.dialogSound}>
        <Button
          label={"OK"}
          onClick={() => {
            console.log("deleting glb");
            fetch(getServerUri("glb/deleteOne"), {
              method: "DELETE",
              headers: {
                "content-type": "application/x-www-form-urlencoded"
              },
              body: new URLSearchParams({
                accessToken: localStorage.getItem("session.accessToken"),
                glbID: props.glbToDelete._id,
                fileName: _.get(props.glbToDelete, "file")
              })
            })
              .then((response) => {
                if (response.status === 200) {
                  let newGlbs = _.cloneDeep(props.glbs);
                  newGlbs.splice(newGlbs.indexOf(newGlbs.filter((glb) => glb._id === props.glbToDelete._id)[0]), 1);
                  props.deleteGlbFromGlbs(props.glbToDelete._id);
                  props.setGlbs(newGlbs);
                  if (newGlbs.length > 0) {
                    if (props.glbToDelete._id === props.selectedGlbID) {
                      props.selectGlb(newGlbs[0]._id);
                    }
                  } else {
                    props.selectGlb("");
                  }
                  console.log("deleted glb");
                  props.createSnackbar("Glb deleted", "success");
                  props.setIsOpen(false);
                } else {
                  response
                    .text()
                    .then((responseText) => {
                      console.log(responseText);
                    })
                    .catch(console.error);
                }
              })
              .catch((err) => {
                console.log("fetch error");
                console.error(err);
                props.createSnackbar("An error occurred deleting the glb.", "error");
              });
            props.setIsOpen(false);
          }}
        >
          OK
        </Button>
        <Button
          label={"Cancel"}
          onClick={() => {
            props.setIsOpen(false);
          }}
        >
          Cancel
        </Button>
      </div>
    </Dialog>
  );
}
