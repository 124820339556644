import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useClasses = makeStyles({
  dialog: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  root: {
    display: "flex",
  },
  headerWrapper: {
    margin: "1rem 2rem 0rem 2rem",
  },
  headerText: {
    textAlign: "center",
    fontSize: "1vw",
  },
  buttonText: {
    fontSize: "0.8vw",
  },
});
/**
 *
 * @param {Boolean} props.isOpen is it open?
 * @param {Function} props.setIsOpen set is open to true or false
 * @returns {JSX.Element}
 */
export default function dialogConfirmRefresh(props) {
  const [isOpen, setIsOpen] = useState(false);
  const classes = useClasses();

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);
  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        props.setIsOpen(false);
      }}
    >
      <div className={classes.headerWrapper}>
        <Typography className={classes.headerText} align="center">
          New media is just uploaded, refresh the page if it was not you.
        </Typography>
      </div>
      <DialogActions className={classes.dialog}>
        <Button
          label={"Refresh"}
          color="primary"
          variant="contained"
          style={{
            margin: "0.5rem",
          }}
          onClick={(event) => {
            window.location.reload();
          }}
        >
          <span className={classes.buttonText}>Refresh</span>
        </Button>
      </DialogActions>
    </Dialog>
  );
}
