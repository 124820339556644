import React, { useState } from "react";
import {
  Checkbox,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  Button,
  Typography,
  MenuItem,
  Select,
  Input,
  ListItemText,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import applovinLogo from "../../images/network/IconsThin/applovin-icon.png";
import facebookLogo from "../../images/network/IconsThin/facebook-icon.png";
import googleLogo from "../../images/network/IconsThin/googleads-icon.png";
import ironsourceLogo from "../../images/network/IconsThin/ironsource-icon.png";
import mintegralLogo from "../../images/network/IconsThin/mintegral-icon.png";
import unityLogo from "../../images/network/IconsThin/unityads-icon.png";
import vungleLogo from "../../images/network/IconsThin/vungle-icon.png";
import tiktokLogo from "../../images/network/IconsThin/tiktok-icon.png";
import playinIcon from "../../images/network/IconsThin/playin-icon.png";
import molocoIcon from "../../images/network/IconsThin/moloco-icon.png";

import { getServerUri } from "../modules/uriUtility";
import _ from "lodash";

const networkViews = [
  { name: "default", logo: playinIcon, caption: "Test Playable" },
  { name: "applovin", logo: applovinLogo, caption: "AppLovin" },
  { name: "facebook", logo: facebookLogo, caption: "Facebook" },
  { name: "google", logo: googleLogo, caption: "Google" },
  { name: "ironsource", logo: ironsourceLogo, caption: "IronSource" },
  { name: "mintegral", logo: mintegralLogo, caption: "Mintegral" },
  { name: "unity", logo: unityLogo, caption: "Unity" },
  { name: "vungle", logo: vungleLogo, caption: "Vungle" },
  { name: "tiktok", logo: tiktokLogo, caption: "Tiktok" },
  { name: "moloco", logo: molocoIcon, caption: "Moloco" },
];

const useStyles = makeStyles(() => ({
  networkLogo: { height: 20 },
  networkDiv: { display: "flex", alignItems: "center", padding: "2px 10px 2px 0px" },
  languageSelector: { width: "100%" },
  downloadDialogCard: { margin: "0px 10px 10px 10px", padding: 0, width: 300, backgroundColor: "#363636" },
}));

const DOWNLOAD_ERROR_MESSAGE = "Error During Downloading HTMLs";

/**
 *
 * @param props.open
 * @param props.isOpen
 * @param props.onClose
 * @param props.selectedVersion
 * @param props.selectedBase
 * @param props.setIsDownloadingPlayable
 * @returns {JSX.Element}
 */
export default function dialogVersionDownload(props) {
  const [networks, setNetworks] = useState(new Set());
  const [newNetworks, setNewNetworks] = useState(new Set());

  const [totalSet, setTotalSet] = useState(new Set());

  const [isBuilding, setIsBuilding] = useState(false);
  const [buttonText, setButtonText] = useState("Download");
  const [error, setError] = useState("");
  const [selectedLanguages, setSelectedLanguages] = useState(["en"]);
  const [isAllLanguagesSelected, setIsAllLanguagesSelected] = useState(false);
  const classes = useStyles();

  function changeNetwork(event) {
    const newNetwork = new Set(networks);
    const newNetwork2 = new Set(newNetworks);
    const newTotalNetwork = new Set(totalSet);
    if (event.target.checked) {
      if (event.target.name != "default") {
        newNetwork.add(event.target.name);
      } else {
        newNetwork2.add(event.target.name);
      }
      newTotalNetwork.add(event.target.name);
    } else {
      newNetwork2.delete(event.target.name);
      newNetwork.delete(event.target.name);
      newTotalNetwork.delete(event.target.name);
    }
    setNetworks(newNetwork);
    setNewNetworks(newNetwork2);
    setTotalSet(newTotalNetwork);
  }

  /**
   *
   * @param buildsToDo
   * @param baseDir
   * @param timeStamp
   * @param version
   * @param versionName
   * @param onCompleteCallback
   */
  function buildGamesWithRecursive(buildsToDo, baseDir, timeStamp, version, versionName, onCompleteCallback) {
    if (buildsToDo.length === 0) {
      onCompleteCallback();
    } else {
      const buildRecord = buildsToDo.shift();
      fetch(getServerUri("version/buildGame"), {
        method: "POST",
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          accessToken: localStorage.getItem("session.accessToken"),
          baseDir,
          buildRecord: JSON.stringify(buildRecord),
          timeStamp,
          version: JSON.stringify(version),
          versionName,
        }),
      }).then((response) => {
        if (response.status === 200) {
          buildGamesWithRecursive(buildsToDo, baseDir, timeStamp, version, versionName, onCompleteCallback);
        }
      });
    }
  }

  function fetchCompany(companyId) {
    return new Promise((resolve, reject) => {
      fetch(getServerUri("company/findOne"), {
        method: "POST",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams({
          accessToken: localStorage.getItem("session.accessToken"),
          companyID: companyId,
        }),
      })
        .then((response) => {
          if (response.status === 200) {
            response
              .json()
              .then((company) => {
                props.setUserCompany(company);
                resolve(company);
              })
              .catch((err) => reject);
          } else {
            response
              .text()
              .then((err) => reject)
              .catch((err) => reject);
          }
        })
        .catch((err) => reject);
    });
  }

  function updatePlayableCounter(totalSet, selectedLanguages, onCompleteCallback) {
    return fetchCompany(_.get(props.userCompany, "_id")).then((company) => {
      const newCompany = _.cloneDeep(company);
      let a = 0;

      if (newNetworks.size > 0) {
        a = 1;
      }
      let oldVal = _.get(
        newCompany.subscription.periods[newCompany.subscription.periods.length - 1],
        "monthlyCreatedPlayables"
      );
      let oldValCount = _.get(
        newCompany.subscription.periods[newCompany.subscription.periods.length - 1],
        "testPlayableCount"
      );

      if (_.isNil(oldVal)) oldVal = 0;
      _.set(
        newCompany.subscription.periods[newCompany.subscription.periods.length - 1],
        "monthlyCreatedPlayables",
        parseInt(oldVal) + networks.size * selectedLanguages.length
      );

      if (_.isNil(oldValCount)) oldValCount = 0;
      _.set(
        newCompany.subscription.periods[newCompany.subscription.periods.length - 1],
        "testPlayableCount",
        parseInt(oldValCount) + a * selectedLanguages.length
      );
      const fetchUri = getServerUri("company/updateOne");
      const fetchInit = {
        method: "POST",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams({
          accessToken: localStorage.getItem("session.accessToken"),
          companyID: String(_.get(newCompany, "_id")),
          subscription: JSON.stringify(_.get(newCompany, "subscription")),
          //testPlayableCount: _.get(newCompany, "testPlayableCount")
        }),
      };
      fetch(fetchUri, fetchInit)
        .then((response) => {
          if (response.status === 200) {
            props.setUserCompany(newCompany);
            onCompleteCallback();
            //console.debug("createdPlayables updated");
          } else {
            response
              .text()
              .then((textResponse) => {
                console.log("createdPlayables update failed: " + textResponse);
              })
              .catch(console.error);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    });
  }

  if (props.selectedBase == null) {
    window.location.reload();
  }
  if (!props.selectedVersion === undefined) return null;
  return (
    <Dialog open={props.isOpen} onClose={props.onClose}>
      <DialogTitle style={{ textAlign: "center" }}>Download Creative</DialogTitle>
      <Card className={classes.downloadDialogCard}>
        <CardContent style={{ padding: 10 }}>
          <Typography>Version: {String(_.get(props, "selectedVersion.title"))}</Typography>
          <Typography> {`Estimated Size: ${(props.totalSizeValue / 1024).toFixed(2)} kb`} </Typography>

          <Select
            multiple
            input={<Input />}
            className={classes.languageSelector}
            renderValue={() => {
              if (isAllLanguagesSelected) {
                return "All Languages";
              } else if (selectedLanguages.length === 1) {
                return props.selectedBase.supportedLanguages[selectedLanguages[0]];
              } else if (selectedLanguages.length > 1) {
                return "Multiple Languages";
              } else {
                return "Select Languages";
              }
            }}
            onChange={(event) => {
              setSelectedLanguages(event.target.value);
            }}
            value={selectedLanguages}
          >
            <MenuItem key="All Languages" value="*">
              <Checkbox
                checked={isAllLanguagesSelected}
                onChange={(event) => {
                  if (event.target.checked === true) {
                    const allLanguages = Object.keys(props.selectedBase.supportedLanguages);
                    //console.debug("setting all languages as selected", allLanguages);
                    setSelectedLanguages(allLanguages);
                    setIsAllLanguagesSelected(true);
                  } else if (event.target.checked === false) {
                    setSelectedLanguages([]);
                    setIsAllLanguagesSelected(false);
                  }
                  //console.debug("changed all lang options");
                }}
              />
              <ListItemText primary={"All Languages"} />
              {"All Languages"}
            </MenuItem>
            {Object.entries(props.selectedBase.supportedLanguages).map(([languageKey, languageCaption]) => {
              return (
                <MenuItem key={languageKey} value={languageKey}>
                  <Checkbox checked={selectedLanguages.indexOf(languageKey) > -1} />
                  <ListItemText primary={languageCaption} />
                  {languageCaption}
                </MenuItem>
              );
            })}
          </Select>
        </CardContent>
      </Card>
      <Card className={classes.downloadDialogCard}>
        <CardContent style={{ margin: 0, padding: 0 }}>
          {networkViews.map((network, index, array) => (
            <div key={index}>
              <div className={classes.networkDiv} key={network.name}>
                {/* <Checkbox name={network.name} checked={totalSet.has(network.name)} onChange={changeNetwork}></Checkbox> */}
                <Checkbox name={network.name} onChange={changeNetwork}></Checkbox>
                <Typography style={{ flex: 1 }}>{network.caption}</Typography>
                <img className={classes.networkLogo} src={network.logo} />
              </div>
              {index !== array.length - 1 && (
                <hr style={{ borderWidth: 1, borderColor: "#515151", margin: "0px 10px 0px 10px" }} />
              )}
            </div>
          ))}
        </CardContent>
      </Card>
      <Card className={classes.downloadDialogCard}>
        <CardContent
          style={{
            textAlign: "center",
            margin: 0,
            padding: 5,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress
            color={"secondary"}
            style={{ textAlign: "center", display: isBuilding ? "block" : "none" }}
          ></CircularProgress>
          <Button
            name="download"
            disabled={isBuilding}
            style={{ display: !isBuilding ? "block" : "none", width: "100%" }}
            onClick={() => {
              if (
                props.userCompany.subscription.periods[props.userCompany.subscription.periods.length - 1]
                  .monthlyCreatedPlayables +
                  networks.size * selectedLanguages.length >
                props.plans[props.getPlanIndex(props.userCompany.subscription.currentPlan)].numberOfPlayables
              ) {
                props.createSnackbar(
                  `Your company's current plan allows up to ${
                    props.plans[props.getPlanIndex(props.userCompany.subscription.currentPlan)].numberOfPlayables
                  } playables`,
                  "warning"
                );
                return;
              }
              if (
                props.userCompany.subscription.periods[props.userCompany.subscription.periods.length - 1]
                  .testPlayableCount +
                  newNetworks.size * selectedLanguages.length >
                props.plans[props.getPlanIndex(props.userCompany.subscription.currentPlan)].numberOfTests
              ) {
                props.createSnackbar(
                  `Your company's current plan allows up to ${
                    props.plans[props.getPlanIndex(props.userCompany.subscription.currentPlan)].numberOfTests
                  } playables`,
                  "warning"
                );
                return;
              }
              if (networks.size === 0 && newNetworks.size === 0) {
                setError("Network was not selected");
                return;
              }
              if (selectedLanguages.length === 0) {
                setError("No languages were selected.");
                return;
              }
              setError(null);
              setIsBuilding(true);
              setButtonText("Building...");
              console.info("sending download request");
              let networkArray = Array.from(totalSet);
              console.info("networks: " + JSON.stringify(networkArray));
              props.setIsDownloadingPlayable(true);
              fetch(getServerUri("version/preBuildGame"), {
                method: "POST",
                headers: {
                  "content-type": "application/x-www-form-urlencoded",
                },
                body: new URLSearchParams({
                  accessToken: localStorage.getItem("session.accessToken"),
                  versionID: String(props.selectedVersion._id),
                  networks: networkArray,
                  languages: selectedLanguages,
                }),
              })
                .then((response) => {
                  if (response.status === 200) {
                    response.json().then((dataPreBuild) => {
                      // console.log({ dataPreBuild });
                      const buildsToDo = _.clone(dataPreBuild.buildsToDo);
                      if (buildsToDo.length < 1) {
                        console.error("There is no build to do");
                        return;
                      }
                      const versionName = dataPreBuild.version.title.replaceAll(" ", "_");
                      const baseDir = dataPreBuild.baseDir;
                      const timeStamp = dataPreBuild.timeStamp;
                      const version = dataPreBuild.version;
                      buildGamesWithRecursive(buildsToDo, baseDir, timeStamp, version, versionName, () => {
                        fetch(getServerUri("version/uploadGame"), {
                          method: "POST",
                          headers: {
                            "content-type": "application/x-www-form-urlencoded",
                          },
                          body: new URLSearchParams({
                            accessToken: localStorage.getItem("session.accessToken"),
                            baseDir,
                            baseID: dataPreBuild.baseID,
                            companyName: dataPreBuild.companyName,
                            gameID: dataPreBuild.gameID,
                            requestedSupportedLanguages: JSON.stringify(dataPreBuild.requestedSupportedLanguages),
                            requestedSupportedNetworks: JSON.stringify(dataPreBuild.requestedSupportedNetworks),
                            timeStamp,
                            versionName,
                          }),
                        })
                          .then((response) => {
                            if (response.status === 200) {
                              response.json().then((obj) => {
                                if (_.has(obj, "completion")) {
                                  console.info("Progress Received: ", obj);
                                } else if (_.has(obj, "file")) {
                                  setButtonText("Download");
                                  setIsBuilding(false);
                                  props.setIsDownloadingPlayable(false);
                                  // updateDefaultPlayableCounter(newNetworks.size * selectedLanguages.length).catch((err) => {
                                  // });
                                  setTotalSet(new Set());
                                  //new added
                                  setNetworks(new Set());
                                  setNewNetworks(new Set());
                                  props.setIsDownloadDialogOpen(false);
                                  updatePlayableCounter(totalSet, selectedLanguages, () => {
                                    window.location = obj.file;
                                  }).catch((err) => {
                                    console.log(err);
                                    setError(DOWNLOAD_ERROR_MESSAGE);
                                  });
                                }
                              });
                            } else {
                              props.setIsDownloadingPlayable(false);
                              response
                                .json()
                                .then((object) => {
                                  setError("Server did not process the request");
                                  console.log("non 200 response:");
                                  console.dir(object);
                                  setTotalSet(new Set());
                                  //new added
                                  setNetworks(new Set());
                                  setNewNetworks(new Set());
                                })
                                .catch((err) => {
                                  console.log("can't parse response as json", err);
                                  setError(DOWNLOAD_ERROR_MESSAGE);
                                });
                            }
                          })
                          .catch((err) => {
                            props.setIsDownloadingPlayable(false);
                            setError("Server did not process the request");
                            setError(err);
                            console.log(err);
                          });
                      });
                    });
                  } else {
                    props.setIsDownloadingPlayable(false);
                    response
                      .json()
                      .then((object) => {
                        setError("Server did not process the request");
                        console.log("non 200 response:");
                        console.dir(object);
                        setTotalSet(new Set());
                        //new added
                        setNetworks(new Set());
                        setNewNetworks(new Set());
                      })
                      .catch((err) => {
                        console.log("can't parse response as json", err);
                        setError(DOWNLOAD_ERROR_MESSAGE);
                      });
                  }
                })
                .catch((err) => {
                  props.setIsDownloadingPlayable(false);
                  setError("Could not send request to server");
                  console.error("FETCH ERROR");
                  console.error(err);
                });
            }}
          >
            {buttonText}
          </Button>
        </CardContent>
      </Card>
      <Typography style={{ textAlign: "center" }}>{error}</Typography>
    </Dialog>
  );
}
