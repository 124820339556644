import React, { useEffect, useState } from "react";
import { Popover, Button, Typography, TextField } from "@material-ui/core";
import { ChromePicker } from "react-color";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  fullWidthEditor: {
    margin: 10,
    width: "100%",
  },
  textGameObjectContainer: { marginTop: 5, marginBottom: 5 },
  title: {},
  imageItem: { margin: 2 },
});

/**
 *
 * @param props.setValue {Function}
 * @param props.value {Number} - Number color
 * @param props.label {String}
 * @param props.classes
 * @param props.mode
 * @returns {JSX.Element}
 */
export default function selectorColor(props) {
  let [value, setValue] = useState(getStringColor(props.value)); //always string color
  let [isPickerVisible, setIsPickerVisible] = useState(false);
  let [anchorElement, setAnchorElement] = useState(null);
  const classes = useStyles();

  function getNumberColor(colorString) {
    return parseInt(String(colorString).replace("#", "0x"));
  }

  function getStringColor(numberColor) {
    return "#" + (numberColor & 0x00ffffff).toString(16).padStart(6, "0");
  }
  function gettingMargin(numberMargin) {
    if (props.tooltip != undefined) {
      if (numberMargin) {
        return numberMargin.toString() + "px";
      }
    } else return (0).toString() + "px";
  }

  useEffect(() => {
    if (_.get(props, "mode") === "String") {
      setValue(props.value);
    } else {
      const stringColor = getStringColor(props.value);
      setValue(stringColor);
    }
  }, [props.value]);

  return (
    <div id={`${props.id}.Div`}>
      {" "}
      {props.isVisible && (
        <div className={props.classes.editorItem}>
          <Typography style={{ marginTop: gettingMargin(props.tooltipLength) }} className={classes.title} gutterBottom>
            {_.get(props, `label`)}
          </Typography>
          <Button
            id={`${props.id}.paletteButton`}
            style={{
              backgroundColor: value,
              borderStyle: "solid",
              borderWidth: 1,
              borderColor: "gray",
            }}
            onClick={(event) => {
              if (props.userPermissions["update"] === false) {
                props.createSnackbar(
                  "You do not have permission to update, contact your administrator.",
                  "warning",
                  false
                );
                return;
              }
              setAnchorElement(event.currentTarget);
              setIsPickerVisible(true);
            }}
          ></Button>
          <Popover
            open={isPickerVisible}
            onClose={(event) => {
              setAnchorElement(null);
              setIsPickerVisible(false);
            }}
            anchorEl={anchorElement}
          >
            <ChromePicker
              color={value}
              disableAlpha={true}
              defaultView={"rgb"}
              onChange={(color, event) => {
                if (props.userPermissions["update"] === false) {
                  props.createSnackbar(
                    "You do not have permission to update, contact your administrator.",
                    "warning",
                    false
                  );
                  return;
                }
                setValue(color.hex);
                if (_.get(props, "mode") === "String") {
                  props.setValue(color.hex);
                } else {
                  //mode === number
                  const numberColor = getNumberColor(color.hex);
                  props.setValue(numberColor);
                }
              }}
            ></ChromePicker>
          </Popover>
        </div>
      )}
    </div>
  );
}
