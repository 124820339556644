import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@mui/material/Autocomplete";

import TextField from "@mui/material/TextField";
import { Card, CardContent, Typography, Button, IconButton } from "@material-ui/core";
import { Add, Remove } from "@material-ui/icons";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import Tooltip from "@material-ui/core/Tooltip";
import EditorText from "./editorText";
import EditorTextArea from "./editorTextArea";
import SelectorColor from "./selectorColor";
import SelectorImageSet from "./selectorImageSet";
import SelectorTexture from "./selectorTexture";
import SelectorImage from "./selectorImage";
import SelectorAtlas from "./selectorAtlas";
import SelectorSound from "./selectorSound";
import SelectorVideo from "./selectorVideo";
import SelectorLevel from "./selectorLevel";
import SelectorAnimation from "./selectorAnimation";
import SwitchGeneric from "./switchGeneric";
import SliderGeneric from "./sliderGeneric";
import SelectorGeneric from "./selectorGeneric";
import SelectorCardOrder from "./selectorCardOrder";
import _, { join } from "lodash";
import SelectorFontFamily from "./selectorFontFamily";
import SelectorFontStyle from "./selectorFontStyle";
import ArrowDropDownRounded from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpRounded from "@material-ui/icons/ArrowDropUp";
import SelectorFont from "./selectorFont";
import SelectorRibbonType from "./selectorRibbonType";
import DialogPlayableSize from "./dialogPlayableSize";
import DefaultVersionSetting from "./defaultVersionSetting";
import CardItemsSelector from "./cardItemsSelector";
import EditorNumber from "./editorNumber";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const useStyles = makeStyles({
  card: { margin: "5px" },
  imageSelector: { width: "100%" },
  imageSetSelector: { width: "100%" },
  cardOrderSelector: { width: "100%" },
  containerVersionSettingsDiv: {
    margin: "0px",
    height: "100%",
    overflowY: "auto",
  },
  showMoreButton: {
    float: "right",
    margin: "0.5rem 0rem 0.5rem 0rem",
    padding: "0.5rem 0.5rem 0.5rem 0.5rem",
    textTransform: "none",
    textDecorationLine: "underline",
  },
});
const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: { main: "#4eabb1" },
  },
});

/**
 * @param props.selectedVersion
 * @param props.selectedBase
 * @param props.selectedBase.gameObjectLabels
 * @param props.selectedBase.versionSettings
 * @param props.setSelectedVersionPathValue
 * @param props.setGameObjectKeyValue
 * @param props.setComponentPathValue
 * @param props.setKeyValue
 * @param props.resetGameConfig
 * @param props.media
 * @param props.areMediaLoaded
 * @param props.insertMediaPathValue
 * @param props.atlases
 * @param props.areAtlasesLoaded
 * @param props.insertAtlasPathValue
 * @param props.sounds
 * @param props.areSoundsLoaded
 * @param props.insertSoundPathValue
 * @param props.fonts
 * @param props.areFontsLoaded
 * @param props.videos
 * @param props.areVideosLoaded
 * @param props.insertFontPathValue
 * @param props.classes
 * @param props.createSnackbar
 * @param props.deleteImageFromMedia
 * @param props.deleteImageSetFromMedia
 * @param props.deleteFontFromFonts
 * @param props.deleteSoundFromSounds
 * @param props.deleteVideoFromVideos
 * @param props.deleteAnimationSetFromAtlases
 * @param props.userPermissions
 * @param props.setIsDialogConfirmRefreshOpen
 * @param props.setIsDialogCircularProgressOpen
 */
export default function containerVersionSettings(props) {
  if (
    !_.has(props, "selectedBase") ||
    _.get(props, "areMediaLoaded") !== true ||
    _.get(props, "areAtlasesLoaded") !== true ||
    _.get(props, "areSoundsLoaded") !== true ||
    _.get(props, "areFontsLoaded") !== true ||
    _.get(props, "areFontsLoaded") !== true
  ) {
    return null;
  }
  const classes = useStyles();

  let [components, setComponents] = useState(_.get(props, "selectedBase.versionSettings.components"));
  let [copyComponents, setCopyComponents] = useState(_.get(props, "selectedBase.versionSettings.components"));

  const [refresh, doRefresh] = useState(0);
  const [selectedCardOrder, setSelectedCardOrder] = useState(
    _.get(props, "selectedVersion.gameConfig.cardOrder.key", "")
  );
  const cardOrderPath = "selectedVersion.gameConfig.cardOrder.key";
  const [filterComponentsArray, setFilterComponentsArray] = useState(null);
  const [lengthOfFilter, setLengthOfFilter] = useState(filterComponents().length);
  const [firstTime, setFirstTime] = useState(false);
  const setValue = (p) => {
    props.setSelectedVersionPathValue(p);
  };

  useEffect(() => {
    setTimeout(() => {
      prepareEnemyComponents();
      prepareVideoDecisionMakers();
      prepareVideoPauseInfos();
    }, 50);
  }, [props.selectedVersion]);

  useEffect(() => {
    const selectedCardOrder = _.get(props, cardOrderPath);
    setSelectedCardOrder(selectedCardOrder);
  }, [_.get(props, cardOrderPath)]);

  useEffect(() => {
    doRefresh((prev) => prev + 1);

    if (!_.isNil(components)) {
      components.map((component) => {
        if (_.isNil(components[components.indexOf(component)].isShowMoreClicked)) {
          components[components.indexOf(component)].isShowMoreClicked = false;
        }
      });
    }
  }, []);

  let returnedData = localStorage.getItem("newgetVal");
  let baseIDSession = JSON.parse(localStorage.getItem("selectedBaseSession"));
  if (baseIDSession === _.get(props, "selectedBase._id")) {
    localStorage.setItem("selectedBaseSession", JSON.stringify(_.get(props, "selectedBase._id")));

    if (!firstTime) {
      if (!_.isNil(returnedData)) {
        setFilterComponentsArray(JSON.parse(localStorage.getItem("newgetVal")));
        setNewComponents(JSON.parse(localStorage.getItem("newgetVal")));
      } else {
        setFilterComponentsArray([]);
        setNewComponents([]);
      }
      setFirstTime(true);
    }
  } else {
    localStorage.setItem("newgetVal", JSON.stringify([]));
  }

  function getCardOrderByKey(key) {
    const foundCardOrders = _.get(props, "selectedBase.cardOrders").filter((cardOrder) => {
      return cardOrder.key === key;
    });
    return _.get(foundCardOrders, "[0]", {});
  }

  function prepareVideoPauseInfos() {
    const videoPauseInfos = _.get(props, "selectedVersion.gameConfig.components.video1.pauseInfos", null);
    if (videoPauseInfos === null) {
      return;
    }

    let decisionMakerComponent = components.find((component) => component.type === "decisionMaker");

    const videoPauseInfoBaseComponent = components.find((component) => component.type === "pauseInfo");

    if (_.isNil(videoPauseInfoBaseComponent)) {
      console.warn("video Pause Infos Base is nil", videoPauseInfoBaseComponent);
      return;
    }

    const currentVideoPauseInfoBaseComponents = components.filter((component) => component.type === "pauseInfo");

    const videoPauseInfoBases = [];

    let items = [];

    videoPauseInfos.forEach((videoPauseInfo, index) => {
      let newVideoPauseInfoBase;

      if (_.isNil(currentVideoPauseInfoBaseComponents[index])) {
        newVideoPauseInfoBase = _.cloneDeep(videoPauseInfoBaseComponent);

        newVideoPauseInfoBase.isShowMoreClicked = false;
      } else {
        newVideoPauseInfoBase = currentVideoPauseInfoBaseComponents[index];
      }

      newVideoPauseInfoBase.caption = newVideoPauseInfoBase.initialCaption + " " + (index + 1);
      newVideoPauseInfoBase.index = index;

      let pauseTime = newVideoPauseInfoBase.caption;

      if (!items.includes(pauseTime)) {
        items.push(pauseTime);
      }

      newVideoPauseInfoBase.settings.forEach((setting) => {
        if (setting.type === "indexedPointerImages") {
          if (setting.pathIndex !== index + 1) {
            setting.pathIndex = index + 1;

            setting.path = setting.path.replace(/\d/g, "") + (index + 1);
          }

          let destinations = {};

          Object.entries(setting.destinations).forEach(([key, value]) => {
            const splitValue = value.split(".");
            destinations[key] = "pointer" + (index + 1) + "_" + key + "." + splitValue[1];
          });

          setting.destinations = destinations;
        } else {
          if (_.isNil(setting.correctPath)) {
            setting.correctPath = setting.path;
          }

          const splitPath = setting.correctPath.split(".");

          setting.path = splitPath[0] + `[${index}]`;

          for (let i = 1; i < splitPath.length; i++) {
            setting.path += "." + splitPath[i];
          }
        }
      });

      decisionMakerComponent.settings.forEach((setting) => {
        if (setting.path == "showImageAtPauseTime") {
          setting.items = items;
        }
      });

      videoPauseInfoBases.push(newVideoPauseInfoBase);
    });

    const newComponents = [];

    // clear video pause infos
    components
      .filter((component) => component.type !== "pauseInfo")
      .forEach((component, index) => {
        newComponents.push(component);
        // place new video pause info configs
        if (component.type === "decisionMaker") {
          videoPauseInfoBases.forEach((videoPauseInfo) => {
            newComponents.push(videoPauseInfo);
          });
        }
      });

    setComponents(newComponents);
  }

  function addVideoPauseInfo() {
    const videoPauseInfoBaseComponent = components.find((component) => component.type === "pauseInfo");
    const versionVideoPauseInfos = _.get(props, "selectedVersion.gameConfig.components.video1.pauseInfos");

    let versionVideoSelectedImages = _.get(props, "selectedVersion.gameConfig.components.video1.selectedImages");
    versionVideoSelectedImages[`pointers${versionVideoPauseInfos.length + 1}`] = _.cloneDeep(
      versionVideoSelectedImages[`pointers${versionVideoPauseInfos.length}`]
    );

    versionVideoSelectedImages[`pointers${versionVideoPauseInfos.length + 1}`].forEach((image) => {
      if (image.variant === "image1") {
        image.destination = `pointer${versionVideoPauseInfos.length + 1}_image1.png`;
      } else if (image.variant === "image2") {
        image.destination = `pointer${versionVideoPauseInfos.length + 1}_image2.png`;
      }

      image.path = "pointers" + (versionVideoPauseInfos.length + 1);
    });

    const lastVideoPauseInfoConfig = _.cloneDeep(versionVideoPauseInfos[versionVideoPauseInfos.length - 1]);

    const pauseTimeMaxInfo = videoPauseInfoBaseComponent.settings.find((setting) => {
      return setting.correctPath === "pauseInfos.pauseTime";
    }).max;

    // const lastVideoPauseInfoPauseTimeNumber = parseFloat(lastVideoPauseInfoConfig.pauseTime)

    lastVideoPauseInfoConfig.pauseTime =
      lastVideoPauseInfoConfig.pauseTime + 1 > pauseTimeMaxInfo
        ? pauseTimeMaxInfo
        : lastVideoPauseInfoConfig.pauseTime + 1;

    versionVideoPauseInfos.push(lastVideoPauseInfoConfig);

    setValue({
      path: "gameConfig.components.video1.pauseInfos",
      value: versionVideoPauseInfos,
    });

    setValue({
      path: "gameConfig.components.video1.selectedImages",
      value: versionVideoSelectedImages,
    });

    prepareVideoPauseInfos();
  }

  function removeVideoPauseInfo(index) {
    const versionVideoPauseInfos = _.get(props, "selectedVersion.gameConfig.components.video1.pauseInfos");
    versionVideoPauseInfos.splice(index, 1);

    let versionVideoSelectedImages = _.get(props, "selectedVersion.gameConfig.components.video1.selectedImages");

    let versionVideoSelectedDecisionMakers = selectedDecisionMakers(versionVideoSelectedImages);

    let versionVideoSelectedPointerImages = selectedPointerImages(versionVideoSelectedImages);

    let versionVideoSelectedDecisonMakerPointerImages = selectedDecisionMakerPointerImages(versionVideoSelectedImages);

    delete versionVideoSelectedImages[`pointers${index + 1}`];

    Object.entries(versionVideoSelectedImages).forEach(([key, images], index) => {
      if (key === `pointers${index + 2}`) {
        images.forEach((image) => {
          if (image.variant === "image1") {
            image.destination = `pointer${index + 1}_image1.png`;
          } else if (image.variant === "image2") {
            image.destination = `pointer${index + 1}_image2.png`;
          }

          image.path = "pointers" + (index + 1);
        });

        versionVideoSelectedImages[`pointers${index + 1}`] = images;

        delete versionVideoSelectedImages[`pointers${index + 2}`];
      }
    });

    versionVideoSelectedImages = {
      ...versionVideoSelectedDecisionMakers,
      ...versionVideoSelectedPointerImages,
      ...versionVideoSelectedDecisonMakerPointerImages,
    };

    setValue({
      path: "gameConfig.components.video1.pauseInfos",
      value: versionVideoPauseInfos,
    });

    setValue({
      path: "gameConfig.components.video1.selectedImages",
      value: versionVideoSelectedImages,
    });

    prepareVideoPauseInfos();
  }

  function isVideoPauseInfoLast(component) {
    const videoPauseInfoComponents = components.filter((component) => component.type === "pauseInfo");

    return videoPauseInfoComponents.indexOf(component) === videoPauseInfoComponents.length - 1;
  }

  function canAddVideoPauseInfo() {
    const videoPauseInfoComponents = components.filter((component) => component.type === "pauseInfo");

    return videoPauseInfoComponents.length < 10;
  }

  function isVideoPauseInfoMulti() {
    const videoPauseInfoComponents = components.filter((component) => component.type === "pauseInfo");

    return videoPauseInfoComponents.length > 1;
  }

  function prepareVideoDecisionMakers() {
    const videoDecisionMakers = _.get(
      props,
      "selectedVersion.gameConfig.components.video1.decisionMakerImagesInfos",
      null
    );
    if (videoDecisionMakers === null) {
      return;
    }

    const videoDecisionMakerBaseComponent = components.find((component) => component.type === "decisionMaker");

    if (_.isNil(videoDecisionMakerBaseComponent)) {
      console.warn("video Pause Images Base is nil", videoDecisionMakerBaseComponent);
      return;
    }

    let currentVideoDecisionMakerBaseComponentsSettings = components.filter(
      (component) => component.type === "decisionMaker"
    )[0].settings;

    const decisionMakerImageSettings = currentVideoDecisionMakerBaseComponentsSettings.filter((setting) => {
      return setting.type === "decisionMakerImage";
    });

    const videoDecisionMakerBases = [];

    videoDecisionMakers.forEach((videoDecisionMaker, index) => {
      let newVideoDecisionMakerBase;

      if (_.isNil(decisionMakerImageSettings[index])) {
        newVideoDecisionMakerBase = _.cloneDeep(decisionMakerImageSettings);
        newVideoDecisionMakerBase = newVideoDecisionMakerBase[0];

        newVideoDecisionMakerBase.isShowMoreClicked = false;
      } else {
        newVideoDecisionMakerBase = decisionMakerImageSettings[index];
      }

      newVideoDecisionMakerBase.caption = newVideoDecisionMakerBase.initialCaption + " " + (index + 1);
      newVideoDecisionMakerBase.index = index;

      newVideoDecisionMakerBase.settings.forEach((setting) => {
        if (setting.type === "indexedDecisionMakerImages") {
          if (setting.pathIndex !== index + 1) {
            setting.pathIndex = index + 1;

            setting.path = setting.path.replace(/\d/g, "") + (index + 1);
          }

          const splitValue = setting.destination.split(".");

          let destination = "decisionMakerImage" + (index + 1) + "." + splitValue[1];

          setting.destination = destination;
        } else {
          if (_.isNil(setting.correctPath)) {
            setting.correctPath = setting.path;
          }
          const splitPath = setting.correctPath.split(".");

          setting.path = splitPath[0] + `[${index}]`;

          for (let i = 1; i < splitPath.length; i++) {
            setting.path += "." + splitPath[i];
          }
        }
      });

      videoDecisionMakerBases.push(newVideoDecisionMakerBase);
    });

    let settings = [];
    components
      .filter((component) => component.type === "decisionMaker")[0]
      .settings.forEach((setting) => {
        if (setting.type !== "decisionMakerImage") {
          settings.push(setting);
        }
      });
    settings.push(...videoDecisionMakerBases);
    components.filter((component) => component.type === "decisionMaker")[0].settings = settings;

    const newComponents = [];
    components
      .filter((component) => component.type !== "decisionMaker")
      .forEach((component, index) => {
        newComponents.push(component);
        // place new video pause info configs
        if (component.type === "video") {
          newComponents.push(components.filter((component) => component.type === "decisionMaker")[0]);
        }
      });

    setComponents(newComponents);

    if (videoDecisionMakers.length < 2) {
      addVideoDecisionMaker();
    }
  }

  function addVideoDecisionMaker() {
    const versionVideoDecisionMakers = _.get(
      props,
      "selectedVersion.gameConfig.components.video1.decisionMakerImagesInfos"
    );

    if (versionVideoDecisionMakers.length >= 4) {
      return;
    }

    let versionVideoSelectedImages = _.get(props, "selectedVersion.gameConfig.components.video1.selectedImages");

    versionVideoSelectedImages[`decisionMakerImage${versionVideoDecisionMakers.length + 1}`] = _.cloneDeep(
      versionVideoSelectedImages[`decisionMakerImage${versionVideoDecisionMakers.length}`]
    );

    versionVideoSelectedImages[`decisionMakerImage${versionVideoDecisionMakers.length + 1}`].forEach((image) => {
      image.destination = `decisionMakerImage${versionVideoDecisionMakers.length + 1}.png`;

      image.path = "decisionMakerImage" + (versionVideoDecisionMakers.length + 1);
    });

    const lastVideoDecisionMakerConfig = _.cloneDeep(versionVideoDecisionMakers[versionVideoDecisionMakers.length - 1]);

    if (lastVideoDecisionMakerConfig.position.x + 0.3 < 1) {
      lastVideoDecisionMakerConfig.position.x += 0.1;
    } else {
      lastVideoDecisionMakerConfig.position.x = 0.25;

      if (lastVideoDecisionMakerConfig.position.y + 0.3 < 1) {
        lastVideoDecisionMakerConfig.position.y += 0.1;
      } else {
        lastVideoDecisionMakerConfig.position.y = 0.3;
      }
    }

    lastVideoDecisionMakerConfig.position.x = Math.round(lastVideoDecisionMakerConfig.position.x * 100) / 100;
    lastVideoDecisionMakerConfig.position.y = Math.round(lastVideoDecisionMakerConfig.position.y * 100) / 100;

    versionVideoDecisionMakers.push(lastVideoDecisionMakerConfig);

    setValue({
      path: "gameConfig.components.video1.decisionMakerImagesInfos",
      value: versionVideoDecisionMakers,
    });

    setValue({
      path: "gameConfig.components.video1.selectedImages",
      value: versionVideoSelectedImages,
    });

    prepareVideoDecisionMakers();
  }

  function removeVideoDecisionMaker(component, componentSetting) {
    const versionVideoDecisionMakers = _.get(
      props,
      "selectedVersion.gameConfig.components.video1.decisionMakerImagesInfos"
    );

    if (versionVideoDecisionMakers.length <= 2) {
      return;
    }

    versionVideoDecisionMakers.splice(componentSetting.index, 1);

    let versionVideoSelectedImages = _.get(props, "selectedVersion.gameConfig.components.video1.selectedImages");

    let versionVideoSelectedDecisionMakers = selectedDecisionMakers(versionVideoSelectedImages);

    let versionVideoSelectedPointerImages = selectedPointerImages(versionVideoSelectedImages);

    let versionVideoSelectedDecisonMakerPointerImages = selectedDecisionMakerPointerImages(versionVideoSelectedImages);

    delete versionVideoSelectedDecisionMakers[`decisionMakerImage${componentSetting.index + 1}`];

    Object.entries(versionVideoSelectedDecisionMakers).forEach(([key, images], index) => {
      if (key === `decisionMakerImage${index + 2}`) {
        images.forEach((image) => {
          image.destination = `decisionMakerImage${index + 1}.png`;

          image.path = "decisionMakerImage" + (index + 1);
        });

        versionVideoSelectedDecisionMakers[`decisionMakerImage${index + 1}`] = images;

        delete versionVideoSelectedDecisionMakers[`decisionMakerImage${index + 2}`];
      }
    });

    versionVideoSelectedImages = {
      ...versionVideoSelectedDecisionMakers,
      ...versionVideoSelectedPointerImages,
      ...versionVideoSelectedDecisonMakerPointerImages,
    };

    setValue({
      path: "gameConfig.components.video1.decisionMakerImagesInfos",
      value: versionVideoDecisionMakers,
    });

    setValue({
      path: "gameConfig.components.video1.selectedImages",
      value: versionVideoSelectedImages,
    });

    prepareVideoDecisionMakers();
  }

  function isVideoDecisionMakerImageLast(component, componentSetting) {
    const videoDecisionMakerImageSettings = components[components.indexOf(component)].settings;

    return videoDecisionMakerImageSettings.indexOf(componentSetting) === videoDecisionMakerImageSettings.length - 1;
  }

  function canAddVideoDecisionMakerImagesInfo(component) {
    const videoDecisionMakerImageSettings = components[components.indexOf(component)].settings.filter(
      (setting) => setting.type === "decisionMakerImage"
    );

    return videoDecisionMakerImageSettings.length < 4;
  }

  function isVideoDecisionMakerImagesMulti(component) {
    const videoDecisionMakerImageSettings = components[components.indexOf(component)].settings.filter(
      (setting) => setting.type === "decisionMakerImage"
    );

    return videoDecisionMakerImageSettings.length > 2;
  }
  function selectedDecisionMakers(versionVideoSelectedImages) {
    let selectedDecisionMakers = Object.entries(versionVideoSelectedImages).filter(([key, value]) =>
      key.includes("decisionMakerImage")
    );

    let versionVideoSelectedDecisionMakers = {};

    selectedDecisionMakers.forEach(([key, images], index) => {
      versionVideoSelectedDecisionMakers[key] = images;
    });

    return versionVideoSelectedDecisionMakers;
  }

  function selectedPointerImages(versionVideoSelectedImages) {
    let selectedPointerImages = Object.entries(versionVideoSelectedImages).filter(([key, value]) =>
      key.includes("pointers")
    );

    let versionVideoSelectedPointerImages = {};

    selectedPointerImages.forEach(([key, images], index) => {
      versionVideoSelectedPointerImages[key] = images;
    });

    return versionVideoSelectedPointerImages;
  }

  function selectedDecisionMakerPointerImages(versionVideoSelectedImages) {
    let selectedDecisionMakerPointerImages = Object.entries(versionVideoSelectedImages).filter(([key, value]) =>
      key.includes("decisionMakerPointer")
    );

    let versionVideoSelectedDecisionMakerPointerImages = {};

    selectedDecisionMakerPointerImages.forEach(([key, images]) => {
      versionVideoSelectedDecisionMakerPointerImages[key] = images;
    });

    return versionVideoSelectedDecisionMakerPointerImages;
  }

  function renderDecisionMakerImagesComponent(component, componentSetting, isVisible) {
    if (_.isNil(componentSetting.isShowMoreClicked)) {
      componentSetting.isShowMoreClicked = false;
    }
    return (
      <div>
        {isVisible && (
          <Card
            className={props.classes.card}
            key={`${component.id}-${component.caption.replace(/\s/g, "")}-${componentSetting.caption.replace(
              /\s/g,
              ""
            )}`}
          >
            <CardContent>
              <div style={{ "display": "flex", "justifyContent": "space-between" }}>
                <Typography className={classes.title} variant={"h6"}>
                  {_.get(componentSetting, "caption")}
                </Typography>

                <div
                  style={{
                    "display": "flex",
                    "justifyContent": "space-between",
                    "marginTop": "-0.88rem",
                    "marginLeft": "-3,5rem",
                  }}
                >
                  {isVideoDecisionMakerImageLast(component, componentSetting) &&
                    canAddVideoDecisionMakerImagesInfo(component) && (
                      <IconButton
                        color="secondary"
                        disableRipple={true}
                        style={{ "background": "none" }}
                        onClick={(event) => {
                          if (props.userPermissions["update"] === false) {
                            props.createSnackbar(
                              "You do not have permission to update, contact your administrator.",
                              "warning",
                              false
                            );
                            return;
                          }
                          event.preventDefault();
                          addVideoDecisionMaker(component);
                        }}
                      >
                        <Add />
                      </IconButton>
                    )}
                  {isVideoDecisionMakerImagesMulti(component) && (
                    <IconButton
                      color="secondary"
                      disableRipple={true}
                      style={{ "background": "none" }}
                      onClick={(event) => {
                        if (props.userPermissions["update"] === false) {
                          props.createSnackbar(
                            "You do not have permission to update, contact your administrator.",
                            "warning",
                            false
                          );
                          return;
                        }
                        removeVideoDecisionMaker(component, componentSetting);
                      }}
                    >
                      <Remove />
                    </IconButton>
                  )}
                  {_.get(componentSetting, "settings").length > 0 && (
                    <Button
                      disableRipple={true}
                      startIcon={componentSetting.isShowMoreClicked ? <ArrowDropUpRounded /> : <ArrowDropDownRounded />}
                      color="secondary"
                      className={classes.showMoreButton}
                      style={{
                        // marginLeft:"-2rem",
                        width: "10rem",
                        background: "none",
                      }}
                      onClick={() => {
                        const newComponents = _.cloneDeep(components);

                        const decisionMakerImageComponents = newComponents[
                          components.indexOf(component)
                        ].settings.filter((setting) => {
                          return setting.type === "decisionMakerImage";
                        });
                        decisionMakerImageComponents.forEach((setting) => {
                          if (componentSetting.index == setting.index) {
                            componentSetting = setting;
                          }
                        });

                        componentSetting.isShowMoreClicked = !componentSetting.isShowMoreClicked;
                        setComponents(newComponents);
                      }}
                    >
                      {componentSetting.isShowMoreClicked ? "Hide" : "Show More"}
                    </Button>
                  )}
                </div>
              </div>
              {_.get(componentSetting, "settings").map((setting) => {
                const isVisible =
                  componentSetting.isShowMoreClicked || componentSetting.settings.indexOf(setting) === 0;
                if (setting.editorType === "selectorImage")
                  return (
                    <SelectorImage
                      id={`${component.id}.${setting.path}`}
                      key={`${component.id}.${setting.path}`}
                      selectedBase={props.selectedBase}
                      selectedGame={props.selectedGame}
                      componentID={component.id}
                      label={setting.caption}
                      classes={props.classes}
                      images={_.get(props, "media.images", [])}
                      componentType={component.type}
                      path={setting.path}
                      destination={setting.destination}
                      bucket={setting.bucket}
                      selectedImage={_.get(
                        props,
                        `selectedVersion.gameConfig.components.${component.id}.${setting.path}[0]`
                      )}
                      setValue={(p) => {
                        setValue({
                          path: `gameConfig.components.${component.id}.${setting.path}`,
                          value: p.value,
                        });
                      }}
                      insertMediaPathValue={props.insertMediaPathValue}
                      createSnackbar={props.createSnackbar}
                      selectedVersion={props.selectedVersion}
                      deleteImageFromMedia={props.deleteImageFromMedia}
                      userPermissions={props.userPermissions}
                      isVisible={isVisible}
                      setIsDialogConfirmRefreshOpen={props.setIsDialogConfirmRefreshOpen}
                      setIsDialogCircularProgressOpen={props.setIsDialogCircularProgressOpen}
                    ></SelectorImage>
                  );

                if (setting.editorType === "sliderGeneric")
                  return (
                    <SliderGeneric
                      key={`${component.id}.${setting.path}`}
                      label={setting.caption}
                      classes={props.classes}
                      path={`gameConfig.components.${component.id}.${setting.path}`} // string olarak alınıyo
                      value={_.get(props, `selectedVersion.gameConfig.components.${component.id}.${setting.path}`, 0)}
                      min={setting.min}
                      max={setting.max}
                      step={setting.step}
                      setValue={(pathToSet, value) => {
                        setValue({
                          path: pathToSet,
                          value,
                        });
                      }}
                      userPermissions={props.userPermissions}
                      createSnackbar={props.createSnackbar}
                      isVisible={isVisible}
                    ></SliderGeneric>
                  );
                if (setting.editorType === "number")
                  return (
                    <EditorNumber
                      key={`${component.id}.${setting.path}`}
                      label={setting.caption}
                      classes={props.classes}
                      value={_.get(props, `selectedVersion.gameConfig.components.${component.id}.${setting.path}`, 0)}
                      setValue={(value) => {
                        setValue({
                          path: `gameConfig.components.${component.id}.${setting.path}`,
                          value,
                        });
                      }}
                      userPermissions={props.userPermissions}
                      createSnackbar={props.createSnackbar}
                      isVisible={isVisible}
                    ></EditorNumber>
                  );
              })}
            </CardContent>
          </Card>
        )}
      </div>
    );
  }

  function prepareEnemyComponents() {
    let enemySoldiersObject = _.get(props, "selectedVersion.gameConfig.components.enemy1.enemySoldiers");
    if (_.isNil(enemySoldiersObject)) {
      return;
    }

    const enemySoldierKeys = Object.keys(enemySoldiersObject);

    const enemyBaseComponent = _.get(props, "selectedBase.versionSettings.components").find(
      (component) => component.type === "enemy"
    );
    if (!_.isNil(enemyBaseComponent)) {
      enemyBaseComponent.isShowMoreClicked = _.get(
        components.find((component) => component.type === "enemy"),
        "isShowMoreClicked",
        false
      );
    }

    let createdEnemySoldiers = [];

    for (let i = 0; i < enemySoldierKeys.length; i++) {
      let newEnemyBase = _.cloneDeep(enemyBaseComponent);
      if (!_.isNil(newEnemyBase)) {
        let newEnemyId = newEnemyBase.id.split(/\.(?=[^\.]+$)/)[0] + "." + enemySoldierKeys[i];
        newEnemyBase.id = newEnemyId;
        newEnemyBase.caption += " " + enemySoldierKeys[i].substr("enemySoldier".length);
        if (!_.isNil(components.find((component) => component.id === newEnemyId)))
          newEnemyBase.isShowMoreClicked = components.find(
            (component) => component.id === newEnemyId
          ).isShowMoreClicked;
        else newEnemyBase.isShowMoreClicked = false;
        createdEnemySoldiers.push(newEnemyBase);
      }
    }

    let newComponents = [];

    // clear enemies
    components
      .filter((component) => component.type !== "enemy")
      .forEach((component) => {
        newComponents.push(component);

        // place new enemy configs to bottom of hero config
        if (component.type === "hero") {
          createdEnemySoldiers.forEach((enemySoldier) => {
            newComponents.push(enemySoldier);
          });
        }
      });

    setComponents(newComponents);
  }

  function addEnemy(selectedEnemyComponent) {
    const baseEnemySoldierConfig = _.get(
      props,
      "selectedBase.gameConfig.components.enemy1.enemySoldiers.enemySoldier1"
    );
    let enemySoldiers = _.get(props, "selectedVersion.gameConfig.components.enemy1.enemySoldiers");

    const enemySoldierKeys = Object.keys(enemySoldiers);

    const lastEnemyKey = enemySoldierKeys[enemySoldierKeys.length - 1];
    const newId = "enemySoldier" + (parseInt(lastEnemyKey.substr("enemySoldier".length)) + 1);

    // get selected enemy's config from selected version to set new enemy component(clone enemy component)
    const selectedEnemyConfig = _.get(props, `selectedVersion.gameConfig.components.${selectedEnemyComponent.id}`);
    if (!_.isNil(selectedEnemyConfig)) {
      _.set(enemySoldiers, newId, _.cloneDeep(selectedEnemyConfig));
    } else {
      const baseEnemy = _.cloneDeep(baseEnemySoldierConfig);
      _.set(enemySoldiers, newId, baseEnemy);
    }

    setValue({
      path: "gameConfig.components.enemy1.enemySoldiers",
      value: enemySoldiers,
    });

    prepareEnemyComponents();
  }

  function removeEnemy(id) {
    let enemyKey = id.split(/\.(?=[^\.]+$)/)[1];
    let enemySoldiers = _.get(props, "selectedVersion.gameConfig.components.enemy1.enemySoldiers");

    delete enemySoldiers[enemyKey];

    setValue({
      path: "gameConfig.components.enemy1.enemySoldiers",
      value: enemySoldiers,
    });

    prepareEnemyComponents();
  }

  function isEnemyLast(component) {
    let enemyComponents = components.filter((component) => component.type === "enemy");
    if (enemyComponents.indexOf(component) === enemyComponents.length - 1) {
      return true;
    }
    return false;
  }

  function isEnemyMulti() {
    let enemyComponents = components.filter((component) => component.type === "enemy");
    return enemyComponents.length > 1;
  }

  function renderTooltip(tooltip, isVisible, marginLeft) {
    return isVisible && tooltip ? (
      <div>
        <Tooltip title={tooltip} aria-label="add">
          <HelpOutlineIcon style={{ marginLeft: marginLeft }} color={"primary"} />
        </Tooltip>
      </div>
    ) : null;
  }
  function filterComponents() {
    let componentArray = [];
    copyComponents.map((component) => {
      try {
        if (Array.isArray(component.tag)) {
          for (let i = 0; i < component.tag.length; i++) {
            if (!componentArray.find((el) => el.title === component.tag[i])) {
              componentArray.push({
                "title": component.tag[i],
              });
            }
          }
        } else {
          if (typeof component.tag !== "undefined") {
            if (!componentArray.find((el) => el.title === component.tag)) {
              componentArray.push({
                "title": component.tag,
              });
            }
          }
        }
      } catch {
        console.log();
      }
    });

    return componentArray;
  }

  function checkIfComponentFiltered(component) {
    let checkBool = false;
    if (filterComponentsArray == null) {
      checkBool = true;
    } else {
      if (filterComponentsArray.length == 0) {
        checkBool = true;
      } else {
        checkBool = false;

        filterComponentsArray.map((fil) => {
          try {
            if (Array.isArray(component.tag)) {
              if (component.tag.includes(fil.title)) {
                checkBool = true;
              }
            } else {
              if (component.tag == fil.title) {
                checkBool = true;
              }
            }
          } catch {
            console.log("component caption not array");
          }
        });
      }
    }

    return checkBool;
  }

  function setNewComponents(componentsList) {
    let newArray = [];
    let componentIDArray = [];
    try {
      for (let j = 0; j < componentsList.length; j++) {
        for (let i = 0; i < components.length; i++) {
          try {
            if (Array.isArray(components[i].tag)) {
              if (components[i].tag.includes(componentsList[j].title)) {
                if (componentIDArray.includes(components[i].id)) {
                  componentIDArray = componentIDArray.filter((a) => a != components[i].id);
                  newArray = newArray.filter((a) => a != components[i]);
                }
              } else {
                if (!componentIDArray.includes(components[i].id)) {
                  let checkIfComponentExist = false;
                  for (let k = 0; k < components[i].tag.length; k++) {
                    if (componentsList.find((el) => el.title === components[i].tag[k])) {
                      checkIfComponentExist = true;
                      break;
                    }
                  }
                  if (!checkIfComponentExist) {
                    if (!componentsList.find((el) => el.title === components[i].title)) {
                      newArray.push(components[i]);
                      componentIDArray.push(components[i].id);
                    }
                  }
                }
                // newArray.push(copyComponents[i]);
                // break;
              }
            } else {
              if (typeof component.tag !== "undefined") {
                if (components[i].tag == componentsList[j].title) {
                  if (componentIDArray.includes(components[i].id)) {
                    componentIDArray = componentIDArray.filter((a) => a != components[i].id);
                    newArray = newArray.filter((a) => a != components[i]);
                  }
                } else {
                  if (!componentIDArray.includes(components[i].id)) {
                    newArray.push(components[i]);
                    componentIDArray.push(components[i].id);
                  }
                }
              }
            }
          } catch (e) {
            console.log("err", e);
          }
        }
      }
      if (lengthOfFilter == componentsList.length) {
        setCopyComponents([]);
      } else if (newArray.length == 0) {
        setCopyComponents(_.get(props, "selectedBase.versionSettings.components"));
      } else {
        setCopyComponents(newArray);
      }
    } catch (e) {
      console.log("error", e);
    }
  }

  function setFilterValue() {
    //value={JSON.parse(localStorage.getItem("newgetVal")) != null ? JSON.parse(localStorage.getItem("newgetVal")) : []}
    let filterValue = [];
    if (baseIDSession != null) {
      if (baseIDSession === _.get(props, "selectedBase._id")) {
        if (JSON.parse(localStorage.getItem("newgetVal")) != null) {
          filterValue = JSON.parse(localStorage.getItem("newgetVal"));
        }
      }
    }

    return filterValue;
  }

  function getTextWidth(ttt) {
    let text = document.createElement("span");
    document.body.appendChild(text);

    (text.style.font = " Roboto"), "Helvetica", "Arial", "sans-serif";
    text.style.fontSize = "0.9285714285714286rem";
    text.style.height = "auto";
    text.style.width = "auto";
    text.style.letterSpacing = "0.00938em";
    text.style.position = "absolute";
    text.style.whiteSpace = "no-wrap";
    text.innerHTML = ttt;

    let width = Math.ceil(text.clientWidth);

    document.body.removeChild(text);
    return width;
  }

  return (
    <div className={classes.containerVersionSettingsDiv}>
      <div>
        <DialogPlayableSize
          classes={props.classes}
          selectedVersion={props.selectedVersion}
          selectedBase={props.selectedBase}
          setTotalSize={props.setTotalSize}
        />
        <ThemeProvider theme={darkTheme}>
          <Card className={props.classes.card2}>
            <CardContent>
              <Autocomplete
                multiple
                sx={{
                  "&.MuiInput-underline": {
                    color: "red !important",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#ffffff",
                    "& .Mui-focused": {
                      color: "#ffffff",
                    },
                  },

                  "& .MuiFormLabel-root ": {
                    color: "#ffffff !important",
                  },
                  "& .MuiCardContent-root": {
                    color: "#ffffff !important",
                    backgroundColor: "red",
                  },

                  "& .MuiTextField-root": {
                    color: "#ffffff",
                  },
                  "& .MuiInput-root": {
                    color: "#ffffff !important",
                  },
                  "& .MuiCardContent-root ": {
                    color: "#ffffff !important",
                  },

                  "& .MuiChip-label ": {
                    color: "#ffffff",
                  },
                }}
                id="tags-standard"
                options={filterComponents()}
                getOptionLabel={(option) => option.title}
                filterSelectedOptions
                onChange={(event, newValue) => {
                  if (firstTime) {
                    localStorage.setItem("newgetVal", JSON.stringify(newValue));

                    setFilterComponentsArray(newValue);
                    setNewComponents(newValue);
                    setFirstTime(false);

                    localStorage.setItem("selectedBaseSession", JSON.stringify(_.get(props, "selectedBase._id")));
                  } else {
                    setFirstTime(false);
                    setFilterComponentsArray(newValue);
                    localStorage.setItem("newgetVal", JSON.stringify(newValue));

                    setNewComponents(newValue);
                    localStorage.setItem("selectedBaseSession", JSON.stringify(_.get(props, "selectedBase._id")));
                  }
                }}
                value={setFilterValue()}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Filter Components"
                    style={{ placeholder: "white", label: "#ffffff" }}
                    placeholder="Components"
                  />
                )}
              />
            </CardContent>
          </Card>
        </ThemeProvider>
        {!_.isNil(components) &&
          components.map((component) => {
            {
              checkIfComponentFiltered(component) ? (component.vis = "") : (component.vis = "none");
            }

            return (
              <Card
                className={props.classes.card}
                style={{ display: component.vis }}
                key={`${component.id}-${component.caption.replace(/\s/g, "")}`}
              >
                <CardContent>
                  <div style={{ "display": "flex", "justifyContent": "space-between" }}>
                    <Typography
                      className={classes.title}
                      style={{
                        // marginLeft:"2rem",
                        width: "14rem",
                      }}
                      variant={"h6"}
                    >
                      {_.get(component, "caption")}
                    </Typography>
                    {component.type === "enemy" && (
                      <div
                        style={{
                          "display": "flex",
                          "justifyContent": "space-between",
                          "marginTop": "-0.88rem",
                          "marginLeft": "-3,5rem",
                        }}
                      >
                        {isEnemyLast(component) && (
                          <IconButton
                            color="secondary"
                            disableRipple={true}
                            style={{ "background": "none" }}
                            onClick={(event) => {
                              if (props.userPermissions["update"] === false) {
                                props.createSnackbar(
                                  "You do not have permission to update, contact your administrator.",
                                  "warning",
                                  false
                                );
                                return;
                              }
                              event.preventDefault();
                              addEnemy(component);
                            }}
                          >
                            <Add />
                          </IconButton>
                        )}
                        {isEnemyMulti() && (
                          <IconButton
                            color="secondary"
                            disableRipple={true}
                            style={{ "background": "none" }}
                            onClick={(event) => {
                              if (props.userPermissions["update"] === false) {
                                props.createSnackbar(
                                  "You do not have permission to update, contact your administrator.",
                                  "warning",
                                  false
                                );
                                return;
                              }
                              removeEnemy(component.id);
                            }}
                          >
                            <Remove />
                          </IconButton>
                        )}
                      </div>
                    )}
                    {component.type === "pauseInfo" && (
                      <div
                        style={{
                          "display": "flex",
                          "justifyContent": "space-between",
                          "marginTop": "-0.88rem",
                          "marginLeft": "-3,5rem",
                        }}
                      >
                        {isVideoPauseInfoLast(component) && canAddVideoPauseInfo() && (
                          <IconButton
                            color="secondary"
                            disableRipple={true}
                            style={{ "background": "none" }}
                            onClick={(event) => {
                              if (props.userPermissions["update"] === false) {
                                props.createSnackbar(
                                  "You do not have permission to update, contact your administrator.",
                                  "warning",
                                  false
                                );
                                return;
                              }
                              event.preventDefault();
                              addVideoPauseInfo();
                            }}
                          >
                            <Add />
                          </IconButton>
                        )}
                        {isVideoPauseInfoMulti() && (
                          <IconButton
                            color="secondary"
                            disableRipple={true}
                            style={{ "background": "none" }}
                            onClick={(event) => {
                              if (props.userPermissions["update"] === false) {
                                props.createSnackbar(
                                  "You do not have permission to update, contact your administrator.",
                                  "warning",
                                  false
                                );
                                return;
                              }
                              removeVideoPauseInfo(component.index);
                            }}
                          >
                            <Remove />
                          </IconButton>
                        )}
                      </div>
                    )}
                    {(_.get(component, "settings").length > 1 ||
                      (_.get(component, "settings").length === 1 &&
                        _.has(_.get(component, "settings")[0], "components"))) && (
                      <Button
                        id={String(components[components.indexOf(component)].id)}
                        disableRipple={true}
                        startIcon={
                          components[components.indexOf(component)].isShowMoreClicked ? (
                            <ArrowDropUpRounded />
                          ) : (
                            <ArrowDropDownRounded />
                          )
                        }
                        color="secondary"
                        className={classes.showMoreButton}
                        style={{
                          // marginLeft:"-2rem",
                          width: "10rem",
                          marginTop: "-0.5rem",
                          background: "none",
                        }}
                        onClick={() => {
                          const newComponents = _.cloneDeep(components);
                          // setShowMoreBtn(Math.floor(Math.random() * 1000))

                          newComponents[components.indexOf(component)].isShowMoreClicked =
                            !newComponents[components.indexOf(component)].isShowMoreClicked;
                          setComponents(newComponents);
                        }}
                      >
                        {components[components.indexOf(component)].isShowMoreClicked ? "Hide" : "Show More"}
                      </Button>
                    )}

                    {/*
                                {
                                    component.type !== "enemy" && (
                                        <>
                                        <div style={{ "width": "2rem"}}></div>
                                        </>
                                        )
                                } */}
                  </div>
                  {_.get(component, "settings").map((setting, index) => {
                    const isVisible = component.isShowMoreClicked || component.settings.indexOf(setting) === 0;
                    let sliderGenericPath = `gameConfig.components.${component.id}.${setting.path}`;
                    let sliderGenericValue = _.get(
                      props,
                      `selectedVersion.gameConfig.components.${component.id}.${setting.path}`,
                      {}
                    );

                    if (setting.editorSubType) {
                      sliderGenericPath = setting.editorSubType.includes("FullPath")
                        ? `gameConfig.components.${setting.path}`
                        : `gameConfig.components.${component.id}.${setting.path}`;

                      sliderGenericValue = setting.editorSubType.includes("FullPath")
                        ? _.get(props, `selectedVersion.gameConfig.components.${setting.path}`, {})
                        : _.get(props, `selectedVersion.gameConfig.components.${component.id}.${setting.path}`, {});
                    }

                    if (setting.editorType === "editorCardOrder") {
                      let tooltipLength = getTextWidth(setting.caption);

                      return (
                        <div key={`${component.id}.${setting.path}`}>
                          {renderTooltip(setting.tooltip, isVisible, tooltipLength + 5)}

                          <SelectorCardOrder
                            id={`${component.id}.${setting.path}`}
                            key={`${component.id}.${setting.path}`}
                            tooltipLength={-28}
                            tooltip={setting.tooltip}
                            cardOrders={_.get(props, "selectedBase.cardOrders")}
                            componentID={component.id}
                            classes={props.classes}
                            selectedCardOrderKey={_.get(
                              props,
                              `selectedVersion.gameConfig.components.${component.id}.cardOrder.key`
                            )}
                            setValue={(p) => {
                              setValue({
                                path: `gameConfig.components.${component.id}.${setting.path}`,
                                value: p.value,
                              });
                            }}
                            userPermissions={props.userPermissions}
                            createSnackbar={props.createSnackbar}
                            isVisible={isVisible}
                          />
                        </div>
                      );
                    }
                    if (setting.editorType === "editorText") {
                      let tooltipLength = getTextWidth(setting.caption);

                      return (
                        <div key={`${component.id}.${setting.path}`}>
                          {renderTooltip(setting.tooltip, isVisible, tooltipLength + 5)}

                          <EditorText
                            id={`${component.id}.${setting.path}`}
                            componentID={component.id}
                            classes={props.classes}
                            tooltipLength={-24}
                            tooltip={setting.tooltip}
                            caption={setting.caption}
                            value={_.get(
                              props,
                              `selectedVersion.gameConfig.components.${component.id}.${setting.path}`
                            )}
                            setValue={(value) => {
                              setValue({
                                path: `gameConfig.components.${component.id}.${setting.path}`,
                                value,
                              });
                            }}
                            userPermissions={props.userPermissions}
                            createSnackbar={props.createSnackbar}
                            isVisible={isVisible}
                          />
                        </div>
                      );
                    }
                    if (setting.editorType === "editorTextArea") {
                      let tooltipLength = getTextWidth(setting.caption);

                      return (
                        <div key={`${component.id}.${setting.path}`}>
                          {renderTooltip(setting.tooltip, isVisible, tooltipLength + 5)}

                          <EditorTextArea
                            id={`${component.id}.${setting.path}`}
                            componentID={component.id}
                            tooltipLength={-28}
                            tooltip={setting.tooltip}
                            classes={props.classes}
                            caption={setting.caption}
                            isDialogEditEnabled={
                              _.get(component, "type") === "tilemap" ||
                              _.get(component, "type") === "level" ||
                              setting.caption === "Level"
                            }
                            isTextFieldVisible={
                              _.get(component, "type") !== "tilemap" &&
                              _.get(component, "type") !== "level" &&
                              setting.caption !== "Level"
                            }
                            value={_.get(
                              props,
                              `selectedVersion.gameConfig.components.${component.id}.${setting.path}`
                            )}
                            setValue={(value) => {
                              setValue({
                                path: `gameConfig.components.${component.id}.${setting.path}`,
                                value,
                              });
                            }}
                            userPermissions={props.userPermissions}
                            createSnackbar={props.createSnackbar}
                            isVisible={isVisible}
                          />
                        </div>
                      );
                    }
                    if (setting.editorType === "selectorVideo") {
                      let tooltipLength = getTextWidth(setting.caption);

                      return (
                        <div key={`${component.id}.${setting.path}`}>
                          {renderTooltip(setting.tooltip, isVisible, tooltipLength + 5)}

                          <SelectorVideo
                            id={`${component.id}.${setting.path}`}
                            selectedBase={props.selectedBase}
                            componentID={component.id}
                            classes={props.classes}
                            tooltipLength={-28}
                            tooltip={setting.tooltip}
                            label={setting.caption}
                            videos={_.get(props, "videos", [])}
                            componentType={component.type}
                            path={setting.path}
                            destination={setting.destination}
                            bucket={setting.bucket}
                            selectedVideo={_.get(
                              props,
                              `selectedVersion.gameConfig.components.${component.id}.${setting.path}[0]`
                            )}
                            setValue={(p) => {
                              setValue({
                                path: `gameConfig.components.${component.id}.${setting.path}`,
                                value: p.value,
                              });
                            }}
                            insertVideoPathValue={props.insertVideoPathValue}
                            deleteVideoFromVideos={props.deleteVideoFromVideos}
                            createSnackbar={props.createSnackbar}
                            selectedVersion={props.selectedVersion}
                            userPermissions={props.userPermissions}
                            isVisible={isVisible}
                            setIsDialogConfirmRefreshOpen={props.setIsDialogConfirmRefreshOpen}
                            setIsDialogCircularProgressOpen={props.setIsDialogCircularProgressOpen}
                          />
                        </div>
                      );
                    }
                    if (setting.editorType === "selectorImage") {
                      let tooltipLength = getTextWidth(setting.caption);

                      return (
                        <div key={`${component.id}.${setting.path}`}>
                          {renderTooltip(setting.tooltip, isVisible, tooltipLength + 5)}
                          <SelectorImage
                            id={`${component.id}.${setting.path}`}
                            x
                            selectedBase={props.selectedBase}
                            componentID={component.id}
                            tooltipLength={-28}
                            tooltip={setting.tooltip}
                            label={setting.caption}
                            classes={props.classes}
                            images={_.get(props, "media.images", [])}
                            componentType={component.type}
                            path={setting.path}
                            destination={setting.destination}
                            bucket={setting.bucket}
                            selectedImage={_.get(
                              props,
                              `selectedVersion.gameConfig.components.${component.id}.${setting.path}[0]`
                            )}
                            setValue={(p) => {
                              setValue({
                                path: `gameConfig.components.${component.id}.${setting.path}`,
                                value: p.value,
                              });
                            }}
                            insertMediaPathValue={props.insertMediaPathValue}
                            createSnackbar={props.createSnackbar}
                            selectedVersion={props.selectedVersion}
                            deleteImageFromMedia={props.deleteImageFromMedia}
                            userPermissions={props.userPermissions}
                            isVisible={isVisible}
                            setIsDialogConfirmRefreshOpen={props.setIsDialogConfirmRefreshOpen}
                            setIsDialogCircularProgressOpen={props.setIsDialogCircularProgressOpen}
                          ></SelectorImage>
                        </div>
                      );
                    }
                    if (setting.editorType === "selectorImageFullPath") {
                      let tooltipLength = getTextWidth(setting.caption);

                      return (
                        <div key={`${component.id}.${setting.path}`}>
                          {renderTooltip(setting.tooltip, isVisible, tooltipLength + 5)}

                          <SelectorImage
                            id={`${component.id}.${setting.path}`}
                            selectedBase={props.selectedBase}
                            selectedGame={props.selectedGame}
                            componentID={component.id}
                            label={setting.caption}
                            classes={props.classes}
                            tooltipLength={-28}
                            tooltip={setting.tooltip}
                            images={_.get(props, "media.images", [])}
                            componentType={component.type}
                            path={setting.path}
                            destination={setting.destination}
                            bucket={setting.bucket}
                            selectedImage={_.get(props, `selectedVersion.gameConfig.components.${setting.path}[0]`)}
                            setValue={(p) => {
                              setValue({
                                path: `gameConfig.components.${setting.path}`,
                                value: p.value,
                              });
                            }}
                            insertMediaPathValue={props.insertMediaPathValue}
                            createSnackbar={props.createSnackbar}
                            selectedVersion={props.selectedVersion}
                            deleteImageFromMedia={props.deleteImageFromMedia}
                            userPermissions={props.userPermissions}
                            isVisible={isVisible}
                            setIsDialogConfirmRefreshOpen={props.setIsDialogConfirmRefreshOpen}
                            setIsDialogCircularProgressOpen={props.setIsDialogCircularProgressOpen}
                          ></SelectorImage>
                        </div>
                      );
                    }
                    if (setting.editorType === "selectorSound") {
                      let tooltipLength = getTextWidth(setting.caption);

                      return (
                        <div key={`${component.id}.${setting.path}`}>
                          {renderTooltip(setting.tooltip, isVisible, tooltipLength + 5)}

                          <SelectorSound
                            id={`${component.id}.${setting.path}`}
                            selectedBase={props.selectedBase}
                            componentID={component.id}
                            label={setting.caption}
                            classes={props.classes}
                            tooltipLength={-28}
                            tooltip={setting.tooltip}
                            sounds={_.get(props, "sounds", [])}
                            componentType={component.type}
                            path={setting.path}
                            destination={setting.destination}
                            bucket={setting.bucket}
                            selectedSound={_.get(props, `selectedVersion.gameConfig.components.${setting.path}[0]`)}
                            setValue={(p) => {
                              setValue({
                                path: `gameConfig.components.${setting.path}`,
                                value: p.value,
                              });
                            }}
                            insertSoundPathValue={props.insertSoundPathValue}
                            deleteSoundFromSounds={props.deleteSoundFromSounds}
                            createSnackbar={props.createSnackbar}
                            selectedVersion={props.selectedVersion}
                            userPermissions={props.userPermissions}
                            isVisible={isVisible}
                            setIsDialogConfirmRefreshOpen={props.setIsDialogConfirmRefreshOpen}
                          ></SelectorSound>
                        </div>
                      );
                    }
                    if (setting.editorType === "selectorFont") {
                      let tooltipLength = getTextWidth(setting.caption);

                      return (
                        <div key={`${component.id}.${setting.path}`}>
                          {renderTooltip(setting.tooltip, isVisible, tooltipLength + 5)}

                          <SelectorFont
                            id={`${component.id}.${setting.path}`}
                            selectedBase={props.selectedBase}
                            componentID={component.id}
                            label={setting.caption}
                            classes={props.classes}
                            fonts={_.get(props, "fonts", [])}
                            componentType={component.type}
                            path={setting.path}
                            destination={setting.destination}
                            selectedFont={_.get(
                              props,
                              `selectedVersion.gameConfig.components.${component.id}.${setting.path}`
                            )}
                            setValue={setValue}
                            insertFontPathValue={props.insertFontPathValue}
                            deleteFontFromFonts={props.deleteFontFromFonts}
                            createSnackbar={props.createSnackbar}
                            selectedVersion={props.selectedVersion}
                            userPermissions={props.userPermissions}
                            isVisible={isVisible}
                            setIsDialogConfirmRefreshOpen={props.setIsDialogConfirmRefreshOpen}
                          ></SelectorFont>
                        </div>
                      );
                    }
                    if (setting.editorType === "selectorAtlas") {
                      let tooltipLength = getTextWidth(setting.caption);

                      return (
                        <div key={`${component.id}.${setting.path}`}>
                          {renderTooltip(setting.tooltip, isVisible, tooltipLength + 5)}

                          <SelectorAtlas
                            id={`${component.id}.${setting.path}`}
                            selectedBase={props.selectedBase}
                            componentID={component.id}
                            label={setting.caption}
                            classes={props.classes}
                            tooltipLength={-28}
                            tooltip={setting.tooltip}
                            atlases={_.get(props, "atlases", [])}
                            componentType={component.type}
                            path={setting.path}
                            bucket={setting.bucket}
                            destinations={setting.destinations}
                            selectedAnimationSetID={_.get(
                              props,
                              `selectedVersion.gameConfig.components.${component.id}.${setting.path}.[0].animationSet`
                            )}
                            setValue={(p) => {
                              setValue({
                                path: `gameConfig.components.${component.id}.${setting.path}`,
                                value: p.value,
                              });
                            }}
                            insertAtlasPathValue={props.insertAtlasPathValue}
                            deleteAnimationSetFromAtlases={props.deleteAnimationSetFromAtlases}
                            createSnackbar={props.createSnackbar}
                            selectedVersion={props.selectedVersion}
                            userPermissions={props.userPermissions}
                            isVisible={isVisible}
                          ></SelectorAtlas>
                        </div>
                      );
                    }
                    if (setting.editorType === "selectorTexture") {
                      return (
                        <div key={"texture"}>
                          {_.get(props, `selectedVersion.gameConfig.components.${component.id}.useTexture`) && (
                            <SelectorTexture
                              id={`${component.id}.${setting.path}`}
                              selectedBase={props.selectedBase}
                              componentID={component.id}
                              classes={props.classes}
                              images={_.get(props, "media.images", [])}
                              componentType={component.type}
                              path={setting.path}
                              destination={setting.destination}
                              bucket={setting.bucket}
                              selectedImage={_.get(
                                props,
                                `selectedVersion.gameConfig.components.${component.id}.${setting.path}[0]`
                              )}
                              setValue={(p) => {
                                setValue({
                                  path: `gameConfig.components.${component.id}.${setting.path}`,
                                  value: p.value,
                                });
                              }}
                              insertMediaPathValue={props.insertMediaPathValue}
                              userPermissions={props.userPermissions}
                              createSnackbar={props.createSnackbar}
                              isVisible={isVisible}
                            ></SelectorTexture>
                          )}
                        </div>
                      );
                    }
                    if (setting.editorType === "selectorImageSet") {
                      let tooltipLength = getTextWidth(setting.caption);

                      return (
                        <div key={`${component.id}.${setting.path}`}>
                          {renderTooltip(setting.tooltip, isVisible, tooltipLength + 5)}

                          <SelectorImageSet
                            id={`${component.id}.${setting.path}`}
                            tooltipLength={-28}
                            tooltip={setting.tooltip}
                            selectedBase={props.selectedBase}
                            componentID={component.id}
                            label={setting.caption}
                            classes={props.classes}
                            images={_.get(props, "media.images", [])}
                            componentType={component.type}
                            path={setting.path}
                            bucket={setting.bucket}
                            destinations={setting.destinations}
                            selectedImageSetID={_.get(
                              props,
                              `selectedVersion.gameConfig.components.${component.id}.${setting.path}.[0].imageSet`
                            )}
                            setValue={(p) => {
                              setValue({
                                path: `gameConfig.components.${component.id}.${setting.path}`,
                                value: p.value, //images of the imageSet, in the form of image records
                              });
                            }}
                            insertMediaPathValue={props.insertMediaPathValue}
                            createSnackbar={props.createSnackbar}
                            selectedVersion={props.selectedVersion}
                            deleteImageSetFromMedia={props.deleteImageSetFromMedia}
                            userPermissions={props.userPermissions}
                            isVisible={isVisible}
                            setIsDialogConfirmRefreshOpen={props.setIsDialogConfirmRefreshOpen}
                            setIsDialogCircularProgressOpen={props.setIsDialogCircularProgressOpen}
                          ></SelectorImageSet>
                        </div>
                      );
                    }
                    if (setting.editorType === "sliderGeneric") {
                      let tooltipLength = getTextWidth(setting.caption);
                      return (
                        <div key={`${component.id}.${setting.path}`}>
                          {renderTooltip(setting.tooltip, isVisible, tooltipLength + 5)}
                          <SliderGeneric
                            id={`${component.id}.${setting.path}`}
                            classes={props.classes}
                            tooltipLength={-28}
                            newSetPortrait={props.newSetPortrait}
                            tooltip={setting.tooltip}
                            min={setting.min}
                            max={setting.max}
                            step={setting.step}
                            value={sliderGenericValue}
                            path={sliderGenericPath}
                            y_axis={!!setting.y_axis}
                            editorSubType={setting.editorSubType}
                            label={setting.caption}
                            setValue={(pathToSet, value) => {
                              setValue({
                                path: pathToSet,
                                value,
                              });
                            }}
                            userPermissions={props.userPermissions}
                            createSnackbar={props.createSnackbar}
                            isVisible={isVisible}
                          ></SliderGeneric>
                        </div>
                      );
                    }
                    if (setting.editorType === "switchGeneric") {
                      let tooltipLength = getTextWidth(setting.caption);
                      return (
                        <div key={`${component.id}.${setting.path}`}>
                          {renderTooltip(setting.tooltip, isVisible, tooltipLength + 52)}

                          <SwitchGeneric
                            id={`${component.id}.${setting.path}`}
                            classes={props.classes}
                            tooltipLength={-54}
                            tooltip={setting.tooltip}
                            value={_.get(
                              props,
                              `selectedVersion.gameConfig.components.${component.id}.${setting.path}`,
                              false
                            )}
                            setValue={(value) => {
                              setValue({
                                path: `gameConfig.components.${component.id}.${setting.path}`,
                                value,
                              });
                            }}
                            label={setting.caption}
                            userPermissions={props.userPermissions}
                            createSnackbar={props.createSnackbar}
                            isVisible={isVisible}
                          ></SwitchGeneric>
                        </div>
                      );
                    }
                    if (setting.editorType === "switchGenericFullPath") {
                      let tooltipLength = getTextWidth(setting.caption);

                      return (
                        <div key={`${component.id}.${setting.path}`}>
                          {renderTooltip(setting.tooltip, isVisible, tooltipLength + 5)}

                          <SwitchGeneric
                            id={`${component.id}.${setting.path}`}
                            classes={props.classes}
                            tooltipLength={-30}
                            value={_.get(props, `selectedVersion.gameConfig.components.${setting.path}`, false)}
                            setValue={(value) => {
                              setValue({
                                path: `gameConfig.components.${setting.path}`,
                                value,
                              });
                            }}
                            label={setting.caption}
                            userPermissions={props.userPermissions}
                            createSnackbar={props.createSnackbar}
                            isVisible={isVisible}
                          ></SwitchGeneric>
                        </div>
                      );
                    }
                    if (setting.editorType === "color") {
                      let tooltipLength = getTextWidth(setting.caption);
                      return (
                        <div key={`${component.id}.${setting.path}`}>
                          {renderTooltip(setting.tooltip, isVisible, tooltipLength + 5)}

                          <SelectorColor
                            id={`${component.id}.${setting.path}`}
                            label={setting.caption}
                            classes={props.classes}
                            tooltipLength={-28}
                            tooltip={setting.tooltip}
                            value={_.get(
                              props,
                              `selectedVersion.gameConfig.components.${component.id}.${setting.path}`,
                              "#ffffff"
                            )}
                            setValue={(value) => {
                              setValue({
                                path: `gameConfig.components.${component.id}.${setting.path}`,
                                value,
                              });
                            }}
                            userPermissions={props.userPermissions}
                            createSnackbar={props.createSnackbar}
                            isVisible={isVisible}
                          ></SelectorColor>
                        </div>
                      );
                    }
                    if (setting.editorType === "selectorLevel") {
                      let tooltipLength = getTextWidth(setting.caption);

                      return (
                        <div key={`${component.id}.${setting.path}`}>
                          {renderTooltip(setting.tooltip, isVisible, tooltipLength + 5)}

                          <SelectorLevel
                            id={`${component.id}.${setting.path}`}
                            classes={props.classes}
                            label={setting.caption}
                            value={_.get(
                              props,
                              `selectedVersion.gameConfig.components.${component.id}.${setting.path}`,
                              1
                            )}
                            setValue={(value) => {
                              setValue({
                                path: `gameConfig.components.${component.id}.${setting.path}`,
                                value,
                              });
                            }}
                            userPermissions={props.userPermissions}
                            createSnackbar={props.createSnackbar}
                            isVisible={isVisible}
                          ></SelectorLevel>
                        </div>
                      );
                    }
                    if (setting.editorType === "selectorAnimation") {
                      let tooltipLength = getTextWidth(setting.caption);

                      return (
                        <div key={`${component.id}.${setting.path}`}>
                          {renderTooltip(setting.tooltip, isVisible, tooltipLength + 5)}

                          <SelectorAnimation
                            id={`${component.id}.${setting.path}`}
                            classes={props.classes}
                            label={setting.caption}
                            tooltipLength={-28.5}
                            tooltip={setting.tooltip}
                            value={_.get(
                              props,
                              `selectedVersion.gameConfig.components.${component.id}.${setting.path}`,
                              1
                            )}
                            setValue={(value) => {
                              setValue({
                                path: `gameConfig.components.${component.id}.${setting.path}`,
                                value,
                              });
                            }}
                            userPermissions={props.userPermissions}
                            createSnackbar={props.createSnackbar}
                            isVisible={isVisible}
                          ></SelectorAnimation>
                        </div>
                      );
                    }
                    if (setting.editorType === "selectorFontFamily") {
                      let tooltipLength = getTextWidth(setting.caption);

                      return (
                        <div key={`${component.id}.${setting.path}`}>
                          {renderTooltip(setting.tooltip, isVisible, tooltipLength + 5)}

                          <SelectorFontFamily
                            id={`${component.id}.${setting.path}`}
                            classes={props.classes}
                            label={setting.caption}
                            tooltipLength={-24.5}
                            tooltip={setting.tooltip}
                            value={_.get(
                              props,
                              `selectedVersion.gameConfig.components.${component.id}.${setting.path}`,
                              1
                            )}
                            setValue={(value) => {
                              setValue({
                                path: `gameConfig.components.${component.id}.${setting.path}`,
                                value,
                              });
                            }}
                            selectedVersion={props.selectedVersion}
                            userPermissions={props.userPermissions}
                            createSnackbar={props.createSnackbar}
                            isVisible={isVisible}
                          ></SelectorFontFamily>
                        </div>
                      );
                    }
                    if (setting.editorType === "selectorFontStyle") {
                      let tooltipLength = getTextWidth(setting.caption);

                      return (
                        <div key={`${component.id}.${setting.path}`}>
                          {renderTooltip(setting.tooltip, isVisible, tooltipLength + 5)}

                          <SelectorFontStyle
                            id={`${component.id}.${setting.path}`}
                            classes={props.classes}
                            tooltipLength={-26}
                            tooltip={setting.tooltip}
                            value={_.get(
                              props,
                              `selectedVersion.gameConfig.components.${component.id}.${setting.path}`,
                              1
                            )}
                            setValue={(value) => {
                              setValue({
                                path: `gameConfig.components.${component.id}.${setting.path}`,
                                value,
                              });
                            }}
                            label={setting.caption}
                            userPermissions={props.userPermissions}
                            createSnackbar={props.createSnackbar}
                            isVisible={isVisible}
                          ></SelectorFontStyle>
                        </div>
                      );
                    }
                    if (setting.editorType === "selectorColor") {
                      let tooltipLength = getTextWidth(setting.caption);

                      return (
                        <div key={`${component.id}.${setting.path}`}>
                          {renderTooltip(setting.tooltip, isVisible, tooltipLength + 5)}

                          <SelectorColor
                            id={`${component.id}.${setting.path}`}
                            classes={props.classes}
                            mode={"String"}
                            tooltipLength={-28}
                            tooltip={setting.tooltip}
                            value={_.get(
                              props,
                              `selectedVersion.gameConfig.components.${component.id}.${setting.path}`,
                              1
                            )}
                            setValue={(value) => {
                              setValue({
                                path: `gameConfig.components.${component.id}.${setting.path}`,
                                value,
                              });
                            }}
                            label={setting.caption}
                            userPermissions={props.userPermissions}
                            createSnackbar={props.createSnackbar}
                            isVisible={isVisible}
                          ></SelectorColor>
                        </div>
                      );
                    }
                    if (setting.editorType === "selectorString") {
                      let tooltipLength = getTextWidth(setting.caption);

                      return (
                        <div key={`${component.id}.${setting.path}`}>
                          {renderTooltip(setting.tooltip, isVisible, tooltipLength + 5)}

                          <SelectorGeneric
                            id={`${component.id}.${setting.path}`}
                            label={setting.caption}
                            classes={props.classes}
                            tooltipLength={-32.5}
                            tooltip={setting.tooltip}
                            value={_.get(
                              props,
                              `selectedVersion.gameConfig.components.${component.id}.${setting.path}`,
                              0
                            )}
                            items={setting.items}
                            setValue={(value) => {
                              setValue({
                                path: `gameConfig.components.${component.id}.${setting.path}`,
                                value,
                              });
                            }}
                            userPermissions={props.userPermissions}
                            createSnackbar={props.createSnackbar}
                            isVisible={isVisible}
                          ></SelectorGeneric>
                        </div>
                      );
                    }
                    if (setting.editorType === "selectorRibbonType") {
                      let tooltipLength = getTextWidth(setting.caption);

                      return (
                        <div key={`${component.id}.${setting.path}`}>
                          {renderTooltip(setting.tooltip, isVisible, tooltipLength + 5)}

                          <SelectorRibbonType
                            id={`${component.id}.${setting.path}`}
                            label={setting.caption}
                            tooltipLength={-28}
                            tooltip={setting.tooltip}
                            newSetPortrait={props.newSetPortrait}
                            classes={props.classes}
                            path={`gameConfig.components.${component.id}.${setting.path}`}
                            value={_.get(
                              props,
                              `selectedVersion.gameConfig.components.${component.id}.${setting.path}`,
                              0
                            )}
                            items={setting.items}
                            components={setting.components}
                            atlases={_.get(props, "atlases", [])}
                            images={_.get(props, "media.images", [])}
                            setValue={(pathToSet, value) => {
                              setValue({
                                path: pathToSet,
                                value,
                              });
                            }}
                            insertAtlasPathValue={props.insertAtlasPathValue}
                            insertMediaPathValue={props.insertMediaPathValue}
                            deleteImageFromMedia={props.deleteImageFromMedia}
                            userPermissions={props.userPermissions}
                            createSnackbar={props.createSnackbar}
                            selectedBase={props.selectedBase}
                            selectedVersion={props.selectedVersion}
                            setSelectedVersionPathValue={props.setSelectedVersionPathValue}
                            isVisible={component.isShowMoreClicked} // this visibility checked inside because of this component is parent component
                            setIsDialogConfirmRefreshOpen={props.setIsDialogConfirmRefreshOpen}
                            setIsDialogCircularProgressOpen={props.setIsDialogCircularProgressOpen}
                          ></SelectorRibbonType>
                        </div>
                      );
                    }
                    if (setting.editorType === "cardItemsSelector") {
                      let tooltipLength = getTextWidth(setting.caption);

                      return (
                        <div key={`${component.id}.${setting.path}`}>
                          {renderTooltip(setting.tooltip, isVisible, tooltipLength + 5)}

                          <CardItemsSelector
                            id={`${component.id}.${setting.path}`}
                            classes={props.classes}
                            value={_.get(
                              props,
                              `selectedVersion.gameConfig.components.${component.id}.${setting.path}`
                            )}
                            setValue={(value) => {
                              setValue({
                                path: `gameConfig.components.${component.id}.${setting.path}`,
                                value,
                              });
                            }}
                            label={setting.caption}
                            userPermissions={props.userPermissions}
                            createSnackbar={props.createSnackbar}
                            isVisible={isVisible}
                            setSelectedVersionPathValue={props.setSelectedVersionPathValue}
                            duration={_.get(
                              props,
                              `selectedVersion.gameConfig.components.${component.id}.selectedVideos.video[0].duration`
                            )}
                          ></CardItemsSelector>
                        </div>
                      );
                    }
                    if (setting.editorType === "number") {
                      let tooltipLength = getTextWidth(setting.caption);

                      return (
                        <div key={`${component.id}.${setting.path}`}>
                          {renderTooltip(setting.tooltip, isVisible, tooltipLength + 5)}

                          <EditorNumber
                            id={`${component.id}.${setting.path}`}
                            classes={props.classes}
                            tooltipLength={-28}
                            tooltip={setting.tooltip}
                            value={_.get(
                              props,
                              `selectedVersion.gameConfig.components.${component.id}.${setting.path}`
                            )}
                            min={setting.min}
                            max={setting.max}
                            setValue={(value) => {
                              setValue({
                                path: `gameConfig.components.${component.id}.${setting.path}`,
                                value,
                              });
                            }}
                            label={setting.caption}
                            userPermissions={props.userPermissions}
                            createSnackbar={props.createSnackbar}
                            isVisible={isVisible}
                            setSelectedVersionPathValue={props.setSelectedVersionPathValue}
                            duration={_.get(
                              props,
                              `selectedVersion.gameConfig.components.${component.id}.selectedVideos.video[0].duration`
                            )}
                          ></EditorNumber>
                        </div>
                      );
                    }
                    if (component.type === "decisionMaker" && setting.type === "decisionMakerImage") {
                      return (
                        <div style={{ marginTop: "1rem" }} key={`${component.id}.${index}`}>
                          {renderDecisionMakerImagesComponent(component, setting, isVisible)}
                        </div>
                      );
                    }
                  })}
                </CardContent>
              </Card>
            );
          })}
        {_.has(props.userCompany, "isPermittedSetDefault") && props.userCompany.isPermittedSetDefault === true && (
          <DefaultVersionSetting
            classes={props.classes}
            selectedVersion={props.selectedVersion}
            selectedBase={props.selectedBase}
            getDefaultVersion={props.getDefaultVersion}
            setDefaultVersion={(value) => {
              props.setDefaultVersion(value);
            }}
            setSearchBarValue={(value) => {
              props.setSearchBarValue(value);
            }}
            defaultVersion={props.defaultVersion}
            defaultVersionUpdated={props.defaultVersionUpdated}
            setDefaultVersionUpdated={(value) => {
              props.setDefaultVersionUpdated(value);
            }}
            prop={props}
            versionRename={props.versionRename}
            searchBarValue={props.searchBarValue}
          />
        )}
      </div>
    </div>
  );
}
