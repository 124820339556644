import { useEffect } from "react";
import { getServerUri } from "../modules/uriUtility";

export default function RequireLogin(props) {
  console.log(localStorage.getItem("session.accessToken"));
  useEffect(() => {
    if (!localStorage.getItem("session.accessToken")) {
      //todo: also validate validity of the token
      window.location = "/login"; //getFrontendUri("login");
    }
  });
  return null;
}
