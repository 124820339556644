import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogTitle } from "@material-ui/core";
import { getServerUri } from "../modules/uriUtility";
import { makeStyles } from "@material-ui/core/styles";

const useClasses = makeStyles({
  dialogDelete: {
    margin: 10,
  },
});
/**
 *
 * @param {Boolean} props.isOpen is it open?
 * @param {Function} props.setIsOpen set is open to true or false
 * @param {Function} props.deleteVersionFromView delete the version from view
 * @param {String} props.selectedVersionID the selected version id
 * @param props.createSnackbar
 * @returns {JSX.Element}
 */
export default function dialogVersionDelete(props) {
  let [versionHie, setVersionHierarchy] = useState(1);

  const [versionID, setVersionID] = useState("");
  const [nversionID, setNVersionID] = useState("");

  useEffect(() => {
    if (versionHie != 1) {
      var baseFolder = new Promise((resolve, reject) => {
        fetch(
          getServerUri("version/updateHierarchy"),

          {
            method: "POST",
            headers: { "content-type": "application/x-www-form-urlencoded" },
            body: new URLSearchParams({
              accessToken: localStorage.getItem("session.accessToken"),
              baseID: _.get(props, "selectedBase._id"),
              versionHierarchy: JSON.stringify(versionHie),
              companyID: props.companyID,
            }),
          }
        ).then((response) => {
          if (response.status === 200) {
            props.setVersionHierarchy(versionHie);
            // this is for folder render process
            props.setInsertNewVersion(versionID);
          }
        });
      });
    }
  }, [versionHie]);

  useEffect(() => {
    if (nversionID === String(_.get(props, "selectedVersion._id"))) {
      fetchVersionHierarchy(_.get(props, "selectedVersion"));
    }
  }, [nversionID]);

  function fetchVersionHierarchy(version) {
    //take the version hierarchy
    fetch(getServerUri("version/getHierarchy"), {
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({
        accessToken: localStorage.getItem("session.accessToken"),
        baseID: _.get(props, "selectedBase._id"),
        companyID: props.companyID,
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          //güncellenicek burası
          response.json().then((json) => {
            json.versionHierarchy.map(function (versionHier, index) {
              if (versionHier.id === version._id) {
                json.versionHierarchy.splice(index, 1);
              }
            });

            let newVersionTree = {};
            newVersionTree["tree"] = json.versionHierarchy;
            setVersionHierarchy(newVersionTree);
          });
        } else if (response.status === 401) {
          props.createSnackbar("You are not authorized to update, contact your administrator.", "warning");
          response
            .text()
            .then((textResponse) => {
              console.log({ message: "error updating", textResponse });
            })
            .catch(console.error);
        } else {
          response
            .text()
            .then((textResponse) => {
              console.log({ message: "error renaming", textResponse });
              props.createSnackbar("An error occurred when renaming a new version.", "error");
            })
            .catch(console.error);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function deleteVersions(versionIdList) {
    fetch(getServerUri("version/deleteMultiple"), {
      method: "DELETE",
      headers: {
        "content-type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams({
        accessToken: localStorage.getItem("session.accessToken"),
        versionIdList: JSON.stringify(versionIdList),
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          props.createSnackbar("Successfully deleted", "success");

          //setVersionID(versionID);

          //  props.deleteVersionFromView(versionId);
        } else if (response.status === 401) {
          props.createSnackbar("You are not authorized to delete, contact your administrator.", "warning");
          response
            .text()
            .then((textResponse) => {
              console.error(textResponse);
            })
            .catch(console.error);
        } else {
          response
            .text()
            .then((responseText) => {
              console.log(responseText);
            })
            .catch(console.error);
        }
        props.setIsOpen(false);
      })
      .catch((err) => {
        console.error(err);
        props.createSnackbar("An error occurred when deleting the version.", "error");
      });
  }

  const classes = useClasses();
  return (
    <Dialog
      id="delete_dialog"
      open={props.isOpen}
      onClose={() => {
        props.setIsOpen(false);
      }}
    >
      <DialogTitle id="delete_dialogTitle">Delete All?</DialogTitle>
      <div className={classes.dialogDelete}>
        <Button
          id="dialogDeleteAll_button"
          label={"OK"}
          onClick={(event) => {
            console.log("props.selectedFolderId", props.selectedFolderId);

            fetch(getServerUri("version/getHierarchy"), {
              method: "POST",
              headers: { "content-type": "application/x-www-form-urlencoded" },
              body: new URLSearchParams({
                accessToken: localStorage.getItem("session.accessToken"),
                baseID: _.get(props, "selectedBase._id"),
                companyID: props.companyID,
              }),
            })
              .then((response) => {
                if (response.status === 200) {
                  response.json().then((json) => {
                    let newVersionArray = [];
                    let nNewVersionArray = [];

                    json.versionHierarchy.map(function (versionHier, index) {
                      if (versionHier.data.fileType) {
                        newVersionArray.push(versionHier.id);
                      }
                    });
                    if (newVersionArray.length > 0) {
                      let newVersionTree = {};

                      deleteVersions(newVersionArray);
                      newVersionTree["tree"] = [];

                      setVersionHierarchy(newVersionTree);

                      props.deleteVersionFromView(String(_.get(props, "selectedVersion._id")), true, newVersionArray);
                    } else {
                      props.setIsOpen(false);
                    }

                    props.setSelectedFolderId(9999999);
                  });
                } else if (response.status === 401) {
                  props.createSnackbar("You are not authorized to update, contact your administrator.", "warning");
                  response
                    .text()
                    .then((textResponse) => {
                      console.log({ message: "error updating", textResponse });
                    })
                    .catch(console.error);
                } else {
                  response
                    .text()
                    .then((textResponse) => {
                      console.log({ message: "error renaming", textResponse });
                      props.createSnackbar("An error occurred when renaming a new version.", "error");
                    })
                    .catch(console.error);
                }
              })
              .catch((err) => {
                console.error(err);
              });
          }}
        >
          OK
        </Button>
        <Button
          id="delete_cancelBtn"
          label={"Cancel"}
          onClick={() => {
            props.setIsOpen(false);
          }}
        >
          Cancel
        </Button>
      </div>
    </Dialog>
  );
}
