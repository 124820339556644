import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Divider, Card, CardContent, Typography, IconButton, Chip } from "@material-ui/core";
import KebabMenu from "../../../images/documentationImages/kebabMenu.png";
import RenameField from "../../../images/documentationImages/renameField.png";

const useStyles = makeStyles((theme) => ({
  main: {
    align: "center",
    textAlign: "left",
  },
  chip: {
    float: "left",
    marginRight: "90%",
    marginBottom: "5%",
  },
  context: {
    textAlign: "justify",
  },
  imgContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    borderRadius: "4px",
    border: "1px solid #727171",
    boxShadow: "rgb(0 0 0 / 20%) 1px 1px 10px 0px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px",
  },
  img: {
    borderRadius: "4px",
    width: "100%",
    height: "auto",
  },
  container: {
    marginTop: "10px",
    marginBottom: "20px",
  },
}));

const RenameVersion = () => {
  const classes = useStyles();

  return (
    <div className={classes.main}>
      <Card className={classes.container}>
        <CardContent>
          <div className={classes.imgContainer}>
            <img src={KebabMenu} className={classes.img} alt="" />
            <Divider />
            <img src={RenameField} className={classes.img} alt="" />
          </div>
          <br />
          <Typography variant="h6">Versions' Names Are Editible Now!</Typography>
          <Typography paragraph>28.10.2021</Typography>
          <Chip className={classes.chip} label="Standard" variant="outlined" />
          <Typography paragraph className={classes.context}>
            Whether it's an already existing version or a newly created version, the versions’ names can easily be
            edited. With clicking on the menu, the “Rename Version” button will be visible. Clicking the button will
            open a pop up to write the new title for the version. Warning: this option will change the version on the
            preview. To change different versions’ names, open that version into preview then follow the instructions.
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
};

export default RenameVersion;
