import { Dialog, DialogTitle, Avatar, Button, Typography } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { fetchCompanyLogo } from "../modules/accountLogos";

const avatarSize = 30;
const useStyles = makeStyles((theme) => ({
  centerContent: { display: "flex", justifyContent: "center", alignItems: "center" },
  avatarLarge: { width: theme.spacing(avatarSize), height: theme.spacing(avatarSize), margin: 30 },
  textCenter: { textAlign: "center" },
  dialog: { margin: 20 },
}));

export default function dialogAccountInfo(props) {
  const classes = useStyles();
  const logo = fetchCompanyLogo(localStorage.getItem("user.companyID")); //get logo url from accountLogos
  //const logo = _.get(logos, localStorage.getItem("user.companyID"));
  if (!logo) return <div>Logo is not defined {JSON.stringify(fetchCompanyLogo)}</div>;

  return (
    <Dialog
      open={props.open}
      onClose={(event) => {
        props.onClose();
      }}
      className={classes.dialog}
    >
      <DialogTitle id="accountInformationTitle" className={classes.textCenter}>
        Account Information
      </DialogTitle>
      <div>
        <div className={classes.centerContent}>
          <Avatar src={logo} className={classes.avatarLarge}></Avatar>
        </div>
        <div className={classes.textCenter}>
          <Typography variant="h4" gutterBottom>
            {localStorage.getItem("user.username")}
          </Typography>
          <Typography variant="h5" gutterBottom>
            {localStorage.getItem("user.isAdmin") == true ? "Administrator" : "Standard User"}
          </Typography>
        </div>
      </div>
    </Dialog>
  );
}
