import React, { useEffect, useState } from "react";
import { MenuItem, TextField, Typography } from "@material-ui/core";

export default function selectorFontStyle(props) {
  if (_.isNil(props.value)) {
    return null;
  }
  let [value, setValue] = useState(props.value || "normal");

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  useEffect(() => {
    checkMargin(props.tooltipLength);
  }, [props.isVisible]);
  function gettingMargin(numberMargin) {
    if (props.tooltip != undefined) {
      if (numberMargin) {
        return numberMargin.toString() + "px";
      }
    } else return (0).toString() + "px";
  }
  function checkMargin(numberMargin) {
    try {
      if (props.tooltip) {
        if (numberMargin) {
          document.getElementById(`${props.id}.textField-label`).style.marginTop = "-48px";
        }
      } else {
        document.getElementById(`${props.id}.textField-label`).style.marginTop = "-25px";
      }
    } catch {}
  }

  return (
    <div id={`${props.id}.Div`}>
      {" "}
      {props.isVisible && (
        <div className={props.classes.editorItem}>
          <Typography id="discrete-slider" gutterBottom>
            {_.get(props, `label`)}
          </Typography>
          <TextField
            select
            InputLabelProps={{
              shrink: false,
            }}
            style={{ marginTop: gettingMargin(props.tooltipLength) }}
            id={`${props.id}.textField`}
            // label={_.get(props, `label`)}
            value={value}
            className={props.classes.textField}
            onChange={(event) => {
              if (props.userPermissions["update"] === false) {
                props.createSnackbar(
                  "You do not have permission to update, contact your administrator.",
                  "warning",
                  false
                );
                return;
              }
              setValue(event.target.value);
              props.setValue(event.target.value);
            }}
          >
            <MenuItem key={"100"} value={"100"}>
              100
            </MenuItem>
            <MenuItem key={"200"} value={"200"}>
              200
            </MenuItem>
            <MenuItem key={"300"} value={"300"}>
              300
            </MenuItem>
            <MenuItem key={"normal"} value={"normal"}>
              Normal
            </MenuItem>
            <MenuItem key={"500"} value={"500"}>
              500
            </MenuItem>
            <MenuItem key={"600"} value={"600"}>
              600
            </MenuItem>
            <MenuItem key={"bold"} value={"bold"}>
              Bold
            </MenuItem>
            <MenuItem key={"800"} value={"800"}>
              800
            </MenuItem>
            <MenuItem key={"900"} value={"900"}>
              900
            </MenuItem>
          </TextField>
        </div>
      )}
    </div>
  );
}
