import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Card, CardContent, Typography } from "@material-ui/core";
import Imgdownload from "../../../images/documentationImages/imgdownload.png";
import Platform from "../../../images/documentationImages/platform.png";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    backgroundColor: "#404040",
  },
  content: {
    backgroundColor: "#404040",
    align: "center",
    textAlign: "left",
    marginTop: "1%",
    marginLeft: "5%",
    marginRight: "20%",
    color: "white",
  },
  card: {
    margin: "5%",
  },
  img: {
    borderRadius: "4px",
    border: "1px solid #727171",
    boxShadow: "rgb(0 0 0 / 20%) 1px 1px 10px 0px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px",
    width: "40%",
    height: "auto",
    marginBottom: "16px",
  },
  imgContainer: {
    display: "flex",
    justifyContent: "center",
  },
}));

const Download = () => {
  const classes = useStyles();

  return (
    <div className={classes.root} id="Download">
      <main className={classes.content}>
        <Card>
          <CardContent>
            <Typography variant="h5">Download Playable</Typography>
            <br />
            <Typography paragraph>
              The game versions can be downloaded through the download icon top right of the preview section. By
              clicking the download icon, export options will open up on the screen.
            </Typography>
            <div className={classes.imgContainer}>
              <img className={classes.img} src={Imgdownload} alt="" />
            </div>
            <Typography paragraph>
              The version that is being downloaded can be seen at the top. Under the version information, the language
              version can be picked. Multiple languages can be chosen from here, or just one. To finish downloading the
              file, the ad platform for the file has to be chosen. Multiple ad platforms can be chosen, in addition to
              the one.
            </Typography>
            <div className={classes.imgContainer}>
              <img className={classes.img} src={Platform} alt="" />
            </div>
          </CardContent>
        </Card>
      </main>
    </div>
  );
};

export default Download;
