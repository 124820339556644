import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, ListItemSecondaryAction, TextField, Typography } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Card from "@material-ui/core/Card";
import _ from "lodash";
import { AddRounded } from "@material-ui/icons";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import { getServerUri } from "../../modules/uriUtility";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Checkbox from "@material-ui/core/Checkbox";
import Avatar from "@material-ui/core/Avatar";
import SwitchGeneric from "../switchGeneric";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import DeleteIcon from "@material-ui/icons/Delete";
import DialogDeleteUser from "./dialogDeleteUser";

import { images } from "../selectorGame";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    marginLeft: "21.5rem",
    paddingBottom: "1rem",
    backgroundColor: "#404040",
  },
  content: {
    flexGrow: 1,
    backgroundColor: "#404040",
    color: "white",
    align: "center",
    textAlign: "center",
    marginTop: "0.5%",
    marginRight: "1rem",
  },
  userManagementWrapper: {
    padding: "2rem 2rem 2rem 2rem",
  },
  userManagement: {
    display: "flex",
    justifyContent: "flex-start",
    margin: "2rem 0rem 2rem 0rem",
  },
  card: {
    margin: "0rem 0rem 0.5rem 0.5rem",
  },
  titles: {
    width: "33%",
    float: "left",
    margin: "0rem",
  },
  titleText: {
    margin: "0rem 0rem 0.5rem 0rem",
  },
  cardTitleText: {
    fontSize: 26,
    fontWeight: 540,
  },
  addUserWrapper: {
    padding: "2rem",
    display: "flex",
    flexDirection: "column",
  },
  deleteUserWrapper: {
    padding: "2rem",
  },
  textField: {
    width: "100%",
    padding: "0 0 1rem 0",
  },
  checkboxList: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  checkboxWrapper: {
    float: "left",
    width: "40%",
    padding: "1rem",
  },
  userCredentials: {
    width: "20%",
    float: "left",
    display: "flex",
    flexDirection: "column",
    padding: "1rem 1rem 1rem 0",
  },
  addButton: {
    float: "right",
  },
  deleteButton: {},
  userListRoot: {
    flexGrow: 1,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
  addButtonWrapper: {},
  gameTitle: {
    fontSize: "0.9rem",
  },
  permissionTitle: {
    fontSize: "0.9rem",
  },
}));

/**
 * @param props.createSnackbar
 * @param props.userPermissions
 */
export default function userManagement(props) {
  const classes = useStyles();
  const [userName, setUserName] = useState("");
  const [users, setUsers] = useState();
  const [userToDelete, setUserToDelete] = useState();
  const [areUsersLoaded, setAreUsersLoaded] = useState(false);
  const [isDeleteUserDialogOn, setIsDeleteUserDialogOn] = useState(false);

  const [password, setPassword] = useState("");
  const [gamesChecked, setGamesChecked] = useState([]);
  const [permissionsChecked, setPermissionsChecked] = useState(["view"]);
  const [isAdminChecked, setIsAdminChecked] = useState(false);

  useEffect(() => {
    fetchUsers().then(() => {
      setAreUsersLoaded(true);
    });
  }, []);

  const handleToggleGames = (value) => () => {
    const currentIndex = gamesChecked.indexOf(value);
    const newChecked = [...gamesChecked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setGamesChecked(newChecked);
  };

  const handleTogglePermissions = (value) => () => {
    const currentIndex = permissionsChecked.indexOf(value);
    const newChecked = [...permissionsChecked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setPermissionsChecked(newChecked);
  };

  const renderListOfGames = function () {
    return (
      <List dense className={classes.checkboxList}>
        {props.games.map((game) => (
          <ListItem key={game.title} button onClick={handleToggleGames(game._id)}>
            <ListItemAvatar>
              {images[String(game._id)] ? (
                <Avatar src={images[String(game._id)]} alt={`Avatar n°${game.title}`} />
              ) : (
                <Avatar alt={`Avatar n°${game.title}`}>G</Avatar>
              )}
            </ListItemAvatar>
            <ListItemText classes={{ primary: classes.gameTitle }} primary={`${game.title}`} />
            <ListItemSecondaryAction>
              <Checkbox onChange={handleToggleGames(game._id)} checked={gamesChecked.indexOf(game._id) !== -1} />
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    );
  };

  const renderListOfPermissions = function () {
    return (
      <List dense className={classes.checkboxList}>
        {["view", "update", "insert", "delete", "export"].map((value) => (
          <ListItem
            key={value}
            button
            onClick={value.toLowerCase() !== "view" ? handleTogglePermissions(value.toLowerCase()) : () => {}}
          >
            <ListItemText classes={{ primary: classes.permissionTitle }} primary={`${value}`} />
            <ListItemSecondaryAction>
              <Checkbox
                onChange={value.toLowerCase() !== "view" ? handleTogglePermissions(value.toLowerCase()) : () => {}}
                checked={permissionsChecked.indexOf(value.toLowerCase()) !== -1}
              />
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    );
  };

  const renderListOfUsers = function () {
    function generate() {
      if (_.isNil(users)) return null;
      return users.map((user, idx) =>
        React.cloneElement(
          <div>
            <ListItem>
              <ListItemAvatar>
                <Avatar>{user.isAdmin ? <SupervisorAccountIcon /> : <AccountCircleIcon />}</Avatar>
              </ListItemAvatar>
              {user.isAdmin ? (
                <ListItemText
                  primary={<Typography variant="h5">{user.username}</Typography>}
                  secondary={
                    <Typography
                      variant="caption"
                      style={{
                        color: "#FBC02D",
                        fontSize: "0.8rem",
                      }}
                    >
                      admin
                    </Typography>
                  }
                />
              ) : (
                <ListItemText
                  primary={<Typography variant="h5">{user.username}</Typography>}
                  secondary={
                    <Typography
                      variant="caption"
                      style={{
                        color: "#388E3C",
                        fontSize: "0.8rem",
                      }}
                    >
                      user
                    </Typography>
                  }
                />
              )}
              {user.username !== props.userName && (
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => {
                      setUserToDelete(user);
                      setIsDeleteUserDialogOn(true);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                  <DialogDeleteUser
                    isOpen={isDeleteUserDialogOn}
                    onClose={() => {
                      setIsDeleteUserDialogOn(false);
                    }}
                    createSnackbar={props.createSnackbar}
                    user={userToDelete}
                    users={users}
                    setUsers={setUsers}
                    classes={props.classes}
                  ></DialogDeleteUser>
                </ListItemSecondaryAction>
              )}
            </ListItem>
            <Divider />
          </div>,
          {
            key: idx,
          }
        )
      );
    }

    return (
      <div className={classes.userListRoot}>
        <Grid item xs={12} md={6}>
          <Typography variant="h5" className={classes.title}>
            Users
          </Typography>
          <div className={classes.demo}>
            <List>{generate()}</List>
          </div>
        </Grid>
      </div>
    );
  };

  const fetchUsers = function () {
    return fetch(getServerUri("user/find"), {
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({
        accessToken: localStorage.getItem("session.accessToken"),
        username: userName,
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((fetchedUsers) => {
            setUsers(fetchedUsers.filter((fetchedUser) => fetchedUser.companyID === props.userCompany._id));
          });
        } else {
          response
            .text()
            .then((textResponse) => {
              console.log({ message: "error finding users", textResponse });
            })
            .catch(console.error);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getCompanyUserLimit = function (plans, company) {
    try {
      return _.get(plans[props.getPlanIndex(_.get(company, "subscription.currentPlan"))], "numberOfUsers");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className={classes.root} id="UserManagement">
      <main className={classes.content}>
        <Card className={classes.card}>
          <div className={classes.userManagementWrapper}>
            <Typography className={classes.cardTitleText} align="left">
              User Management
            </Typography>
            <div className={classes.userManagement}>
              <div className={classes.titles}>
                <Typography className={classes.titleText} variant="h6" align="left">
                  User Name
                </Typography>
                <Typography align="left" gutterBottom>
                  {_.get(props, "userName")}
                </Typography>
              </div>
              <div className={classes.titles}>
                <Typography className={classes.titleText} variant="h6" align="left">
                  Email Address
                </Typography>
                <Typography align="left" gutterBottom>
                  {_.get(props, "userEmail") === "undefined" ? "" : _.get(props, "userEmail")}
                </Typography>
              </div>
              <div className={classes.titles}>
                <Typography className={classes.titleText} variant="h6" align="left">
                  Company User Limit
                </Typography>
                <Typography align="left" gutterBottom>
                  Up to {getCompanyUserLimit(_.get(props, "plans"), _.get(props, "userCompany"))} Users
                </Typography>
              </div>
            </div>
          </div>
        </Card>
        <Card className={classes.card}>
          <div className={classes.addUserWrapper}>
            <div>
              <Typography className={classes.cardTitleText} style={{ paddingBottom: "1rem" }} align="left">
                Add User
              </Typography>
              <div className={classes.userCredentials}>
                <TextField
                  className={classes.textField}
                  rowsMax={1}
                  label={"Username"}
                  value={userName}
                  onChange={(event) => {
                    setUserName(event.target.value);
                  }}
                ></TextField>
                <TextField
                  type="password"
                  className={classes.textField}
                  rowsMax={1}
                  label={"Password"}
                  value={password}
                  onChange={(event) => {
                    setPassword(event.target.value);
                  }}
                ></TextField>
                <SwitchGeneric
                  value={isAdminChecked}
                  setValue={setIsAdminChecked}
                  label={isAdminChecked ? "This is an admin" : "This is a user"}
                  userPermissions={props.userPermissions}
                  createSnackbar={props.createSnackbar}
                  isVisible={true}
                ></SwitchGeneric>
              </div>
              <div className={classes.checkboxWrapper}>{renderListOfGames()}</div>
              <div className={classes.checkboxWrapper}>{renderListOfPermissions()}</div>
            </div>
            <div className={classes.addButtonWrapper}>
              <Button
                className={classes.addButton}
                disabled={gamesChecked.length === 0}
                startIcon={<AddRounded />}
                color="primary"
                onClick={() => {
                  if (users.length >= getCompanyUserLimit(_.get(props, "plans"), _.get(props, "userCompany"))) {
                    props.createSnackbar(
                      `Your company's current plan allows up to ${getCompanyUserLimit(
                        _.get(props, "plans"),
                        _.get(props, "userCompany")
                      )} users`,
                      "error"
                    );
                    return;
                  }
                  if (userName.length < 2 || userName.length > 40) {
                    props.createSnackbar("Username must be between 2-40 characters long", "error");
                    return;
                  }
                  if (password.length < 8 || password.length > 40) {
                    props.createSnackbar("Password must be between 8-40 characters long", "error");
                    return;
                  }
                  if (gamesChecked.length == 0) {
                    props.createSnackbar("Select at least one game", "warning");
                    return;
                  }
                  if (permissionsChecked.length == 0) {
                    props.createSnackbar("Select at least one permission", "warning");
                    return;
                  }

                  let params = new URLSearchParams({
                    accessToken: localStorage.getItem("session.accessToken"),
                    username: String(userName),
                    password: String(password),
                    isAdmin: Boolean(isAdminChecked),
                  });
                  gamesChecked.map((game) => {
                    params.append("games", game);
                  });
                  permissionsChecked.map((permission) => {
                    params.append("permissions", permission);
                  });

                  fetch(getServerUri("user/insert"), {
                    method: "POST",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    body: params,
                  })
                    .then((response) => {
                      if (response.status === 200) {
                        response.json().then((insertedUser) => {
                          const newUsers = _.cloneDeep(users);
                          newUsers.push(insertedUser);
                          setUsers(newUsers);
                        });
                        console.log("user inserted");
                        props.createSnackbar("User added.", "success");
                      } else {
                        response
                          .text()
                          .then((textResponse) => {
                            console.log({ message: "error inserting user", textResponse });
                            if (textResponse === "USER_NAME_EXISTS") {
                              props.createSnackbar("That username is taken, try another.", "error");
                            } else {
                              props.createSnackbar("An error occurred when inserting the user.", "error");
                            }
                          })
                          .catch(console.error);
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              >
                Add User
              </Button>
            </div>
          </div>
        </Card>
        <Card className={classes.card}>
          <div className={classes.deleteUserWrapper}>
            <Typography className={classes.cardTitleText} style={{ paddingBottom: "1rem" }} align="left">
              Delete User
            </Typography>
            {renderListOfUsers()}
          </div>
        </Card>
      </main>
    </div>
  );
}
