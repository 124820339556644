import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogTitle } from "@material-ui/core";
import { getServerUri } from "../modules/uriUtility";
import { TextField } from "@material-ui/core";
import ReactGA from "react-ga";
import ReactGA4 from "react-ga4";

import { makeStyles } from "@material-ui/core/styles";
import dialogViewOnlyMode from "./dialogViewOnlyMode";
import { version } from "react-dom";
ReactGA.initialize("UA-186063268-1", {
  debug: false,
  gaOptions: {
    userId: localStorage.getItem("user.id"),
  },
});
ReactGA4.initialize("G-TB46JQ70PR", {
  debug: false,
  gaOptions: {
    userId: localStorage.getItem("user.id"),
  },
});
const useClasses = makeStyles({
  dialogDelete: {
    margin: 10,
  },
});
/**
 *
 * @param {Boolean} props.isOpen is it open?
 * @param {Function} props.setIsOpen set is open to true or false
 * @param {Function} props.deleteVersionFromView delete the version from view
 * @param {String} props.selectedVersionID the selected version id
 * @param props.createSnackbar
 * @returns {JSX.Element}
 */
export default function dialogFolderDuplicate(props) {
  let [newVersionTitle, setNewVersionTitle] = useState(
    props.selectedFolderId ? props.selectedFolderId.text + " copy" : ""
  );
  let [newVersionId, setNewVersionId] = useState(1);
  let [newParentID, setParentId] = useState("");
  let [title, setTitle] = useState(newVersionTitle);
  let [versionsToAdd, setVersionsToAdd] = useState(1);

  let [newVersionArray, setNewVersionArray] = useState([]);
  let [dummyVersion, setDummyVersion] = useState("");
  let [baseIDMain, setBaseID] = useState(props.selectedBase ? props.selectedBase._id : "");

  let [versionHierarchy, setVersionHierarchy] = useState(1);
  let [newversionHierarchy, setNewVersionHierarchy] = useState(1);

  let ab = [];
  var tmpObj = {};

  useEffect(() => {
    console.log("versionHierarchy updated", versionHierarchy);
  }, [versionHierarchy]);

  useEffect(() => {
    if (newversionHierarchy != 1) {
      let chain = Promise.resolve();
      versionsToAdd.map((item) => {
        chain = chain.then(() => addVersions(item));
      });
      chain = chain.then(function () {
        //props.setVersionHierarchy(newVersionTree);
      });
    }
  }, [newversionHierarchy]);
  useEffect(() => {
    if (versionsToAdd != 1) {
      let newVersionTree = {};
      let returnedData = JSON.parse(localStorage.getItem("returnedData"));
      let tmptree = JSON.parse(localStorage.getItem("resJson"));

      returnedData.newFolders.map((fld) => {
        tmptree.push(fld);
      });
      newVersionTree["tree"] = tmptree;
      setVersionHierarchy(newVersionTree);
      setNewVersionHierarchy(newVersionTree);
    }
  }, [versionsToAdd]);

  useEffect(() => {
    setNewVersionTitle(props.selectedFolderId.text + " copy");
    setTitle(props.selectedFolderId.text + " copy");
  }, [props.selectedFolderId]);
  useEffect(() => {
    if (newVersionId != 1) {
      var baseFolder = new Promise((resolve, reject) => {
        fetch(getServerUri("version/updateHierarchy"), {
          method: "POST",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          body: new URLSearchParams({
            accessToken: localStorage.getItem("session.accessToken"),
            baseID: props.selectedBase._id,
            versionHierarchy: JSON.stringify(versionHierarchy),
            companyID: props.companyID,
          }),
        }).then((response) => {
          if (response.status === 200) {
            props.setVersionHierarchy(versionHierarchy);

            // this is for folder render process
            // props.setRenameVersionName(String(title));
          }
        });
      });
    }
  }, [newVersionId]);

  useEffect(() => {
    if (dummyVersion !== "") {
      newVersionArray.push(dummyVersion[0]);
      setNewVersionArray(newVersionArray);

      let ver = newVersionArray.at(-1);
      var tmpObj = {
        "id": ver._id,
        "parent": dummyVersion[1],
        "text": ver.title,
        "data": {
          "fileType": "text",
          "createdDate": getCurrentDate("."),
          "updateDate": getCurrentDate("."),
        },
      };
      var obj = versionHierarchy;
      obj["tree"].push(tmpObj);
      setVersionHierarchy(obj);
      fetch(getServerUri("version/updateHierarchy"), {
        method: "POST",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams({
          accessToken: localStorage.getItem("session.accessToken"),
          baseID: baseIDMain,
          versionHierarchy: JSON.stringify(obj),
          companyID: props.companyID,
        }),
      }).then((response) => {
        if (response.status === 200) {
          props.setVersionHierarchy(obj);
        }
      });
    }
  }, [dummyVersion]);
  function resetSearchBar() {
    const searchBar = document.getElementById("searchBar");

    if (searchBar) {
      props.setSearchTerm(Math.floor(Math.random() * 10000) + 1);
      // searchBar.value = "";
    }
  }

  function getCurrentDate(separator = "") {
    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    let time = newDate.getHours();
    let minute = newDate.getMinutes() < 10 ? "0" + newDate.getMinutes() : newDate.getMinutes();
    return `${date < 10 ? `0${date}` : `${date}`}${separator}${
      month < 10 ? `0${month}` : `${month}`
    }${separator}${year} ${time}:${minute}`;
  }

  let addVersionTree = [];
  let addFolderTree = [];

  function addFolders(tree, parentID, addFolderTree) {
    tree.map((version) => {
      if (version.parent === parentID) {
        if (version.data.fileType !== "text") {
          addFolderTree = addFolders(tree, version.id, addFolderTree);
        }
      } else if (version.id === parentID) {
        if (version.parent === 0) {
          var tmpFolder = {
            "id": Math.floor(Math.random() * 100000),
            "text": title,
            "droppable": true,
            "parent": version.parent,
            "data": {
              "createdDate": getCurrentDate("."),
              "updateDate": getCurrentDate("."),
            },
            "oldId": version.id,
          };
        } else {
          let newTitle;
          if (props.selectedFolderId.id == version.id) {
            newTitle = title;
          } else {
            newTitle = version.text + "-d";
          }
          var tmpFolder = {
            "id": Math.floor(Math.random() * 100000),
            "text": newTitle,
            "droppable": true,
            "parent": version.parent,
            "data": {
              "createdDate": getCurrentDate("."),
              "updateDate": getCurrentDate("."),
            },
            "oldId": version.id,
          };
        }

        addFolderTree.push(tmpFolder);
      }
    });
    setNewVersionTitle("");

    return addFolderTree;
  }

  function addNode(tree, parentID, addVersionTree) {
    tree.map((version) => {
      if (version.parent === parentID) {
        if (version.data.fileType !== "text") {
          addVersionTree = addNode(tree, version.id, addVersionTree);
        } else {
          addVersionTree.push(version);
        }
      }
    });

    return addVersionTree;
  }

  function updateParents(newFolders, newVersions) {
    for (let i = 0; i < newVersions.length; i++) {
      for (let j = 0; j < newFolders.length; j++) {
        if (newVersions[i].parent === newFolders[j].oldId) {
          newVersions[i].parent = newFolders[j].id;
          break;
        }
      }
    }

    for (let i = 0; i < newFolders.length; i++) {
      for (let j = 0; j < newFolders.length; j++) {
        if (newFolders[i].parent === newFolders[j].oldId) {
          newFolders[i].parent = newFolders[j].id;
          break;
        }
      }
    }
    return [newFolders, newVersions];
  }

  function addFoldersToTree(tree, newFolders) {
    newFolders.map((folder) => {
      tree.push({
        "id": folder.id,
        "text": folder.text,
        "parent": folder.parent,
        "droppable": true,
        "data": {
          "createdDate": getCurrentDate("."),
          "updateDate": getCurrentDate("."),
        },
      });
    });
    return tree;
  }

  function addVersionsToTree(tree, newVersions) {
    newVersions.map((version) => {
      tree.push({
        id: version.id + 100,
        text: version.text + "-dup",
        parent: version.parent,
      });
    });
    return tree;
  }

  function duplicateFolder(tree, parentID) {
    var newFolders = addFolders(tree, parentID, addFolderTree);
    var newVersions = addNode(tree, parentID, addVersionTree);
    var updatedArrays = updateParents(newFolders, newVersions);

    newFolders = updatedArrays[0];
    newVersions = updatedArrays[1];

    tree = addFoldersToTree(tree, newFolders);
    //tree = addVersionsToTree(tree, newVersions)
    return { "tree": tree, "newFolders": newFolders, "newVersions": newVersions };
  }

  async function addVersions(versionHier) {
    let result = 0;
    let response = await fetch(getServerUri("version/duplicate"), {
      method: "POST",
      headers: {
        "content-type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams({
        accessToken: localStorage.getItem("session.accessToken"),
        versionID: versionHier.id,
        title: versionHier.text + "-d",
      }),
    });

    let object = await response.json();

    if (response.status === 200) {
      props.fetchVersions();
      let newSelectedVersion;
      newSelectedVersion = object.insertedVersion;

      result = newSelectedVersion;
      console.trace("toolbarGamePreview - version duplicate");

      setNewVersionTitle("");
      ReactGA.event({
        category: "Preview",
        action: "Version Duplicated Clicked",
        label:
          "game: " +
          props.selectedGame.title +
          ", base: " +
          props.selectedBase.title +
          "originalVersion: " +
          props.selectedVersion.title +
          ", duplicatedVersion: " +
          newVersionTitle,
        dimension1: localStorage.getItem("user.id"),
      });
      ReactGA4.event({
        category: "Preview",
        action: "Version Duplicated Clicked",
        label:
          "game: " +
          props.selectedGame.title +
          ", base: " +
          props.selectedBase.title +
          "originalVersion: " +
          props.selectedVersion.title +
          ", duplicatedVersion: " +
          newVersionTitle,
        dimension1: localStorage.getItem("user.id"),
      });
      props.createSnackbar("Successfully duplicated", "success");
      let parentID = versionHier.parent;
      setDummyVersion([newSelectedVersion, parentID]);
    } else if (response.status === 401) {
      props.createSnackbar("You are not authorized to insert, contact your administrator.", "warning");
      response
        .text()
        .then((textResponse) => {
          console.log({ message: "error inserting", textResponse });
        })
        .catch(console.error);
    }

    await Promise.resolve(5);
    //return result;
  }

  useEffect(() => {
    setNewVersionTitle(props.selectedFolderId ? props.selectedFolderId.text + " copy" : "");
    setTitle(props.selectedFolderId ? props.selectedFolderId.text + " copy" : "");
  }, [props.isOpen]);

  const handleKeyPress = (event) => {
    if (event.key === "Enter" || event.key === "Return") {
      handleButtonClick();
    }
  };
  const handleButtonClick = () => {
    resetSearchBar();
    props.onClose(false);
    fetch(getServerUri("version/getHierarchy"), {
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({
        accessToken: localStorage.getItem("session.accessToken"),
        baseID: _.get(props, "selectedBase._id"),
        companyID: props.companyID,
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          ab = [];
          response.json().then((json) => {
            // tmpObj = {
            //     "id": Math.floor(Math.random() * 100000),
            //     "parent": 0,
            //     "droppable": true,
            //     "text": title,
            //     "data": {
            //         "createdDate": getCurrentDate("."),
            //         "updateDate": getCurrentDate(".")
            //     }
            // }
            // json.versionHierarchy.push(tmpObj);
            //

            // newVersionTree["tree"] = json.versionHierarchy;
            // setVersionHierarchy(newVersionTree);

            // setParentId(tmpObj.id);
            // props.setSelectedFolderId(tmpObj);
            let newVersionTree = {};
            let versionsToadd = [];
            var newArrayHi = JSON.parse(JSON.stringify(json.versionHierarchy));
            let returnedData = duplicateFolder(newArrayHi, props.selectedFolderId.id);
            localStorage.setItem("returnedData", JSON.stringify(returnedData));

            versionsToadd = returnedData.newVersions;
            //newVersionTree = returnedData.tree;
            localStorage.setItem("resJson", JSON.stringify(json.versionHierarchy));

            setVersionsToAdd(versionsToadd);

            // returnedData.newFolders.map((fld) => {
            //     json.versionHierarchy.push(fld);
            // })
            // newVersionTree["tree"] = json.versionHierarchy;

            // setVersionHierarchy(newVersionTree);

            // let chain = Promise.resolve();
            // versionsToadd.map((item) => {
            //     chain = chain.then(() => addVersions(item))

            // });
            // chain = chain.then(function () {
            //     props.setVersionHierarchy(newVersionTree);
            // })
            //setNewVersionId(Math.floor(Math.random() * 100000).toString());
          });
        } else if (response.status === 401) {
          props.createSnackbar("You are not authorized to update, contact your administrator.", "warning");
          response
            .text()
            .then((textResponse) => {
              console.log({ message: "error updating", textResponse });
            })
            .catch(console.error);
        } else {
          response
            .text()
            .then((textResponse) => {
              console.log({ message: "error renaming", textResponse });
              props.createSnackbar("An error occurred when renaming a new version.", "error");
            })
            .catch(console.error);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  // useEffect(() => {

  //     let chain = Promise.resolve();
  //     versionsToadd.map((item) => {
  //         chain = chain.then(() => addVersions(item))

  //     });
  //     chain = chain.then(function () {
  //         props.setVersionHierarchy(newVersionTree);
  //     })
  // }, [versionsToAdd])

  if (_.isNil(String(_.get(props, "selectedVersion._id")))) return null;

  return (
    <Dialog
      id="dialogFolderDuplicate_dialog"
      open={props.isOpen}
      onClose={() => {
        props.onClose(false);
      }}
    >
      <DialogTitle id="dialogFolderDuplicate_dialogTitle">Name of the new folder</DialogTitle>
      <div id="dialogFolderDuplicate_div" style={{ padding: "10px", textAlign: "center" }}>
        <TextField
          id="dialogFolderDuplicate_textField"
          style={{ width: "100%" }}
          value={newVersionTitle}
          onKeyPress={handleKeyPress}
          inputProps={{ maxLength: 31, autoFocus: true }}
          onChange={(event) => {
            setTitle("New Folder");

            var a = event.target.value;
            var a = a.replace(/\s+/g, " ").replace(/^\s/, "").replace(/\s$/, "");
            if (a.length == 0) {
              setTitle("New Folder");
              setNewVersionTitle("");
            } else {
              setTitle(event.target.value);
              setNewVersionTitle(event.target.value);
            }
          }}
        ></TextField>
        <Button disabled={!props.isOpen} id="dialogFolderDuplicate_button" onClick={handleButtonClick}>
          Save
        </Button>
      </div>
    </Dialog>
  );
}
