import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import fetcher from "../modules/fetcher";
import { TextField, MenuItem } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({}));

export default function selectorCardOrder(props) {
  const classes = useStyles();
  function gettingMargin(numberMargin) {
    if (props.tooltip != undefined) {
      if (numberMargin) {
        return numberMargin.toString() + "px";
      }
    } else return (0).toString() + "px";
  }
  const getCardOrderByKey = (key) => {
    return props.cardOrders.filter((orders) => orders.key == key)[0];
  };

  return (
    <>
      {" "}
      {props.isVisible && (
        <div>
          <TextField
            style={{ marginTop: gettingMargin(props.tooltipLength) }}
            select
            value={props.selectedCardOrderKey}
            className={props.classes.textField}
            onChange={(event) => {
              if (props.userPermissions["update"] === false) {
                props.createSnackbar(
                  "You do not have permission to update, contact your administrator.",
                  "warning",
                  false
                );
                return;
              }
              props.setValue({ value: getCardOrderByKey(event.target.value) });
            }}
          >
            {props.cardOrders.map((cardOrder) => (
              <MenuItem key={String(cardOrder.key)} value={String(cardOrder.key)}>
                {" "}
                {String(cardOrder.key)}
              </MenuItem>
            ))}
          </TextField>
        </div>
      )}
    </>
  );
}
