import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Typography } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import _ from "lodash";
import Divider from "@material-ui/core/Divider";
import { useEffect, useState } from "react";
import SwitchGeneric from "../switchGeneric";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    marginLeft: "21.5rem",
    paddingBottom: "1rem",
    backgroundColor: "#404040",
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: "#404040",
    color: "white",
    align: "center",
    textAlign: "center",
    marginTop: "0.5%",
  },
  pricingWrapper: {
    padding: "2rem 2rem 2rem 2rem",
  },
  pricing: {
    display: "flex",
    justifyContent: "space-evenly",
    margin: "2rem 0rem 2rem 0rem",
  },
  card: {
    margin: "0rem 0rem 0.5rem 0.5rem",
  },
  titles: {
    width: "28%",
    height: "25rem",
    float: "left",
    margin: "1rem 1rem 1rem 1rem",
  },
  titleText: {
    padding: "1rem 1rem 1rem 1rem",
  },
  list: {
    textAlign: "left",
    margin: "0.3rem",
    height: "10rem",
  },
  divider: {
    margin: "2rem 2rem 2rem 2rem",
  },
  prices: {
    margin: "1rem",
  },
  titleWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  cardTitleText: {
    fontSize: 26,
    fontWeight: 540,
  },
}));

export default function pricing(props) {
  const classes = useStyles();

  const [isAnnual, setIsAnnual] = useState(false);

  useEffect(() => {}, []);

  return (
    <div className={classes.root} id="Pricing">
      <CssBaseline />
      <main className={classes.content}>
        <div className={classes.toolbar}>
          <Card className={classes.card}>
            <div className={classes.pricingWrapper}>
              <div className={classes.titleWrapper}>
                <Typography className={classes.cardTitleText} align="left">
                  Pricing
                </Typography>
                <SwitchGeneric
                  classes={props.classes}
                  value={isAnnual}
                  setValue={setIsAnnual}
                  label={"Annual Plans"}
                ></SwitchGeneric>
              </div>
              <div className={classes.pricing}>
                <Card className={classes.titles}>
                  <Typography className={classes.titleText} variant="h5" align="center">
                    Basic Plan
                  </Typography>
                  <Divider className={classes.divider} />
                  <ul className={classes.list}>
                    <li style={{ marginBottom: "1rem" }}>
                      Up to {_.get(props.plans[0], "numberOfVideos")} Monthly Video Downloads
                    </li>
                    <li style={{ marginBottom: "1rem" }}>
                      Up to {_.get(props.plans[0], "numberOfPlayables")} Monthly Playable Downloads
                    </li>
                    <li style={{ marginBottom: "1rem" }}>Recommended for Personal Use and Small Companies</li>
                  </ul>
                  <Divider className={classes.divider} />
                  <div className={classes.prices}>
                    {isAnnual ? (
                      <Typography>${_.get(props.plans[0], "annualPrice")}/mth</Typography>
                    ) : (
                      <Typography>${_.get(props.plans[0], "monthlyPrice")}/mth</Typography>
                    )}
                  </div>
                </Card>
                <Card className={classes.titles}>
                  <Typography className={classes.titleText} variant="h5" align="center">
                    Pro Plan
                  </Typography>
                  <Divider className={classes.divider} />
                  <ul className={classes.list}>
                    <li style={{ marginBottom: "1rem" }}>
                      Up to {_.get(props.plans[1], "numberOfVideos")} Monthly Video Downloads
                    </li>
                    <li style={{ marginBottom: "1rem" }}>
                      Up to {_.get(props.plans[1], "numberOfPlayables")} Monthly Playable Downloads
                    </li>
                    <li style={{ marginBottom: "1rem" }}>Recommended for Mid-Sized Companies</li>
                  </ul>
                  <Divider className={classes.divider} />
                  <div className={classes.prices}>
                    {isAnnual ? (
                      <Typography>${_.get(props.plans[1], "annualPrice")}/mth</Typography>
                    ) : (
                      <Typography>${_.get(props.plans[1], "monthlyPrice")}/mth</Typography>
                    )}
                  </div>
                </Card>
                <Card className={classes.titles}>
                  <Typography className={classes.titleText} variant="h5" align="center">
                    Enterprise Plan
                  </Typography>
                  <Divider className={classes.divider} />
                  <ul className={classes.list}>
                    <li style={{ marginBottom: "1rem" }}>
                      Up to {_.get(props.plans[2], "numberOfVideos")} Monthly Video Downloads
                    </li>
                    <li style={{ marginBottom: "1rem" }}>
                      Up to {_.get(props.plans[2], "numberOfPlayables")} Monthly Playable Downloads
                    </li>
                    <li style={{ marginBottom: "1rem" }}>Recommended for Enterprises with Multiple Products</li>
                  </ul>
                  <Divider className={classes.divider} />
                  <div className={classes.prices}>
                    {isAnnual ? (
                      <Typography>${_.get(props.plans[2], "annualPrice")}/mth</Typography>
                    ) : (
                      <Typography>${_.get(props.plans[2], "monthlyPrice")}/mth</Typography>
                    )}
                  </div>
                </Card>
              </div>
            </div>
          </Card>
        </div>
      </main>
    </div>
  );
}
