import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Button } from "@material-ui/core";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  root: {},
  drawerPaper: {
    top: "inherit",
    width: "21rem",
    color: "white",
  },
  list: {
    padding: "0",
  },
  listItem: {
    padding: "1rem",
  },
  logoutButtonWrapper: {
    bottom: "0",
    top: "auto",
    position: "fixed",
    width: "21rem",
    textAlign: "center",
  },
  logoutButton: {
    padding: "1.5rem",
    width: "100%",
  },
}));

export default function PermanentDrawerLeft(props) {
  const classes = useStyles();
  const [isAdmin, setIsAdmin] = useState(props.isAdmin);

  useEffect(() => {
    setIsAdmin(props.isAdmin);
  }, [props.isAdmin]);

  return (
    <div id="navbarAccountSettings" className={classes.root}>
      <Drawer
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
      >
        <Divider />
        <List id="navbarAccountSettings_list" className={classes.list}>
          <ListItem
            id="navbarAccountSettings_button"
            button
            className={classes.listItem}
            component="a"
            onClick={() => {
              props.setSelectedPage(0);
            }}
          >
            <ListItemText primary="Account Information"></ListItemText>
          </ListItem>
          <Divider />
        </List>
        {isAdmin && (
          <List id="navbarAccountSettings_list2" className={classes.list}>
            <ListItem
              id="navbarAccountSettings_button2"
              button
              className={classes.listItem}
              component="a"
              onClick={() => {
                props.setSelectedPage(1);
              }}
            >
              <ListItemText primary="Billing"></ListItemText>
            </ListItem>
            <Divider />
          </List>
        )}
        {isAdmin && (
          <List id="navbarAccountSettings_list3" className={classes.list}>
            <ListItem
              id="navbarAccountSettings_button3"
              button
              className={classes.listItem}
              component="a"
              onClick={() => {
                props.setSelectedPage(2);
              }}
            >
              <ListItemText primary="User Management"></ListItemText>
            </ListItem>
            <Divider />
          </List>
        )}
        {/*{*/}
        {/*    isAdmin &&*/}
        {/*    <List>*/}
        {/*        <ListItem button*/}
        {/*                  component="a"*/}
        {/*                  onClick={() => {*/}
        {/*                      props.setSelectedPage(3);*/}
        {/*                  }}>*/}
        {/*            <ListItemText primary="Pricing"></ListItemText>*/}
        {/*        </ListItem>*/}
        {/*        <Divider/>*/}
        {/*    </List>*/}
        {/*}*/}
        <div className={classes.logoutButtonWrapper}>
          <Divider />
          <Button
            id="logout_button"
            className={classes.logoutButton}
            color={"primary"}
            onClick={() => {
              props.setIsVersionBeingUpdatedFalse(props.selectedVersion);
              localStorage.clear();
              location = "/login";
            }}
          >
            Logout
          </Button>
          <Divider />
        </div>
      </Drawer>
    </div>
  );
}
