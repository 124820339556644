import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { TextField, Button, Box, Typography } from "@material-ui/core";
import { getServerUri } from "../modules/uriUtility";

const useStyles = makeStyles({
  loginDiv: { display: "flex", justifyContent: "center" },
});

const loginStates = {
  attempt: "attempt",
  success: "success",
  wrongCredentials: "wrongCredentials",
  invalidRequest: "invalidRequest",
  internalError: "internalError",
  badResponse: "badResponse",
};

export default function login(props) {
  let [username, setUsername] = useState("");
  let [password, setPassword] = useState("");
  let [loginState, setLoginState] = useState(loginStates.attempt);

  const classes = useStyles();
  const theme = useTheme();

  function tryLogin(event) {
    console.log("will try logging in using:");
    //console.dir({ username, password });
    //send a login request and save the access token, redirect to home
    fetch(getServerUri("authentication/login"), {
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({ username, password }),
    }).then((response) => {
      if (response.status === 400) {
        setLoginState(loginStates.invalidRequest);
      } else if (response.status === 401) {
        setLoginState(loginStates.wrongCredentials);
      } else if (response.status === 500) {
        setLoginState(loginStates.internalError);
      }
      response
        .json()
        .then((data) => {
          console.dir(data);
          if (response.status === 400) {
            setLoginState(loginStates.invalidRequest);
          } else if (response.status === 401) {
            setLoginState(loginStates.wrongCredentials);
          } else if (response.status === 500) {
            setLoginState(loginStates.internalError);
          } else if (response.status === 200) {
            if (!_.isObject(_.get(data, "session"))) {
              console.error("bad response, login endpoint did not return session");
              setLoginState(loginStates.badResponse);
            } else if (!_.isObject(_.get(data, "user"))) {
              console.error("bad response, login endpoint did not return user data");
              setLoginState(loginStates.badResponse);
            } else {
              Object.entries(_.get(data, "session")).forEach(([key, value]) => {
                localStorage.setItem("session." + key, String(value));
              });
              Object.entries(_.get(data, "user")).forEach(([key, value]) => {
                localStorage.setItem("user." + key, String(value));
              });
              console.log("setting user  now");
              setLoginState(loginStates.success);
              window.location = "/";
            }
          }
        })
        .catch((err) => {
          setLoginState(loginStates.wrongCredentials);
          console.error(err);
        });
    });
  }

  return (
    <div className={classes.loginDiv}>
      <div style={{ width: "300px", display: "flex", flexDirection: "column" }}>
        <TextField
          style={{
            marginTop: "0.5rem",
          }}
          label={"User Name"}
          onChange={(event) => {
            setUsername(event.target.value);
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              tryLogin(event);
            }
          }}
        ></TextField>
        <TextField
          style={{
            marginTop: "0.5rem",
          }}
          label={"Password"}
          type={"password"}
          onChange={(event) => {
            setPassword(event.target.value);
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              tryLogin(event);
            }
          }}
        ></TextField>
        <Button
          style={{
            margin: "0.5rem 0 0.5rem 0",
          }}
          color={"primary"}
          label={"Login"}
          onClick={tryLogin}
        >
          Login
        </Button>
        <div>
          {(loginState === loginStates.wrongCredentials || loginState === loginStates.invalidRequest) && (
            <Typography>Wrong username or password</Typography>
          )}
          {loginState === loginStates.badResponse && <Typography>Bad response from server</Typography>}
        </div>
      </div>
    </div>
  );
}
