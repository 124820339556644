import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Divider, Card, CardContent, Typography, IconButton, Chip } from "@material-ui/core";
import titleAdjustImage from "../../../images/documentationImages/titleAdjust.jpeg";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "80px",
  },
  imgContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    borderRadius: "4px",
    border: "1px solid #727171",
    boxShadow: "rgb(0 0 0 / 20%) 1px 1px 10px 0px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px",
  },
  img: {
    borderRadius: "4px",
    width: "100%",
    height: "auto",
  },
  main: {
    align: "center",
    textAlign: "left",
  },
  chip: {
    float: "left",
    marginRight: "90%",
    marginBottom: "5%",
  },
  context: {
    textAlign: "justify",
  },
}));

const TitleAdjust = () => {
  const classes = useStyles();

  return (
    <div className={classes.main}>
      <Card className={classes.container}>
        <CardContent>
          <div className={classes.imgContainer}>
            <img src={titleAdjustImage} className={classes.img} alt="" />
            <Divider />
          </div>
          <br />
          <Typography variant="h6">New Feature Alert !</Typography>
          <Typography paragraph>28.07.2023</Typography>
          <Chip className={classes.chip} label="Standard" variant="outlined" />
          <Typography paragraph className={classes.context}>
            Here is easy way to see whole title of folders or versions. Simply expand all text using the arrow in the
            corner of the text areas, which are title, update date, created date.
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
};

export default TitleAdjust;
