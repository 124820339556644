import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Card, CardContent, Typography } from "@material-ui/core";
import Imgrecorder from "../../../images/documentationImages/imgrecorder.png";
import Taprecorder from "../../../images/documentationImages/taprecorder.png";
import Playinrecorder from "../../../images/documentationImages/playinrecorder.png";
import Manual from "../../../images/documentationImages/manual.png";
import Batch from "../../../images/documentationImages/batch.png";
import Autoplay from "../../../images/documentationImages/autoplay.png";
import Languagepicker from "../../../images/documentationImages/languagepicker.png";
import Resolutions from "../../../images/documentationImages/resolutions.png";
import Delay from "../../../images/documentationImages/delay.png";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    backgroundColor: "#404040",
  },
  content: {
    backgroundColor: "#404040",
    color: "white",
    align: "center",
    textAlign: "left",
    marginTop: "1%",
    marginLeft: "5%",
    marginRight: "20%",
  },
  card: {
    margin: "5%",
  },
  img: {
    borderRadius: "4px",
    border: "1px solid #727171",
    boxShadow: "rgb(0 0 0 / 20%) 1px 1px 10px 0px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px",
    width: "40%",
    height: "auto",
    marginBottom: "16px",
  },
  imgContainer: {
    display: "flex",
    justifyContent: "center",
  },
}));

const Recorder = () => {
  const classes = useStyles();

  return (
    <div className={classes.root} id="Recorder">
      <main className={classes.content}>
        <Card>
          <CardContent>
            <Typography variant="h5">Recorder</Typography>
            <br />
            <Typography paragraph>
              This feature is only available for our Pro & Enterprise subscribers. With this feature, the playable can
              be recorded for video ads. Either the video ads can be recorded with manual play or autoplay feature which
              is offered by the dashboard.
            </Typography>
            <div className={classes.imgContainer}>
              <img className={classes.img} src={Imgrecorder} alt="" />
            </div>
            <Typography paragraph>
              To be able to use this feature, Playin Extension for Google (which can only be downloaded by the Pro &
              Enterprise subscribers’ authenticated accounts) should be downloaded beforehand. After downloading the
              extension, the Recorder section beside the General Settings can be used. When on the Recorder tab, the
              extension should be activated. Otherwise the settings will stay as disabled.
            </Typography>
            <div className={classes.imgContainer}>
              <img className={classes.img} src={Taprecorder} alt="" />
            </div>
            <div className={classes.imgContainer}>
              <img className={classes.img} src={Playinrecorder} alt="" />
            </div>
            <Typography paragraph>
              By entering the requested resolution to the “width x height” field under the Manual Recording and clicking
              the record button, the video recording can be started. With manual recording, the user’s manual gameplay
              will be recorded. To record automatic gameplay, the Batch Recording can be used. After the recording
              starts by clicking the extension’s icon above, the recording can be ended and the downloading of the video
              will start automatically.
            </Typography>
            <div className={classes.imgContainer}>
              <img className={classes.img} src={Manual} alt="" />
            </div>
            <Typography paragraph>
              Under the Batch Recording autoplay settings can be adjusted. Images for autoplay hand can be uploaded and
              picked. Autoplay hand’s position and scale can be adjusted. Custom duration times, which differentiate
              from game to game, can also be adjusted down below. There is a preview button under the autoplay settings
              to see changes in real time.
            </Typography>
            <div className={classes.imgContainer}>
              <img className={classes.img} src={Batch} alt="" />
            </div>
            <Typography paragraph>
              Under the Autoplay & Record section, edited languages can be seen and picked through language selection.
              With the Select All, all of the edited languages can be picked in one click.
            </Typography>
            <div className={classes.imgContainer}>
              <img className={classes.img} src={Autoplay} alt="" />
            </div>
            <div className={classes.imgContainer}>
              <img className={classes.img} src={Languagepicker} alt="" />
            </div>
            <Typography paragraph>
              After language selection, the requested resolution for the videos can be added, deleted or edited.
            </Typography>
            <Typography paragraph>
              For resolution adjustments maximum resolution for both width and height is 4000. In addition to that when
              one’s entering a height value higher than the computer screen resolution, the dashboard will warn with a
              yellow warning text. This is just a warning to let users know about their screen resolution. Entering
              higher height values won’t affect the recording. Video screen will scale accordingly to fit the screen but
              the recorded video’s resolution will be as given resolution.
            </Typography>
            <div className={classes.imgContainer}>
              <img className={classes.img} src={Resolutions} alt="" />
            </div>
            <Typography paragraph>
              With the delay after the end game option, the video will last a few more seconds after the autoplay ends.
            </Typography>
            <div className={classes.imgContainer}>
              <img className={classes.img} src={Delay} alt="" />
            </div>
            <Typography paragraph>
              Clicking the record button will start the recording process. After time count, the autoplay will start and
              recording will end when the autoplay ends or after delay if requested. If needed the recording can be
              stopped just by tapping the extension’s icon above. After that to stop the recording process altogether,
              “stop” button will appear in place of the record button. By clicking it, the last video will be downloaded
              but recording won’t continue.
            </Typography>
            <Typography paragraph>PS: The recorded videos will be downloaded as an mp4 file in a zip file.</Typography>
          </CardContent>
        </Card>
      </main>
    </div>
  );
};

export default Recorder;
