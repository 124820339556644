import React, { useState, useEffect } from "react";
import { Dialog, TextField, Button, DialogTitle } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { getServerUri } from "../modules/uriUtility";
import _ from "lodash";

let useStyles = makeStyles({
  renameDialog: {
    margin: 10,
    display: "flex",
    alignItems: "center",
  },
  renameButton: {
    marginLeft: "0.5rem",
  },
});

/**
 *
 * @param props
 * @param props.open
 * @param props.setOpen
 * @param props.renameVersion
 * @param props.createSnackbar
 * @param props.setSelectedVersionPathValue
 * @returns {JSX.Element|null}
 */
export default function dialogVersionRename(props) {
  let [versionName, setVersionName] = useState("New Version");
  let [title, setTitle] = useState("");
  let [isCharacterAllowed, setIsCharacterAllowed] = useState(true);
  let [value, setValue] = useState("");

  let [versionHie, setVersionHierarchy] = useState(1);
  let [buttonClick, setButtonClick] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    if (versionHie != 1) {
      var baseFolder = new Promise((resolve, reject) => {
        fetch(getServerUri("version/updateHierarchy"), {
          method: "POST",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          body: new URLSearchParams({
            accessToken: localStorage.getItem("session.accessToken"),
            baseID: _.get(props, "selectedBase._id"),
            versionHierarchy: JSON.stringify(versionHie),
            companyID: props.companyID,
          }),
        }).then((response) => {
          if (response.status === 200) {
            // this is for folder render process

            props.setRenameVersionName(String(title));
          }
        });
      });
    }
  }, [versionHie]);

  useEffect(() => {
    if (title != "") {
      fetchVersionHierarchy(_.get(props, "selectedVersion"));
    }
  }, [title]);
  function resetSearchBar() {
    const searchBar = document.getElementById("searchBar");

    if (searchBar) {
      props.setSearchTerm(Math.floor(Math.random() * 10000) + 1);
      // searchBar.value = "";
    }
  }
  const handleKeyPress = (event) => {

    var blockSpecialRegex = /[~`!@#$%|&()={}[\]:;,.<>+\/?]/;
    var blockedChar = "^";
    var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if(blockSpecialRegex.test(key) || _.isNumber(key) || event.target.value.includes(blockedChar)){
      event.preventDefault();
      setIsCharacterAllowed(false);
    }

    if (event.key === "Enter" || event.key === "Return") {
      handleButtonClick();
    }
  };
  const handleButtonClick = () => {
    resetSearchBar();
    props.setOpen(false);
    const createdLanguages = _.get(props, "selectedVersion.createdLanguages") ? _.get(props, "selectedVersion.createdLanguages"): ["en"];

    fetch(getServerUri("version/updateOne"), {
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({
        accessToken: localStorage.getItem("session.accessToken"),
        versionID: String(_.get(props, "selectedVersion._id")),
        gameID: String(_.get(props, "selectedGame._id")),
        baseID: String(_.get(props, "selectedBase._id")),
        gameConfig: JSON.stringify(_.get(props, "selectedVersion.gameConfig")),
        recorderSettings: JSON.stringify(_.get(props, "selectedVersion.recorderSettings")),
        title: String(versionName),
        createdLanguages: JSON.stringify(createdLanguages)

      }),
    })
      .then((response) => {
        if (response.status === 200) {
          props.renameVersion(versionName);
          console.log({ message: "version updated", versions: props.versions });
          console.log({ message: "rename successful" });
          props.createSnackbar("Version Renamed", "success");
          props.setSelectedVersionPathValue({
            path: "lastUpdatingUserID",
            value: localStorage.getItem("user.id"),
          });
          setTitle(String(versionName));
          setVersionName("New Version");
        } else if (response.status === 401) {
          props.createSnackbar("You are not authorized to update, contact your administrator.", "warning");
          response
            .text()
            .then((textResponse) => {
              console.log({ message: "error updating", textResponse });
            })
            .catch(console.error);
        } else {
          response
            .text()
            .then((textResponse) => {
              console.log({ message: "error renaming", textResponse });
              props.createSnackbar("An error occurred when renaming a new version.", "error");
            })
            .catch(console.error);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  function getCurrentDate(separator = "") {
    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    let time = newDate.getHours();
    let minute = newDate.getMinutes() < 10 ? "0" + newDate.getMinutes() : newDate.getMinutes();
    return `${date < 10 ? `0${date}` : `${date}`}${separator}${
      month < 10 ? `0${month}` : `${month}`
    }${separator}${year} ${time}:${minute}`;

    //return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date}`
  }

  function fetchVersionHierarchy(version) {
    //take the version hierarchy
    fetch(getServerUri("version/getHierarchy"), {
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({
        accessToken: localStorage.getItem("session.accessToken"),
        baseID: _.get(props, "selectedBase._id"),
        companyID: props.companyID,
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((json) => {
            json.versionHierarchy.map(function (versionHier) {
              if (versionHier.id === String(_.get(props, "selectedVersion._id"))) {
                versionHier.text = title;
                versionHier.data.updateDate = getCurrentDate(".");
              }
            });

            let newVersionTree = {};
            newVersionTree["tree"] = json.versionHierarchy;
            setVersionHierarchy(newVersionTree);
          });
        } else if (response.status === 401) {
          props.createSnackbar("You are not authorized to update, contact your administrator.", "warning");
          response
            .text()
            .then((textResponse) => {
              console.log({ message: "error updating", textResponse });
            })
            .catch(console.error);
        } else {
          response
            .text()
            .then((textResponse) => {
              console.log({ message: "error renaming", textResponse });
              props.createSnackbar("An error occurred when renaming a new version.", "error");
            })
            .catch(console.error);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  return (
    <Dialog
      id="versionRename_dialog"
      open={props.open}
      onClose={() => {
        props.setOpen(false);
      }}
    >
      <DialogTitle
        id="versionRename_dialogTitle"
        style={{
          textAlign: "center",
        }}
      >
        Rename Version{" "}
      </DialogTitle>
      <div id="versionRename_div" className={classes.renameDialog}>
        <TextField
          error={!isCharacterAllowed}
          id="versionRename_textField"
          label={"New Version Title"}
          variant={"filled"}
          onKeyPress={handleKeyPress}
          helperText={isCharacterAllowed ? "" : "This character is not allowed"}
          inputProps={{ maxLength: 101, autoFocus: true }}
          value={value}
          onChange={(event) => {

            const regex = /[~`!@#$%|&()={}[\]:;,.<>+\/?^]/;
            const inputValue = event.target.value;

            // Remove characters that match the regex
            const sanitizedValue = inputValue.split("").filter(char => !regex.test(char)).join("");

            setValue(sanitizedValue);

            if(inputValue != sanitizedValue){

              setIsCharacterAllowed(false);
            }else{
              setIsCharacterAllowed(true);

            }

            if (event.target.value.length < 101) {
              // setVersionName(event.target.value);
              setVersionName("New Version");

              var a = event.target.value;
              a = a.replace(/\s+/g, " ").replace(/^\s/, "").replace(/\s$/, "");
              if (a.length == 0) {
                setVersionName("New Version");
                setButtonClick(false);
              } else {
                setVersionName(event.target.value);
                setButtonClick(false);
              }
            } else {
              setButtonClick(true);
              props.createSnackbar("No more than 100 characters can be entered", "error");
            }
          }}
        ></TextField>
        <Button
          id="dialogVersionRename_button"
          className={classes.renameButton}
          label={"Rename"}
          disabled={buttonClick}
          onClick={handleButtonClick}
        >
          OK
        </Button>
      </div>
    </Dialog>
  );
  return null;
}
