import React, { useEffect, useState, useRef } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { DialogTitle } from "@material-ui/core";
import Snackbar from "@mui/material/Snackbar";

//import Slider from "@mui/material/Slider";
import { Slider, Typography } from "@material-ui/core";
import _ from "lodash";
import { getServerUri } from "../modules/uriUtility";
import { CircularProgress } from "@material-ui/core";

import TextField from "@mui/material/TextField";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import ImageSlider from "react-image-comparison-slider";
import ReactGA4 from "react-ga4";
ReactGA4.initialize("G-TB46JQ70PR", {
  debug: false,
  gaOptions: {
    userId: localStorage.getItem("user.id"),
  },
});
// import img2 from "./photo.jpeg";
import { Button } from "@material-ui/core";
import { size } from "lodash";
const useStyles = makeStyles({
  mainDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  leftAlignedDiv: {
    textAlign: "right",
    fontSize: "0.85rem",
    marginRight: "10px",
    fontFamily: "DIN Alternate, sans-serif",
    marginBottom: "12px",
    marginTop: "12px",
  },
  rightAlignedDiv: {
    textAlign: "left",
    fontSize: "0.85rem",
    marginLeft: "10px",
    fontFamily: "DIN Alternate, sans-serif",
    marginBottom: "12px",
    marginTop: "12px",
  },
  Text: {
    marginLeft: "20px",
    marginTop: "8px",
    color: "white",
    fontSize: "1rem",
    fontFamily: "DIN Alternate, sans-serif",
  },
  container: {
    display: "flex",
    alignItems: "center",
    margin: "60px",
    flexDirection: "column",
    maxHeight: "800px",
    "@media (min-width: 780px)": {
      flexDirection: "row",
    },
  },
  Slider: {
    height: "5px",
    marginLeft: "15px",
    width: "93%",
    marginTop: "5px",
  },
  Box: {
    maxWidth: "400px",
    width: "100%",
    minHeight: "126px",
    marginBottom: "40px",
    borderRadius: "10px",
    border: "1px solid white",
  },
  Button: {
    minWidth: "300px",
    maxWidth: "325px",
    height: "60px",
    width: "80%",
    m: 1,
    backgroundColor: "#51abb0",
    borderRadius: "30px",
    // border: "1px solid ",
  },
  gamePreviewLoader: {
    position: "fixed",
    top: "50%",
    textAlign: "center",
    transform: "translate(-50%, -50%)",
    left: "60%",
  },
});

const Input = styled(TextField)`
margin-right: 12px;
& input {
height: 0.3rem;
width: 0.9rem;
font-size: small;
color: white;
border: 2px solid #51abb0;
border-radius: 5px;
text-align: center;
}
,
&.hover: {
bordercolor: "yellow";
}
,
& .MuiOutlinedInput-root {
&.Mui-focused fieldset {
Image Dimensions


display: none;
}
}
`;
const PrettoSlider = withStyles({
  thumb: {
    height: 15,
    width: 15,
    "&:focus,&:hover": {
      boxShadow: "none",
    },
  },
  track: {
    height: 5,
  },
  rail: {
    height: 5,
  },
  mark: {
    display: "none",
  },
})(Slider);
export default function ImagePreviewComponent(props) {
  const [value, setValue] = useState(props.sliderValue);
  const [resizedFile, setResizedFile] = useState(props.selectedFile);
  const [imageSrc, setImageSrc] = useState(props.newImageSrc);
  const [fileOptimizedSize, setOptimizedSize] = useState(props.fileOptimizedSize);
  const [previousValue, setPreviousValue] = useState(1); // Önceki değeri saklamak için bir state tanımlandı
  const [images, setImages] = useState(imagesToView(_.get(props, "images", [])));
  const fileName = props.fileName;
  // const [dbImage, setDbImage] = useState(props.DBImage);
  const [selectedImageID, setSelectedImageID] = useState(
    isSelectionValid(_.get(props, "selectedImage._id"))
      ? _.get(props, "selectedImage._id")
      : String(_.get(images, `[0]._id`, ``))
  );
  useEffect(() => {
    setImages(imagesToView(_.get(props, "images", [])));
  }, [_.get(props, "images", []), _.size(props.images)]);
  const defaultImageID = String(_.get(images, `[0]._id`, ``));

  const canvasRef = useRef(null);
  useEffect(() => {
    setResizedFile(props.selectedFile);
    setImageSrc(props.newImageSrc);
    setValue(props.sliderValue);
  }, [props.selectedFile]);
  useEffect(() => {
    setValue(props.sliderValue);
    const inputElement = document.getElementById("input");
    const sliderElement = document.getElementById("compressSlider");

    if (props.sliderValue === 0) {
      sliderElement.style.color = "grey";
      inputElement.style.borderColor = "grey";
    } else {
      sliderElement.style.color = "#4eabb1";
      inputElement.style.borderColor = "#51abb0";
    }
  }, [props.sliderValue]);
  useEffect(() => {
    if (isSelectionValid(_.get(props, "selectedImage._id", ""))) {
      setSelectedImageID(_.get(props, "selectedImage._id", ""));
    } else if (isSelectionValid(defaultImageID)) {
      selectImage(defaultImageID);
    }
  }, [_.get(props, "selectedVersion")]);
  useEffect(() => {
    setImageSrc(props.newImageSrc);
  }, [props.newImageSrc]);

  useEffect(() => {
    CompressImage(1, props.selectedFile);
  }, [props.fileOptimizedSize]);
  const classes = useStyles();
  function imagesToView(images) {
    return images.filter((image) => {
      return _.get(image, "bucket") === _.get(props, "bucket");
    });
  }
  function isSelectionValid(id, newImages) {
    let images2;
    if (_.isNil(newImages)) {
      images2 = images;
    } else {
      images2 = newImages;
    }
    const foundImages = images2.filter((image) => {
      return String(image._id) === String(id);
    });
    return foundImages.length > 0 || id === "";
  }
  function selectImage(id, newImages) {
    if (!isSelectionValid(id, newImages)) return;
    setSelectedImageID(id);
    let images2;
    if (_.isNil(newImages)) {
      images2 = images;
    } else {
      images2 = newImages;
    }
    const selectedImageRecord = images2.filter((imageRecord) => {
      return String(imageRecord._id) === String(id);
    })[0];
    const newSelectedImageRecord = _.clone(selectedImageRecord);
    _.set(newSelectedImageRecord, "destination", _.get(props, "destination"));
    props.setValue({
      value: [newSelectedImageRecord],
    });
  }
  function setInputFileListEmpty(inputId) {
    let list = new DataTransfer();
    document.getElementById(inputId).files = list.files;
  }

  function ApplyToDB(file) {
    const formData = new FormData();
    formData.append("files", file);
    formData.append("componentType", _.get(props, "componentType"));
    formData.append("path", _.get(props, "path"));
    formData.append("bucket", _.get(props, "bucket"));
    formData.append("accessToken", localStorage.getItem("session.accessToken"));
    formData.append("uploadedFileName", fileName);


    fetch(getServerUri("media/uploadImage"), {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.status !== 200) {
          if (response.status === 401) {
            props.createSnackbar("You are not authorized to insert , contact your administrator.", "warning");
          }
          response
            .text()
            .then((textResponse) => {
              console.error(textResponse);
            })
            .catch(console.error);
        } else {
          props.setIsDialogCircularProgressOpen(false);

          response.json().then((objectResponse) => {
            props.setImageUploadOpen(false);
            console.info(`Upload successful`);
            const newImages = _.cloneDeep(images);
            const newRecords = _.get(objectResponse, "records", []);
            newImages.push(...newRecords);
            setImages(newImages);

            props.insertMediaPathValue({
              path: `images`,
              value: newRecords,
            });

            const newImageID = String(newRecords[0]._id);
            setSelectedImageID(newImageID);
            selectImage(newImageID, newImages);
            setInputFileListEmpty(props.inputID);

            ReactGA4.event({
              category: "Preview",
              action: "Image ",
              label:
                "filename: " +
                formData.file +
                " path " +
                formData.path +
                " ,base: " +
                props.selectedBase.title +
                " ,originalVersion: " +
                props.selectedVersion.title,
              dimension1: localStorage.getItem("user.id"),
            });
          });
        }
      })
      .catch(console.error);
  }
  //text change according to sliderValue
  function ChangeText(sliderValue) {
    let changedText = null;
    if (sliderValue == 0) {
      changedText = "Original";
    } else if (sliderValue < 3) {
      changedText = "Low";
    } else if (sliderValue === 3) {
      changedText = "Recommended";
    } else if (sliderValue < 7) {
      changedText = "Medium";
    } else {
      changedText = "High";
    }
    return changedText;
  }
  //Convert the base64-encoded image data to binary
  function webpToFile(webp) {
    const parts = webp.split(",");
    const mime = parts[0].split(":")[1].split(";")[0];
    const bstr = atob(parts[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return u8arr;
  }
  //Compress with sliderValue
  function CompressImage(newValue, file) {
    let mime = file.type;
    let sliderValue = newValue;
    const imgResized = new Image();
    let safariAgent = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    imgResized.src = URL.createObjectURL(file);
    // Image compression with sliderValue
    imgResized.onload = () => {
      const cnvs = document.createElement("canvas");
      cnvs.width = imgResized.width;
      cnvs.height = imgResized.height;
      const ctx = cnvs.getContext("2d");
      ctx.drawImage(imgResized, 0, 0, imgResized.width, imgResized.height);
      let webpFile = null;
      let webp = null;
      let calculation = 0;
      if (props.compressionRate === 0) {
        if (props.previousValue < sliderValue && props.previousValue > 0) {
          calculation = 1 - props.previousValue / 10;
        } else {
          calculation = 1 - sliderValue / 10;
        }
      } else {
        if (props.previousValue !== 0) {
          if (props.previousValue < sliderValue) {
            calculation = 1 - (1 - props.compressionRate + (props.compressionRate * props.previousValue) / 10);
          } else {
            calculation = 1 - (1 - props.compressionRate + (props.compressionRate * sliderValue) / 10);
          }
        } else {
          calculation = 1 - (1 - props.compressionRate + (props.compressionRate * sliderValue) / 10);
        }
      }
      if (safariAgent && file.type === "image/png") {
        webp = file;
        webpFile = webp;
      } else {
        if (newValue === 0) {
          webp = file;
          webpFile = file;
        } else {
          if (safariAgent) {
            webp = cnvs.toDataURL("image/jpeg", calculation);
          } else {
            webp = cnvs.toDataURL("image/webp", calculation);
          }
          webpFile = new File([webpToFile(webp)], file.type, { type: mime });
          if (props.previousValue > 0) {
            if (newValue > props.previousValue) {
              setValue(props.previousValue);
              props.createSnackbar("Image cannot be compressed any further.", "warning", false);
            }
          }
        }
      }

      let resizedSizeInMB = null;
      let valueToConsider = newValue;

      if (Math.floor(valueToConsider) === 0) {
        resizedSizeInMB = file.size / (1024 * 1024);

        setOptimizedSize(props.fileOrginalSize);
      } else {
        if (props.sliderValue == 0) {
          setOptimizedSize(`${(file.size / 1024).toFixed(2)}KB`);
        } else {
          if (props.sliderValue == 0) {
            setOptimizedSize(`${(file.size / 1024).toFixed(2)}KB`);
          } else if (resizedSizeInMB >= 1) {
            setOptimizedSize(`${resizedSizeInMB.toFixed(1)}MB`);
          } else {
            const fileSizeInKB = webpFile.size / 1024;
            setOptimizedSize(`${fileSizeInKB.toFixed(2)}KB`);
          }
        }
      }
      setResizedFile(webpFile);
      let compressedImage = new Image();
      //drawing to canvas image to imagePreviewComponent
      compressedImage.onload = () => {
        const newCanvas = document.createElement("canvas");
        const newCanvasCtx = newCanvas.getContext("2d");
        if (window.devicePixelRatio > 1) {
          newCanvas.width = 450 * window.devicePixelRatio;
          newCanvas.height = 450 * window.devicePixelRatio;
          newCanvas.style.width = "450px";
          newCanvas.style.height = "450px";
          newCanvasCtx.scale(window.devicePixelRatio, window.devicePixelRatio);
        } else {
          newCanvas.width = 450;
          newCanvas.height = 450;
        }
        const patternCanvas = createPatternCanvas();
        const pattern = newCanvasCtx.createPattern(patternCanvas, "repeat");
        newCanvasCtx.fillStyle = pattern;
        newCanvasCtx.fillRect(0, 0, 450, 450);
        const aspectRatio = imgResized.width / imgResized.height;
        if (imgResized.width < 450 && imgResized.height < 450) {
          newCanvasCtx.drawImage(
            compressedImage,
            (450 - imgResized.width) / 2,
            (450 - imgResized.height) / 2,

            imgResized.width,
            imgResized.height
          );
        } else {
          if (aspectRatio > 1) {
            const imgHeight = 450 / aspectRatio;

            newCanvasCtx.drawImage(compressedImage, 0, (450 - imgHeight) / 2, 450, 450 / aspectRatio);
          } else {
            const imgWidth = 450 * aspectRatio;
            newCanvasCtx.drawImage(compressedImage, (450 - imgWidth) / 2, 0, 450 * aspectRatio, 450);
          }
        }

        newCanvas.toBlob((blob) => {
          if (blob !== null) {
            setImageSrc(URL.createObjectURL(blob));
          }
        });
      };
      compressedImage.src = URL.createObjectURL(webpFile);
    };
  }
  const handleChange = (event, newValue) => {
    if (newValue > props.previousValue) {
      setValue(props.previousValue);
    }

    if (props.sliderValue === 0) {
      event.target.setAttribute("disabled", true);
    } else {
      event.target.removeAttribute("disabled");
      setValue(newValue);
    }
  };
  function handleKeyDown(event) {
    if (event.key === "ArrowUp" || event.key === "ArrowDown") {
      event.preventDefault();
    }
  }
  // save after drag on slider
  const handleSliderChange = (event, newValue) => {
    CompressImage(value, props.selectedFile);
  };
  const handleInputChange = (event) => {
    let inputValue = event.target.value;
    if (props.sliderValue == 0) {
      setValue(0);
    }
    if (event.type === "click") {
      CompressImage(0, props.selectedFile);

      setValue(inputValue);
    }

    if (inputValue < 0) {
      CompressImage(0, props.selectedFile);
      inputValue = 0;
    } else if (inputValue > 10) {
      inputValue = 10;
    }

    // Sadece sayısal değerleri kabul et
    if (/^\d*$/.test(inputValue)) {
      CompressImage(inputValue, props.selectedFile);
      setValue(inputValue);
    }
  };
  //Draws a checkerboard pattern on the background.
  const createPatternCanvas = () => {
    const patternCanvas = document.createElement("canvas");
    const patternCtx = patternCanvas.getContext("2d");
    const size = 8; // desen boyutu

    patternCanvas.width = size * 2;
    patternCanvas.height = size * 2;
    // Dama deseni oluşturuluyor
    patternCtx.fillStyle = "#bfbfbf";
    patternCtx.fillRect(0, 0, size, size);
    patternCtx.fillRect(size, size, size, size);
    patternCtx.fillStyle = "#fefefe";
    patternCtx.fillRect(size, 0, size, size);
    patternCtx.fillRect(0, size, size, size);

    return patternCanvas;
  };

  return (
    <div className={classes.container}>
      <div style={{ flex: "1", height: "100%" }}>
        <DialogTitle style={{ float: "left", position: "absolute", top: "0", marginTop: "55px", marginLeft: "-40px" }}>
          <Typography
            variant="h5"
            color={"primary"}
            style={{ textTransform: "uppercase", fontFamily: "DIN Alternate, sans-serif", fontSize: "2rem" }}
            component="div"
          >
            Image Optimizer
          </Typography>
        </DialogTitle>
        <div style={{ display: "flex", flexWrap: "wrap", marginLeft: "-15px" }}>
          <div style={{ marginTop: "10px", maxWidth: "300px" }}>
            <Box className={classes.Box}>
              <Typography style={{ marginTop: " 10px", marginBottom: "5px" }} className={classes.Text}>
                Compression
              </Typography>
              {/* style={{ marginBottom: "20px" }} */}
              <Grid container spacing={2} alignItems="center">
                <Grid item xs>
                  <PrettoSlider
                    // disabled={isDisabled}
                    id="compressSlider"
                    className={classes.Slider}
                    step={1}
                    min={0}
                    max={10}
                    value={value}
                    onChange={handleChange}
                    onChangeCommitted={handleSliderChange}
                    aria-labelledby="input-slider2"
                  />
                </Grid>
                <Grid item>
                  <Input
                    id="input"
                    value={value}
                    size="small"
                    onKeyDown={handleKeyDown}
                    InputProps={{
                      type: props.sliderValue === 0 ? "text" : "text",
                      readOnly: props.sliderValue === 0 ? true : false,
                    }}
                    // onFocus={handleInputFocus}
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>

              {props.sliderValue !== 0 ? (
                <div className={classes.leftAlignedDiv}>{ChangeText(value)}</div>
              ) : (
                <div className={classes.rightAlignedDiv}>{props.compressText}</div>
              )}
            </Box>
            <div id="fileInfo" style={{ width: "100%", marginBottom: "20px" }}>
              <div style={{ display: "flex", flexWrap: "noWrap" }}>
                <Typography className={classes.Text} style={{ width: "60%" }}>
                  Image Dimensions
                </Typography>
                <div
                  className={classes.Text}
                  style={{ width: "50%", color: props.circularProgress ? "#424242" : "#379499", textAlign: "right" }}
                >
                  {props.imageLength}
                </div>
              </div>
              <div style={{ display: "flex", flexWrap: "noWrap" }}>
                <Typography className={classes.Text} style={{ width: "100%" }}>
                  Original Size
                </Typography>
                <div
                  style={{ color: props.circularProgress ? "#424242" : "#379499", textAlign: "right", width: "50%" }}
                  className={classes.Text}
                >
                  {props.fileOrginalSize}
                </div>
              </div>
              <div style={{ display: "flex", flexWrap: "noWrap" }}>
                <Typography className={classes.Text} style={{ width: "100%" }}>
                  Opitimized Size
                </Typography>
                <div
                  style={{
                    width: "50%",
                    color: props.circularProgress ? "#424242" : "#4eabb1",
                    fontSize: "1.2rem",
                    marginTop: "8px",
                    textAlign: "right",
                    fontWeight: "900",
                  }}
                >
                  {fileOptimizedSize}
                </div>
              </div>
            </div>
            <Button
              color={"primary"}
              style={{ marginBottom: "-95px" }}
              className={classes.Button}
              variant="contained"
              onClick={() => ApplyToDB(resizedFile)}
            >
              <Typography variant="h4" style={{ color: "white", fontFamily: "DIN Alternate, sans-serif" }}>
                APPLY
              </Typography>
            </Button>
            <Button
              style={{
                fontFamily: "DIN Alternate, sans-serif",
                fontSize: "0.9rem" ,
                textDecorationLine: "underline",
                background: "none",
                marginBottom: "-200px",
                marginLeft: "75px",
                textAlign: "center"
              }}
              onClick={() => ApplyToDB(props.selectedFile)}
            >
              Skip Compress

            </Button>

          </div>
        </div>
      </div>

      <div
        id="imageSlider"
        style={{
          // flex: "1",
          height: "450px",
          width: "450px",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          marginRight: "-10px",
        }}
      >
        <canvas id="canvasID" ref={canvasRef} style={{ display: "none" }} />
        {props.circularProgress === true ? (
          // <CircularProgress className={classes.gamePreviewLoader} />
          <Typography variant="h4" style={{ color: "white", fontFamily: "DIN Alternate, sans-serif" }}>
            Image is loading...
          </Typography>
        ) : (
          <ImageSlider
            style={{
              width: "500px",
              height: "500px",
            }}
            image1={imageSrc} // canvas showed right side
            image2={props.originalImage}
            sliderWidth={3}
            image
            sliderColor="#51abb0"
            handleColor="#51abb0"
            handleBackgroundColor="white"
          />
        )}
      </div>

      {/* <div>Uyarı uyarııııı</div> */}
    </div>
  );
}
