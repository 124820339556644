import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Card, CardContent, Typography } from "@material-ui/core";
import Imgsettings from "../../../images/documentationImages/imgsettings.png";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    backgroundColor: "#404040",
  },
  content: {
    backgroundColor: "#404040",
    color: "white",
    align: "center",
    textAlign: "left",
    marginTop: "1%",
    marginLeft: "5%",
    marginRight: "20%",
  },
  card: {
    margin: "5%",
  },
  img: {
    borderRadius: "4px",
    border: "1px solid #727171",
    boxShadow: "rgb(0 0 0 / 20%) 1px 1px 10px 0px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px",
    width: "40%",
    height: "auto",
    marginBottom: "16px",
  },
  imgContainer: {
    display: "flex",
    justifyContent: "center",
  },
}));

const Settings = () => {
  const classes = useStyles();

  return (
    <div className={classes.root} id="Settings">
      <main className={classes.content}>
        <Card>
          <CardContent>
            <Typography variant="h5">Settings</Typography>
            <br />
            <Typography paragraph>
              Settings field has four sections. Version settings, localization settings, general settings, and recorder.
              With settings sections, the playables can be customized and adjusted.
            </Typography>
            <div className={classes.imgContainer}>
              <img className={classes.img} src={Imgsettings} alt="" />
            </div>
          </CardContent>
        </Card>
      </main>
    </div>
  );
};

export default Settings;
