import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { Card, CardContent, Typography, Button, MenuItem, Slider } from "@material-ui/core";
import EditorCaption from "./editorCaption";
import EditorWordManager from "./editorWordManager";
import SelectorFontFamily from "./selectorFontFamily";
import SliderGeneric from "./sliderGeneric";
import SelectorFontStyle from "./selectorFontStyle";
import SelectorColor from "./selectorColor";
import SwitchGeneric from "./switchGeneric";
import _ from "lodash";
import ArrowDropUpRounded from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownRounded from "@material-ui/icons/ArrowDropDown";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const useStyles = makeStyles({
  card: { margin: "5px" },
  imageSelector: { width: "100%" },
  imageSetSelector: { width: "100%" },
  cardOrderSelector: { width: "100%" },
  containerVersionSettingsDiv: {
    margin: "0px",
    height: "100%",
    overflowY: "auto",
  },
  showMoreButton: {
    float: "right",
    margin: "0.5rem 0rem 0.5rem 0rem",
    padding: "0.5rem 0.5rem 0.5rem 0.5rem",
    textTransform: "none",
    textDecorationLine: "underline",
  },
  textField: {
    width: "150%",
    // fontFamily: "monospace",
  },
});
const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: { main: "#4eabb1" },
  },
});

/**
 * @param props
 * @param props.classes
 * @param props.selectedBase
 * @param props.selectedBase.localizationSettings
 * @param props.selectedBase.localizationSettings.components
 * @param props.setSelectedVersionPathValue
 * @param props.selectedVersion
 * @returns {null}
 */
export default function containerLocalization(props) {
  console.debug(`Selected language during rendering of containerLocalization: ${props.selectedLanguage}`);

  const [components, setComponents] = useState(_.get(props, "selectedBase.localizationSettings.components"));
  const classes = useStyles();
  let [copyComponents, setCopyComponents] = useState(_.get(props, "selectedBase.localizationSettings.components"));
  const [filterComponentsArray, setFilterComponentsArray] = useState([]);
  const [lengthOfFilter, setLengthOfFilter] = useState(filterComponents().length);
  const [firstTime, setFirstTime] = useState(false);
  //const [showMoreBtn, setShowMoreBtn] = useState();

  useEffect(() => {
    if (!_.isNil(components)) {
      components.map((component) => {
        //TODO: Can be improved
        if (component.id === "cheerBoardSelector1") {
          const selectedCheerBoard = _.get(
            props,
            `selectedVersion.gameConfig.components.${component.id}.selectedCheerBoard`
          );
          if (selectedCheerBoard === "Ribbon Type 1") {
            _.set(component, "gameComponentId", "cheerBoard1");
          } else if (selectedCheerBoard === "Ribbon Type 2") {
            _.set(component, "gameComponentId", "cheerBoardWithTexture1");
          }
        }

        components[components.indexOf(component)].isShowMoreClicked = false;
      });
    }
  }, []);

  if (!_.has(props, `selectedBase.localizationSettings`)) {
    return null;
  }
  let returnedData = localStorage.getItem("newgetValLocal");
  let showMoreStatus = JSON.parse(localStorage.getItem("ShowMoreStatusSessionLocal"));
  let baseIDSession = JSON.parse(localStorage.getItem("selectedBaseSessionLocal"));
  if (baseIDSession === _.get(props, "selectedBase._id")) {
    if (!firstTime) {
      if (!_.isNil(returnedData)) {
        setFilterComponentsArray(JSON.parse(localStorage.getItem("newgetValLocal")));
        setNewComponents(JSON.parse(localStorage.getItem("newgetValLocal")));
      } else {
        setFilterComponentsArray([]);
        setNewComponents([]);
      }
      setFirstTime(true);
    }
  } else {
    localStorage.setItem("newgetValLocal", JSON.stringify([]));
  }
  function renderTooltip(tooltip, isVisible, marginLeft) {
    return isVisible && tooltip ? (
      <div>
        <Tooltip title={tooltip} aria-label="add">
          <HelpOutlineIcon style={{ marginLeft: marginLeft }} color={"primary"} />
        </Tooltip>
      </div>
    ) : null;
  }
  function filterComponents() {
    let componentArray = [];
    copyComponents.map((component) => {
      try {
        if (Array.isArray(component.tag)) {
          for (let i = 0; i < component.tag.length; i++) {
            //if (!componentArray.includes(component.tag[i])) {
            if (!componentArray.find((el) => el.title === component.tag[i])) {
              componentArray.push({
                "title": component.tag[i],
              });
            }
          }
        } else {
          if (typeof component.tag !== "undefined") {
            if (!componentArray.find((el) => el.title === component.tag)) {
              componentArray.push({
                "title": component.tag,
              });
            }
          }
        }
      } catch {
        console.log();
      }
    });

    return componentArray;
  }
  function getTextWidth(ttt) {
    let text = document.createElement("span");
    document.body.appendChild(text);

    (text.style.font = " Roboto"), "Helvetica", "Arial", "sans-serif";
    text.style.fontSize = "0.9285714285714286rem";
    text.style.height = "auto";
    text.style.width = "auto";
    text.style.letterSpacing = "0.00938em";
    text.style.position = "absolute";
    text.style.whiteSpace = "no-wrap";
    text.innerHTML = ttt;

    let width = Math.ceil(text.clientWidth);
    let formattedWidth = width + "px";

    document.body.removeChild(text);
    return width;
  }

  function checkIfComponentFiltered(component) {
    let checkBool = false;
    if (filterComponentsArray.length == 0) {
      checkBool = true;
    } else {
      checkBool = false;

      filterComponentsArray.map((fil) => {
        try {
          if (Array.isArray(component.tag)) {
            if (component.tag.includes(fil.title)) {
              checkBool = true;
            }
          } else {
            if (component.tag == fil.title) {
              checkBool = true;
            }
          }
        } catch {
          console.log("component caption not array");
        }
      });
    }

    return checkBool;
  }

  function setNewComponents(componentsList) {
    let newArray = [];
    let componentIDArray = [];
    let counter = 0;
    try {
      for (let j = 0; j < componentsList.length; j++) {
        for (let i = 0; i < components.length; i++) {
          try {
            if (Array.isArray(components[i].tag)) {
              //components[i].tag.find(el => el === componentsList[j].title)
              if (components[i].tag.includes(componentsList[j].title)) {
                if (componentIDArray.includes(components[i].id)) {
                  componentIDArray = componentIDArray.filter((a) => a != components[i].id);
                  newArray = newArray.filter((a) => a != components[i]);
                }
              } else {
                if (!componentIDArray.includes(components[i].id)) {
                  let checkIfComponentExist = false;
                  for (let k = 0; k < components[i].tag.length; k++) {
                    if (componentsList.find((el) => el.title === components[i].tag[k])) {
                      checkIfComponentExist = true;
                      break;
                    }
                  }
                  if (!checkIfComponentExist) {
                    if (!componentsList.find((el) => el.title === components[i].title)) {
                      counter++;

                      newArray.push(components[i]);
                      componentIDArray.push(components[i].id);
                    }
                  }
                }
              }
            } else {
              if (typeof component.tag !== "undefined") {
                if (components[i].tag == componentsList[j].title) {
                  if (componentIDArray.includes(components[i].id)) {
                    componentIDArray = componentIDArray.filter((a) => a != components[i].id);
                    newArray = newArray.filter((a) => a != components[i]);
                  }
                } else {
                  if (!componentIDArray.includes(components[i].id)) {
                    newArray.push(components[i]);
                    componentIDArray.push(components[i].id);
                  }
                  // newArray.push(copyComponents[i]);
                  // break;
                }
              }
            }
          } catch (e) {
            console.log("err", e);
          }
        }
      }

      if (lengthOfFilter == componentsList.length) {
        setCopyComponents([]);
      } else if (newArray.length == 0) {
        setCopyComponents(_.get(props, "selectedBase.localizationSettings.components"));
      } else {
        setCopyComponents(newArray);
      }
    } catch (e) {
      console.log("error", e);
    }
  }

  function setFilterValue() {
    //value={JSON.parse(localStorage.getItem("newgetVal")) != null ? JSON.parse(localStorage.getItem("newgetVal")) : []}
    let filterValue = [];
    if (baseIDSession != null) {
      if (baseIDSession === _.get(props, "selectedBase._id")) {
        if (JSON.parse(localStorage.getItem("newgetValLocal")) != null) {
          filterValue = JSON.parse(localStorage.getItem("newgetValLocal"));
        }
      }
    }
    return filterValue;
  }

  const editor = components.map(
    /**
     *
     * @param component
     * @param component.id
     * @returns {JSX.Element}
     */
    (component) => {
      {
        checkIfComponentFiltered(component) ? (component.vis = "") : (component.vis = "none");
      }

      return (
        <Card
          className={props.classes.card}
          style={{ display: component.vis }}
          key={`${component.id}-${component.caption.replace(/\s/g, "")}`}
        >
          <CardContent>
            <Typography key={`${component.id}_caption`} variant={"h6"} gutterBottom>
              {component.caption}
            </Typography>
            {_.get(component, "settings").length > 1 && (
              <Button
                startIcon={
                  components[components.indexOf(component)].isShowMoreClicked ? (
                    <ArrowDropUpRounded />
                  ) : (
                    <ArrowDropDownRounded />
                  )
                }
                color="secondary"
                className={classes.showMoreButton}
                style={{
                  marginTop: "-40px",
                }}
                onClick={() => {
                  const newComponents = _.cloneDeep(components);
                  // setShowMoreBtn(Math.floor(Math.random() * 1000))

                  newComponents[components.indexOf(component)].isShowMoreClicked =
                    !newComponents[components.indexOf(component)].isShowMoreClicked;
                  setComponents(newComponents);
                }}
              >
                {components[components.indexOf(component)].isShowMoreClicked ? "Hide" : "Show More"}
              </Button>
            )}

            {component.settings.map(
              /**
               * @param setting
               * @param setting.setting
               * @returns {JSX.Element}
               */
              (setting) => {
                //setting, caption, type

                const value = _.get(
                  props,
                  `selectedVersion.gameConfig.components.${component.gameComponentId}.localization.${props.selectedLanguage}.${setting.path}`,
                  _.get(
                    props,
                    `selectedVersion.gameConfig.components.${component.id}.localization.${props.selectedLanguage}.${setting.path}`
                  )
                );
                const path = _.has(component, "gameComponentId")
                  ? `gameConfig.components.${component.gameComponentId}.localization.${props.selectedLanguage}.${setting.path}`
                  : `gameConfig.components.${component.id}.localization.${props.selectedLanguage}.${setting.path}`;

                const setValue = (value) => {
                  console.log("debounce value");
                  let path;
                  if (_.has(component, "gameComponentId")) {
                    path = `gameConfig.components.${component.gameComponentId}.localization.${props.selectedLanguage}.${setting.path}`;
                  } else {
                    path = `gameConfig.components.${component.id}.localization.${props.selectedLanguage}.${setting.path}`;
                  }
                  props.setSelectedVersionPathValue({
                    path,
                    value,
                  });
                };

                const setValueSliderGeneric = (p) => {
                  props.setSelectedVersionPathValue(p);
                };
                const isVisible = component.isShowMoreClicked || component.settings.indexOf(setting) === 0;
                return (
                  <div key={`${component.id}.${setting.path}`}>
                    {setting.editorType === "caption" && (
                      <div key={`${component.id}.${setting.path}`}>
                        {renderTooltip(setting.tooltip, isVisible, getTextWidth(setting.caption) + 5)}
                        {console.log("setting.tooltip", setting.tooltip)}
                        <EditorCaption
                          id={`${component.id}.${setting.path}`}
                          key={`${component.id}.${setting.path}`}
                          classes={props.classes}
                          value={value}
                          tooltipLength={-24}
                          tooltip={setting.tooltip}
                          setValue={setValue}
                          label={setting.caption}
                          userPermissions={props.userPermissions}
                          createSnackbar={props.createSnackbar}
                          isVisible={isVisible}
                        ></EditorCaption>
                      </div>
                    )}
                    {setting.editorType === "editorTextArea" && (
                      <div key={`${component.id}.${setting.path}`}>
                        {renderTooltip(setting.tooltip, isVisible, getTextWidth(setting.caption) + 5)}
                        <EditorCaption
                          id={`${component.id}.${setting.path}`}
                          key={`${component.id}.${setting.path}`}
                          multiline={true}
                          rows={2}
                          rowsMax={10}
                          tooltip={setting.tooltip}
                          tooltipLength={-24}
                          classes={props.classes}
                          value={value}
                          setValue={setValue}
                          label={setting.caption}
                          userPermissions={props.userPermissions}
                          createSnackbar={props.createSnackbar}
                          isVisible={isVisible}
                        ></EditorCaption>
                      </div>
                    )}
                    {setting.editorType === "wordManager" && (
                      <div key={`${component.id}.${setting.path}`}>
                        {renderTooltip(setting.tooltip, isVisible, getTextWidth(setting.caption) + 5)}
                        <EditorWordManager
                          id={`${component.id}.${setting.path}`}
                          key={`${component.id}.${setting.path}`}
                          tooltip={setting.tooltip}
                          tooltipLength={-23}
                          classes={props.classes}
                          value={value}
                          setValue={setValue}
                          selectedGame={props.selectedGame}
                          selectedBase={props.selectedBase}
                          label={setting.caption}
                          seperator={setting.seperator || ""}
                          userPermissions={props.userPermissions}
                          createSnackbar={props.createSnackbar}
                          isVisible={isVisible}
                        ></EditorWordManager>
                      </div>
                    )}
                    {setting.editorType === "selectorFontFamily" && (
                      <div key={`${component.id}.${setting.path}`}>
                        {renderTooltip(setting.tooltip, isVisible, getTextWidth(setting.caption) + 5)}
                        <SelectorFontFamily
                          id={`${component.id}.${setting.path}`}
                          key={`${component.id}.${setting.path}`}
                          tooltip={setting.tooltip}
                          tooltipLength={-24}
                          classes={props.classes}
                          value={value}
                          setValue={setValue}
                          label={setting.caption}
                          editorSubType={setting.editorSubType}
                          selectedVersion={props.selectedVersion}
                          userPermissions={props.userPermissions}
                          createSnackbar={props.createSnackbar}
                          isVisible={isVisible}
                        ></SelectorFontFamily>
                      </div>
                    )}
                    {setting.editorType === "sliderGeneric" && (
                      <div key={`${component.id}.${setting.path}`}>
                        {renderTooltip(setting.tooltip, isVisible, getTextWidth(setting.caption) + 5)}
                        <SliderGeneric
                          id={`${component.id}.${setting.path}`}
                          key={`${component.id}.${setting.path}`}
                          classes={props.classes}
                          tooltip={setting.tooltip}
                          tooltipLength={-28}
                          editorSubType={setting.editorSubType}
                          value={value}
                          min={setting.min}
                          max={setting.max}
                          step={setting.step}
                          path={path}
                          setValue={(pathToSet, value) => {
                            setValueSliderGeneric({
                              path: pathToSet,
                              value,
                            });
                          }}
                          label={setting.caption}
                          userPermissions={props.userPermissions}
                          createSnackbar={props.createSnackbar}
                          isVisible={isVisible}
                        ></SliderGeneric>
                      </div>
                    )}
                    {setting.editorType === "selectorFontStyle" && (
                      <div key={`${component.id}.${setting.path}`}>
                        {renderTooltip(setting.tooltip, isVisible, getTextWidth(setting.caption) + 5)}
                        <SelectorFontStyle
                          id={`${component.id}.${setting.path}`}
                          key={`${component.id}.${setting.path}`}
                          classes={props.classes}
                          value={value}
                          tooltip={setting.tooltip}
                          tooltipLength={-26}
                          setValue={setValue}
                          label={setting.caption}
                          userPermissions={props.userPermissions}
                          createSnackbar={props.createSnackbar}
                          isVisible={isVisible}
                        ></SelectorFontStyle>
                      </div>
                    )}
                    {setting.editorType === "selectorColor" && (
                      <div>
                        {renderTooltip(setting.tooltip, isVisible, getTextWidth(setting.caption) + 5)}
                        <SelectorColor
                          id={`${component.id}.${setting.path}`}
                          key={`${component.id}.${setting.path}`}
                          classes={props.classes}
                          mode={"String"}
                          tooltip={setting.tooltip}
                          tooltipLength={-28}
                          value={value}
                          setValue={setValue}
                          label={setting.caption}
                          userPermissions={props.userPermissions}
                          createSnackbar={props.createSnackbar}
                          isVisible={isVisible}
                        ></SelectorColor>
                      </div>
                    )}

                    {setting.editorType === "switchGeneric" && (
                      <div>
                        {renderTooltip(setting.tooltip, isVisible, getTextWidth(setting.caption) + 5)}
                        <SwitchGeneric
                          id={`${component.id}.${setting.path}`}
                          key={`${component.id}.${setting.path}`}
                          classes={props.classes}
                          value={value}
                          tooltip={setting.tooltip}
                          tooltipLength={-22}
                          setValue={setValue}
                          label={setting.caption}
                          userPermissions={props.userPermissions}
                          createSnackbar={props.createSnackbar}
                          isVisible={isVisible}
                        ></SwitchGeneric>
                      </div>
                    )}
                  </div>
                );
              }
            )}
          </CardContent>
        </Card>
      );
    }
  );
  if (_.isNil(editor)) {
    return null;
  } else {
    return (
      <div>
        <ThemeProvider theme={darkTheme}>
          <Card className={props.classes.card} style={{ background: "#30302f" }}>
            <CardContent>
              <Autocomplete
                multiple
                //MuiFormLabel-root-MuiInputLabel-root.Mui-focused
                sx={{
                  "&.MuiInput-underline": {
                    color: "red !important",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#ffffff",
                    "& .Mui-focused": {
                      color: "#ffffff",
                    },
                  },

                  "& .MuiFormLabel-root ": {
                    color: "#ffffff !important",
                  },
                  "& .MuiCardContent-root": {
                    color: "#ffffff !important",
                    backgroundColor: "red",
                  },

                  "& .MuiTextField-root": {
                    color: "#ffffff",
                  },
                  "& .MuiInput-root": {
                    color: "#ffffff !important",
                  },
                  "& .MuiCardContent-root ": {
                    color: "#ffffff !important",
                  },

                  "& .MuiChip-label ": {
                    color: "#ffffff",
                  },
                }}
                id="tags-standard"
                options={filterComponents()}
                getOptionLabel={(option) => option.title}
                filterSelectedOptions
                onChange={(event, newValue) => {
                  if (firstTime) {
                    localStorage.setItem("newgetValLocal", JSON.stringify(newValue));

                    setFilterComponentsArray(newValue);
                    setNewComponents(newValue);
                    setFirstTime(false);

                    localStorage.setItem("selectedBaseSessionLocal", JSON.stringify(_.get(props, "selectedBase._id")));
                  } else {
                    setFirstTime(false);
                    setFilterComponentsArray(newValue);
                    localStorage.setItem("newgetValLocal", JSON.stringify(newValue));

                    setNewComponents(newValue);
                    localStorage.setItem("selectedBaseSessionLocal", JSON.stringify(_.get(props, "selectedBase._id")));
                  }
                }}
                value={setFilterValue()}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Filter Components"
                    style={{ placeholder: "white", label: "#ffffff" }}
                    placeholder="Components"
                  />
                )}
              />
            </CardContent>
          </Card>
        </ThemeProvider>
        {editor}
      </div>
    );
  }
}
