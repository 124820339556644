import React from "react";
import { Card, Dialog, DialogTitle, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { getServerUri } from "../../modules/uriUtility";
import _ from "lodash";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme) => ({
  textField: {
    width: "100%",
    padding: "0 0 1rem 0",
  },
  deleteUserDialogCard: { margin: "0px 10px 10px 10px", padding: 0, width: 300, backgroundColor: "#363636" },
  noButtonWrapper: {
    justifyContent: "flex-start",
    marginRight: "1rem",
  },
  noButton: {
    textAlign: "center",
  },
  yesButtonWrapper: {
    justifyContent: "flex-end",
    marginLeft: "1rem",
  },
  yesButton: {
    textAlign: "center",
  },
  deleteUserDialogWrapper: {
    padding: "0.5rem",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
}));

/**
 *
 * @param props.isOpen
 * @param props.onClose
 * @param props.user
 * @param props.setUsers
 * @returns {JSX.Element}
 */
export default function dialogDeleteUser(props) {
  const classes = useStyles();

  const findUserIdByName = function (userName) {
    return fetch(getServerUri("user/findOneByName"), {
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({
        accessToken: localStorage.getItem("session.accessToken"),
        username: userName,
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          response
            .text()
            .then((textResponse) => {
              console.log({ message: "error finding user", textResponse });
              props.createSnackbar("An error occurred when finding the user.", "error");
            })
            .catch(console.error);
        }
      })
      .catch((err) => {
        console.error(err);
        props.createSnackbar("No such user exists", "error");
      });
  };

  return (
    <Dialog
      open={props.isOpen}
      onClose={props.onClose}
      BackdropProps={{ style: { backgroundColor: "rgba(0,0,0,0.1)" } }}
    >
      <DialogTitle style={{ textAlign: "center" }}>Are you sure?</DialogTitle>
      <Divider />
      <div className={classes.deleteUserDialogWrapper}>
        <Card className={classes.noButtonWrapper}>
          <Button
            className={classes.noButton}
            label={"No"}
            onClick={() => {
              props.onClose();
            }}
          >
            No, keep it.
          </Button>
        </Card>
        <Card className={classes.yesButtonWrapper}>
          <Button
            className={classes.yesButton}
            label={"Yes"}
            onClick={() => {
              findUserIdByName(props.user.username).then((user) => {
                if (_.isNil(user)) {
                  console.log("No such user");
                  return;
                }
                fetch(getServerUri("user/delete"), {
                  method: "DELETE",
                  headers: { "content-type": "application/x-www-form-urlencoded" },
                  body: new URLSearchParams({
                    accessToken: localStorage.getItem("session.accessToken"),
                    userID: String(user._id),
                  }),
                })
                  .then((response) => {
                    if (response.status === 200) {
                      props.setUsers(props.users.filter((filteredUser) => filteredUser._id !== user._id));
                      console.log("user deleted");
                      props.createSnackbar("User deleted", "success");
                    } else {
                      response
                        .text()
                        .then((textResponse) => {
                          console.log({ message: "Error deleting user", textResponse });
                          props.createSnackbar("An error occurred when deleting the user", "error");
                        })
                        .catch(console.error);
                    }
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              });
              props.onClose();
            }}
          >
            Yes, delete!
          </Button>
        </Card>
      </div>
    </Dialog>
  );
}
