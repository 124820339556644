import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Card, CardContent, Typography } from "@material-ui/core";
import Language from "../../../images/documentationImages/language.png";
import Wordmanager from "../../../images/documentationImages/wordmanager.png";
import Font from "../../../images/documentationImages/font.png";
import Fontcolor from "../../../images/documentationImages/fontcolor.png";
import Stroke from "../../../images/documentationImages/stroke.png";
import Textorigin from "../../../images/documentationImages/textorigin.png";
import Shadow from "../../../images/documentationImages/shadow.png";
import Resetlocalization from "../../../images/documentationImages/resetlocalization.png";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    backgroundColor: "#404040",
  },
  content: {
    backgroundColor: "#404040",
    color: "white",
    align: "center",
    textAlign: "left",
    marginTop: "1%",
    marginLeft: "5%",
    marginRight: "20%",
  },
  card: {
    margin: "5%",
  },
  img: {
    borderRadius: "4px",
    border: "1px solid #727171",
    boxShadow: "rgb(0 0 0 / 20%) 1px 1px 10px 0px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px",
    width: "40%",
    height: "auto",
    marginBottom: "16px",
  },
  imgContainer: {
    display: "flex",
    justifyContent: "center",
  },
}));

const LocalizationSettings = () => {
  const classes = useStyles();

  return (
    <div className={classes.root} id="LocalizationSettings">
      <main className={classes.content}>
        <Card>
          <CardContent>
            <Typography variant="h5">Localization Settings</Typography>
            <br />
            <Typography paragraph>
              Under the localization settings, the changes for the versions’ language and texts can be done. All of the
              texts in the playable can be customized through here.
            </Typography>
            <Typography paragraph>
              At the top of the localization settings window, there is a language dropdown list. By clicking on it, the
              dropdown menu for the languages can be opened and selected from. When the language is changed, the
              selected language will open with default text settings. If that language has been already customized,
              those adjustments will be shown on the screen instead of default settings.
            </Typography>
            <div className={classes.imgContainer}>
              <img className={classes.img} src={Language} alt="" />
            </div>
            <Typography paragraph>
              For the word games, the playin dashboard offers word manager under the localization settings. Word list of
              the game can be adjusted and the word order can be changed with next and previous word buttons.
            </Typography>
            <div className={classes.imgContainer}>
              <img className={classes.img} src={Wordmanager} alt="" />
            </div>
            <Typography paragraph>
              If the playable has a banner, the banner text can be customized from here. Caption is what is shown on the
              banner. Font family can be picked from the dropdown menu through font family. Font size settings can be
              adjusted with a range bar. Starting from left to right, font size increases with every slide.
            </Typography>
            <div className={classes.imgContainer}>
              <img className={classes.img} src={Font} alt="" />
            </div>
            <Typography paragraph>
              Font color changes by clicking on the color and then using the color picker. Font style settings stands
              for the font weight, i.e. boldness of the text. From the dropdown menu, font weight can be chosen.
            </Typography>
            <div className={classes.imgContainer}>
              <img className={classes.img} src={Fontcolor} alt="" />
            </div>
            <Typography paragraph>
              Stroke means putting a frame around the letters. Stroke color may differ from the letter color to create
              striking text, if needed. Stroke thickness can be adjusted with the range bar. Starting from left to
              right, thickness increases with every slide.
            </Typography>
            <div className={classes.imgContainer}>
              <img className={classes.img} src={Stroke} alt="" />
            </div>
            <Typography paragraph>
              Text positions can be adjusted with Text Origin settings. X stands for horizontal position and Y stands
              for vertical position. To position the text, the range bar can be used similar to the CTA Buttons.
            </Typography>
            <div className={classes.imgContainer}>
              <img className={classes.img} src={Textorigin} alt="" />
            </div>
            <Typography paragraph>
              Adding shadow to the text is an option that can be enabled through the toggle button. Shadows’ position
              can be adjusted from the shadow offset range bar. Shadow color can be changed from the color option.
            </Typography>
            <div className={classes.imgContainer}>
              <img className={classes.img} src={Shadow} alt="" />
            </div>
            <Typography paragraph>
              Similar to the version settings, localization settings have a reset button. By clicking the reset
              localization settings button, all of the text changes can be reverted to default.
            </Typography>
            <div className={classes.imgContainer}>
              <img className={classes.img} src={Resetlocalization} alt="" />
            </div>
          </CardContent>
        </Card>
      </main>
    </div>
  );
};

export default LocalizationSettings;
