import React, { useEffect, useState } from "react";
import { Dialog, Button, Typography, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import categoryIconGreen from "../../../images/galleryImages/category-icon-green.svg";
import categoryIconRed from "../../../images/galleryImages/category-icon-red.svg";
import image1 from "../../../images/galleryImages/atlasGameImage.png";
import video from "./../../../images/galleryImages/wow-gallery.mp4";
import { Close } from "@material-ui/icons";
import _ from "lodash-core";
import { getServerUri } from "../../modules/uriUtility";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  headerWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    width: "50%",
    margin: "2rem 0 0 3rem",
  },
  headerText: {
    textAlign: "center",
    fontSize: "1.7vw",
    fontWeight: "bold",
    marginLeft: "1rem",
  },
  textField: {
    width: "100%",
    padding: "0 0 1rem 0",
  },
  contentWrapper: {
    display: "flex",
    justifyContent: "center",
    marginTop: "2rem",
    marginBottom: "1.4rem",
  },
  contentText: {
    fontSize: "1.0vw",
    fontWeight: "300",
    lineHeight: "1.5vw",
    margin: "0 3rem 1.6rem 3rem",
  },
  closeButton: {
    margin: "1rem",
    padding: "1rem",
  },
  closeButtonWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    width: "50%",
  },
  selectPlayableWrapper: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "1.5rem",
  },
  selectPlayableButton: {
    padding: "0.6rem 1.2rem",
  },
}));

/**
 *
 * @param props.isOpen
 * @param props.onClose
 * @param props.setIsOpen
 * @param props.selectedPlayable
 * @param props.selectedGame
 * @param props.setSelectedGame
 * @param props.selectedVersion
 * @param props.versions
 * @param props.setVersions
 * @param props.userPermissions
 * @returns {JSX.Element}
 */
export default function dialogPlayableSelection(props) {
  const classes = useStyles();

  return (
    <Dialog
      id="dialogPlayableSelection_dialog"
      maxWidth={"md"}
      fullWidth={true}
      open={props.isOpen}
      onClose={props.onClose}
    >
      <div id="dialogPlayableSelection_dialog1" className={classes.root}>
        <div id="dialogPlayableSelection_dialog2" className={classes.headerWrapper}>
          <img
            id="dialogPlayableSelection_dialog_img"
            src={
              !_.isNil(props.selectedPlayable.isApp) && props.selectedPlayable.isApp
                ? categoryIconRed
                : categoryIconGreen
            }
            width={28}
            height={42}
          ></img>
          <Typography className={classes.headerText}>{props.selectedPlayable.title}</Typography>
        </div>
        <div id="dialogPlayableSelection_dialogBtn" className={classes.closeButtonWrapper}>
          <IconButton
            id="dialogPlayableSelection_dialogButton"
            component="span"
            className={classes.closeButton}
            onClick={() => props.setIsOpen(false)}
          >
            <Close />
          </IconButton>
        </div>
      </div>
      <div>
        <div id="dialogPlayableSelection_dialog3" className={classes.contentWrapper}>
          <img
            id="dialogPlayableSelection_dialog_img2"
            src={image1}
            width="200"
            height="362"
            style={{ margin: "0 1rem 0 1rem" }}
          ></img>
          <video
            id="dialogPlayableSelection_dialog_video"
            width="200"
            height="362"
            controls
            style={{ margin: "0 1rem 0 1rem" }}
          >
            <source src={video} type="video/mp4" />
          </video>
          <video
            id="dialogPlayableSelection_dialog_video2"
            width="200"
            height="362"
            controls
            style={{ margin: "0 1rem 0 1rem" }}
          >
            <source src={video} type="video/mp4" />
          </video>
        </div>
        <Typography className={classes.contentText}>
          EndCard is designed to be used after any video ad. You can design any product you want with static images or
          animations and download it as .html and use it in your ads.
        </Typography>
        <div id="dialogPlayableSelection_dialog_btnDiv" className={classes.selectPlayableWrapper}>
          <Button
            id="dialogPlayableSelection_dialog_btn"
            className={classes.selectPlayableButton}
            disabled={_.get(props, "selectedGame._id", "") === _.get(props, "selectedPlayable._id")}
            color="secondary"
            variant="contained"
            label={"Details"}
            onClick={() => {
              if (!_.isNil(props.selectedVersion) && props.userPermissions["update"]) {
                const createdLanguages = _.get(props, "selectedVersion.createdLanguages") ? _.get(props, "selectedVersion.createdLanguages"): ["en"];

                fetch(getServerUri("version/updateOne"), {
                  method: "POST",
                  headers: { "content-type": "application/x-www-form-urlencoded" },
                  body: new URLSearchParams({
                    accessToken: localStorage.getItem("session.accessToken"),
                    versionID: String(_.get(props, "selectedVersion._id")),
                    gameConfig: JSON.stringify(_.get(props, "selectedVersion.gameConfig")),
                    recorderSettings: JSON.stringify(_.get(props, "selectedVersion.recorderSettings")),
                    createdLanguages: JSON.stringify(createdLanguages)

                    //isBeingUpdated: String(false)
                  }),
                })
                  .then((response) => {
                    if (response.status === 200) {
                      console.log({ message: "version updated" });
                      const newVersion = _.cloneDeep(props.selectedVersion);
                      //_.set(newVersion, 'isBeingUpdated', false);
                      const newVersions = _.cloneDeep(props.versions);
                      for (let i = 0; i < newVersions.length; i++)
                        if (newVersions[i]._id === props.selectedVersion._id) {
                          newVersions[i] = newVersion;
                        }
                      props.setVersions(newVersions);
                      props.setSelectedGame(props.selectedPlayable);
                    } else if (response.status === 401) {
                      //props.createSnackbar("You are not authorized to update, contact your administrator.", "warning");
                      response
                        .text()
                        .then((textResponse) => {
                          console.log({ message: "error updating", textResponse });
                        })
                        .catch(console.error);
                      props.setSelectedGame(props.selectedPlayable);
                    } else {
                      props.setSelectedGame(props.selectedPlayable);
                    }
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              } else {
                props.setSelectedGame(props.selectedPlayable);
              }
            }}
          >
            SELECT PLAYABLE
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
