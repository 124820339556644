import React, { useEffect, useState } from "react";
import { Card, CardContent, Dialog, DialogTitle, Button, Typography, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { getServerUri } from "../../modules/uriUtility";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  textField: {
    width: "100%",
    padding: "0 0 1rem 0",
  },
  changePasswordDialogCard: { margin: "0px 10px 10px 10px", padding: 0, width: 300, backgroundColor: "#363636" },
  okButtonWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  okButton: {
    textAlign: "center",
  },
}));

/**
 *
 * @param props.isOpen
 * @param props.onClose
 * @returns {JSX.Element}
 */
export default function dialogChangePassword(props) {
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [error, setError] = useState("Password is too short");
  //    const [isDialogOpen, setIsDialogOpen] = useState(props.isDialogOpen);

  const classes = useStyles();

  useEffect(() => {
    matchPasswords();
  }, []);

  // useEffect(() => {
  //     setIsDialogOpen(props.isDialogOpen);
  // },[props.isDialogOpen]);

  useEffect(() => {
    matchPasswords();
  }, [newPassword]);

  useEffect(() => {
    matchPasswords();
  }, [confirmNewPassword]);

  const matchPasswords = function () {
    if (newPassword.length < 8) {
      setError("Password is too short");
      return false;
    }
    if (newPassword.length > 40) {
      setError("Password is too long");
      return false;
    }
    if (newPassword !== confirmNewPassword) {
      setError("Passwords do not match");
      return false;
    }
    setError("Passwords match");
    return true;
  };

  return (
    <Dialog open={props.isOpen} onClose={props.onClose}>
      <DialogTitle style={{ textAlign: "center" }}>Change Password</DialogTitle>
      <Card className={classes.changePasswordDialogCard}>
        <CardContent style={{ padding: 10 }}>
          <TextField
            type="password"
            className={classes.textField}
            rowsMax={1}
            label={"New password"}
            value={newPassword}
            onChange={(event) => {
              setNewPassword(event.target.value);
            }}
          ></TextField>
          <TextField
            type="password"
            className={classes.textField}
            rowsMax={1}
            label={"Confirm new password"}
            value={confirmNewPassword}
            onChange={(event) => {
              setConfirmNewPassword(event.target.value);
            }}
          ></TextField>
          <div className={classes.okButtonWrapper}>
            <Button
              className={classes.okButton}
              label={"Ok"}
              disabled={error !== "Passwords match"}
              onClick={() => {
                fetch(getServerUri("user/updateOne"), {
                  method: "POST",
                  headers: { "content-type": "application/x-www-form-urlencoded" },
                  body: new URLSearchParams({
                    accessToken: localStorage.getItem("session.accessToken"),
                    userID: localStorage.getItem("user.id"),
                    password: String(newPassword),
                  }),
                })
                  .then((response) => {
                    if (response.status === 200) {
                      console.log({ message: "password updated" });
                      props.createSnackbar("Password Updated", "success");
                    } else {
                      response
                        .text()
                        .then((textResponse) => {
                          console.log({ message: "error", textResponse });
                          props.createSnackbar("An error occurred", "error");
                        })
                        .catch(console.error);
                    }
                  })
                  .catch((err) => {
                    console.error(err);
                  });
                props.onClose();
              }}
            >
              OK
            </Button>
          </div>
        </CardContent>
      </Card>
      {error === "Passwords match" ? (
        <Typography style={{ textAlign: "center", paddingBottom: "0.5rem", fontSize: 12, color: "#7fa54a" }}>
          {error}
        </Typography>
      ) : (
        <Typography style={{ textAlign: "center", paddingBottom: "0.5rem", fontSize: 12, color: "#E57373" }}>
          {error}
        </Typography>
      )}
    </Dialog>
  );
}
