import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Card, CardContent, Typography } from "@material-ui/core";
import Picker from "../../../images/documentationImages/picker.png";
import Color from "../../../images/documentationImages/color.png";
import Level from "../../../images/documentationImages/level.png";
import Level2 from "../../../images/documentationImages/level2.png";
import Image from "../../../images/documentationImages/image.png";
import Cta from "../../../images/documentationImages/CTA.png";
import Hand from "../../../images/documentationImages/hand.png";
import Selectimg from "../../../images/documentationImages/selectimg.png";
import Resetversion from "../../../images/documentationImages/resetversion.png";
import PlayableSize from "../../../images/documentationImages/playableSize.png";
import filterComponent from "../../../images/documentationImages/filterComponent.png";
import imageCompressD2 from "../../../images/documentationImages/imageCompressD2.png";
import imageCompressD4 from "../../../images/documentationImages/imageCompressD4.png";
import imageCompressD3 from "../../../images/documentationImages/imageCompressD3.png";
import imageCompressD5 from "../../../images/documentationImages/imageCompressD5.png";
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: `calc(100% - ${drawerWidth}px)`,
    backgroundColor: "#404040",
    marginLeft: drawerWidth,
  },
  content: {
    backgroundColor: "#404040",
    color: "white",
    align: "center",
    textAlign: "left",
    marginTop: "1%",
    marginLeft: "5%",
    marginRight: "20%",
  },
  card: {
    margin: "5%",
  },
  img: {
    borderRadius: "4px",
    border: "1px solid #727171",
    boxShadow: "rgb(0 0 0 / 20%) 1px 1px 10px 0px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px",
    width: "40%",
    height: "auto",
    marginBottom: "16px",
  },
  imgContainer: {
    display: "flex",
    justifyContent: "center",
  },
}));

const VersionSettings = () => {
  const classes = useStyles();

  return (
    <div className={classes.root} id="VersionSettings">
      <main className={classes.content}>
        <Card>
          <CardContent>
            <Typography variant="h5">Version Settings</Typography>
            <br />
            <Typography paragraph>
              Under the version settings, the game levels, style and playable aspects can be changed. These settings may
              differ from game to game. For example; some games have an option for uploading a new background, while
              others may only have an option to select background color. This depends on the game itself and the clients
              needs.
            </Typography>
            <Typography paragraph>
              At the top of components, estimated size of a playable is shown. Details of components’ sizes can be seen
              by clicking on the details button.
            </Typography>
            <div className={classes.imgContainer}>
              <img className={classes.img} src={PlayableSize} alt="" />
            </div>
            <Typography paragraph>
              Under playable size, any component can be filtered through Filter Components section.
            </Typography>
            <Typography paragraph>
              Filtering options may include “tutorial”, “in game”, “end card” or “revive” as well as customized options
              like “enemy”, “hero”.{" "}
            </Typography>
            <Typography paragraph>
              While creating playables, filter components section can be used in order to update components effectively.
            </Typography>
            <Typography paragraph>Filter components apply the same for localization settings too.</Typography>
            <div className={classes.imgContainer}>
              <img className={classes.img} src={filterComponent} alt="" />
            </div>

            <div className={classes.imgContainer}>
              <img className={classes.img} src={Picker} alt="" />
            </div>
            <Typography paragraph>
              For color specific settings; clicking the color picker will open up the color palette. The wanted color
              can be chosen directly from the palette or RGB (Red - Green - Blue) can be adjusted for that purpose.
            </Typography>
            <div className={classes.imgContainer}>
              <img className={classes.img} src={Color} alt="" />
            </div>
            <Typography paragraph>
              For some settings, there are dropdown menus. By clicking the option, the dropdown list will show the other
              options on the screen. Arrow buttons allow entering hex codes for required color.
            </Typography>
            <div className={classes.imgContainer}>
              <img className={classes.img} src={Level} alt="" />
            </div>
            <div className={classes.imgContainer}>
              <img className={classes.img} src={Level2} alt="" />
            </div>
            <Typography paragraph>
              When there is a plus button, new images can be uploaded for that setting. For example; in the background
              setting, there is an option for upload images in addition to a dropdown list that shows the uploaded
              background images. While uploading images, it should be remembered that the images’ size shouldn’t exceed
              1 MB.
            </Typography>
            <div className={classes.imgContainer}>
              <img className={classes.img} src={Image} alt="" />
            </div>
            <Typography paragraph>
              There are some version settings that can be encountered in almost every game. One of these settings is CTA
              Button (a.k.a. Click to Action Button). CTA Button usually works as a store link in the ad. When clicked
              on, it opens the game’s store page. With a plus icon, new buttons can be added to the dropdown list
              similar to the background. Unlike the background images, file size for the CTA buttons' should not exceed
              200 kbs. It’s because some ad publishing sites limit the size of an ad file, e.g. Google.
            </Typography>
            <Typography paragraph>
              CTA Button can be adjusted in scale and position. Scale and positions have options for both the landscape
              and portrait orientation. Thereby playables can be customized for both orientations. In positioning, X
              stands for the horizontal position of the object and Y stands for the vertical position of the object. The
              adjustments for scale and positions can be done by the range bar. For scale; from left to right the range
              bar increases the size of the object. For horizontal positioning; the range bar can be slid to the left
              for positioning objects to the left or to the right for otherwise. For vertical positioning; the range bar
              can be slid to the left for positioning objects to the top and to the right for otherwise.
            </Typography>
            <div className={classes.imgContainer}>
              <img className={classes.img} src={Cta} alt="" />
            </div>
            <Typography paragraph>
              In some cases, two images need to be uploaded together for one specific setting. Tutorial Hand is one of
              those cases.
            </Typography>
            <div className={classes.imgContainer}>
              <img className={classes.img} src={Hand} alt="" />
            </div>
            <Typography paragraph>
              The Tutorial Hand usually has two objects. One is normal and the other is glow. The glow hand appears as a
              click animation. Normal and glow hand doesn’t have to be different. Same image can be uploaded for both
              normal and glow in addition to uploading different images for each. Uploaded images for the tutorial hands
              can be chosen as a pair from the dropdown menu.
            </Typography>
            <div className={classes.imgContainer}>
              <img className={classes.img} src={Selectimg} alt="" />
            </div>
            <Typography paragraph>
              Images can be compressed via the image optimizer which is available in components containing the image
              upload. Clicking the <strong>&quot;+&quot;</strong>/ plus icon will open the image optimizer on the
              screen. The supported images that are being picked can be seen in files. After selecting an image, image
              optimizer will run. On this page it will allow to images can be compressed at any rate as well as at low,
              high, and recommended rates.
            </Typography>
            <div className={classes.imgContainer}>
              <img className={classes.img} src={imageCompressD2} alt="" />
            </div>
            <Typography paragraph>
              {" "}
              The supported images that are being picked can be seen in files. After selecting an image, image optimizer
              will run. On this page it will allow to images can be compressed at any rate as well as at low, high, and
              recommended rates.
            </Typography>
            <div className={classes.imgContainer}>
              <img className={classes.img} src={imageCompressD4} alt="" />
            </div>
            <Typography paragraph>
              It can be seen the effects of the compression ratio selected in the preview window on photo, and it can be
              compared with the scrollable preview bar. Moreover, it allows us to see the original size of the image and
              the size after compression.
            </Typography>
            <div className={classes.imgContainer}>
              <img className={classes.img} src={imageCompressD3} alt="" />
            </div>
            <Typography paragraph>
              When the compression ratio of the desired quality is determined; just press the{" "}
              <strong>&quot;apply&quot;</strong> button below. Your compressed photo has been uploaded.
            </Typography>
            <div className={classes.imgContainer}>
              <img className={classes.img} src={imageCompressD5} alt="" />
            </div>
            <Typography paragraph>
              The last button under the Version Settings is the reset button. By clicking the reset version settings
              button, the changes that have been done under the version settings can be reverted to the default.
            </Typography>
            <div className={classes.imgContainer}>
              <img className={classes.img} src={Resetversion} alt="" />
            </div>
          </CardContent>
        </Card>
      </main>
    </div>
  );
};

export default VersionSettings;
