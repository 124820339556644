import React, { useState, useEffect } from "react";
import Slide from "@material-ui/core/Slide";
import { useSnackbar } from "notistack";
import { FlashOnOutlined } from "@material-ui/icons";

export const UndoContext = React.createContext(null);

export const UndoContextProvider = ({ children }) => {
  const [undoStack, setUndoStack] = useState([]);
  const [cursor, setCursor] = useState(0);
  const [buttonUndoColor, setButtonUndoColor] = useState("#EEEEEE");
  const [buttonRedoColor, setButtonRedoColor] = useState("#FF0000");

  const { enqueueSnackbar } = useSnackbar();
  function createSnackbar(message, variant, isPersist) {
    let vertical = "bottom";
    let horizontal = "center";

    let opts = {
      key: !isPersist ? "sameKey" : makeid(10),
      variant,
      anchorOrigin: {
        vertical,
        horizontal,
      },
      preventDuplicate: true,
      autoHideDuration: 1000,
      TransitionComponent: Slide,
      persist: isPersist,
    };
    return enqueueSnackbar(message, opts);
  }

  const pushUndoStack = (gameConfig) => {
    setButtonUndoColor("#EEEEEE");
    if (cursor === 0 || undoStack.length > cursor) {
      setButtonRedoColor("#545454");
    }
    let setArray = [...undoStack.slice(0, cursor), gameConfig];
    // setUndoStack(undoStack => [...undoStack.slice(0, cursor), gameConfig])
    if (setArray.length > 50) {
      setArray = setArray.slice(1, setArray.length);
    }
    setCursor((prev) => Math.min(prev + 1, setArray.length));
    setUndoStack(setArray);
  };

  const applyUndo = (currentGameConfig, configType) => {
    // type == 1 game, 0 recorder
    if (cursor < 1) {
      createSnackbar("Nothing To Undo", "warning");
      return;
    }
    if (cursor == 1) {
      setButtonUndoColor("#545454");
      setButtonRedoColor("#EEEEEE");
    }
    const gameConfig = undoStack[cursor - 1];
    if (cursor === undoStack.length) {
      setButtonRedoColor("#EEEEEE");
      if (configType) {
        setUndoStack((prev) => [...prev, currentGameConfig]);
      } else {
        setUndoStack((prev) => [...prev, JSON.stringify(currentGameConfig)]);
      }
    }
    setCursor((prev) => prev - 1);
    return gameConfig;
  };

  const applyRedo = () => {
    if (cursor === undoStack.length) {
      setButtonRedoColor("#545454");
      createSnackbar("Nothing To Redo", "warning");

      return;
    }
    if (cursor === 0 || undoStack.length > cursor) {
      setButtonRedoColor("#545454");
      setButtonUndoColor("#EEEEEE");
    }
    const nextCursor = cursor + 1;
    setCursor(nextCursor);
    if (nextCursor < undoStack.length - 1) {
      const gameConfig = undoStack[nextCursor];
      setButtonRedoColor("#EEEEEE");
      setButtonUndoColor("#EEEEEE");
      return gameConfig;
    }

    const lastGameConfig = undoStack[undoStack.length - 1];
    setUndoStack(undoStack.slice(0, undoStack.length - 1));
    return lastGameConfig;
  };

  const clearUndoStack = () => {
    setUndoStack([]);
    setCursor(0);
  };
  const clearButtons = () => {
    if (undoStack.length !== 0) {
      return "flex";
    } else {
      setButtonRedoColor("#545454");
      setButtonUndoColor("#EEEEEE");
      return "none";
    }
  };
  return (
    <UndoContext.Provider
      value={{
        undoStack,
        cursor,
        setCursor,
        pushUndoStack,
        setUndoStack,
        applyUndo,
        applyRedo,
        clearUndoStack,
        buttonUndoColor,
        buttonRedoColor,
        clearButtons,
      }}
    >
      {children}
    </UndoContext.Provider>
  );
};
