import React from "react";
import Popover from "@material-ui/core/Popover";
import { AddRounded } from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import CreateNewFolderIcon from "@material-ui/icons/CreateNewFolder";
import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, IconButton } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  renameButton: {
    textTransform: "none",
  },
  editIcon: {
    margin: "0.1rem",
  },
  CreateNewFolderIcon: {
    margin: "0.1rem",
  },
  addFolder: {
    textTransform: "none",
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
  },
}));

export default function versionStorageOptions(props) {
  let [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div id="iconButton">
      <IconButton id="versionStorageOptions_button" component="span" onClick={handleClick}>
        <AddRounded
          aria-owns={open ? "simple-popper" : undefined}
          aria-haspopup="true"
          variant="contained"
        ></AddRounded>
      </IconButton>

      <Popover
        id="simplePopper_versionStorage"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div>
          <Button
            id="insertVersion_button"
            className={classes.addFolder}
            style={{
              width: "150px",
              justifyContent: "flex-start",
            }}
            startIcon={<EditIcon className={classes.editIcon} />}
            onClick={() => {
              if (props.userPermissions["insert"] === false) {
                props.createSnackbar("You are not authorized to insert, contact your administrator.", "warning", false);
                return;
              }
              if (props.userPermissions["update"] === false) {
                props.createSnackbar("You are not authorized to update, contact your administrator.", "warning", false);
                return;
              }
              props.setIsInsertVersionDialogOpen(true);
              setAnchorEl(false);
            }}
          >
            Insert Version
          </Button>
          <Button
            id="createFolder_button"
            className={classes.renameButton}
            startIcon={<CreateNewFolderIcon className={classes.CreateNewFolderIcon} />}
            style={{
              width: "150px",
              justifyContent: "flex-start",
            }}
            onClick={() => {
              if (props.userPermissions["insert"] === false) {
                props.createSnackbar("You are not authorized to insert, contact your administrator.", "warning", false);
                return;
              }
              if (props.userPermissions["update"] === false) {
                props.createSnackbar("You are not authorized to update, contact your administrator.", "warning", false);
                return;
              }

              props.setIsFolderVersionDialogOpen(true);
              setAnchorEl(false);
            }}
          >
            Create Folder
          </Button>
        </div>
      </Popover>
    </div>
  );
}
