import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { DateRange } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: "2rem",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    flexDirection: "row",
  },
  select: {},
  dateIcon: {
    marginTop: "0.2rem",
    marginRight: "0.2rem",
  },
}));

export default function selectorPeriod(props) {
  const [period, setPeriod] = useState(3);

  const classes = useStyles();

  const handleChange = (event) => {
    setPeriod(event.target.value);
    props.setPeriod(event.target.value);
  };

  return (
    <div className={classes.root}>
      <FormControl className={classes.formControl}>
        <DateRange className={classes.dateIcon} />
        <Select
          className={classes.select}
          native
          value={period}
          onChange={handleChange}
          inputProps={{
            name: "period",
            id: "period-native-simple",
          }}
        >
          <option value="" />
          <option value={1}> Last Month</option>
          <option value={3}> Last 3 Months</option>
          <option value={6}> Last 6 Months</option>
          <option value={12}> Last 1 Year</option>
          <option value={24}> Last 2 Years</option>
          <option value={60}> Last 5 Years</option>
        </Select>
      </FormControl>
    </div>
  );
}
