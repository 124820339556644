import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Card, CardContent, Typography } from "@material-ui/core";
import List from "../../../images/documentationImages/list.png";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    backgroundColor: "#404040",
  },
  content: {
    backgroundColor: "#404040",
    align: "center",
    textAlign: "left",
    marginTop: "1%",
    marginLeft: "5%",
    marginRight: "20%",
    color: "white",
  },
  card: {
    margin: "5%",
  },
  img: {
    borderRadius: "4px",
    border: "1px solid #727171",
    boxShadow: "rgb(0 0 0 / 20%) 1px 1px 10px 0px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px",
    width: "40%",
    height: "auto",
    marginBottom: "16px",
  },
  imgContainer: {
    display: "flex",
    justifyContent: "center",
  },
}));

const GameVersionList = () => {
  const classes = useStyles();

  return (
    <div className={classes.root} id="GameVersionList">
      <main className={classes.content}>
        <Card>
          <CardContent>
            <Typography variant="h5">Game and Version List</Typography>
            <br />
            <Typography paragraph>
              Under the account information button, the game list comes. All of the games our clients have will be shown
              at this section. Swapping the games can easily be done by clicking on the game icons.
            </Typography>
            <Typography paragraph>
              Version list comes right next to the game list. All of the created versions can be seen here. By clicking
              the version name, version swapping can be done.
            </Typography>
            <Typography paragraph>
              Above the version list, the name of the game will be shown at top. Under it, there is an option to choose
              different game bases. In case our clients may need different types of playables for the same game, the
              dashboard offers a base pool for this purpose. Instead of creating the same game again, a new base is
              being created under the same game section. This way while the original game base is stored, the new bases
              can be added. The bases can be chosen from the dropdown menu under the game title and can be swapped
              easily.
            </Typography>
            <div className={classes.imgContainer}>
              <img className={classes.img} src={List} alt="" />
            </div>
          </CardContent>
        </Card>
      </main>
    </div>
  );
};

export default GameVersionList;
