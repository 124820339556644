import React, { useEffect, useState } from "react";
import { MenuItem, TextField } from "@material-ui/core";

export default function selectorAnimation(props) {
  if (_.isNil(props.value)) {
    return null;
  }
  let [value, setValue] = useState(props.value || 1);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  function gettingMargin(numberMargin) {
    if (props.tooltip != undefined) {
      if (numberMargin) {
        return numberMargin.toString() + "px";
      }
    } else return (-20).toString() + "px";
  }
  useEffect(() => {
    checkMargin();
  }, [props.isVisible]);

  function checkMargin() {
    try {
      if (props.tooltip) {
        document.getElementById(`${props.id}.textField-label`).style.marginTop = "-48.5px";
      }
    } catch {
      console.log("checkMargin error");
    }
  }
  return (
    <>
      {" "}
      {props.isVisible && (
        <div className={props.classes.editorItem}>
          <TextField
            select
            label={_.get(props, `label`)}
            value={value}
            InputLabelProps={{ shrink: false, style: { marginTop: gettingMargin(props.tooltipLength) } }}
            className={props.classes.textField}
            onChange={(event) => {
              if (props.userPermissions["update"] === false) {
                props.createSnackbar(
                  "You do not have permission to update, contact your administrator.",
                  "warning",
                  false
                );
                return;
              }
              setValue(event.target.value);
              props.setValue(event.target.value);
            }}
          >
            <MenuItem key={"1"} value={"sliding"}>
              Sliding
            </MenuItem>
            <MenuItem key={"2"} value={"tapping"}>
              Tapping
            </MenuItem>
            <MenuItem key={"3"} value={"circle"}>
              Circle
            </MenuItem>
          </TextField>
        </div>
      )}
    </>
  );
}
