import { Dialog, DialogTitle, DialogContent, TextField, Button, DialogActions } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Save, WrapText, CancelRounded } from "@material-ui/icons";
import _ from "lodash-core";
import { IconButton } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    overflow: "auto",
    margin: "1rem 1rem 1rem 1rem",
  },
  containerDialog: {
    width: "30rem",
    height: "42rem",
    overflowX: "scroll",
  },
  textCenter: {
    textAlign: "left",
  },
  textField: {
    width: "150%",
    fontFamily: "monospace",
  },
  dialog: {
    marginTop: "4rem",
    display: "flex",
    justifyContent: "flex-end",
  },
  saveButton: {
    width: "30%",
  },
  cancelButton: {
    padding: "0rem 1rem 0rem 1rem",
  },
  arrangeButton: {
    width: "30%",
  },
  dialogActions: {
    padding: "1.8rem",
    justifyContent: "space-between",
  },
  titleWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

export default function dialogTextEdit(props) {
  const [value, setValue] = useState(_.get(props, `value`, ""));
  const [isOpen, setIsOpen] = useState(_.get(props, `open`, ""));
  const classes = useStyles();
  const [initalString, setInitialString] = useState(_.get(props, `value`, ""));

  useEffect(() => {
    props.setValue(value);
  }, [value]);

  function handleCloseCancel() {
    props.setValue(initalString);
    setIsOpen(false);
    props.onClose();
  }

  function handleCloseSave() {
    setIsOpen(false);
    props.onClose();
  }

  function arrangeMapString() {
    let newValue = value;

    while (newValue.includes("\n ") || newValue.includes(" \n")) {
      newValue = newValue.replaceAll("\n ", "\n");
      newValue = newValue.replaceAll(" \n", "\n");
    }

    newValue = newValue.replaceAll("|", "|\n").replaceAll("\n\n", "\n");

    while (newValue.includes("\n ") || newValue.includes(" \n")) {
      newValue = newValue.replaceAll("\n ", "\n");
      newValue = newValue.replaceAll(" \n", "\n");
    }
    setValue(newValue);
  }

  return (
    <div className={classes.wrapper}>
      <Dialog
        open={isOpen}
        onClose={handleCloseCancel}
        className={classes.dialog}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <div className={classes.titleWrapper}>
          <DialogTitle id="mapEditTitle" className={classes.textCenter}>
            Edit Map
          </DialogTitle>
          <IconButton color="secondary" className={classes.cancelButton} component="span" onClick={handleCloseCancel}>
            <CancelRounded />
          </IconButton>
        </div>
        <DialogContent dividers={true}>
          <div className={classes.containerDialog}>
            <div className={classes.textCenter}>
              <TextField
                multiline
                rows={2}
                rowsMax={Infinity}
                className={classes.textField}
                style={{ width: "calc(100% - 5px)" }}
                label={props.label}
                value={value}
                onChange={(event) => {
                  setValue(event.target.value);
                }}
                InputProps={{
                  className: classes.textField,
                }}
              ></TextField>
            </div>
          </div>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            color="secondary"
            className={classes.arrangeButton}
            variant="contained"
            startIcon={<WrapText />}
            onClick={arrangeMapString}
          >
            Arrange
          </Button>
          <Button
            color="secondary"
            className={classes.saveButton}
            variant="contained"
            startIcon={<Save />}
            onClick={handleCloseSave}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
