import React, { useRef, useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { Dialog, DialogTitle } from "@material-ui/core";

import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  timerWrapper: {
    position: "relative",
  },
  timeWrapper: {
    position: "relative",
    width: "200px",
    height: "150px",
    fontSize: "48px",
    fontFamily: "Montserrat",
  },
  timeWrapperTime: {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transform: "translateY(0)",
    opacity: 1,
    transition: "all 0.2s",
  },
  timeUp: {
    opacity: 0,
    transform: "translateY(-100%)",
  },
  timeDown: {
    opacity: 0,
    transform: "translateY(100%)",
  },
  textCenter: { textAlign: "center" },
  backdrop: {
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 150000,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backdropFilter: "blur(7px) brightness(30%)",
  },
}));

export default function dialogCountdown(props) {
  const [isCountdownDialogOpen, setIsCountdownDialogOpen] = useState(true);
  const classes = useStyles();

  function renderTime({ remainingTime }) {
    const currentTime = useRef(remainingTime);
    const prevTime = useRef(null);
    const isNewTimeFirstTick = useRef(false);

    if (currentTime.current !== remainingTime) {
      isNewTimeFirstTick.current = true;
      prevTime.current = currentTime.current;
      currentTime.current = remainingTime;
    } else {
      isNewTimeFirstTick.current = false;
    }

    // force one last re-render when the time is over to tirgger the last animation
    if (remainingTime === 0) {
      setIsCountdownDialogOpen(false);
      props.setIsRecordingStarted(true);
    }

    const isTimeUp = isNewTimeFirstTick.current;

    return (
      <div className={classes.timeWrapper}>
        <div
          key={remainingTime}
          className={classes.timeWrapperTime}
          style={{ opacity: isTimeUp && 0, transform: isTimeUp && "translateY(-100%)" }}
        >
          {remainingTime}
        </div>
        {prevTime.current !== null && (
          <div
            key={prevTime.current}
            className={classes.timeWrapperTime}
            style={{ opacity: !isTimeUp && 0, transform: !isTimeUp && "translateY(100%)" }}
          >
            {prevTime.current}
          </div>
        )}
      </div>
    );
  }

  return isCountdownDialogOpen ? (
    <div
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        zIndex: 150000,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backdropFilter: "blur(5px) brightness(30%)",
      }}
    >
      <div className={classes.timerWrapper}>
        <CountdownCircleTimer
          isPlaying
          duration={5}
          size={350}
          strokeWidth={12}
          trailStrokeWidth={12}
          colors={[["#216576", 0.33], ["#4eabb1", 0.33], ["#9bcec0"]]}
        >
          {renderTime}
        </CountdownCircleTimer>
      </div>
    </div>
  ) : (
    ""
  );
}
