import React from "react";
import { Button, Dialog, DialogTitle } from "@material-ui/core";
import { getServerUri } from "../modules/uriUtility";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";

const useClasses = makeStyles({
  dialogAtlas: {
    margin: 10,
  },
});
/**
 *
 * @param {Boolean} props.isOpen is it open?
 * @param {Function} props.setIsOpen set is open to true or false
 * @param props.animationSetToDeleteID
 * @param props.atlases
 * @param props.setAtlases
 * @param props.createSnackbar
 * @param props.selectAtlas
 * @param props.deleteAnimationSetFromAtlases
 * @returns {JSX.Element}
 */
export default function dialogAtlasDelete(props) {
  const classes = useClasses();
  return (
    <Dialog
      open={props.isOpen}
      onClose={() => {
        props.setIsOpen(false);
      }}
    >
      <DialogTitle>Delete atlas?</DialogTitle>
      <div className={classes.dialogAtlas}>
        <Button
          label={"OK"}
          onClick={(event) => {
            fetch(getServerUri("atlas/deleteAnimationSet"), {
              method: "DELETE",
              headers: {
                "content-type": "application/x-www-form-urlencoded",
              },
              body: new URLSearchParams({
                accessToken: localStorage.getItem("session.accessToken"),
                animationSetID: _.get(props, "animationSetToDeleteID", ""),
              }),
            })
              .then((response) => {
                if (response.status === 200) {
                  const newAtlases = props.atlases.filter(
                    (atlas) => atlas.animationSet !== props.animationSetToDeleteID
                  );
                  props.deleteAnimationSetFromAtlases(props.animationSetToDeleteID);
                  props.setAtlases(newAtlases);
                  props.createSnackbar("Atlas deleted", "success");
                  props.setIsOpen(false);
                } else {
                  response
                    .text()
                    .then((responseText) => {
                      console.log(responseText);
                    })
                    .catch(console.error);
                }
              })
              .catch((err) => {
                console.log("fetch error");
                console.error(err);
                props.createSnackbar("An error occurred deleting the atlas.", "error");
              });
            props.setIsOpen(false);
          }}
        >
          OK
        </Button>
        <Button
          label={"Cancel"}
          onClick={() => {
            props.setIsOpen(false);
          }}
        >
          Cancel
        </Button>
      </div>
    </Dialog>
  );
}
