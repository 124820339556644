import { Slider, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";

export default function sliderOrigin(props) {
  if (_.isNil(props.value)) {
    return null;
  }

  const [x, setX] = useState(_.get(props, "value.x", 0.5));
  const [y, setY] = useState(_.get(props, "value.y", 0.5));

  useEffect(() => {
    setX(_.get(props, "value.x", 0.5));
    setY(_.get(props, "value.y", 0.5));
  }, [props.value]);
  function gettingMargin(numberMargin) {
    if (props.tooltip != undefined) {
      if (numberMargin) {
        return numberMargin.toString() + "px";
      }
    } else return (0).toString() + "px";
  }

  return (
    <>
      {" "}
      {props.isVisible && (
        <div id={"sliderOriginDiv"} className={props.classes.editorItem}>
          <Typography id="discrete-slider" gutterBottom>
            {_.get(props, "label")}
          </Typography>
          <Typography id="labelOriginX" gutterBottom>
            {"X"}
          </Typography>
          <Slider
            onKeyDown={(event) => {
              event.stopPropagation();
            }}
            style={{ width: "100%" }}
            value={x}
            aria-labelledby="labelOriginX"
            valueLabelDisplay="auto"
            step={0.01}
            min={0}
            max={1}
            onChange={(event, newValue) => {
              if (props.userPermissions["update"] === false) {
                props.createSnackbar(
                  "You do not have permission to update, contact your administrator.",
                  "warning",
                  false
                );
                return;
              }
              setX(newValue);
              props.setValue({ x: newValue, y });
            }}
          />
          <Typography id="labelOriginY" gutterBottom>
            {"Y"}
          </Typography>
          <Slider
            style={{ width: "100%" }}
            value={y}
            aria-labelledby="labelOriginY"
            valueLabelDisplay="auto"
            step={0.01}
            min={0}
            max={1}
            onChange={(event, newValue) => {
              if (props.userPermissions["update"] === false) {
                props.createSnackbar(
                  "You do not have permission to update, contact your administrator.",
                  "warning",
                  false
                );
                return;
              }
              setY(newValue);
              props.setValue({ x, y: newValue });
            }}
          />
        </div>
      )}
    </>
  );
}
