import React from "react";
import { useState, useEffect } from "react";
import Drawer from "../components/documentationComponents/Navbar";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import Title from "../components/documentationComponents/Title";
import Overview from "../components/documentationComponents/Overview";
import LoginAccount from "../components/documentationComponents/LoginAccount";
import GameVersionList from "../components/documentationComponents/GameVersionList";
import NewVersion from "../components/documentationComponents/NewVersion";
import FolderandVersionFeatures from "../components/documentationComponents/FolderandVersionFeatures";

import Preview from "../components/documentationComponents/Preview";
import Settings from "../components/documentationComponents/Settings";
import VersionSettings from "../components/documentationComponents/VersionSettings";
import LocalizationSettings from "../components/documentationComponents/LocalizationSettings";
import GeneralSettings from "../components/documentationComponents/GeneralSetting";
import Recorder from "../components/documentationComponents/Recorder";
import Download from "../components/documentationComponents/Download";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    backgroundColor: "#404040",
    top: "46px",
  },
  closeButton: {
    position: "fixed",
    left: "95%",
  },
}));

export default function Documentation(props) {
  const classes = useStyles();
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    if (props.isDocumentationOpen) {
      toggleDrawer(props.isDocumentationOpen);
    }
  }, [props.isDocumentationOpen]);

  const toggleDrawer = (isOpen) => {
    setOpen(isOpen);
    props.setDocumentationOpen(isOpen);
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "bottom",
      })}
      role="presentation"
      onKeyDown={() => toggleDrawer(false)}
    >
      <CssBaseline />
      <Drawer />
      <Title />
      <Overview />
      <LoginAccount />
      <GameVersionList />
      <NewVersion />
      <FolderandVersionFeatures />
      <Preview />
      <Settings />
      <VersionSettings />
      <LocalizationSettings />
      <GeneralSettings />
      <Download />
      <Recorder />
    </div>
  );

  return (
    <div>
      {
        <React.Fragment key={"bottom"}>
          <SwipeableDrawer
            anchor={"bottom"}
            open={isOpen}
            onClose={() => toggleDrawer(false)}
            onOpen={() => toggleDrawer(true)}
            classes={{ paper: classes.paper }}
          >
            <div className={classes.closeButton}>
              <IconButton edge="start" color="inherit" onClick={() => toggleDrawer(false)}>
                <Close />
              </IconButton>
            </div>
            {list("bottom")}
          </SwipeableDrawer>
        </React.Fragment>
      }
    </div>
  );
}
