import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

const drawerWidth = 240;
const headerHeight = 46;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: "#404040",
  },
  drawerPaper: {
    top: "inherit",
    width: "240px",
    backgroundColor: "#404040",
    color: "white",
  },
}));

export default function PermanentDrawerLeft() {
  const classes = useStyles();
  var elements = document.getElementsByTagName("a");
  function a() {
    return false;
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
      >
        <Divider />
        <List>
          <ListItem button component="a" href="#Dashboard" onClick={a}>
            <ListItemText primary="Playin Dashboard"></ListItemText>
          </ListItem>
        </List>
        <Divider />
        {/* <List>
          <ListItem button component="a" href="#GetStarted" >
              <ListItemText primary="Get Started"></ListItemText>
          </ListItem>
        </List>
        <Divider /> */}
        <List>
          <ListItem button component="a" href="#Overview" onClick={a}>
            <ListItemText primary="Overview"></ListItemText>
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem button component="a" href="#LoginAccInfo" onClick={a}>
            <ListItemText primary="Login and Account Information"></ListItemText>
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem button component="a" href="#GameVersionList">
            <ListItemText primary="Game and Version List"></ListItemText>
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem button component="a" href="#NewVersion" onClick={a}>
            <ListItemText primary="Creating a New Version"></ListItemText>
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem button component="a" href="#FolderandVersionFeatures" onClick={a}>
            <ListItemText primary="Folder and Version Features"></ListItemText>
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem button component="a" href="#PreviewGame">
            <ListItemText primary="Preview Game"></ListItemText>
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem button component="a" href="#Settings">
            <ListItemText primary="Settings"></ListItemText>
          </ListItem>
        </List>
        <Divider />
        {/* <List>
          <ListItem button component="a" href="#PlayableSettings" >
              <ListItemText primary="Playable Settings"></ListItemText>
          </ListItem>
        </List>
        <Divider /> */}
        <List>
          <ListItem button component="a" href="#VersionSettings">
            <ListItemText primary="Version Settings"></ListItemText>
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem button component="a" href="#LocalizationSettings">
            <ListItemText primary="Localization Settings"></ListItemText>
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem button component="a" href="#GeneralSettings">
            <ListItemText primary="General Settings"></ListItemText>
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem button component="a" href="#Download">
            <ListItemText primary="Download Playable"></ListItemText>
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem button component="a" href="#Recorder">
            <ListItemText primary="Recorder"></ListItemText>
          </ListItem>
        </List>
        <Divider />
      </Drawer>
    </div>
  );
}
