import React, { useEffect, useState } from "react";
import { TextField, Typography } from "@material-ui/core";

/**
 *
 * @param props
 * @param props.value
 * @param props.classes
 * @param props.setValue
 * @returns {JSX.Element}
 */
export default function editorCaption(props) {
  let [value, setValue] = useState(_.get(props, "value", ""));
  useEffect(() => {
    setValue(_.get(props, "value", ""));
  }, [props.value]);
  function gettingMargin(numberMargin) {
    if (props.tooltip != undefined) {
      if (numberMargin) {
        return numberMargin.toString() + "px";
      }
    } else return (0).toString() + "px";
  }
  return (
    <>
      {" "}
      {props.isVisible && (
        <div className={props.classes.editorItem}>
          <Typography
            style={{ marginTop: gettingMargin(props.tooltipLength), fontSize: "0.75em" }}
            id="discrete-slider"
            gutterBottom
          >
            {_.get(props, "label")}
          </Typography>
          <TextField
            onKeyDown={(event) => {
              event.stopPropagation();
            }}
            id={`${props.id}.textField`}
            className={props.classes.textField}
            // label={_.get(props, `label`)}
            value={value}
            multiline={props.multiline ? true : false}
            rows={props.rows ? props.rows : 1}
            rowsMax={props.rowsMax ? props.rowsMax : 1}
            onChange={(event) => {
              if (props.userPermissions["update"] === false) {
                props.createSnackbar(
                  "You do not have permission to update, contact your administrator.",
                  "warning",
                  false
                );
                return;
              }
              setValue(event.target.value);
              // props.delayedSetValue(event.target.value,200);

              props.setValue(event.target.value);
            }}
          ></TextField>
        </div>
      )}
    </>
  );
}
