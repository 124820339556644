import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Divider, Card, CardContent, Typography, IconButton, Chip } from "@material-ui/core";
import landscapehide from "../../../images/documentationImages/landscapehide.png";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "80px",
  },
  imgContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    borderRadius: "4px",
    border: "1px solid #727171",
    boxShadow: "rgb(0 0 0 / 20%) 1px 1px 10px 0px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px",
  },
  img: {
    borderRadius: "4px",
    width: "100%",
    height: "auto",
  },
  main: {
    align: "center",
    textAlign: "left",
  },
  chip: {
    float: "left",
    marginRight: "90%",
    marginBottom: "5%",
  },
  context: {
    textAlign: "justify",
  },
}));

const FolderCreation = () => {
  const classes = useStyles();

  return (
    <div className={classes.main}>
      <Card className={classes.container}>
        <CardContent>
          <div className={classes.imgContainer}>
            <img src={landscapehide} className={classes.img} alt="" />
            <Divider />
          </div>
          <br />
          <Typography variant="h6">
            Visibility feature of settings according to screen orientation is live !{" "}
          </Typography>
          <Typography paragraph>02.11.2022</Typography>
          <Chip className={classes.chip} label="Standard" variant="outlined" />
          <Typography paragraph className={classes.context}>
            While changing orientation settings of your creatives, sometimes landscape and portrait sliders can be
            confusing seeing altogether under a component. To prevent this mislead, creative dashboard now reveals the
            settings according to preview screen orientation. No worries if you’re changing the wrong orientation
            setting, if preview is on portrait mode, only portrait settings will be revealed and vice verse.
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
};

export default FolderCreation;
