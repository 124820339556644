import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Typography } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import _ from "lodash";
import DialogChangePassword from "./dialogChangePassword";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    marginLeft: "21.5rem",
    paddingBottom: "1rem",
    backgroundColor: "#404040",
  },
  content: {
    flexGrow: 1,
    backgroundColor: "#404040",
    color: "white",
    align: "center",
    textAlign: "center",
    marginTop: "0.5%",
    marginRight: "1rem",
  },
  accountInfoWrapper: {
    padding: "2rem 2rem 2rem 2rem",
  },
  accountInfo: {
    display: "flex",
    justifyContent: "flex-start",
    margin: "2rem 0rem 2rem 0rem",
  },
  card: {
    margin: "0rem 0rem 0.5rem 0.5rem",
  },
  titles: {
    width: "33%",
    float: "left",
    margin: "0rem 0rem 0rem 0rem",
  },
  titleText: {
    margin: "0rem 0rem 0.5rem 0rem",
  },
  cardTitleText: {
    fontSize: 26,
    fontWeight: 540,
  },
}));

export default function accountInformation(props) {
  const [isChangePasswordDialogOn, setIsChangePasswordDialogOn] = useState(false);

  const classes = useStyles();

  return (
    <div className={classes.root} id="AccountInformation">
      <main className={classes.content}>
        <Card className={classes.card}>
          <div className={classes.accountInfoWrapper}>
            <Typography className={classes.cardTitleText} align="left">
              Account Info
            </Typography>
            <div className={classes.accountInfo}>
              <div className={classes.titles}>
                <Typography className={classes.titleText} variant="h6" align="left">
                  Company
                </Typography>
                <Typography align="left" gutterBottom>
                  {_.get(props, "userCompany.name")}
                </Typography>
              </div>
              <div className={classes.titles}>
                <Typography className={classes.titleText} variant="h6" align="left">
                  Username
                </Typography>
                <Typography align="left" gutterBottom>
                  {_.get(props, "userName")}
                </Typography>
                <Button
                  color={"primary"}
                  style={{
                    margin: "0 0 1rem 0",
                    padding: "0",
                    float: "left",
                    textTransform: "none",
                    textDecorationLine: "underline",
                  }}
                  onClick={() => {
                    setIsChangePasswordDialogOn(true);
                  }}
                >
                  Change Password
                </Button>
                <DialogChangePassword
                  isOpen={isChangePasswordDialogOn}
                  onClose={() => {
                    setIsChangePasswordDialogOn(false);
                  }}
                  createSnackbar={props.createSnackbar}
                ></DialogChangePassword>
              </div>
              <div className={classes.titles}>
                <Typography className={classes.titleText} variant="h6" align="left">
                  Current Plan
                </Typography>
                <Typography align="left" gutterBottom>
                  {_.get(props, "userCompany.subscription.currentPlan")}
                </Typography>
              </div>
            </div>
          </div>
        </Card>
        <Card className={classes.card}>
          <div className={classes.accountInfoWrapper}>
            <Typography className={classes.cardTitleText} align="left">
              Playables
            </Typography>
            <div className={classes.accountInfo}>
              <div className={classes.titles}>
                <Typography className={classes.titleText} variant="h6" align="left">
                  Active Playable Count
                </Typography>
                <Typography align="left" gutterBottom>
                  {_.get(props, "numberOfBases")}
                </Typography>
              </div>
              <div className={classes.titles}>
                <Typography className={classes.titleText} variant="h6" align="left">
                  Monthly Downloads
                </Typography>
                <Typography align="left" gutterBottom>
                  {
                    _.get(props, "userCompany.subscription.periods")[props.userCompany.subscription.periods.length - 1]
                      .monthlyCreatedPlayables
                  }
                </Typography>
              </div>
              <div className={classes.titles}>
                <Typography className={classes.titleText} variant="h6" align="left">
                  Monthly Download Limit
                </Typography>
                <Typography align="left" gutterBottom>
                  {_.get(
                    props.plans[props.getPlanIndex(_.get(props, "userCompany.subscription.currentPlan"))],
                    "numberOfPlayables"
                  )}
                </Typography>
              </div>
            </div>
          </div>
        </Card>
        <Card className={classes.card}>
          <div className={classes.accountInfoWrapper}>
            <Typography className={classes.cardTitleText} align="left">
              Videos
            </Typography>
            <div className={classes.accountInfo}>
              <div className={classes.titles}>
                <Typography className={classes.titleText} variant="h6" align="left">
                  Monthly Downloads
                </Typography>
                <Typography align="left" gutterBottom>
                  {
                    _.get(props, "userCompany.subscription.periods")[props.userCompany.subscription.periods.length - 1]
                      .monthlyCreatedVideos
                  }
                </Typography>
              </div>
              <div className={classes.titles}>
                <Typography className={classes.titleText} variant="h6" align="left">
                  Monthly Download Limit
                </Typography>
                <Typography align="left" gutterBottom>
                  {_.get(
                    props.plans[props.getPlanIndex(_.get(props, "userCompany.subscription.currentPlan"))],
                    "numberOfVideos"
                  )}
                </Typography>
              </div>
            </div>
          </div>
        </Card>
      </main>
    </div>
  );
}
