import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar } from "@material-ui/core";
import galleryLogo from "../../../images/game/gallery-button@3x.png";
import PlayableGalleryDrawer from "./playableGalleryDrawer";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  gamePickerDiv: { display: "flex", flexDirection: "column", justifyContent: "flex-start", flex: 1 },
}));

/**
 *
 * @param props.items {Array}
 * @param props.setSelectedItem {Function}
 * @param props.setSelectedGame {Function}
 * @param props.galleryGames
 * @param props.selectedGame
 * @param props.classes
 * @param props.createSnackbar
 * @param props.selectedVersion
 * @param props.versions
 * @param props.setVersions
 * @param props.userPermissions
 * @returns {JSX.Element}
 * @constructor
 */
export default function playableGallery(props) {
  const [isPlayableGalleryDrawerOpen, setIsPlayableGalleryDrawerOpen] = useState(false);
  const classes = useStyles();

  return (
    <div id="playableGallery" className={classes.gamePickerDiv}>
      <div
        id="playableGallery_selected"
        style={{
          backgroundColor:
            !_.isNil(_.get(props, "selectedGame.isInPlayableGallery")) && props.selectedGame.isInPlayableGallery
              ? "hsla(128,0%,50%,0.5)"
              : "hsla(128,0%,50%,0)",
        }}
      >
        <Avatar
          id="playableGallery_avatar"
          className={props.classes.avatarSmall}
          src={galleryLogo}
          title={"Playable Gallery"}
          onClick={(event) => {
            if (_.get(props, "galleryGames", []).length === 0) {
              props.createSnackbar("Request access to gallery games from your administrator", "warning", false);
            } else {
              setIsPlayableGalleryDrawerOpen(true);
            }
          }}
        />
      </div>
      <PlayableGalleryDrawer
        isOpen={isPlayableGalleryDrawerOpen}
        setIsOpen={setIsPlayableGalleryDrawerOpen}
        galleryGames={props.galleryGames}
        selectedGame={props.selectedGame}
        setSelectedGame={props.setSelectedGame}
        selectedVersion={props.selectedVersion}
        versions={props.versions}
        setVersions={props.setVersions}
        userPermissions={props.userPermissions}
      />
    </div>
  );
}
