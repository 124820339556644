import React from "react";
import fugoLogo from "../../images/company/fugoLogo.png";
import playinLogo from "../../images/company/playinLogo.png";
import goodjobgames from "../../images/company/goodjobgamesLogo.png";
import { config } from "../config/config";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar } from "@material-ui/core";
import { fetchCompanyLogo } from "../modules/accountLogos";
const useStyles = makeStyles({
  centerContent: { display: "flex", justifyContent: "center", alignItems: "center" },
});

/**
 *
 * @param props.classes
 * @param props.classes.avatarSmall
 * @param props.onClick
 * @returns {JSX.Element}
 */
export default function imageAccountItem(props) {
  //const image = _.get(logos, localStorage.getItem("user.companyID"));
  const image = fetchCompanyLogo(localStorage.getItem("user.companyID"));
  const classes = useStyles();
  if (!image)
    return (
      <div>
        <Avatar id="gameAvatar1" className={props.classes.avatarSmall}></Avatar>
        {JSON.stringify(fetchCompanyLogo)}
      </div>
    );
  return (
    <div className={classes.centerContent}>
      <Avatar
        id="gameAvatar2"
        className={props.classes.avatarSmall}
        src={image}
        onClick={props.onClick}
        title={"Account Information"}
      ></Avatar>
    </div>
  );
}
