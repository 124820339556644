import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Divider, Card, CardContent, Typography, IconButton, Chip } from "@material-ui/core";
import versionPathImage from "../../../images/documentationImages/versionPath.jpeg";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "80px",
  },
  imgContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    borderRadius: "4px",
    border: "1px solid #727171",
    boxShadow: "rgb(0 0 0 / 20%) 1px 1px 10px 0px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px",
  },
  img: {
    borderRadius: "4px",
    width: "100%",
    height: "auto",
  },
  main: {
    align: "center",
    textAlign: "left",
  },
  chip: {
    float: "left",
    marginRight: "90%",
    marginBottom: "5%",
  },
  context: {
    textAlign: "justify",
  },
}));

const VersionPath = () => {
  const classes = useStyles();

  return (
    <div className={classes.main}>
      <Card className={classes.container}>
        <CardContent>
          <div className={classes.imgContainer}>
            <img src={versionPathImage} className={classes.img} alt="" />
            <Divider />
          </div>
          <br />
          <Typography variant="h6">A guide that provides a clear path !</Typography>
          <Typography paragraph>28.07.2023</Typography>
          <Chip className={classes.chip} label="Standard" variant="outlined" />
          <Typography paragraph className={classes.context}>
            This feature is designed to assist you in locating specific items within numerous versions and nested
            folders, including versions contained within these folders. While you edit your folders or versions, this
            path will conveniently display your current location, allowing you to easily keep track of where you are."
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
};

export default VersionPath;
