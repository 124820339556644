import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import { getServerUri } from "../modules/uriUtility";
import { makeStyles } from "@material-ui/core/styles";
import { useDragOver } from "@minoru/react-dnd-treeview";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import Tooltip from "@mui/material/Tooltip";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { CustomTypeIcon } from "./customTypeIcon";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import _ from "lodash";
const useStyles = makeStyles({
  nodeRow: {
    "&:hover": {
      background: "rgba(79, 79, 79, 255)",
    },
  },
  a: {
    display: "inline-flex",
    width: "180px",
    // background: 'rgba(52, 52, 52, 0.8)',
  },
  b: {
    display: "inline-flex",
    justifyContent: "flex-start",
    height: "100%",
    width: "100%",
    // background: 'rgba(52, 52, 52, 0.8)',
    //marginLeft: "5px",
  },
  title: {
    padding: "10px",
    //width: {(150 -  props => props.depth * 24).toString() + "px"},
    //width: (props) => props.calculatedIndent,
    //width: (1 * 24).toString() + "px",
    //paddingLeft: "8px"
  },
  titleVersion: {
    paddingLeft: "12px",
    overflow: "hidden",
    //width: {(150 -  props => props.depth * 24).toString() + "px"},
    //width: (props) => props.calculatedIndent,
    //width: (1 * 24).toString() + "px",
    //paddingLeft: "8px"
  },
  createdDate: {
    position: "absolute",
    right: "100px",
    width: "160px",
    overflow: "hidden",
    paddingTop: "10.5px",
    paddingLeft: "2px",
  },
  updatedDate: {
    position: "absolute",
    right: "-95px",
    width: "160px",
    overflow: "hidden",
    paddingTop: "10.5px",
    //paddingLeft: "2px"
  },
  createdDateFolder: {
    position: "absolute",
    right: "127px",
    width: "160px",
    paddingTop: "10.5px",
    // paddingLeft: "17px"
  },
  updatedDateFolder: {
    position: "absolute",
    right: "-62px",
    width: "160px",
    paddingTop: "10.5px",
    paddingLeft: "4px",
  },
});

export const CustomNode = (props) => {
  const { id, droppable, data } = props.node;
  const indent = props.depth * 24;
  const [nodeVersion, setNodeVersion] = useState(0);
  const { titleWidth, createdDateWidth, updatedDateWidth } = props.parentWidths;
  const [versionViews, setVersionViews] = useState([]);
  const [color, setColor] = useState("#404040");
  const [initialFontSize, setSelectedFont] = useState();
  const [initialFontWeight, setInitialFontWeight] = useState();
  const [isSelectionDisabled, setIsSelectionDisabled] = useState(false);
  const [initialSelectedVersion, setInitialSelectedVersion] = useState(props.p.selectedVersion);
  const titleWidth2 = (150 - props.depth * 24).toString() + "px";
  const fileName = props.node.text;
  const classes = useStyles(props);

  // if (props.node.text.length > 31) {
  //   fileName = props.node.text.substring(0, 30);
  // } else {
  //   fileName = props.node.text;
  // }

  useEffect(() => {
    if (props.p.selectedVersion != null) {
      if (props.p.selectedVersion._id == props.node.id) {
        setColor("#30302f");
        setSelectedFont("italic");
        setInitialFontWeight("bolder");
      } else {
        setColor("#404040");
        setSelectedFont("normal");
        setInitialFontWeight("normal");
      }
    }
  }, [props.p.selectedVersion]);

  useEffect(() => {
    if (initialSelectedVersion != null) {
      if (initialSelectedVersion._id == props.node.id) {
        setColor("#30302f");
      } else {
        setColor("#404040");
      }
    }
  }, [initialSelectedVersion]);

  useEffect(() => {
    if (props.p.selectedFolderId != 9999999) {
      if (props.node.id == props.p.selectedFolderId.id) {
        setColor("#30302f");
      } else if (props.p.selectedVersion != null) {
        if (props.p.selectedVersion._id == props.node.id) {
          setColor("#404040");

          setSelectedFont("italic");
          setInitialFontWeight("bolder");
        } else {
          setColor("#404040");
          setSelectedFont();
          setInitialFontWeight();
        }
      } else {
        setColor();
        setSelectedFont();
        setInitialFontWeight();
      }
    }
  }, [props.p.selectedFolderId]);

  useEffect(() => {
    props.updateNode(props.node);
  }, [props.parent, props.hasChild]);

  function getVersionByID(versionIDSString) {
    //console.debug("getting version by id", versionIDSString);
    const found = props.p.versions.filter((version) => {
      return String(version._id) === versionIDSString;
    });

    if (_.size(found) > 0) {
      return found[0];
    }

    return {};
  }

  function viewVersions() {
    if (getVersionByID(props.node.id) == null) {
      console.log("props.p.versions " + props.p.versions);
    }

    const createdDate = new Date(getVersionByID(props.node.id).dateCreated);
    const updatedDate = new Date(getVersionByID(props.node.id).dateUpdated);

    if (props.node.data.fileType) {
      try {
        return {
          createdDate: props.node.data.createdDate,
          updatedDate: props.node.data.updateDate,

          // createdDate: `${createdDate.getDate() < 10 ? `0${createdDate.getDate()}` : `${createdDate.getDate()}`}.${createdDate.getMonth() + 1 < 10 ? `0${createdDate.getMonth() + 1}` : `${createdDate.getMonth() + 1}`}.${createdDate.getFullYear()}  ${createdDate.getHours() < 10 ? `0${createdDate.getHours()}` : `${createdDate.getHours()}`}:${String(createdDate.getMinutes()).padStart(2, "0")}`,
          // createdDateTime: createdDate.getTime(),
          // updatedDate: `${updatedDate.getDate() < 10 ? `0${updatedDate.getDate()}` : `${updatedDate.getDate()}`}.${updatedDate.getMonth() + 1 < 10 ? `0${updatedDate.getMonth() + 1}` : `${updatedDate.getMonth() + 1}`}.${updatedDate.getFullYear()}  ${updatedDate.getHours() < 10 ? `0${updatedDate.getHours()}` : `${updatedDate.getHours()}`}:${String(updatedDate.getMinutes()).padStart(2, "0")}`,
        };
      } catch {
        return {
          createdDate: props.node.data.createdDate,
          updatedDate: props.node.data.updateDate,
        };
      }
    } else if (!props.node.data.fileType) {
      return {
        createdDate: props.node.data.createdDate,
        updatedDate: props.node.data.updateDate,
      };
    }
  }

  const handleToggle = (e) => {
    e.stopPropagation();
    props.setCustomNode(props.node);
    props.onToggle(props.node.id);
  };

  const logla = () => {
    props.setCustomNode(9999999);

    if (props.node.data.fileType !== "" && props.node.data.fileType !== "text") {
      props.setCustomNode(props.node);
      props.onToggle(props.node.id);
    }

    if (props.node.data.fileType == "text") {
      props.setCustomNode(9999999);

      if (isSelectionDisabled) return;

      setIsSelectionDisabled(true);

      const selection = props.node;

      if (
        !_.isNil(props.p.selectedVersion) &&
        _.get(props.p.selectedVersion, "lastUpdatingUserID", "-1") === localStorage.getItem("user.id") &&
        props.p.userPermissions["update"] &&
        selection.id !== props.p.selectedVersion._id
      ) {
        props.p.setIsDialogCircularProgressOpen(true);

        const createdLanguages = _.get(props.p, "selectedVersion.createdLanguages")
          ? _.get(props.p, "selectedVersion.createdLanguages")
          : ["en"];

        fetch(getServerUri("version/updateOne"), {
          method: "POST",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          body: new URLSearchParams({
            accessToken: localStorage.getItem("session.accessToken"),
            versionID: String(_.get(props.p, "selectedVersion._id")),
            gameConfig: JSON.stringify(_.get(props.p, "selectedVersion.gameConfig")),
            recorderSettings: JSON.stringify(_.get(props.p, "selectedVersion.recorderSettings")),
            createdLanguages: JSON.stringify(createdLanguages),

            //isBeingUpdated: String(false)
          }),
        })
          .then((response) => {
            if (response.status === 200) {
              console.log({ message: "version updated" });
              setNodeVersion(selection.id);

              const newVersion = _.cloneDeep(props.p.selectedVersion);
              //_.set(newVersion, 'isBeingUpdated', false);
              const newVersions = _.cloneDeep(props.p.versions);

              for (let i = 0; i < newVersions.length; i++) {
                if (newVersions[i]._id === props.p.selectedVersion._id) {
                  newVersions[i] = newVersion;
                }
              }

              props.p.setVersions(newVersions);
              props.p.setValue(getVersionByID(selection.id));
              setIsSelectionDisabled(false);
              props.p.setIsDialogCircularProgressOpen(false);
            } else if (response.status === 401) {
              //props.createSnackbar("You are not authorized to update, contact your administrator.", "warning");
              response
                .text()
                .then((textResponse) => {
                  console.log({ message: "error updating", textResponse });
                })
                .catch(console.log);

              setIsSelectionDisabled(false);
              props.p.setIsDialogCircularProgressOpen(false);
            }
          })
          .catch((err) => {
            console.log(err);

            props.p.setIsDialogCircularProgressOpen(false);
            setIsSelectionDisabled(false);
          });
      } else {
        const newSelectedVersion = _.cloneDeep(getVersionByID(selection.id));

        props.p.setValue(newSelectedVersion);

        if (Array.isArray(selection) && selection.length > 0) {
          const newSelectedVersion = _.cloneDeep(getVersionByID(selection[0].id));
          props.p.setValue(newSelectedVersion);
        }

        setIsSelectionDisabled(false);
      }
    } else {
      console.log("folder");
    }
  };

  const dragOverProps = useDragOver(id, props.isOpen, props.onToggle);

  if (_.size(props.p.versions) < 1) return null;
  //- {getVersionByID(props.node.id).dateCreated} - {getVersionByID(props.node.id).dateUpdated}

  //console.log(props);

  return (
    <div
      id="custom-node"
      style={{
        backgroundColor: color,
        display: "flex",
        minWidth: `${titleWidth + createdDateWidth + updatedDateWidth + 16}px`,
        height: "53px",
        alignItems: "center",
        justifyContent: "flex-start",
      }}
      className={classes.nodeRow}
      onClick={logla}
    >
      <div
        id="name-and-icon-container"
        style={{
          paddingInline: `${titleWidth < indent ? 0 : indent}px 8px`,
          transition: "all 50ms linear",
          minWidth: titleWidth + "px",
          maxWidth: titleWidth + "px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          borderBottom: "1px solid rgba(81, 81, 81, 1)",
          height: "100%",
          textOverflow: "ellipsis",
          overflow: "hidden",
          //paddingRight: props.node.data.fileType != "text" ? "0px" : "24px",
        }}
      >
        {props.node.data.fileType != "text" && (
          <div
            style={{
              width: "24px",
              alignItems: "center",
              fontSize: "0",
              cursor: "pointer",
              display: "flex",
              height: "24px",
              justifyContent: "center",
              transition: "transform linear 0.1s",
              transform: "rotate(0deg)",
            }}
          >
            <div onClick={handleToggle}>{props.isOpen ? <ArrowDropDown /> : <ArrowRightIcon />}</div>
          </div>
        )}
        <CustomTypeIcon
          sx={{ width: "20px" }}
          droppable={droppable}
          parent={props.node.parent}
          fileType={data?.fileType}
        />
        {props.node.data.fileType == "text" && (
          <div
            style={{
              paddingLeft: `${titleWidth < 24 ? titleWidth : 24}px`,
            }}
          >
            {props.isDefaultVersion && (
              <HomeRoundedIcon className={classes.homeRoundedIcon} sx={{ width: "20px", marginTop: "2.5px" }} />
            )}
          </div>
        )}
        {props.node.data.fileType != "text" && (
          <div
            id="folder_div"
            style={{
              minWidth: titleWidth + "px",
              maxWidth: titleWidth + "px",
            }}
            className={classes.a}
          >
            <a
              className={classes.title}
              style={{
                zIndex: 10,
                fontStyle: `${initialFontSize}`,
                marginLeft: "-4.5px",
                lineHeight: "53px",
              }}
            >
              <Tooltip
                id="folderTitle_toolTip"
                title={fileName}
                enterDelay={1000}
                leaveDelay={200}
                placement="bottom-start"
              >
                <Typography
                  id="folderTitle"
                  style={{
                    fontWeight: `${initialFontWeight}`,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    overflowX: "hidden",
                    minWidth: `${titleWidth}px`,
                  }}
                  variant="body2"
                >
                  {fileName}
                </Typography>
              </Tooltip>
            </a>
          </div>
        )}
        {props.node.data.fileType == "text" && (
          <div
            id="version_div"
            style={{
              width: "100%",
              height: "100%",
              // borderBottom: "1px solid rgba(81, 81, 81, 1)",
              lineHeight: "53px",
            }}
            className={classes.a}
          >
            <a
              className={classes.titleVersion}
              style={{
                zIndex: 10,
                fontStyle: `${initialFontSize}`,
                marginLeft: "-7px",
              }}
            >
              <Tooltip
                id="versionTitle_toolTip"
                title={fileName}
                enterDelay={1000}
                leaveDelay={200}
                placement="bottom-start"
              >
                <Typography
                  id="folderTitle"
                  style={{
                    fontWeight: `${initialFontWeight}`,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    minWidth: `${titleWidth}px`,
                    lineHeight: "53px",
                  }}
                  variant="body2"
                >
                  {fileName}
                </Typography>
              </Tooltip>
            </a>
          </div>
        )}
      </div>
      <div
        id="version-container"
        style={{
          lineHeight: "53px",
          height: "53px",
          width: "100%",
        }}
      >
        {props.node.data.fileType == "text" ? (
          <div
            id="versionDates"
            className={classes.b}
            style={{
              backgroundColor: color,
              borderBottom: "1px solid rgba(81, 81, 81, 1)",
              overflow: "hidden",
              minWidth: `${updatedDateWidth + createdDateWidth}px`,
              position: "relative",
            }}
          >
            <p
              style={{
                padding: "0px 16px 0px 26px",
                minWidth: createdDateWidth + "px",
                maxWidth: createdDateWidth + "px",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              {viewVersions().createdDate}
            </p>
            <p
              style={{
                padding: "0px 16px 0px 25px",
                minWidth: updatedDateWidth + "px",
                maxWidth: updatedDateWidth + "px",
                textOverflow: "ellipsis",
                overflow: "hidden",
                position: "absolute",
                left: `${createdDateWidth}px`,
              }}
            >
              {viewVersions().updatedDate}
            </p>
          </div>
        ) : (
          <div
            id="folderDates"
            className={classes.b}
            style={{
              backgroundColor: color,
              borderBottom: "1px solid rgba(81, 81, 81, 1)",
              overflow: "hidden",
              minWidth: `${updatedDateWidth + createdDateWidth}px`,
              position: "relative",
            }}
          >
            <p
              style={{
                padding: "0px 16px 0px 26px",
                minWidth: createdDateWidth + "px",
                maxWidth: createdDateWidth + "px",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              {viewVersions().createdDate}
            </p>
            <p
              style={{
                padding: "0px 16px 0px 26px",
                minWidth: updatedDateWidth + "px",
                maxWidth: updatedDateWidth + "px",
                textOverflow: "ellipsis",
                overflow: "hidden",
                position: "absolute",
                left: `${createdDateWidth}px`,
              }}
            >
              {viewVersions().updatedDate}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
