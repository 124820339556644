import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, Typography } from "@material-ui/core";
import addFolder from "../../../images/documentationImages/addFolder.png";
import addVersion from "../../../images/documentationImages/addVersion.png";
import versionfolderOptions from "../../../images/documentationImages/versionfolderOptions.png";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    backgroundColor: "#404040",
  },
  content: {
    backgroundColor: "#404040",
    color: "white",
    align: "center",
    textAlign: "left",
    marginTop: "1%",
    marginLeft: "5%",
    marginRight: "20%",
  },
  card: {
    margin: "5%",
  },
  img: {
    borderRadius: "4px",
    border: "1px solid #727171",
    boxShadow: "rgb(0 0 0 / 20%) 1px 1px 10px 0px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px",
    width: "40%",
    height: "auto",
    marginBottom: "16px",
  },
  image: {
    borderRadius: "4px",
    border: "1px solid #727171",
    boxShadow: "rgb(0 0 0 / 20%) 1px 1px 10px 0px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px",
    width: "100%",
    height: "auto",
    marginBottom: "16px",
  },
  imgContainer: {
    display: "flex",
    justifyContent: "center",
  },
}));

const FolderandVersionFeatures = () => {
  const classes = useStyles();

  return (
    <div className={classes.root} id="FolderandVersionFeatures">
      <main className={classes.content}>
        <Card>
          <CardContent>
            <Typography variant="h5">Folder and Version Features</Typography>
            <br />
            <div className={classes.imgContainer}>
              <img className={classes.img} src={addFolder} alt="" />
              <img className={classes.img} src={addVersion} alt="" />
            </div>
            <Typography paragraph>
              {" "}
              <span style={{ fontWeight: "bold" }}>Move in/out:</span> Versions can be moved into folders by simply
              dragging and dropping.A folder can be moved into another folder by dragging and dropping.Versions and
              folders can be moved out of the folders by dragging and dropping.
            </Typography>
            <Typography paragraph>
              {" "}
              <span style={{ fontWeight: "bold" }}>Folder expansion:</span> Folders can be extended by the arrow icon
              near its name.
            </Typography>
            <Typography paragraph>
              <span style={{ fontWeight: "bold" }}>Folder selection:</span> Folders can be selected by clicking.New
              version can be inserted inside of the folder by selecting the folder.
            </Typography>
            <Typography paragraph>
              <span style={{ fontWeight: "bold" }}>Search bar:</span> Versions and folders can be searched by search bar
              with typing their names.
            </Typography>
            <Typography paragraph>
              Versions and folders can be renamed, duplicated and deleted by three dots menu. If a folder is deleted,
              inside of the versions will be deleted also.
            </Typography>
            <br />
            <br />
            <div>
              <div className={classes.imgContainer}>
                <img style={{ width: "80%" }} className={classes.img} src={versionfolderOptions} alt="" />
              </div>
            </div>
          </CardContent>
        </Card>
      </main>
    </div>
  );
};

export default FolderandVersionFeatures;
