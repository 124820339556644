import React, { useEffect, useState } from "react";
import { Typography, MenuItem, Select, IconButton } from "@material-ui/core";
import { Remove } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import { getServerUri } from "../modules/uriUtility";
import _ from "lodash";
import DialogGlbDelete from "./dialogGlbDelete";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  iconButton: { padding: 0 }
});

/**
 * @param props
 * @param props.glbs
 * @param props.selectedGLB
 * @param props.classes
 * @param props.setValue
 * @param props.path
 * @param props.destination
 * @param props.bucket
 * @param props.componentType
 * @param props.insertGlbPathValue
 * @param props.componentID
 * @param props.selectedBase
 * @param props.userPermissions
 * @param props.createSnackbar
 * @param props.deleteGlbFromGlbs
 * @param props.selectedVersion
 * @returns {JSX.Element|null}
 */
export default function selectorGlb(props) {
  const [glbs, setGlbs] = useState(GlbsToView(_.get(props, "glbs", [])));
  const defaultGlbID = String(_.get(glbs, "[0]._id", ""));
  const [selectedGlbID, setSelectedGlbID] = useState(_.get(props, "selectedGlb._id", defaultGlbID));
  const [isDeleteGlbDialogOpen, setIsDeleteGlbDialogOpen] = useState(false);
  const [glbToDelete, setGlbToDelete] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    if (isSelectionValid(_.get(props, "selectedGlb._id", ""))) {
      setSelectedGlbID(_.get(props, "selectedGlb._id", ""));
    } else if (isSelectionValid(defaultGlbID)) {
      selectGlb(defaultGlbID);
    }
  }, [_.get(props, "selectedVersion")]);

  function GlbsToView(glbs) {
    return glbs.filter((glb) => {
      return _.get(glb, "bucket") === _.get(props, "bucket");
    });
  }
  function gettingMargin(numberMargin) {
    if (props.tooltip != undefined) {
      if (numberMargin) {
        return numberMargin.toString() + "px";
      }
    } else return (0).toString() + "px";
  }

  useEffect(() => {
    setGlbs(GlbsToView(_.get(props, "glbs", [])));
  }, [_.get(props, "glbs", []), _.size(props.glbs)]);

  function isSelectionValid(id, newGlbs) {
    let glbs2;
    if (_.isNil(newGlbs)) {
      glbs2 = glbs;
    } else {
      glbs2 = newGlbs;
    }
    const foundGlbs = glbs2.filter((glb) => {
      return String(glb._id) === String(id);
    });
    return foundGlbs.length > 0 || id === "";
  }

  const inputID = `${_.get(props, "componentID")}-${_.get(props, "path")}`;

  function selectGlb(id, newGlbs) {
    if (!isSelectionValid(id, newGlbs)) return;
    setSelectedGlbID(id);
    let glbs2;
    if (_.isNil(newGlbs)) {
      glbs2 = glbs;
    } else {
      glbs2 = newGlbs;
    }
    const selectedGlbRecord = glbs2.filter((glb) => {
      return String(glb._id) === String(id);
    })[0];
    const newSelectedGlbRecord = _.clone(selectedGlbRecord);
    _.set(newSelectedGlbRecord, "destination", _.get(props, "destination"));
    props.setValue({
      value: [newSelectedGlbRecord]
    });
  }

  function setInputFileListEmpty(inputId) {
    let list = new DataTransfer();
    document.getElementById(inputId).files = list.files;
  }

  const checkFileSize = (file) => {
    return file.size > 1024 * 1024 * 3; // 3 MB
  };

  return (
    <div id={`${props.id}.Div`}>
      {" "}
      {props.isVisible && (
        <>
          {_.get(props, "label") && (
            <Typography style={{ marginTop: gettingMargin(props.tooltipLength) }} id="discrete-slider" gutterBottom>
              {_.get(props, "label")}
            </Typography>
          )}
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}>
            <input
              id={inputID}
              type={"file"}
              hidden={"hidden"}
              multiple={true}
              accept=".glb"
              onChange={(e) => {
                //upload the file immediately
                for (let i = 0; i < e.target.files.length; i++) {
                  let file = e.target.files[i];
                  // checkImageWidthHeight(file, () => {
                  if (checkFileSize(file)) {
                    props.createSnackbar("File size should be less than 3 MB.", "error");
                    return;
                  }
                  const formData = new FormData();

                  formData.append("files", file);
                  formData.append("name", file.name);
                  formData.append("componentType", _.get(props, "componentType"));
                  formData.append("path", _.get(props, "path"));
                  formData.append("bucket", _.get(props, "bucket"));
                  formData.append("accessToken", localStorage.getItem("session.accessToken"));

                  fetch(getServerUri("glb/uploadGlb"), {
                    method: "POST",
                    body: formData
                  })
                    .then((response) => {
                      if (response.status !== 200) {
                        if (response.status === 401) {
                          props.createSnackbar(
                            "You are not authorized to insert, contact your administrator.",
                            "warning",
                            false
                          );
                        }
                        response
                          .text()
                          .then((textResponse) => {
                            console.error(textResponse);
                          })
                          .catch(console.error);
                      } else {
                        console.info("Upload successful");
                        response.json().then((objectResponse) => {
                          const newGlbs = _.cloneDeep(glbs);
                          const newRecords = _.get(objectResponse, "records", []);
                          newGlbs.push(...newRecords);
                          setGlbs(newGlbs);
                          props.insertGlbPathValue(newRecords);

                          const newGlbID = String(newRecords[0]._id);
                          setSelectedGlbID(newGlbID);
                          selectGlb(newGlbID, newGlbs);

                          //reset file list on input element
                          setInputFileListEmpty(inputID);
                        });
                      }
                    })
                    .catch(console.error);
                }
              }}
            />
            <Select
              onChange={(event) => {
                if (props.userPermissions["update"] === false) {
                  props.createSnackbar(
                    "You do not have permission to update, contact your administrator.",
                    "warning",
                    false
                  );
                  return;
                }
                if (isSelectionValid(String(event.target.value))) {
                  selectGlb(String(event.target.value));
                }
              }}
              className={props.classes.imageSelector}
              value={String(selectedGlbID)}
            >
              {glbs.map((glb) => (
                <MenuItem
                  key={String(glb._id)}
                  value={String(glb._id)}
                  style={{
                    display: "flex",
                    justifyContent: "space-between"
                  }}
                >
                  <Typography id="discrete-slider" gutterBottom>
                    {glb.name}
                  </Typography>
                  {selectedGlbID !== glb._id && (
                    <div
                      style={{
                        float: "right"
                      }}
                    >
                      <IconButton
                        className={classes.iconButton}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (props.userPermissions["delete"] === false) {
                            props.createSnackbar(
                              "You do not have permission to delete, contact your administrator.",
                              "warning",
                              false
                            );
                            return;
                          }
                          setGlbToDelete(glb);
                          setIsDeleteGlbDialogOpen(true);
                        }}
                      >
                        <Remove />
                      </IconButton>
                    </div>
                  )}
                </MenuItem>
              ))}
            </Select>
            <IconButton
              id={`${props.id}.uploadButton`}
              className={classes.iconButton}
              onClick={() => {
                if (props.userPermissions["insert"] === false) {
                  props.createSnackbar(
                    "You are not authorized to insert, contact your administrator.",
                    "warning",
                    false
                  );
                  return;
                }
                if (props.userPermissions["update"] === false) {
                  props.createSnackbar(
                    "You are not authorized to update, contact your administrator.",
                    "warning",
                    false
                  );
                  return;
                }
                document.getElementById(inputID).click();
              }}
            >
              <AddIcon />
            </IconButton>
            <DialogGlbDelete
              isOpen={isDeleteGlbDialogOpen}
              setIsOpen={(value) => setIsDeleteGlbDialogOpen(value)}
              createSnackbar={props.createSnackbar}
              glbToDelete={glbToDelete}
              selectedGlbID={selectedGlbID}
              glbs={glbs}
              setGlbs={setGlbs}
              selectGlb={selectGlb}
              deleteGlbFromGlbs={props.deleteGlbFromGlbs}
            />
          </div>
        </>
      )}
    </div>
  );
}
