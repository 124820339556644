import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Divider, Card, CardContent, Typography, IconButton, Chip } from "@material-ui/core";
import undoRedoImage from "../../../images/documentationImages/undoRedoImage.jpeg";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "80px",
  },
  imgContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    borderRadius: "4px",
    border: "1px solid #727171",
    boxShadow: "rgb(0 0 0 / 20%) 1px 1px 10px 0px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px",
  },
  img: {
    borderRadius: "4px",
    width: "100%",
    height: "auto",
  },
  main: {
    align: "center",
    textAlign: "left",
  },
  chip: {
    float: "left",
    marginRight: "90%",
    marginBottom: "5%",
  },
  context: {
    textAlign: "justify",
  },
}));

const UndoRedo = () => {
  const classes = useStyles();

  return (
    <div className={classes.main}>
      <Card className={classes.container}>
        <CardContent>
          <div className={classes.imgContainer}>
            <img src={undoRedoImage} className={classes.img} alt="" />
            <Divider />
          </div>
          <br />
          <Typography variant="h6">Undo/Redo feature is now available on dashboard !</Typography>
          <Typography paragraph>22.02.2023</Typography>
          <Chip className={classes.chip} label="Standard" variant="outlined" />
          <Typography paragraph className={classes.context}>
            Sometimes it is hard for users to manage changes and keep their mind which option is best for design. To
            make it easier, now you can use UNDO and REDO. After making the first move, you can see the icons for undo
            and redo on the right side of your screen. While editing playable ads, you can also use CMD+Z (CTRL+Z) on
            your keyboard to undo, CMD+SHIFT+Z(CTRL+Z) to redo.
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
};

export default UndoRedo;
