import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Divider, Card, CardContent, Typography, IconButton, Chip } from "@material-ui/core";
import FilterComponentsImage from "../../../images/documentationImages/filterComponents.png";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "80px",
  },
  imgContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    borderRadius: "4px",
    border: "1px solid #727171",
    boxShadow: "rgb(0 0 0 / 20%) 1px 1px 10px 0px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px",
  },
  img: {
    borderRadius: "4px",
    width: "100%",
    height: "auto",
  },
  main: {
    align: "center",
    textAlign: "left",
  },
  chip: {
    float: "left",
    marginRight: "90%",
    marginBottom: "5%",
  },
  context: {
    textAlign: "justify",
  },
}));

const FilterComponents = () => {
  const classes = useStyles();

  return (
    <div className={classes.main}>
      <Card className={classes.container}>
        <CardContent>
          <div className={classes.imgContainer}>
            <img src={FilterComponentsImage} className={classes.img} alt="" />
            <Divider />
          </div>
          <br />
          <Typography variant="h6">Dashboard has filtering components feature now !</Typography>
          <Typography paragraph>17.02.2022</Typography>
          <Chip className={classes.chip} label="Standard" variant="outlined" />
          <Typography paragraph className={classes.context}>
            Sometimes it is hard for users to make changes on one component to an another. To prevent this, now you can
            filter components with using filter component section. While creating playable ads, filter components
            section can be used in order to update components effectively. Filtering options may include “tutorial”, “in
            game”, “end card -win”, “end card - lose“ or “revive” as well as customized options like “enemy”, “hero”
            according to our clients' needs.
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
};

export default FilterComponents;
