import React, { useEffect, useState, useRef } from "react";
import { Typography, MenuItem, Select, IconButton } from "@material-ui/core";
import { Remove } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import { getServerUri } from "../modules/uriUtility";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import DialogFontDelete from "./dialogFontDelete";

const useStyles = makeStyles({
  iconButton: { padding: 0 },
});

/**
 *
 * @param props
 * @param props.fonts
 * @param props.selectedFont
 * @param props.classes
 * @param props.setValue
 * @param props.path
 * @param props.destination
 * @param props.componentType
 * @param props.insertMediaPathValue
 * @param props.componentID
 * @param props.selectedBase
 * @param props.selectedVersion
 * @param props.createSnackbar
 * @param props.userPermissions
 * @param props.deleteFontFromFonts
 * @param props.insertFontPathValue
 * @param props.isVisible
 * @param props.setIsDialogConfirmRefreshOpen
 * @returns {JSX.Element|null}
 */
export default function selectorFont(props) {
  const [fonts, setFonts] = useState(_.get(props, "fonts", []));
  const defaultFontID = String(_.get(fonts, `[0]._id`, ``));
  const [selectedFontID, setSelectedFontID] = useState(_.get(props, "selectedFont._id", defaultFontID));
  const [isDeleteFontDialogOpen, setIsDeleteFontDialogOpen] = useState(false);
  const [fontToDelete, setFontToDelete] = useState(null);
  const prevSelectedFontID = usePrevious(selectedFontID);
  const classes = useStyles();

  useEffect(() => {
    if (isSelectionValid(_.get(props, "selectedFont._id", ""))) {
      setSelectedFontID(_.get(props, "selectedFont._id", ""));
    } else {
      if (isSelectionValid(defaultFontID)) {
        selectFont(defaultFontID);
      }
    }
  }, [_.get(props, "selectedVersion")]);

  useEffect(() => {
    setFonts(props.fonts);
  }, [_.get(props, "fonts", []), _.size(props.fonts)]);

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  function isSelectionValid(id, newFonts) {
    let fonts2;
    if (_.isNil(newFonts)) {
      fonts2 = fonts;
    } else {
      fonts2 = newFonts;
    }
    const foundFonts = fonts2.filter((font) => {
      return String(font._id) === String(id);
    });
    return foundFonts.length > 0 || id === "";
  }

  const inputID = `${_.get(props, "componentID")}-${_.get(props, "path")}`;

  function selectFont(id, newFonts) {
    if (!isSelectionValid(id, newFonts)) return;
    setSelectedFontID(id);

    let fonts2;
    if (_.isNil(newFonts)) {
      fonts2 = fonts;
    } else {
      fonts2 = newFonts;
    }

    const selectedFontRecord = fonts2.filter((font) => {
      return String(font._id) === String(id);
    })[0];
    const newSelectedFontRecord = _.clone(selectedFontRecord);
    _.set(newSelectedFontRecord, "destination", _.get(props, "destination"));

    let newComponents;
    if (!_.isNil(prevSelectedFontID) && prevSelectedFontID !== id) {
      newComponents = setDefaultFontOnSelectionChange(prevSelectedFontID, fonts2, newSelectedFontRecord);
    }
    if (!_.isNil(newComponents)) {
      // if prev font family changed on components
      _.set(newComponents, `${_.get(props, "componentID")}.${_.get(props, "path")}`, newSelectedFontRecord);
      props.setValue({
        path: "gameConfig.components",
        value: newComponents,
      });
    } else {
      // if font family is not selected on any component, just select it
      props.setValue({
        path: `gameConfig.components.${_.get(props, "componentID")}.${_.get(props, "path")}`,
        value: newSelectedFontRecord,
      });
    }
    setFonts(fonts2);
  }

  function setDefaultFontOnSelectionChange(prevFontId, fonts, currentFontRecord) {
    const prevFontRecord = fonts.filter((font) => {
      return String(font._id) === String(prevFontId);
    })[0];
    const prevFontFamily = _.get(prevFontRecord, "fontFamily");
    const currentFontFamily = _.get(currentFontRecord, "fontFamily");

    const selectedVersion = props.selectedVersion;
    const components = _.get(selectedVersion, "gameConfig.components", []);

    let newComponents = {};

    let isChanged = false;
    Object.entries(components).forEach(([componentId, component]) => {
      let newComponent = _.cloneDeep(component);
      Object.keys(component).forEach((key) => {
        //Font Family Depth has three possibility

        // depth is -> component.fontFamily
        if (key === "fontFamily") {
          let fontFamily = _.get(component, key);
          if (fontFamily === prevFontFamily) {
            _.set(newComponent, key, currentFontFamily);
            isChanged = true;
          }
        }

        // depth is -> component.object.fontFamily
        if (_.has(component, `${key}.fontFamily`)) {
          let fontFamily = _.get(component, `${key}.fontFamily`);
          if (fontFamily === prevFontFamily) {
            _.set(newComponent, `${key}.fontFamily`, currentFontFamily);
            isChanged = true;
          }
        }

        // depth is -> component.localization.language.fontFamily
        if (key === "localization") {
          let selectedLanguage = _.get(props.selectedVersion, "gameConfig.options.language");
          let fontFamily = _.get(component, `localization.${selectedLanguage}.fontFamily`);
          if (fontFamily === prevFontFamily) {
            _.set(newComponent, `localization.${selectedLanguage}.fontFamily`, currentFontFamily);
            isChanged = true;
          }
        }
      });
      _.set(newComponents, componentId, newComponent);
    });

    return isChanged ? newComponents : null;
  }

  function setInputFileListEmpty(inputId) {
    let list = new DataTransfer();
    document.getElementById(inputId).files = list.files;
  }

  const checkFileSize = (file) => {
    return file.size > 1024 * 1024; // 1 MB
  };

  return (
    <>
      {" "}
      {props.isVisible && (
        <>
          {_.get(props, `label`) && (
            <Typography id="discrete-slider" gutterBottom>
              {_.get(props, `label`)}
            </Typography>
          )}
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}>
            <input
              id={inputID}
              type={"file"}
              hidden={"hidden"}
              multiple={true}
              accept=".otf, .ttf"
              onChange={(e) => {
                //upload the file immediately
                for (let i = 0; i < e.target.files.length; i++) {
                  let file = e.target.files[i];
                  if (checkFileSize(file)) {
                    props.createSnackbar("File size should be less than 1 MB.", "error");
                    return;
                  }
                  const formData = new FormData();

                  formData.append("files", file);
                  formData.append("fontFamily", file.name.split(".")[0].replace(/\s+/g, "-"));
                  formData.append("componentType", _.get(props, "componentType"));
                  formData.append("path", _.get(props, "path"));
                  formData.append("accessToken", localStorage.getItem("session.accessToken"));

                  fetch(getServerUri("font/uploadFont"), {
                    method: "POST",
                    body: formData,
                  })
                    .then((response) => {
                      if (response.status !== 200) {
                        if (response.status === 401) {
                          props.createSnackbar(
                            "You are not authorized to insert, contact your administrator.",
                            "warning"
                          );
                        }
                        response
                          .text()
                          .then((textResponse) => {
                            console.error(textResponse);
                          })
                          .catch(console.error);
                      } else {
                        console.info(`Upload successful`);
                        response.json().then((objectResponse) => {
                          const newFonts = _.cloneDeep(fonts);
                          const newRecords = _.get(objectResponse, "records", []);
                          newFonts.push(...newRecords);
                          setFonts(newFonts);
                          props.insertFontPathValue(newRecords);
                          const firstFontID = String(newRecords[0]._id);
                          setSelectedFontID(firstFontID);
                          selectFont(firstFontID, newFonts);

                          //reset file list on input element
                          setInputFileListEmpty(inputID);
                        });
                      }
                    })
                    .catch(console.error);
                }
              }}
            />
            <Select
              onChange={(event) => {
                if (props.userPermissions["update"] === false) {
                  props.createSnackbar(
                    "You do not have permission to update, contact your administrator.",
                    "warning",
                    false
                  );
                  return;
                }
                if (isSelectionValid(String(event.target.value))) {
                  selectFont(String(event.target.value));
                }
              }}
              className={props.classes.imageSelector}
              value={String(selectedFontID)}
            >
              {fonts.map((font) => (
                <MenuItem
                  key={String(font._id)}
                  value={String(font._id)}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography id="discrete-slider" gutterBottom>
                    {font.fontFamily}
                  </Typography>
                  {selectedFontID !== font._id && (
                    <div
                      style={{
                        float: "right",
                      }}
                    >
                      <IconButton
                        className={classes.iconButton}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (props.userPermissions["delete"] === false) {
                            props.createSnackbar(
                              "You do not have permission to delete, contact your administrator.",
                              "warning",
                              false
                            );
                            return;
                          }
                          setFontToDelete(font);
                          setIsDeleteFontDialogOpen(true);
                        }}
                      >
                        <Remove />
                      </IconButton>
                    </div>
                  )}
                </MenuItem>
              ))}
            </Select>
            <IconButton
              id={`${props.id}.uploadButton`}
              className={classes.iconButton}
              onClick={(e) => {
                if (props.userPermissions["insert"] === false) {
                  props.createSnackbar(
                    "You are not authorized to insert, contact your administrator.",
                    "warning",
                    false
                  );
                  return;
                }
                if (props.userPermissions["update"] === false) {
                  props.createSnackbar(
                    "You are not authorized to update, contact your administrator.",
                    "warning",
                    false
                  );
                  return;
                }
                document.getElementById(inputID).click();
              }}
            >
              <AddIcon />
            </IconButton>
            <DialogFontDelete
              isOpen={isDeleteFontDialogOpen}
              setIsOpen={(value) => setIsDeleteFontDialogOpen(value)}
              createSnackbar={props.createSnackbar}
              fontToDelete={fontToDelete}
              selectedFontID={selectedFontID}
              fonts={fonts}
              setFonts={setFonts}
              selectFont={selectFont}
              deleteFontFromFonts={props.deleteFontFromFonts}
            />
          </div>
        </>
      )}
    </>
  );
}
