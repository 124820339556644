import React from "react";
import { CustomTypeIcon } from "./customTypeIcon";

export const CustomDragPreview = (props) => {
  const item = props.monitorProps.item;

  return (
    <div
      style={{
        alignItems: "center",
        backgroundColor: "#1967d2",
        borderRadius: "4px",
        boxShadow: "0 12px 24px -6px rgba(0, 0, 0, .25), 0 0 0 1px rgba(0, 0, 0, .08)",
        color: "fff",
        display: "inline-grid",
        gridTemplateColumns: "auto auto",
        fontSize: "14px",
        gap: "8px",
        padding: "4px 8px",
        pointerEvents: "none",
      }}
    >
      <div style={{ alignItems: "center", display: "flex" }}>
        <CustomTypeIcon droppable={item.droppable} parent={item?.parent} fileType={item?.data?.fileType} />
      </div>
      <div style={{ alignItems: "center", display: "flex" }}>{item.text}</div>
    </div>
  );
};
