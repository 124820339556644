import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Card, CardContent, Typography } from "@material-ui/core";
import Resetgeneral from "../../../images/documentationImages/resetgeneral.png";
import Storelinks from "../../../images/documentationImages/storelinks.png";
import Endcard from "../../../images/documentationImages/endcard.png";
import Gotomarket from "../../../images/documentationImages/gotomarket.png";
import Clicktoaction from "../../../images/documentationImages/clicktoaction.png";
import Game from "../../../images/documentationImages/game.png";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    backgroundColor: "#404040",
  },
  content: {
    backgroundColor: "#404040",
    color: "white",
    align: "center",
    textAlign: "left",
    marginTop: "1%",
    marginLeft: "5%",
    marginRight: "20%",
  },
  card: {
    margin: "5%",
  },
  img: {
    borderRadius: "4px",
    border: "1px solid #727171",
    boxShadow: "rgb(0 0 0 / 20%) 1px 1px 10px 0px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px",
    width: "40%",
    height: "auto",
    marginBottom: "16px",
  },
  imgContainer: {
    display: "flex",
    justifyContent: "center",
  },
}));

const GeneralSettings = () => {
  const classes = useStyles();

  return (
    <div className={classes.root} id="GeneralSettings">
      <main className={classes.content}>
        <Card>
          <CardContent>
            <Typography variant="h5">General Settings</Typography>
            <br />
            <Typography paragraph>
              General Settings is where playable ads’ usual settings can be adjusted. Playable ads’ settings can be
              customized through here.
            </Typography>
            <Typography paragraph align="left">
              App Store Links are the links that CTA Buttons open when clicked on. Both android and iOS links can be put
              under the app store links. Thereby even though the device may differ, CTA Buttons open the correct link.
            </Typography>
            <div className={classes.imgContainer}>
              <img className={classes.img} src={Storelinks} alt="" />
            </div>
            <Typography paragraph>
              The End Card is what is shown after the ad end and can be enabled and disabled with a toggle button. When
              the end card will be shown can be adjusted. End card can be shown after X seconds, X taps or X moves
              corresponding to needs. The counts for the seconds, taps and moves can be adjusted by the range bar. If
              the end card is enabled but all of the adjustments are zero, the end card will be shown after the game
              ends.
            </Typography>
            <div className={classes.imgContainer}>
              <img className={classes.img} src={Endcard} alt="" />
            </div>
            <Typography paragraph>
              Instead of having an end card, sending users to the store page directly can also be done. With the Go To
              Market After X Taps settings, when will the store page shown on the screen can be adjusted. Tap counts Our
              clients have an option to open up directly the store page after X taps on the screen. Tap count can be
              adjusted by the range bar under it.
            </Typography>
            <div className={classes.imgContainer}>
              <img className={classes.img} src={Gotomarket} alt="" />
            </div>
            <Typography paragraph>
              CTA Buttons, a.k.a. Click To Action, can be enabled and disabled under the general settings through toggle
              buttons.
            </Typography>
            <div className={classes.imgContainer}>
              <img className={classes.img} src={Clicktoaction} alt="" />
            </div>
            <Typography paragraph>
              Apart from those, game’s sound, tutorial, banner and autoplay like features can also be enabled and
              disabled through toggle buttons. Force portrait mode is a feature that forces the game to be shown as it
              was on the portrait mode while it’s on landscape.
            </Typography>
            <div className={classes.imgContainer}>
              <img className={classes.img} src={Game} alt="" />
            </div>
            <Typography paragraph>
              Similar to the version and localization settings, the general settings has a reset button too. Clicking
              the reset general settings will revert all of the changes that were done under the general settings to the
              default.
            </Typography>
            <div className={classes.imgContainer}>
              <img className={classes.img} src={Resetgeneral} alt="" />
            </div>
          </CardContent>
        </Card>
      </main>
    </div>
  );
};

export default GeneralSettings;
