import React from "react";
import { useState, useEffect } from "react";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { makeStyles } from "@material-ui/core/styles";
import { Card, IconButton, Typography } from "@material-ui/core";
import GridList from "@material-ui/core/GridList";
import { Close } from "@material-ui/icons";
import PlayableSelectionDialog from "./dialogPlayableSelection";
import _ from "lodash-core";
import endCardApp from "../../../images/galleryImages/endCardApp.png";
import atlasGame from "../../../images/galleryImages/atlasGame.png";

const images = {
  "619e14fb68af90fbc06a4345": endCardApp,
  "61af33d3222f4485886da806": atlasGame,
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    backgroundColor: theme.palette.background.paper,
    paddingTop: "2rem",
    paddingLeft: "4.5rem",
    paddingBottom: "3rem",
  },
  gridList: {
    width: "82%",
    height: "100%",
  },
  titleBar: {
    background: "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
  },
  icon: {
    color: "rgba(255, 255, 255, 0.54)",
  },
  paper: {
    backgroundColor: "#404040",
    top: "46px",
  },
  title: {
    marginBottom: "1rem",
    fontSize: "2.5rem",
    fontWeight: "bold",
    color: "#c0c8d0",
  },
  secondTitle: {
    fontSize: "1.25rem",
    color: "#939aa1",
    marginBottom: "2rem",
  },
  rectangle: {
    width: "695px",
    height: "375px",
    padding: "11.5px 18px 0 3.5px",
    backgroundColor: "#414141",
  },
  galleryItemName: {
    marginTop: "auto",
    textAlign: "center",
    color: "#372125",
    fontSize: "1.2vw",
    fontWeight: "600",
  },
  galleryItemInfo: {
    textAlign: "center",
    color: "#472a2f",
    fontSize: "0.7vw",
    fontWeight: "600",
    paddingBottom: "1rem",
  },
  closeButtonWrapper: {
    display: "flex",
    justifyContent: "flex-end",
  },
  closeButton: {
    margin: "1rem 1rem 0 0",
    padding: "1rem",
  },
  playableCardWrapper: {
    width: "20%",
    margin: "2%",
    padding: 0,
  },
  playableCard: {
    borderRadius: "2rem",
    border: "solid 5.0px",
    objectFit: "contain",
  },
}));

/**
 * @param props.createSnackbar
 * @param props.userPermissions
 * @param props.isOpen
 * @param props.setIsOpen
 * @param props.galleryGames
 * @param props.selectedGame
 * @param props.setSelectedGame
 * @param props.selectedVersion
 * @param props.versions
 * @param props.setVersions
 * @param props.userPermissions
 * @returns {JSX.Element}
 * @constructor
 */
export default function playableGalleryDrawer(props) {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [isPlayableSelectionDialogOpen, setIsPlayableSelectionDialogOpen] = useState(false);
  const [selectedPlayable, setSelectedPlayable] = useState(null);
  const [galleryGames, setGalleryGames] = useState(props.galleryGames);

  useEffect(() => {
    if (props.isOpen) {
      toggleDrawer(props.isOpen);
    }
  }, [props.isOpen]);

  const TitlebarGridList = function () {
    galleryGames.sort(function (a, b) {
      if (b.isGame === a.isGame) return 0;
      if (b.isGame === "false") return 1;
      return -1;
    });
    return (
      <div id="playableGalleryDrawer1" className={classes.root}>
        <GridList id="playableGalleryDrawer_grid" cellHeight="auto" spacing={30} className={classes.gridList}>
          {galleryGames.map((game) => {
            const bgColor = !_.isNil(game.isApp) && game.isApp ? "#c87c89" : "#7cc8bb";
            const borderColor = !_.isNil(game.isApp) && game.isApp ? "#f8cad2" : "#b9e1e5";
            return (
              <div
                id="playableGalleryDrawer_gridList"
                key={game._id.toString()}
                style={{
                  width: "20%",
                  margin: "2%",
                  padding: 0,
                }}
                onClick={() => {
                  setSelectedPlayable(game);
                  setIsPlayableSelectionDialogOpen(true);
                }}
              >
                <Card
                  key={game.img}
                  id="playableGalleryDrawer_card"
                  className={classes.playableCard}
                  style={{
                    backgroundColor: bgColor,
                    borderColor: borderColor,
                  }}
                >
                  <img src={images[String(game._id)]} alt={game.title} width={"100%"} />
                  <Typography className={classes.galleryItemName}>{game.title}</Typography>
                  <Typography className={classes.galleryItemInfo}>{game.description}</Typography>
                </Card>
              </div>
            );
          })}
        </GridList>
      </div>
    );
  };

  const toggleDrawer = (isOpen) => {
    setIsOpen(isOpen);
    props.setIsOpen(isOpen);
  };

  const list = (anchor) => (
    <div id="playableGalleryDrawer_presentation" role="presentation" onKeyDown={() => {}}>
      <div className={classes.closeButtonWrapper}>
        <IconButton
          id="playableGalleryDrawer_iconButton"
          component="span"
          className={classes.closeButton}
          onClick={() => toggleDrawer(false)}
        >
          <Close />
        </IconButton>
      </div>
      <div>
        <Typography id="playableGalleryDrawer_text" className={classes.title} align="center">
          Playable Gallery
        </Typography>
      </div>
      <Typography id="playableGalleryDrawer_text2" className={classes.secondTitle} align="center">
        Select any Playable to use
      </Typography>
      {TitlebarGridList()}
    </div>
  );

  return (
    <div id="playableGalleryDrawer2">
      {
        <React.Fragment key={"bottom"}>
          <SwipeableDrawer
            id="playableGalleryDrawer_swipeableDrawer"
            anchor={"bottom"}
            open={isOpen}
            onClose={() => toggleDrawer(false)}
            onOpen={() => toggleDrawer(true)}
            classes={{ paper: classes.paper }}
          >
            {list("bottom")}
          </SwipeableDrawer>
        </React.Fragment>
      }
      {!_.isNil(selectedPlayable) && (
        <PlayableSelectionDialog
          isOpen={isPlayableSelectionDialogOpen}
          setIsOpen={setIsPlayableSelectionDialogOpen}
          onClose={() => {
            setIsPlayableSelectionDialogOpen(false);
          }}
          selectedPlayable={selectedPlayable}
          selectedGame={props.selectedGame}
          setSelectedGame={props.setSelectedGame}
          selectedVersion={props.selectedVersion}
          versions={props.versions}
          setVersions={props.setVersions}
          userPermissions={props.userPermissions}
        />
      )}
    </div>
  );
}
