import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Card, CardContent, Typography } from "@material-ui/core";
import Shortcuts from "../../../images/documentationImages/shortcuts.png";
import Newversion from "../../../images/documentationImages/newversion.png";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    backgroundColor: "#404040",
  },
  content: {
    backgroundColor: "#404040",
    color: "white",
    align: "center",
    textAlign: "left",
    marginTop: "1%",
    marginLeft: "5%",
    marginRight: "20%",
  },
  card: {
    margin: "5%",
  },
  img: {
    borderRadius: "4px",
    border: "1px solid #727171",
    boxShadow: "rgb(0 0 0 / 20%) 1px 1px 10px 0px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px",
    width: "40%",
    height: "auto",
    marginBottom: "16px",
  },
  image: {
    borderRadius: "4px",
    border: "1px solid #727171",
    boxShadow: "rgb(0 0 0 / 20%) 1px 1px 10px 0px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px",
    width: "100%",
    height: "auto",
    marginBottom: "16px",
  },
  imgContainer: {
    display: "flex",
    justifyContent: "center",
  },
}));

const NewVersion = () => {
  const classes = useStyles();

  return (
    <div className={classes.root} id="NewVersion">
      <main className={classes.content}>
        <Card>
          <CardContent>
            <Typography variant="h5">Create a New Version and Folder</Typography>
            <br />
            <Typography paragraph>
              New versions and folders for the games can be created by clicking on the plus icon top of the version
              list. After giving the version/folder a title, one click on the OK button will be enough to create the new
              version or folder. The new version will come with default settings of the playable. Playable settings can
              be adjusted and customized through settings fields.
            </Typography>
            <div className={classes.imgContainer}>
              <img className={classes.image} src={Shortcuts} alt="" />
            </div>
            <div className={classes.imgContainer}>
              <img style={{ width: "80%" }} className={classes.img} src={Newversion} alt="" />
            </div>
          </CardContent>
        </Card>
      </main>
    </div>
  );
};

export default NewVersion;
