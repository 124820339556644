import * as React from "react";
import { useState, useEffect } from "react";

import Popover from "@material-ui/core/Popover";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Documentation from "../../pages/documentation";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import Features from "./NewFeatures";
import { Button, Divider } from "@material-ui/core";

export default function BasicPopover() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isDocumentationOpen, setDocumentationOpen] = React.useState(false);
  const [isFeaturesOpen, setFeaturesOpen] = React.useState(false);

  useEffect(() => {
    if (!isDocumentationOpen) {
      setAnchorEl(null);
    }
  }, [isDocumentationOpen]);

  useEffect(() => {
    if (!isFeaturesOpen) {
      setAnchorEl(null);
    }
  }, [isFeaturesOpen]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <HelpOutlineIcon
        style={{ fontSize: 50, margin: "9px", color: "#9ccec1" }}
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
      />
      <Popover
        style={{ left: "5px" }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <MenuItem style={{ padding: "10px 66px 10px 16px" }} onClick={() => setFeaturesOpen(true)}>
          What's New?
        </MenuItem>
        <Divider />
        <MenuItem style={{ padding: "10px 66px 10px 16px" }} onClick={() => setDocumentationOpen(true)}>
          Documentation
        </MenuItem>
      </Popover>
      <Features isFeaturesOpen={isFeaturesOpen} setFeaturesOpen={setFeaturesOpen} />
      <Documentation isDocumentationOpen={isDocumentationOpen} setDocumentationOpen={setDocumentationOpen} />
    </div>
  );
}
