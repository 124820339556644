import React, { useState, useEffect } from "react";
import {
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Card,
  CardContent,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import { DataGrid } from "@material-ui/data-grid";

const useStyles = makeStyles(() => ({
  dialogPaper: {
    height: "40rem"
  }
}));

/**
 *
 * @param props
 * @returns {JSX.Element|null}
 */
export default function dialogPlayableSize(props) {
  const [isOpen, setOpen] = useState(false);
  const [totalSize, setTotalSize] = useState(0);
  const [componentSizes, setComponentSizes] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    props.setTotalSize(totalSize);
  }, [totalSize]);

  useEffect(() => {
    async function getSizes() {
      if (!_.isNil(props.selectedVersion)) {
        let totalSizeResult = 0;
        let componentSizes = [];
        const promises = [];
        const base64SizeMultiplier = 1.37;

        Object.entries(_.get(props.selectedVersion, "gameConfig.components", {})).forEach(
          async ([componentId, component]) => {
            if (_.get(component, "isEnabled") !== false) {
              if (_.has(component, "selectedImages")) {
                let selectedImages = _.get(component, "selectedImages");
                Object.entries(selectedImages).forEach(([id, images]) => {
                  images.forEach((image) => {
                    let imagePromise = fetch(`images/${image.file}`)
                      .then((response) => response.blob())
                      .then((blob) => {
                        let name = `${componentId} - ${id}`;
                        let size = blob.size * base64SizeMultiplier;

                        if (_.has(image, "variant")) {
                          let variant = _.get(image, "variant");
                          name += ` - ${variant}`;
                        }
                        let index = componentSizes.indexOf((imageSize) => imageSize.name === name);
                        if (index !== -1) {
                          //add old size and new one to find total size
                          const imageSize = componentSizes[index];
                          _.set(componentSizes[index], "size", imageSize.size + blob.size);
                        } else {
                          componentSizes.push({
                            id: componentSizes.length,
                            name: name,
                            size: size,
                            type: "image"
                          });
                        }
                        totalSizeResult += size;
                      });
                    promises.push(imagePromise);
                  });
                });
              }
              if (_.has(component, "selectedAtlases")) {
                let selectedAtlases = _.get(component, "selectedAtlases");
                Object.entries(selectedAtlases).forEach(([id, atlases]) => {
                  atlases.forEach((atlas) => {
                    let atlasPromise = fetch(`atlases/${atlas.file}`)
                      .then((response) => response.blob())
                      .then((blob) => {
                        let name = `${componentId} - ${id}`;
                        let size = blob.size * base64SizeMultiplier;

                        if (_.has(atlas, "variant")) {
                          let variant = _.get(atlas, "variant");
                          name += ` - ${variant}`;
                        }

                        let index = componentSizes.indexOf((atlasSize) => atlasSize.name === name);
                        if (index !== -1) {
                          //add old size and new one to find total size
                          const atlasSize = componentSizes[index];
                          _.set(componentSizes[index], "size", atlasSize.size + blob.size);
                        } else {
                          componentSizes.push({
                            id: componentSizes.length,
                            name: name,
                            size: size,
                            type: "atlas"
                          });
                        }
                        totalSizeResult += size;
                      });
                    promises.push(atlasPromise);
                  });
                });
              }
              if (_.has(component, "selectedSounds")) {
                let selectedSounds = _.get(component, "selectedSounds");
                Object.entries(selectedSounds).forEach(([id, sounds]) => {
                  sounds.forEach((sound) => {
                    let soundPromise = fetch(`sounds/${sound.file}`)
                      .then((response) => response.blob())
                      .then((blob) => {
                        let name = `${componentId} - ${id}`;
                        let size = blob.size * base64SizeMultiplier;

                        if (_.has(sound, "variant")) {
                          let variant = _.get(sound, "variant");
                          name += ` - ${variant}`;
                        }

                        let index = componentSizes.indexOf((soundSize) => soundSize.name === name);
                        if (index !== -1) {
                          //add old size and new one to find total size
                          const soundSize = componentSizes[index];
                          _.set(componentSizes[index], "size", soundSize.size + blob.size);
                        } else {
                          componentSizes.push({
                            id: componentSizes.length,
                            name: name,
                            size: size,
                            type: "sound"
                          });
                        }
                        totalSizeResult += size;
                      });
                    promises.push(soundPromise);
                  });
                });
              }
              if (_.has(component, "selectedFonts")) {
                let selectedFonts = _.get(component, "selectedFonts");
                Object.entries(selectedFonts).forEach(([id, fonts]) => {
                  fonts.forEach((font) => {
                    let fontPromise = fetch(`fonts/${font.file}`)
                      .then((response) => response.blob())
                      .then((blob) => {
                        let name = `${componentId} - ${id}`;
                        let size = blob.size * base64SizeMultiplier;

                        if (_.has(font, "variant")) {
                          let variant = _.get(font, "variant");
                          name += ` - ${variant}`;
                        }

                        let index = componentSizes.indexOf((fontSize) => fontSize.name === name);
                        if (index !== -1) {
                          //add old size and new one to find total size
                          const fontSize = componentSizes[index];
                          _.set(componentSizes[index], "size", fontSize.size + blob.size);
                        } else {
                          componentSizes.push({
                            id: componentSizes.length,
                            name: name,
                            size: size,
                            type: "font"
                          });
                        }
                        totalSizeResult += size;
                      });

                    promises.push(fontPromise);
                  });
                });
              }
              if (_.has(component, "selectedVideos")) {
                let selectedVideos = _.get(component, "selectedVideos");
                Object.entries(selectedVideos).forEach(([id, videos]) => {
                  videos.forEach((video) => {
                    if (video.variant === "path") {
                      let videoPromise = fetch(`videos/${video.file}`)
                        .then((response) => response.blob())
                        .then((blob) => {
                          let name = `${componentId} - ${id}`;
                          let size = blob.size * base64SizeMultiplier;

                          if (_.has(video, "variant")) {
                            let variant = _.get(video, "variant");
                            name += ` - ${variant}`;
                          }

                          let index = componentSizes.indexOf((videoSize) => videoSize.name === name);
                          if (index !== -1) {
                            //add old size and new one to find total size
                            const videoSize = componentSizes[index];
                            _.set(componentSizes[index], "size", videoSize.size + blob.size);
                          } else {
                            componentSizes.push({
                              id: componentSizes.length,
                              name: name,
                              size: size,
                              type: "video"
                            });
                          }
                          totalSizeResult += size;
                        });

                      promises.push(videoPromise);
                    } else if (video.variant === "url") {
                      let name = `${componentId} - ${id}`;
                      let size = null;

                      if (_.has(video, "variant")) {
                        let variant = _.get(video, "variant");
                        name += ` - ${variant}`;
                      }

                      componentSizes.push({
                        id: componentSizes.length,
                        name: name,
                        size: size,
                        type: "external video"
                      });
                    }
                  });
                });
              }
              // glbs
              if (_.has(component, "selectedGltfs")) {
                // games have it as gltf, but it is glb in dashboard
                let selectedGlbs = _.get(component, "selectedGltfs");
                Object.entries(selectedGlbs).forEach(([id, glbs]) => {
                  glbs.forEach((glb) => {
                    let glbPromise = fetch(`glbs/${glb.file}`)
                      .then((response) => response.blob())
                      .then((blob) => {
                        let name = `${componentId} - ${id}`;
                        let size = blob.size * base64SizeMultiplier;

                        let index = componentSizes.indexOf((glbSize) => glbSize.name === name);
                        if (index !== -1) {
                          //add old size and new one to find total size
                          const glbSize = componentSizes[index];
                          _.set(componentSizes[index], "size", glbSize.size + blob.size);
                        } else {
                          componentSizes.push({
                            id: componentSizes.length,
                            name: name,
                            size: size,
                            type: "glb"
                          });
                        }
                        totalSizeResult += size;
                      });
                    promises.push(glbPromise);
                  });
                });
              }
            }
          }
        );
        await Promise.all(promises);

        let defaultPlayableSize;
        if (_.isNil(props.selectedBase.defaultPlayableSizeInMb)) {
          //adding default playable size which is 1.4 mb
          defaultPlayableSize = 1.4 * 1024 * 1024;
        } else {
          //adding default playable size from selected base.
          defaultPlayableSize = props.selectedBase.defaultPlayableSizeInMb * 1024 * 1024;
        }
        componentSizes.push({
          id: componentSizes.length,
          name: "Default playable size",
          size: defaultPlayableSize,
          type: "default"
        });
        totalSizeResult += defaultPlayableSize;

        setComponentSizes(componentSizes);
        setTotalSize(totalSizeResult);
        props.setTotalSize(totalSizeResult);
      }
    }
    getSizes();
  }, [props.selectedVersion]);

  function getColumns() {
    const columns = [
      {
        field: "id",
        headerName: "Id",
        hide: true
      },
      {
        field: "name",
        headerName: "Name",
        width: 250,
        renderCell: (props) => (
          <span className="size-name" style={{ width: "100%", height: "100%", outline: "none" }} title={props.value}>
            {props.value}
          </span>
        )
      }, //, width: "200px"
      {
        field: "type",
        headerName: "Type",
        width: 125,
        sortable: false,
        sortComparator: (v1, v2, param1, param2) => param1.data.type - param2.data.type
      }, //, width: "200px"
      {
        field: "size",
        headerName: "Size",
        width: 175,
        sortComparator: (v1, v2, param1, param2) => param1.data.size - param2.data.size,
        renderCell: (props) => (
          <span
            className="size-name"
            style={{ width: "100%", height: "100%", outline: "none" }}
            title={props.value}
          >{`${(props.value / 1024).toFixed(2)} kb`}</span>
        )
      }
    ];
    return columns;
  }

  return (
    <>
      <Card className={props.classes.card}>
        <CardContent>
          <Typography className={classes.title} variant={"h6"}>
            Playable Size
          </Typography>
          <div
            style={{
              marginTop: "0.5rem"
            }}
          >
            <Typography variant={"subtitle1"}>{`Total Estimated Size: ${(totalSize / 1024).toFixed(2)} kb`}</Typography>
          </div>
          <Button
            id={"playableSizeDetails"}
            color="secondary"
            variant="contained"
            label={"Details"}
            style={{
              margin: "1rem 5rem 0 0"
            }}
            onClick={() => {
              setOpen(true);
            }}
          >
            Details
          </Button>
        </CardContent>
      </Card>

      <Dialog
        open={isOpen}
        scroll="paper"
        fullWidth={true}
        maxWidth={"sm"}
        classes={{ paper: classes.dialogPaper }}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogTitle
          disableTypography
          style={{
            textAlign: "center"
          }}
        >
          <Typography variant="h5">PLAYABLE SIZE</Typography>
        </DialogTitle>
        <DialogContent>
          <DataGrid
            id={"sizeDataGrid"}
            rows={componentSizes}
            columns={getColumns()}
            hideFooterRowCount={true}
            hideFooterSelectedRowCount={true}
            hideFooter={true}
            disableSelectionOnClick
          />
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <div>
            <Typography variant="h5" sx={{ whiteSpace: "pre-line" }}>
              {`Total Estimated Size: ${(totalSize / 1024).toFixed(2)} kb`}
            </Typography>
            <div style={{ margin: "1.5rem 4rem 0.5rem" }}>
              <Typography variant="subtitle2" sx={{ whiteSpace: "pre-line" }}>
                * Total max file size should be: 5 mb
              </Typography>
            </div>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
}
