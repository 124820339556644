import React, { useState, useEffect } from "react";
import { getServerUri } from "./uriUtility";

/* fetch companylogo using company id from uploadLogo. it return companylogo url
 * @param companyId
 * @returns logoUrl
 */
export function fetchCompanyLogo(companyId) {
  const [companyLogoUrl, setCompanyLogoUrl] = useState("");
  fetch(getServerUri("company/uploadLogo"), {
    method: "POST",
    headers: { "content-type": "application/x-www-form-urlencoded" },
    body: new URLSearchParams({
      accessToken: localStorage.getItem("session.accessToken"),
      companyID: companyId,
    }),
  })
    .then((response) => {
      if (response.status === 200) {
        response.json().then((json) => {
          setCompanyLogoUrl(json.logoUrl);
        });
      } else {
        response.text().then((responseText) => {
          console.log(responseText);
        });
      }
    })
    .catch(console.error);
  return companyLogoUrl;
}
