import o133 from "../../images/deviceSize/133.png";
import o177 from "../../images/deviceSize/177.png";
import o216 from "../../images/deviceSize/216.png";
import oCustom from "../../images/deviceSize/icon-customsize.png";
import Phone2 from "../../images/deviceSize/phone2.png";
import Phone3 from "../../images/deviceSize/phone3.png";
import Tablet from "../../images/deviceSize/tablet.png";
import CustomizeIcon from "../../images/deviceSize/customizeIcon2.png";
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { resize } from "./containerGamePreview";
import AspectRatioIcon from "@material-ui/icons/AspectRatio";
import { Dialog, DialogTitle, Typography, Button, Grid, TextField } from "@material-ui/core";

import ReactGA from "react-ga";
import ReactGA4 from "react-ga4";

const useStyles = makeStyles((theme) => ({
  orientationImage: {
    marginLeft: 5,
    marginRight: 5,
  },
}));

/**
 * @param props.selectedVersion
 * @param props.selectedGame
 * @param props.selectedBase
 * @param props.isPortrait
 * @param props.setIsPortrait
 * @param props.aspectRatio
 * @param props.setAspectRatio
 *
 * @returns {JSX.Element}
 */
export default function toolbarGamePreviewOrientation(props) {
  const classes = useStyles();

  const [isCustomDialogOpen, setIsCustomDialogOpen] = useState(false);
  const [customWidth, setCustomWidth] = useState(0);
  const [customHeight, setCustomHeight] = useState(0);
  const [tabletRatio, setTabletRatio] = useState(o133);
  const [phone2Ratio, setPhone2Ratio] = useState(Phone2);
  const [phone3Ratio, setPhone3Ratio] = useState(o216);
  const [customIconRatio, setCustomIconRatio] = useState(oCustom);

  function setAspectRatio(event, newAspectRatio, isRotationPortraitEnabled) {
    let newIsPortrait = props.isPortrait;
    const isClickingTwice = newAspectRatio === props.aspectRatio;
    if (isClickingTwice && isRotationPortraitEnabled) {
      newIsPortrait = !props.isPortrait;
      props.setIsPortrait(newIsPortrait);
      props.setPortrait(newIsPortrait);
      if (!newIsPortrait) {
        if (newAspectRatio == 1.33) {
          setTabletRatio(Tablet);
        } else if (newAspectRatio == 1.77) {
          setPhone2Ratio(Phone2);
        } else if (newAspectRatio == 2.16) {
          setPhone3Ratio(Phone3);
        } else {
          setCustomIconRatio(CustomizeIcon);
        }
      }
    } else {
      newIsPortrait = true;
      props.setIsPortrait(newIsPortrait);
      //setTabletRatio(o133);
      if (newAspectRatio == 1.33) {
        props.setPortrait(newIsPortrait);
        setTabletRatio(Tablet);
        setPhone3Ratio(o216);
        setPhone2Ratio(o177);
        setCustomIconRatio(oCustom);
      } else if (newAspectRatio == 1.77) {
        props.setPortrait(newIsPortrait);
        setTabletRatio(o133);
        setPhone2Ratio(Phone2);
        setPhone3Ratio(o216);
        setCustomIconRatio(oCustom);
      } else if (newAspectRatio == 2.16) {
        props.setPortrait(newIsPortrait);
        setTabletRatio(o133);
        setPhone2Ratio(o177);
        setPhone3Ratio(Phone3);
        setCustomIconRatio(oCustom);
      } else {
        props.setPortrait(newIsPortrait);
        setTabletRatio(o133);
        setPhone2Ratio(o177);
        setPhone3Ratio(o216);
        setCustomIconRatio(customIconRatio);
      }
      // setCustomIconRatio(oCustom)
    }
    props.setAspectRatio(newAspectRatio);
    //console.debug("new aspect ratio: " + newAspectRatio + ", new isPortrait:" + props.isPortrait);
    resize({ event, newAspectRatio, newIsPortrait });
  }

  function getCssTransformValue(aspectRatio) {
    if (!props.isPortrait && aspectRatio === props.aspectRatio) {
      return "rotate(90deg)";
    } else {
      return "rotate(0deg)";
    }
  }

  const toolbar = (
    <div>
      <img
        src={tabletRatio}
        title={"Click to change orientation"}
        style={{ transform: getCssTransformValue(1.33) }}
        onClick={(event) => {
          setAspectRatio(event, 1.33, true);
          ReactGA.event({
            category: "Preview",
            action: "Orientation Changed",
            label:
              "game: " +
              props.selectedGame.title +
              ", base: " +
              props.selectedBase.title +
              ", version: " +
              props.selectedVersion.title +
              ", newOrientation: " +
              1.33,
            dimension1: localStorage.getItem("user.id"),
          });
          ReactGA4.event({
            category: "Preview",
            action: "Orientation Changed",
            label:
              "game: " +
              props.selectedGame.title +
              ", base: " +
              props.selectedBase.title +
              ", version: " +
              props.selectedVersion.title +
              ", newOrientation: " +
              1.33,
            dimension1: localStorage.getItem("user.id"),
          });
        }}
        className={classes.orientationImage}
      />
      <img
        src={phone2Ratio}
        title={"Click to change orientation"}
        style={{ transform: getCssTransformValue(1.77) }}
        onClick={(event) => {
          setAspectRatio(event, 1.77, true);
          ReactGA.event({
            category: "Preview",
            action: "Orientation Changed",
            label:
              "game: " +
              props.selectedGame.title +
              ", base: " +
              props.selectedBase.title +
              ", version: " +
              props.selectedVersion.title +
              ", newOrientation: " +
              1.77,
            dimension1: localStorage.getItem("user.id"),
          });
          ReactGA4.event({
            category: "Preview",
            action: "Orientation Changed",
            label:
              "game: " +
              props.selectedGame.title +
              ", base: " +
              props.selectedBase.title +
              ", version: " +
              props.selectedVersion.title +
              ", newOrientation: " +
              1.77,
            dimension1: localStorage.getItem("user.id"),
          });
        }}
        className={classes.orientationImage}
      />
      <img
        src={phone3Ratio}
        title={"Click to change orientation"}
        style={{ transform: getCssTransformValue(2.16) }}
        onClick={(event) => {
          setAspectRatio(event, 2.16, true);
          ReactGA.event({
            category: "Preview",
            action: "Orientation Changed",
            label:
              "game: " +
              props.selectedGame.title +
              ", base: " +
              props.selectedBase.title +
              ", version: " +
              props.selectedVersion.title +
              ", newOrientation: " +
              2.16,
            dimension1: localStorage.getItem("user.id"),
          });
          ReactGA4.event({
            category: "Preview",
            action: "Orientation Changed",
            label:
              "game: " +
              props.selectedGame.title +
              ", base: " +
              props.selectedBase.title +
              ", version: " +
              props.selectedVersion.title +
              ", newOrientation: " +
              2.16,
            dimension1: localStorage.getItem("user.id"),
          });
        }}
        className={classes.orientationImage}
      />
      <img
        src={customIconRatio}
        title={"Click to change orientation"}
        style={{
          transform: getCssTransformValue(customHeight / customWidth),
          fontSize: "1.84rem",
          marginLeft: "0.3rem",
        }}
        onClick={() => {
          ReactGA.event({
            category: "Preview",
            action: "Custom Orientation Clicked",
            label:
              "game: " +
              props.selectedGame.title +
              ", base: " +
              props.selectedBase.title +
              ", version: " +
              props.selectedVersion.title,
            dimension1: localStorage.getItem("user.id"),
          });
          ReactGA4.event({
            category: "Preview",
            action: "Custom Orientation Clicked",
            label:
              "game: " +
              props.selectedGame.title +
              ", base: " +
              props.selectedBase.title +
              ", version: " +
              props.selectedVersion.title,
            dimension1: localStorage.getItem("user.id"),
          });
          setIsCustomDialogOpen(true);
        }}
      />
      <Dialog
        open={isCustomDialogOpen}
        onClose={() => {
          setIsCustomDialogOpen(false);
        }}
      >
        <div style={{ padding: "10px", textAlign: "center" }}>
          <DialogTitle>Custom Screen Size</DialogTitle>
          <Grid container spacing={3}>
            <Grid item xs={5} sm={5}>
              <TextField
                type="number"
                value={customWidth}
                inputProps={{ min: 0, style: { textAlign: "center" } }}
                onChange={(event) => {
                  setCustomWidth(event.target.value);
                }}
              ></TextField>
            </Grid>
            <Grid item xs={2} sm={2}>
              <Typography variant="h6" gutterBottom>
                x
              </Typography>
            </Grid>
            <Grid item xs={5} sm={5}>
              <TextField
                type="number"
                value={customHeight}
                inputProps={{ min: 0, style: { textAlign: "center" } }}
                onChange={(event) => {
                  setCustomHeight(event.target.value);
                }}
              ></TextField>
            </Grid>
          </Grid>
          <Button
            onClick={(event) => {
              setAspectRatio(event, Number(customHeight) / Number(customWidth), true);
              if (Number(customWidth) >= Number(customHeight)) {
                props.setPortrait(false);
              } else {
                props.setPortrait(true);
              }
              setCustomIconRatio(CustomizeIcon);
              ReactGA.event({
                category: "Preview",
                action: "Orientation Changed",
                label:
                  "game: " +
                  props.selectedGame.title +
                  ", base: " +
                  props.selectedBase.title +
                  ", version: " +
                  props.selectedVersion.title +
                  ", newOrientation: " +
                  customHeight / customWidth,
                dimension1: localStorage.getItem("user.id"),
              });
              ReactGA4.event({
                category: "Preview",
                action: "Orientation Changed",
                label:
                  "game: " +
                  props.selectedGame.title +
                  ", base: " +
                  props.selectedBase.title +
                  ", version: " +
                  props.selectedVersion.title +
                  ", newOrientation: " +
                  customHeight / customWidth,
                dimension1: localStorage.getItem("user.id"),
              });
              setIsCustomDialogOpen(false);
            }}
          >
            Save
          </Button>
        </div>
      </Dialog>
    </div>
  );
  return toolbar;
}
