import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CardContent, Card, Typography, IconButton, TextField } from "@material-ui/core";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@material-ui/lab";
import { Add, Remove } from "@material-ui/icons";
import _ from "lodash";
import SliderOrigin from "./sliderOrigin";
import SliderScale from "./sliderScale";
import EditorText from "./editorText";

const useStyles = makeStyles((theme) => ({
  card: { margin: "5px" },
}));

export default function cardItemsSelector(props) {
  const classes = useStyles();
  const [pauses, setPauses] = useState([]);

  const setValue = _.debounce((p) => {
    props.setSelectedVersionPathValue(p);
  }, 50);

  useEffect(() => {
    let pauseList;
    if (_.isNil(props.value)) {
      pauseList = [];
    } else {
      pauseList = props.value;
    }
    setPauses(pauseList);
  }, [_.get(props, "value")]);

  function addPause() {
    const defaultPause = {
      "pauseTime": 0.1,
      "hand": {
        "origin": {
          "x": 0.5,
          "y": 0.5,
        },
        "scale": {
          "portrait": 0.5,
          "landscape": 0.5,
        },
      },
    };
    const pauseList = _.clone(pauses);
    pauseList.push(defaultPause);
    setPauses(pauseList);
    props.setValue(pauseList);
  }

  function removePause(index) {
    const pauseList = _.clone(pauses);
    pauseList.splice(index, 1);
    setPauses(pauseList);
    props.setValue(pauseList);
  }

  return (
    props.isVisible && (
      <div>
        <Card className={props.classes.card} key={"pause-list-container"}>
          <CardContent>
            <div>
              <Typography className={classes.title} variant={"h6"}>
                {_.get(props, "label")}
              </Typography>

              <div style={{ marginRight: "1rem", marginLeft: "1rem" }}>
                <IconButton
                  color="secondary"
                  style={{ float: "right" }}
                  onClick={(event) => {
                    if (props.userPermissions["update"] === false) {
                      props.createSnackbar(
                        "You do not have permission to update, contact your administrator.",
                        "warning",
                        false
                      );
                      return;
                    }
                    event.preventDefault();
                    addPause();
                  }}
                >
                  <Add />
                </IconButton>
              </div>
              <Timeline position="alternate" style={{ width: "100%" }}>
                {_.isArray(pauses) &&
                  pauses.map((pause, index) => {
                    return (
                      <TimelineItem key={`timeline-${index}`}>
                        <TimelineOppositeContent color="text.secondary">
                          <div style={{ marginTop: "3.5rem" }}>
                            {props.isVisible && (
                              <TextField
                                key={`pause-time-${index}`}
                                className={props.classes.textField}
                                type="number"
                                inputProps={{
                                  max: props.duration ? parseFloat(props.duration) : undefined,
                                  min: "0.1",
                                  step: "0.1",
                                  lang: "en-US",
                                }}
                                label={""}
                                value={pause.pauseTime}
                                onChange={(event) => {
                                  if (props.userPermissions["update"] === false) {
                                    props.createSnackbar(
                                      "You do not have permission to update, contact your administrator.",
                                      "warning",
                                      false
                                    );
                                    return;
                                  }
                                  const duration = props.duration ? parseFloat(props.duration) : undefined;
                                  if (duration && event.target.value > duration) {
                                    props.createSnackbar(
                                      "Video duration is: " +
                                        duration +
                                        ". The pauses that more than duration will not included in the game.",
                                      "warning",
                                      false
                                    );
                                    return;
                                  }
                                  setValue({
                                    path: `gameConfig.components.video1.pauses[${index}].pauseTime`,
                                    value: parseFloat(event.target.value),
                                  });
                                }}
                              ></TextField>
                            )}
                          </div>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <Typography
                            component="p"
                            variant="subtitle1"
                            color="inherit"
                            paragraph
                            style={{ marginBottom: "0", marginTop: "0.5rem" }}
                          >
                            {`${index + 1}`}
                          </Typography>
                          <TimelineDot />
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                          <div style={{ marginTop: "4em" }}>
                            <SliderOrigin
                              key={`slider-origin-${index}`}
                              classes={props.classes}
                              value={_.get(pause, "hand.origin")}
                              setValue={(value) => {
                                setValue({
                                  path: `gameConfig.components.video1.pauses[${index}].hand.origin`,
                                  value,
                                });
                              }}
                              label={`Origin`}
                              userPermissions={props.userPermissions}
                              createSnackbar={props.createSnackbar}
                              isVisible={props.isVisible}
                            ></SliderOrigin>
                            <SliderScale
                              key={`slider-scale-${index}`}
                              classes={props.classes}
                              value={_.get(pause, "hand.scale")}
                              setValue={(value) => {
                                setValue({
                                  path: `gameConfig.components.video1.pauses[${index}].hand.scale`,
                                  value,
                                });
                              }}
                              label={"Scale"}
                              userPermissions={props.userPermissions}
                              createSnackbar={props.createSnackbar}
                              isVisible={props.isVisible}
                            ></SliderScale>
                          </div>
                        </TimelineContent>
                        <div style={{ "display": "flex", "justifyContent": "center", "alignItems": "center" }}>
                          <IconButton
                            color="secondary"
                            style={{ float: "right" }}
                            onClick={(event) => {
                              if (props.userPermissions["update"] === false) {
                                props.createSnackbar(
                                  "You do not have permission to update, contact your administrator.",
                                  "warning",
                                  false
                                );
                                return;
                              }
                              removePause(index);
                            }}
                          >
                            <Remove />
                          </IconButton>
                        </div>
                      </TimelineItem>
                    );
                  })}
              </Timeline>
            </div>
          </CardContent>
        </Card>
      </div>
    )
  );
}
