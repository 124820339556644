import React, { useEffect, useState } from "react";
import { TextField, Typography } from "@material-ui/core";
import _ from "lodash";

/**
 *
 * @param props
 * @param props.caption
 * @param props.value
 * @param props.classes
 * @param props.setValue
 * @param props.min
 * @param props.max
 * @returns {JSX.Element}
 */
export default function editorNumber(props) {
  let [value, setValue] = useState(_.get(props, "value", ""));
  useEffect(() => {
    setValue(_.get(props, "value", ""));
  }, [props.value]);

  function gettingMargin(numberMargin) {
    if (props.tooltip != undefined) {
      if (numberMargin) {
        return numberMargin.toString() + "px";
      }
    } else return (0).toString() + "px";
  }

  return (
    <>
      {" "}
      {props.isVisible && (
        <div className={props.classes.editorItem}>
          <Typography style={{ marginTop: gettingMargin(props.tooltipLength) }} id="discrete-slider" gutterBottom>
            {_.get(props, "label")}
          </Typography>
          <TextField
            onKeyDown={(event) => {
              event.stopPropagation();
            }}
            id={`${props.id}.textField`}
            className={props.classes.textField}
            label={props.caption}
            value={value}
            onChange={(event) => {
              if (props.userPermissions["update"] === false) {
                props.createSnackbar(
                  "You do not have permission to update, contact your administrator.",
                  "warning",
                  false
                );
                return;
              }

              // console.log("editor number", event.target.value);

              const pattern = /^\d{1,3}(\.\d{0,2})?$/;

              if (!pattern.test(event.target.value) && event.target.value !== "") {
                return;
              }

              const number = Number(event.target.value);
              let numberString = event.target.value;

              if (props.min > number) {
                numberString = props.min.toString();
              } else if (number > props.max) {
                numberString = props.max.toString();
              }

              setValue(numberString);
              props.setValue(numberString);
            }}
          ></TextField>
        </div>
      )}{" "}
    </>
  );
}
