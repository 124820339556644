import React from "react";
import { Dialog, DialogActions } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useClasses = makeStyles({});
/**
 *
 * @param {Boolean} props.isOpen is it open?
 * @param {Function} props.setIsOpen set is open to true or false
 * @returns {JSX.Element}
 */
export default function dialogCircularProgress(props) {
  const classes = useClasses();
  return (
    <Dialog
      BackdropProps={{ invisible: true }}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}
      disableEscapeKeyDown={true}
      disablePortal={true}
      disableBackdropClick={true}
      open={props.isOpen}
      onClose={() => {
        props.setIsOpen(false);
      }}
    >
      <DialogActions>
        <div id={"circularProgressDiv"}>
          <CircularProgress variant={"indeterminate"}></CircularProgress>
        </div>
      </DialogActions>
    </Dialog>
  );
}
