import React, { useState, useEffect } from "react";
import { Card, CardContent, Typography, Slider } from "@material-ui/core";
import EditorCaption from "./editorCaption";
import SelectorFontFamily from "./selectorFontFamily";
import SelectorFontStyle from "./selectorFontStyle";
import SelectorColor from "./selectorColor";
import { makeStyles } from "@material-ui/core/styles";
import SliderGeneric from "./sliderGeneric";

const useStyles = makeStyles((theme) => ({}));

/**
 *
 * @param props.gameObjectName {String}
 * @param selectedLanguage {String}
 * @param props.classes {Array}
 * @param props.setPathValue {Function}
 * @param props.label {String}
 * @param props.gameObjectDetail
 * @returns {JSX.Element}
 */
export default function editorTextGameObject(props) {
  const selectedLanguage = _.get(props, `selectedVersion.gameConfig.options.language`, `en`);
  return (
    <div id={"editorTextGameObjectDiv"}>
      <EditorCaption
        classes={props.classes}
        value={_.get(props, "gameObjectDetail.caption." + selectedLanguage)}
        setValue={(value) => {
          props.setPathValue({ path: `caption.${selectedLanguage}`, value });
        }}
      ></EditorCaption>
      <SelectorFontFamily
        classes={props.classes}
        value={_.get(props, "gameObjectDetail.fontFamily", null)}
        setValue={(value) => {
          props.setPathValue({ path: `fontFamily`, value });
        }}
        selectedVersion={props.selectedVersion}
      ></SelectorFontFamily>
      <SliderGeneric
        classes={props.classes}
        value={props.gameObjectDetail.fontSize}
        label={`Font Size`}
        min={0.01}
        max={1}
        step={0.01}
        setValue={(value) => {
          props.setPathValue({ path: `fontSize`, value });
        }}
      ></SliderGeneric>
      <SelectorFontStyle
        classes={props.classes}
        value={_.get(props, "gameObjectDetail.fontStyle", null)}
        setValue={(value) => {
          props.setPathValue({ path: `fontStyle`, value });
        }}
      ></SelectorFontStyle>
      <SelectorColor
        classes={props.classes}
        value={_.get(props, "gameObjectDetail.fontColor", "#ffffff")}
        setValue={(value) => {
          props.setPathValue({ path: `fontColor`, value });
        }}
        label={"Font Color"}
        mode={"String"}
      ></SelectorColor>
      <SelectorColor
        classes={props.classes}
        value={_.get(props, "gameObjectDetail.strokeColor", "#ffffff")}
        setValue={(value) => {
          props.setPathValue({ path: `strokeColor`, value });
        }}
        label={"Stroke Color"}
        mode={"String"}
      ></SelectorColor>
      <SliderGeneric
        label={`Stroke Thickness`}
        classes={props.classes}
        step={1}
        marks
        min={0}
        max={10}
        value={_.get(props, "gameObjectDetail.strokeThickness", 0)}
        setValue={(value) => {
          props.setPathValue({ path: `strokeThickness`, value });
        }}
      ></SliderGeneric>
    </div>
  );
}
