import React, { useEffect, useRef, useState } from "react";
import { makeStyles, MenuItem, TextField } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  formControl: {
    margin: theme.spacing(1),
    width: 300,
  },
  indeterminateColor: {
    color: "#50ADB6",
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: "rgba(255, 255, 255, 0.16)",
  },
  menuItem: {
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.08) !important",
    },
  },
  textField: {
    marginTop: "-30px",
  },
}));

export default function selectorGeneric(props) {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        paddingLeft: "16px",
      },
    },
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center",
    },
    variant: "menu",
  };

  if (_.isNil(props.value)) {
    return null;
  }

  let [value, setValue] = useState(props.value);
  const isAllSelected = useRef(false);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  function gettingMargin(numberMargin) {
    if (props.tooltip != undefined) {
      if (numberMargin) {
        return numberMargin.toString() + "px";
      }
    } else return (-24).toString() + "px";
  }
  useEffect(() => {
    checkMargin();
  }, [props.isVisible]);

  function checkMargin() {
    try {
      if (props.tooltip) {
        document.getElementById(`${props.id}.textField-label`).style.marginTop = "-48.5px";
      }
    } catch {
      console.log("checkMargin error");
    }
  }
  const handleChange = (event) => {
    if (props.userPermissions["update"] === false) {
      props.createSnackbar("You do not have permission to update, contact your administrator.", "warning", false);
      return;
    }
    const selectedItems = event.target.value;
    let selectedItemsList = [];

    if (selectedItems[selectedItems.length - 1] === "all") {
      selectedItemsList = selectedItems.length === props.items.length ? [] : props.items;
      setValue(selectedItemsList);
      props.setValue(selectedItemsList);
      return;
    }

    setValue(value);
    props.setValue(value);
  };

  const renderMultiSelect = () => (
    <FormControl className={classes.formControl}>
      <InputLabel id="mutiple-select">{_.get(props, `label`)}</InputLabel>
      <Select
        labelId="mutiple-select"
        multiple
        value={value}
        onChange={handleChange}
        renderValue={(selected) => (
          <div className={classes.chips}>
            {selected.map((value) => (
              <Chip key={value} label={value} className={classes.chip} />
            ))}
          </div>
        )}
        MenuProps={MenuProps}
      >
        <MenuItem
          value="all"
          classes={{
            root: isAllSelected.current ? classes.selectedAll : "",
          }}
        >
          <ListItemIcon>
            <Checkbox checked={isAllSelected.current} />
          </ListItemIcon>
          <ListItemText classes={{ primary: classes.selectAllText }} primary="Select All" />
        </MenuItem>
        {props.items.map((item, index) => (
          <MenuItem key={index} value={item} classes={{ root: classes.menuItem }}>
            <ListItemIcon>
              <Checkbox checked={value.indexOf(item) > -1} />
            </ListItemIcon>
            <ListItemText primary={item} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  const renderSelect = () => (
    <>
      <TextField
        select
        id={`${props.id}.textField`}
        InputLabelProps={{ shrink: false, style: { marginTop: gettingMargin(props.tooltipLength) } }}
        label={_.get(props, `label`)}
        value={value}
        className={props.classes.textField}
        onChange={(event) => {
          if (props.userPermissions["update"] === false) {
            props.createSnackbar("You do not have permission to update, contact your administrator.", "warning", false);
            return;
          }
          setValue(event.target.value);
          props.setValue(event.target.value);
        }}
      >
        {props.items.map((item, index) => {
          return (
            <MenuItem key={index} value={item}>
              {item}
            </MenuItem>
          );
        })}
      </TextField>
      {checkMargin()}
    </>
  );

  return (
    <>
      {" "}
      {props.isVisible && (
        <div className={props.classes.editorItem}>{props.isMulti ? renderMultiSelect() : renderSelect()}</div>
      )}
    </>
  );
}
